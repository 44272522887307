<app-alert
  *ngIf="error"
  additionalClass=" mb-4 md:mb-6 xl:mb-8"
  type="warning"
  imageSmallSrc="exclamation-triangle"
  imageSmallAlt="exclamation-triangle icon"
  title="Error"
  message="{{ error }}"
></app-alert>
<div *ngIf="plan">
  <app-card-with-image
    title="Your deposit was unsuccessful"
    imageSrc="unsuccessful"
    imageAlt="unsuccessful"
    [customClass]="'mb-4 md:mb-6 xl-mb-8 h-[calc(100vh-150px)]'"
    [titleStyle]="'text-primary-sf-navy dark:text-sf-white my-4'"
    [isButtonOff]="false"
    [button_text]="'Return to home'"
    [routerLink]="url.HOME"
    [button_fullWidth]="false"
    [button_small]="false"
  >
    <p>
      Your deposit into
      <span class="font-bold text-primary-sf-navy dark:text-sf-white">
        {{ plan.formattedPlanNumber }}
      </span>
      has unfortunately been unsuccessful, and no payment has been taken from your debit or credit
      card. Please see below for more information for why a payment was not taken.
    </p>
    <p>
      If you'd like help with this, please call our Member Services team on
      <a
        href="tel:0800526249"
        class="font-bold text-decoration-none text-primary-sf-navy dark:text-sf-white"
      >
        0800 526 249
      </a>
      .
    </p>
  </app-card-with-image>
</div>
