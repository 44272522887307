<app-content-header [title]="'Child Savings FAQs'"></app-content-header>

<div class="help-savings">
  <h2 class="mb-4">Opening a Plan</h2>

  <div id="accordionHelpOne">
    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseOne')"
            [ngClass]="getButtonIcon('collapseOne')"
          >
            <span class="flex-1">Can anyone open a plan for a child?</span>
            <span class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseOne"
        data-toggle-id="collapseOne"
        [hidden]="!isToggle('collapseOne')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          Only a parent or guardian of a child can open a
          <a
            class="underline text-primary-sf-navy dark:text-secondary-sf-blue-light"
            href="https://www.shepherdsfriendly.co.uk/junior-isa/"
          >
            Junior ISA
          </a>
          for them.
        </div>
      </div>
    </div>
    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            style="text-align: left"
            (click)="toggle('collapseTwo')"
            [ngClass]="getButtonIcon('collapseTwo')"
          >
            <span class="flex-1">
              Can my child have a Junior ISA if they already have Child Trust Fund?
            </span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseTwo"
        data-toggle-id="collapseTwo"
        [hidden]="!isToggle('collapseTwo')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          If your child already has a Child Trust Fund you can switch this to our Junior ISA by
          contacting our Customer Services team, however you cannot open a new Junior ISA for a
          child who still has a CTF in place.
        </div>
      </div>
    </div>
    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseFour')"
            [ngClass]="getButtonIcon('collapseFour')"
          >
            <span class="flex-1">Can I open more than one savings plan for my child?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseFour"
        data-toggle-id="collapseFour"
        [hidden]="!isToggle('collapseFour')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          You are not limited to one savings plan for your child, however, you can only have one
          Stocks and Shares Junior ISA, to which you can contribute £9,000 each tax year. You can
          open one plan per child.
        </div>
      </div>
    </div>
    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseFive')"
            [ngClass]="getButtonIcon('collapseFive')"
          >
            <span class="flex-1">
              When will my child receive an ‘ISA boost’ in their Junior ISA?
            </span>
            <span class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseFive"
        data-toggle-id="collapseFive"
        [hidden]="!isToggle('collapseFive')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          The ‘ISA Boost’ will be paid within 30 days of the first monthly deposit being received
          into your child’s Junior ISA. Please note that this is dependent on the terms and
          conditions of the offer, including that it cannot be used in conjunction with any other
          offer.
        </div>
      </div>
    </div>
  </div>

  <br />
  <h2 class="mb-4">Information about your plan</h2>
  <div class="accordion" id="accordionHelpTwo">
    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseSix')"
            [ngClass]="getButtonIcon('collapseSix')"
          >
            <span class="flex-1">Will my child’s savings be taxed?</span>
            <span class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseSix"
        data-toggle-id="collapseSix"
        [hidden]="!isToggle('collapseSix')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          There will be no tax applicable to the final pay out of any money invested in any of our
          children’s savings plans. The growth of the plan will also be tax-efficient.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseSeven')"
            [ngClass]="getButtonIcon('collapseSeven')"
          >
            <span class="flex-1">Who can pay into a Junior ISA?</span>
            <span class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseSeven"
        data-toggle-id="collapseSeven"
        [hidden]="!isToggle('collapseSeven')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          We’ll accept contributions from anyone who wishes, up to the annual allowance. The plan
          must be opened by a parent or guardian, though.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseEight')"
            [ngClass]="getButtonIcon('collapseEight')"
          >
            <span class="flex-1">How much can I save in a Junior ISA?</span>
            <span class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseEight"
        data-toggle-id="collapseEight"
        [hidden]="!isToggle('collapseEight')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          The Junior ISA annual allowance is set by HM Government, and it currently stands at £9,000
          (correct as of the 2024/25 tax year).
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseNine')"
            [ngClass]="getButtonIcon('collapseNine')"
          >
            <span class="flex-1">How can I pay into each plan?</span>
            <span class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseNine"
        data-toggle-id="collapseNine"
        [hidden]="!isToggle('collapseNine')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          The Junior ISA allows you to contribute to your child’s savings with one-off lump sum
          payments at a minimum of £100, monthly Direct Debits starting at £10 a month, or a mixture
          of both. The maximum that you can contribute to a Junior ISA is set by HM Government and
          currently stands at £9,000.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseTen')"
            [ngClass]="getButtonIcon('collapseTen')"
          >
            <span class="flex-1">How is my child’s money invested?</span>
            <span class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseTen"
        data-toggle-id="collapseTen"
        [hidden]="!isToggle('collapseTen')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          We invest your child’s money in our With-Profits Fund, a medium to low-risk fund invested
          primarily in stocks and shares with the aim of achieving higher growth over the long-term.
          However, growth is not guaranteed and depends on the performance of the plan.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseEleven')"
            [ngClass]="getButtonIcon('collapseEleven')"
          >
            <span class="flex-1">Is there an interest rate?</span>
            <span class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseEleven"
        data-toggle-id="collapseEleven"
        [hidden]="!isToggle('collapseEleven')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          We aim to pay an annual bonus into your child’s plan, which will reflect the performance
          of the With Profits fund over the previous year.
          <br />
          <br />
          Please remember that past performance is not a guide to how the plan could perform in the
          future, and although we can never take bonuses away once they have been paid, we may not
          pay one in years when performance is particularly poor.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseTwelve')"
            [ngClass]="getButtonIcon('collapseTwelve')"
          >
            <span class="flex-1">
              What if my child has a University Savings plan but doesn’t want to go to university?
            </span>
            <span class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseTwelve"
        data-toggle-id="collapseTwelve"
        [hidden]="!isToggle('collapseTwelve')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          If you open a plan for a child and they later decide not to pursue higher education, they
          will of course still receive the full tax-free lump sum when they reach age 18, or after
          10 years, whichever is later. They can then use this as they wish for a housing deposit, a
          first car or help them with the first step of their career.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseThirteen')"
            [ngClass]="getButtonIcon('collapseThirteen')"
          >
            <span class="flex-1">
              I have a University Savings Plan – what if my child wants to take a gap year before
              going to university?
            </span>
            <span class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseThirteen"
        data-toggle-id="collapseThirteen"
        [hidden]="!isToggle('collapseThirteen')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          You can choose whether to give your child the full maturity amount as soon as they reach
          18 or pay them a set amount annually for three years until they reach age 21. In the event
          of a gap year they can either receive the money before they go to university, or once they
          start.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseFourteen')"
            [ngClass]="getButtonIcon('collapseFourteen')"
          >
            <span class="flex-1">Is the money I invest with Shepherds Friendly protected?</span>
            <span class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseFourteen"
        data-toggle-id="collapseFourteen"
        [hidden]="!isToggle('collapseFourteen')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          Yes. Any money invested with Shepherds Friendly Society is covered by the Financial
          Services Compensation Scheme. The maximum level of compensation is currently 100% of the
          claim with no upper limit (correct at time of publishing – April 2017). The actual level
          of compensation you receive will depend on the basis of your claim.
          <br />
          <br />
          Find out more information about the
          <a
            class="underline text-primary-sf-navy dark:text-secondary-sf-blue-light"
            href="https://www.fscs.org.uk/"
          >
            Financial Services Compensation Scheme.
          </a>
        </div>
      </div>
    </div>

    <br />
    <h2 class="mb-4">Managing your plan</h2>
    <div class="accordion" id="accordionHelpThree">
      <div class="sf-card flex flex-col justify-center md:p-6">
        <div class="mb-0">
          <h2 class="mb-0">
            <button
              class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
              type="button"
              (click)="toggle('collapseSeventeen')"
              [ngClass]="getButtonIcon('collapseSeventeen')"
            >
              <span class="flex-1">How can I find out what the value of the plan is?</span>
              <span
                class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"
              ></span>
            </button>
          </h2>
        </div>
        <div
          id="collapseSeventeen"
          data-toggle-id="collapseSeventeen"
          [hidden]="!isToggle('collapseSeventeen')"
          [ngClass]="{ visible: isToggle }"
        >
          <div>
            There are two ways you can find out what your up to date value is:
            <br />
            <br />
            1) You can call our Customer Services team on 0800 526 249 and they will be happy to
            answer any questions you may have about your plan
            <br />
            <br />
            2) In April of each year you will receive an Annual Bonus Statement, which outlines the
            activity on your plan and indicates the total amount that has been saved to date.
          </div>
        </div>
      </div>

      <div class="sf-card flex flex-col justify-center md:p-6">
        <div class="mb-0">
          <h2 class="mb-0">
            <button
              class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
              type="button"
              (click)="toggle('collapseEighteen')"
              [ngClass]="getButtonIcon('collapseEighteen')"
            >
              <span class="flex-1">Can I manage the account online?</span>
              <span
                class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"
              ></span>
            </button>
          </h2>
        </div>
        <div
          id="collapseEighteen"
          data-toggle-id="collapseEighteen"
          [hidden]="!isToggle('collapseEighteen')"
          [ngClass]="{ visible: isToggle }"
        >
          <div>
            Currently we’re unable to offer online account management, but it’s something we’ll be
            introducing soon.
          </div>
        </div>
      </div>

      <div class="sf-card flex flex-col justify-center md:p-6">
        <div class="mb-0">
          <h2 class="mb-0">
            <button
              class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
              type="button"
              (click)="toggle('collapseNineteen')"
              [ngClass]="getButtonIcon('collapseNineteen')"
            >
              <span class="flex-1">Can I change how much I pay into the plan?</span>
              <span
                class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"
              ></span>
            </button>
          </h2>
        </div>
        <div
          id="collapseNineteen"
          data-toggle-id="collapseNineteen"
          [hidden]="!isToggle('collapseNineteen')"
          [ngClass]="{ visible: isToggle }"
        >
          <div>
            You can change how much you contribute to your child’s Junior ISA, provided you stay
            within the maximum limits. As well as being able to start, stop, raise and lower your
            premiums, with our Junior ISA you can also add in one-off lump sums if you want to.
          </div>
        </div>
      </div>

      <div class="sf-card flex flex-col justify-center md:p-6">
        <div class="mb-0">
          <h2 class="mb-0">
            <button
              class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
              type="button"
              (click)="toggle('collapseTwenty')"
              [ngClass]="getButtonIcon('collapseTwenty')"
            >
              <span class="flex-1">How can I add money to a Junior ISA?</span>
              <span
                class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"
              ></span>
            </button>
          </h2>
        </div>
        <div
          id="collapseTwenty"
          data-toggle-id="collapseTwenty"
          [hidden]="!isToggle('collapseTwenty')"
          [ngClass]="{ visible: isToggle }"
        >
          <div>
            You can add one-off payments to your child’s account when you like. You can do this by
            sending an online request, or by contacting our Customer Services department on 0800 526
            249.
          </div>
        </div>
      </div>

      <div class="sf-card flex flex-col justify-center md:p-6">
        <div class="mb-0">
          <h2 class="mb-0">
            <button
              class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
              type="button"
              (click)="toggle('collapseTwentyOne')"
              [ngClass]="getButtonIcon('collapseTwentyOne')"
            >
              <span class="flex-1">
                Once the plan has come to an end, when will the child receive the money that has
                been saved?
              </span>
              <span
                class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"
              ></span>
            </button>
          </h2>
        </div>
        <div
          id="collapseTwentyOne"
          data-toggle-id="collapseTwentyOne"
          [hidden]="!isToggle('collapseTwentyOne')"
          [ngClass]="{ visible: isToggle }"
        >
          <div>
            As long as your plan is up to date, we will typically pay out the money that you have
            invested 5-10 working days after we have all the necessary documentation to make the
            payment, such as your child’s bank account details. We will also contact you regarding
            this, before your child’s 18th birthday.
          </div>
        </div>
      </div>

      <div class="sf-card flex flex-col justify-center md:p-6">
        <div class="mb-0">
          <h2 class="mb-0">
            <button
              class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
              type="button"
              (click)="toggle('collapseTwentyTwo')"
              [ngClass]="getButtonIcon('collapseTwentyTwo')"
            >
              <span class="flex-1">
                Do I need to do anything to instruct Shepherds Friendly about payment upon maturity?
              </span>
              <span
                class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"
              ></span>
            </button>
          </h2>
        </div>
        <div
          id="collapseTwentyTwo"
          data-toggle-id="collapseTwentyTwo"
          [hidden]="!isToggle('collapseTwentyTwo')"
          [ngClass]="{ visible: isToggle }"
        >
          <div>We’ll contact you to discuss payment options prior to the maturity of the plan.</div>
        </div>
      </div>

      <div class="sf-card flex flex-col justify-center md:p-6">
        <div class="mb-0">
          <h2 class="mb-0">
            <button
              class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
              type="button"
              (click)="toggle('collapseTwentyThree')"
              [ngClass]="getButtonIcon('collapseTwentyThree')"
            >
              <span class="flex-1">
                How will I receive the money invested once the plan matures?
              </span>
              <span
                class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"
              ></span>
            </button>
          </h2>
        </div>
        <div
          id="collapseTwentyThree"
          data-toggle-id="collapseTwentyThree"
          [hidden]="!isToggle('collapseTwentyThree')"
          [ngClass]="{ visible: isToggle }"
        >
          <div>
            Money invested with Shepherds Friendly is paid to the child for whom you have been
            saving. Money can be transferred by BACS directly into a bank account or can be sent to
            the child as a cheque.
          </div>
        </div>
      </div>

      <div class="sf-card flex flex-col justify-center md:p-6">
        <div class="mb-0">
          <h2 class="mb-0">
            <button
              class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
              type="button"
              (click)="toggle('collapseTwentyFour')"
              [ngClass]="getButtonIcon('collapseTwentyFour')"
            >
              <span class="flex-1">What if my child wants to keep saving?</span>
              <span
                class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"
              ></span>
            </button>
          </h2>
        </div>
        <div
          id="collapseTwentyFour"
          data-toggle-id="collapseTwentyFour"
          [hidden]="!isToggle('collapseTwentyFour')"
          [ngClass]="{ visible: isToggle }"
        >
          <div>
            If they have a Junior ISA then you can request for the money to be transferred into our
            adult Stocks and Shares ISA. There is no charge for this, and they will then be able to
            set up payments into the account to suit them.
          </div>
        </div>
      </div>

      <div class="sf-card flex flex-col justify-center md:p-6">
        <div class="mb-0">
          <h2 class="mb-0">
            <button
              class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
              type="button"
              (click)="toggle('collapseTwentyFive')"
              [ngClass]="getButtonIcon('collapseTwentyFive')"
            >
              <span class="flex-1">
                The plan is a surprise 18th birthday gift; can the money be paid to me?
              </span>
              <span
                class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"
              ></span>
            </button>
          </h2>
        </div>
        <div
          id="collapseTwentyFive"
          data-toggle-id="collapseTwentyFive"
          [hidden]="!isToggle('collapseTwentyFive')"
          [ngClass]="{ visible: isToggle }"
        >
          <div>
            No. The money can only be paid out to the child. However we can send a cheque, which
            will be made payable to the child for whom you have been saving. We will however need
            some notice of this is what you would prefer.
          </div>
        </div>
      </div>

      <div class="sf-card flex flex-col justify-center md:p-6">
        <div class="mb-0">
          <h2 class="mb-0">
            <button
              class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
              type="button"
              (click)="toggle('collapseTwentySix')"
              [ngClass]="getButtonIcon('collapseTwentySix')"
            >
              <span class="flex-1">How can I withdraw money from the plan?</span>
              <span
                class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"
              ></span>
            </button>
          </h2>
        </div>
        <div
          id="collapseTwentySix"
          data-toggle-id="collapseTwentySix"
          [hidden]="!isToggle('collapseTwentySix')"
          [ngClass]="{ visible: isToggle }"
        >
          <div>
            The money you have invested will be held until the child who you are saving for turns a
            minimum of 18 years old. There are some exceptional circumstances and these can all be
            found in the Terms & Conditions included with the plan document.
          </div>
        </div>
      </div>

      <div class="sf-card flex flex-col justify-center md:p-6">
        <div class="mb-0">
          <h2 class="mb-0">
            <button
              class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
              type="button"
              (click)="toggle('collapseTwentySeven')"
              [ngClass]="getButtonIcon('collapseTwentySeven')"
            >
              <span class="flex-1">
                How easy is it to change my Direct Debit or my bank details?
              </span>
              <span
                class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"
              ></span>
            </button>
          </h2>
        </div>
        <div
          id="collapseTwentySeven"
          data-toggle-id="collapseTwentySeven"
          [hidden]="!isToggle('collapseTwentySeven')"
          [ngClass]="{ visible: isToggle }"
        >
          <div>
            It’s quite straightforward. All you need to do is call our Customer Services team on
            0800 526 249 or email
            <a
              class="underline text-primary-sf-navy dark:text-secondary-sf-blue-light"
              href="mailto:members@shepherdsfriendly.co.uk"
            >
              members&#64;shepherdsfriendly.co.uk
            </a>
            and we’ll do the rest.
          </div>
        </div>
      </div>

      <div class="sf-card flex flex-col justify-center md:p-6">
        <div class="mb-0">
          <h2 class="mb-0">
            <button
              class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
              type="button"
              (click)="toggle('collapseTwentyEight')"
              [ngClass]="getButtonIcon('collapseTwentyEight')"
            >
              <span class="flex-1">What if I can’t maintain payments into the plan?</span>
              <span
                class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"
              ></span>
            </button>
          </h2>
        </div>
        <div
          id="collapseTwentyEight"
          data-toggle-id="collapseTwentyEight"
          [hidden]="!isToggle('collapseTwentyEight')"
          [ngClass]="{ visible: isToggle }"
        >
          <div>
            For the Junior ISA there is no regular payment required, so you can choose to reduce or
            cancel your monthly payment.
          </div>
        </div>
      </div>

      <div class="sf-card flex flex-col justify-center md:p-6">
        <div class="mb-0">
          <h2 class="mb-0">
            <button
              class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
              type="button"
              (click)="toggle('collapseTwentyNine')"
              [ngClass]="getButtonIcon('collapseTwentyNine')"
            >
              <span class="flex-1">
                How do I inform Shepherds Friendly of change of my address?
              </span>
              <span
                class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"
              ></span>
            </button>
          </h2>
        </div>
        <div
          id="collapseTwentyNine"
          data-toggle-id="collapseTwentyNine"
          [hidden]="!isToggle('collapseTwentyNine')"
          [ngClass]="{ visible: isToggle }"
        >
          <div>
            We accept changes of home address over the phone from the plan holder, or the registered
            contact of a Child Trust Fund or Junior ISA.
          </div>
        </div>
      </div>

      <div class="sf-card flex flex-col justify-center md:p-6">
        <div class="mb-0">
          <h2 class="mb-0">
            <button
              class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
              type="button"
              (click)="toggle('collapseThirty')"
              [ngClass]="getButtonIcon('collapseThirty')"
            >
              <span class="flex-1">Is there a charge for managing the plans?</span>
              <span
                class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"
              ></span>
            </button>
          </h2>
        </div>
        <div
          id="collapseThirty"
          data-toggle-id="collapseThirty"
          [hidden]="!isToggle('collapseThirty')"
          [ngClass]="{ visible: isToggle }"
        >
          <div>
            There is an annual management charge of 1.5% which is deducted from the investment
            growth prior to bonuses being declared. This covers our expenses, as well as the cost of
            administering and managing the investment.
          </div>
        </div>
      </div>

      <div class="sf-card flex flex-col justify-center md:p-6">
        <div class="mb-0">
          <h2 class="mb-0">
            <button
              class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
              type="button"
              (click)="toggle('collapseThirtyOne')"
              [ngClass]="getButtonIcon('collapseThirtyOne')"
            >
              <span class="flex-1">How do I cancel my plan?</span>
              <span
                class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"
              ></span>
            </button>
          </h2>
        </div>
        <div
          id="collapseThirtyOne"
          data-toggle-id="collapseThirtyOne"
          [hidden]="!isToggle('collapseThirtyOne')"
          [ngClass]="{ visible: isToggle }"
        >
          <div>
            <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
              Junior ISA
            </h4>
            You have 30 days from the date your open your account in which to cancel the Junior ISA
            and your money will be returned. If you cancel within the 30 days you will be treated as
            though you had not used the child’s Junior ISA allowance and would be able to subscribe
            to another Junior ISA in the current tax year.
            <br />
            <br />
            After the 30 days, the Plan becomes a valid Stocks and Shares Junior ISA and cannot be
            cancelled. If payments are stopped, the Junior ISA would be left dormant until the child
            reaches the age of 18 or it is transferred to another Provider. The amount subscribed is
            a gift to the child and cannot be repaid if you change your mind after 30 days.
            <br />
            <br />
            To cancel within 30 days of opening your plan please email our Member Services Team on
            <a
              class="underline text-primary-sf-navy dark:text-secondary-sf-blue-light"
              href="mailto:members@shepherdsfriendly.co.uk"
            >
              members&#64;shepherdsfriendly.co.uk
            </a>
            with your full name and plan number.
            <br />
            <br />
            <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
              Junior Money Maker
            </h4>
            If you want or need to stop paying your premiums, you should contact us as quickly as
            possible to discuss what options are available to you.
            <br />
            <br />
            After three successive missed premiums no sickness benefit can be claimed and we will no
            longer accept payments into the plan.
            <br />
            <br />
            Any money invested is done so on behalf of the child therefore if the plan is
            surrendered the proceeds will be made payable to the child.
            <br />
            <br />
            To cancel your plan or ask for your surrender value please email our Member Services
            Team on
            <a
              class="underline text-primary-sf-navy dark:text-secondary-sf-blue-light"
              href="mailto:members@shepherdsfriendly.co.uk"
            >
              members&#64;shepherdsfriendly.co.uk
            </a>
            with your full name and plan number. Please read the Junior Money Maker Key Information
            Document for more information.
            <br />
            <br />
            Please note: If you stop paying premiums during the early years, the value of your
            Junior Money Maker could be less than you have paid in.
            <br />
            <br />
            <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
              Young Saver Plan
            </h4>
            If you want or need to stop paying premiums, you should contact us as quickly as
            possible to discuss what options are available to you.
            <br />
            <br />
            After three successive missed premiums no sickness benefit can be claimed and we will no
            longer accept payments into the plan.
            <br />
            <br />
            Any money invested is done so on behalf of the child therefore if the plan is
            surrendered the proceeds will be made payable to the child.
            <br />
            <br />
            You are able to withdraw up to 25% of the value of the fund when the child reaches age
            11 to help with the costs of sending them to school, but any other money will remain
            invested until the plan matures.
            <br />
            <br />
            To cancel your plan or ask for your surrender value please email our Member Services
            Team on
            <a
              class="underline text-primary-sf-navy dark:text-secondary-sf-blue-light"
              href="mailto:members@shepherdsfriendly.co.uk"
            >
              members&#64;shepherdsfriendly.co.uk
            </a>
            with your full name and plan number. Please read the Young Saver Plan Key Information
            Document for more information.
            <br />
            <br />
            Please note: If you stop paying premiums during the early years, the value of your Young
            Saver Plan could be less than you have paid in.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
