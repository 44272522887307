<app-content-header
  [title]="'Sorry.'"
  [isButtonOff]="false"
  [button_text]="'Log out'"
  [button_style]="'white'"
  [button_iconColor]="'text-primary-sf-yellow'"
  (buttonClick)="logout()"
/>
<h2>There seems to be a problem with your dashboard.</h2>
<br />
<section>
  <app-need-help></app-need-help>
</section>
