<section class="mb-4">
  <div *ngIf="!addressComplete">
    <div class="g-4 mb-4 align-items-baseline" *ngIf="!enterManually">
      <input
        type="text"
        class="form-control uppercase placeholder:normal-case mb-4"
        id="searchPostCode"
        placeholder="Enter postcode"
        [(ngModel)]="searchPostCode"
        [ngClass]="{
          'is-invalid': invalidPostCode,
        }"
      />
      <app-validation-message
        *ngIf="invalidPostCode"
        class="mb-4"
        [message]="'Please enter a valid post code'"
      ></app-validation-message>
      <app-button
        text="Search"
        buttonStyle="primary py-2.5 px-[15px]"
        [noBorder]="true"
        [fullWidth]="true"
        customClass="py-[11.5px]"
        [disabled]="!searchPostCode || !searchPostCode.length"
        (click)="searchAddress()"
      ></app-button>
    </div>

    <div *ngIf="addressOptions.length > 0 && !invalidPostCode && !enterManually">
      <select
        name="addressOptions"
        id="addressOptions"
        class="form-select mt-2 mb-4"
        (change)="selectAddress($event)"
      >
        <option disabled selected>Please select an address</option>
        <option *ngFor="let addressOption of addressOptions" [attr.value]="addressOption.Id">
          {{ addressOption.StreetAddress }}
        </option>
        <option value="notFound">Can't see your address? Please enter manually</option>
      </select>
    </div>

    <app-button
      *ngIf="addressForm.invalid && !enterManually"
      [noBorder]="true"
      text="Or enter address manually"
      buttonStyle="secondary"
      (click)="toggleEnterManually()"
    ></app-button>

    <div *ngIf="enterManually">
      <form #form="ngForm" [formGroup]="addressForm">
        <div class="mb-4">
          <div>
            <div class="mb-2">
              <label for="line1">Address line one</label>
              <input
                id="line1"
                name="line1"
                formControlName="line1"
                type="text"
                class="form-control"
                [ngClass]="{
                  'is-invalid': lineOneCtrl.touched && lineOneCtrl.errors,
                }"
              />
            </div>
            <div class="mb-2">
              <label for="line2" class="">Address line two</label>
              <input
                id="line2"
                name="line2"
                formControlName="line2"
                type="text"
                class="form-control"
                [ngClass]="{
                  'is-invalid': lineTwoCtrl.touched && lineTwoCtrl.errors,
                }"
              />
            </div>
            <div class="mb-2">
              <label for="county" class="">County</label>
              <input
                id="county"
                name="county"
                formControlName="county"
                type="text"
                class="form-control"
                [ngClass]="{
                  'is-invalid': countyCtrl.touched && countyCtrl.errors,
                }"
              />
            </div>
            <div class="mb-2">
              <label for="city">City</label>
              <input
                id="city"
                name="city"
                formControlName="city"
                type="text"
                class="form-control"
                [ngClass]="{
                  'is-invalid': cityCtrl.touched && cityCtrl.errors,
                }"
              />
            </div>
            <div class="mb-4">
              <label for="postcode">Postcode</label>
              <input
                id="postcode"
                name="postcode"
                formControlName="postcode"
                type="text"
                class="form-control uppercase placeholder:normal-case"
                [ngClass]="{
                  'is-invalid': postCodeCtrl.touched && postCodeCtrl.errors,
                }"
              />
            </div>
            <app-button
              *ngIf="enterManually"
              [disabled]="addressForm.invalid"
              text="Save"
              buttonStyle="secondary"
              [noBorder]="true"
              (click)="emitSelectedAddress()"
            ></app-button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="addressComplete">
    {{ formattedAddress }}
    <p>
      <a (click)="handleOnClickAmend()" class="text-secondary-sf-blue-light">Amend address</a>
    </p>
  </div>
</section>
