import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, LoadingService, ProfileService } from '@app/services';
import { take, catchError, EMPTY } from 'rxjs';

@Component({
    selector: 'app-verify-code',
    templateUrl: './verify-code.component.html',
    styleUrls: ['./verify-code.component.css'],
    standalone: false
})
export class VerifyCodeComponent implements OnInit {
  @Input() emailOrMobile: string;
  @Output() complete: EventEmitter<boolean> = new EventEmitter();

  public readonly loading$ = this.loadingService.loading$;
  public tfaForm: FormGroup;
  public codeCtrl: AbstractControl;
  public submittedCode: boolean = false;
  public resentCode: boolean = false;
  public error: string = '';

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    this.buildForm();

    this.codeCtrl = this.tfaForm.controls['code'];
  }

  public get isEmail(): boolean {
    return !!this.emailOrMobile.includes('@');
  }

  /**
   * Resends the OTP code to the members new mobile number or email
   */
  public resendCode(): void {
    this.resentCode = false;

    this.authService
      .resendPendingTwoFactorAuth(this.isEmail)
      .subscribe((response) => (this.resentCode = response.success));
  }

  /**
   * Calls confirm endpoint with OTP token and emits complete if successful
   */
  public onSubmit(): void {
    this.submittedCode = true;

    if (this.tfaForm.valid) {
      this.profileService
        .confirmProfile(this.codeCtrl.value)
        .pipe(
          take(1),
          catchError((error) => (this.error = `Your code is not valid`))
        )
        .subscribe((response) => {
          if (response[0].success) {
            this.complete.emit(true);
          }
        });
    }
  }

  /**
   * Builds the form configuration with angulars form builder service
   */
  private buildForm(): void {
    this.tfaForm = this.fb.group({
      code: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(6),
          Validators.maxLength(6),
        ]),
      ],
    });
  }
}
