import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { Transactions } from '../models/member-portal';
import { TransactionsService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class TransactionsResolver {
  public planId: string;

  constructor(private transactionsService: TransactionsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Transactions> {
    this.planId = route.params['planId'];

    return this.transactionsService
      .getTransactions(this.planId)
      .pipe(catchError((error) => throwError(() => `TransactionsResolver -> ${error}`)));
  }
}
