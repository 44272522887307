import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeService } from '@app/services';
import { Router } from '@angular/router';

@Component({
    selector: 'app-card-with-image',
    templateUrl: './card-with-image.component.html',
    styleUrls: ['./card-with-image.component.scss'],
    standalone: false
})
export class CardWithImageComponent {
  @Input() title: string;
  @Input() titleStyle?: string;
  @Input() customClass?: string;
  @Input() message?: string;
  @Input() htmlMessage?: string;
  @Input() buttonText: string;
  @Input() icon: string;
  @Input() iconSize: string;
  @Input() iconColor: string;
  @Input() imageSrc: string;
  @Input() imageAlt: string;
  @Input() imageCustomClass: string;
  @Input() additionalContent?: string;
  @Input() routerLink?: string;

  @Input() isButtonOff: boolean = true;

  // embedded button properties
  @Input() button_text: string;
  @Input() button_style: string = 'primary';
  @Input() button_small: boolean = true;
  @Input() button_alignRight: boolean = true;
  @Input() button_noBorder: boolean = true;
  @Input() button_isShadow: boolean = true;
  @Input() button_customClass: string;
  @Input() button_isSubmit: boolean = false;
  @Input() button_icon: string;
  @Input() button_fullWidth: boolean;

  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() secondButtonClick: EventEmitter<void> = new EventEmitter<void>();

  handleButtonClick(): void {
    this.buttonClick.emit();
  }

  handleSecondButtonClick(): void {
    this.secondButtonClick.emit();
  }

  public svg: string;

  constructor(
    private themeService: ThemeService,
    private router: Router
  ) {}

  handleLinkClick() {
    if (this.routerLink) {
      this.router.navigateByUrl(this.routerLink);
    }
  }

  public ngOnInit(): void {
    this.processSVG();
  }

  /*
   * Processes an SVG image using the theme service's colour conversion.
   */
  public processSVG(): void {
    this.themeService
      .processSVGFromImageUrl(`/assets/images/${this.imageSrc}.svg`)
      .then((convertedSVG) => {
        this.svg = convertedSVG;
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
