<section id="registerHomeTitle" class="my-4 mx-0">
  <h1>{{ this.planName }}</h1>
</section>

<section id="registerContent">
  <p>
    We're still working on adding our {{ this.planName }} plan to this service. If you want to stay
    informed of progress, please enter your email address below and we'll be in touch.
  </p>
  <div>
    <form>
      <div class="mb-4">
        <div class="input-group mb-4">
          <input
            type="email"
            class="form-control"
            id="username"
            name="username"
            placeholder="Email address"
            required
            formControlName="username"
          />
        </div>
      </div>
    </form>
  </div>
  <app-need-help />
</section>

<nav class="fixed-bottom -lg pb-4" style="display: block">
  <div class="grid gap-2 p-2">
    <app-button text="Sign up" buttonStyle="secondary" [isSubmitButton]="true"></app-button>
  </div>
</nav>
