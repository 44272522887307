export class RegexUtility {
  static nino =
    /^[A-CEGHJ-PR-TW-Za-ceghj-pr-tw-z]{1}[A-CEGHJ-NPR-TW-Za-ceghj-npr-tw-z]{1}[0-9]{6}[A-DFMa-dfm]{0,1}$/;
  static bankAccNo = /^[0-9]{6,8}$/;
  static mobileNo = /^(07[\d]{8,12})$/;
  // http://stackoverflow.com/a/26887154
  // GA 18-06-2019 Allow zero or one space in postcode search
  static postCode =
    /^(GIR 0AA)|((([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) ?[0-9][A-Z]{2})$/;
  static strongPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\?]).{8,}$/;
  static isaAndSustainablePrefix = /^(S?ISA\d{6,10})$/;
  static jisaAndSustainablePrefix = /^(S?JISA\d{4,10})$/;
}
