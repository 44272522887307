import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService, ProfileService } from '@app/services';
import { catchError, throwError } from 'rxjs';

// import { RequestPasswordReset } from 'app/models/auth/request-password-reset.model';

@Component({
    selector: 'app-verify-identity',
    templateUrl: './verify-identity.component.html',
    styleUrls: ['./verify-identity.component.css'],
    standalone: false
})
export class VerifyIdentityComponent implements OnInit {
  @Output() complete: EventEmitter<boolean> = new EventEmitter();

  public readonly loading$ = this.loadingService.loading$;
  public passwordForm: FormGroup;
  public passwordCtrl: AbstractControl;
  public submittedPassword: boolean = false;
  public error: string = '';

  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    this.buildForm();

    this.passwordCtrl = this.passwordForm.controls['password'];
  }

  /**
   * Calls profile update endpoint with password and emits complete if successful
   */
  public onSubmit(): void {
    this.submittedPassword = true;

    if (this.passwordForm.valid) {
      this.profileService
        .postUpdatedDetails(this.passwordCtrl.value)
        .pipe(
          catchError(() =>
            throwError(() => `Sorry, we could not verify your identity. Please try again later`)
          )
        )
        .subscribe((response) => {
          if (response[0].success) {
            if (response[0].message === 'No changes Updated') {
              this.error =
                'Please check that your new details are different from your current details';
            } else {
              this.complete.emit(true);
            }
          } else {
            this.error = 'We could not verify your identity';
          }
        });
    }
  }

  // TODO: Password journey
  public resetPassword() {}

  /**
   * Builds the form configuration with angulars form builder service
   */
  private buildForm(): void {
    this.passwordForm = this.fb.group({
      password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
    });
  }
}
