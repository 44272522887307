<app-alert
  type="info"
  [imageSmallSrc]="alertImageSmallSrc"
  title="Need help?"
  message="If you're having trouble accessing your account, please contact us and we'll be happy to assist you:"
>
  <div class="flex justify-center items-center gap-3 md:max-w-[300px]">
    <a
      href="tel:0800526249"
      class="text-primary-sf-navy dark:text-sf-white bg-sf-white dark:bg-sf-white-10-opacity text-sm font-semibold no-underline px-4 py-2 w-full flex justify-center items-center gap-2 rounded-full"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" fill="none">
        <g clip-path="url(#a)">
          <path
            stroke="#F8AB00"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M11.537 3.75A3.75 3.75 0 0 1 14.5 6.713M11.537.75A6.75 6.75 0 0 1 17.5 6.705m-.75 5.985v2.25a1.499 1.499 0 0 1-1.635 1.5 14.843 14.843 0 0 1-6.473-2.303 14.625 14.625 0 0 1-4.5-4.5A14.842 14.842 0 0 1 1.84 3.135 1.5 1.5 0 0 1 3.332 1.5h2.25a1.5 1.5 0 0 1 1.5 1.29 9.63 9.63 0 0 0 .525 2.107A1.5 1.5 0 0 1 7.27 6.48l-.953.952a12 12 0 0 0 4.5 4.5l.953-.952a1.5 1.5 0 0 1 1.582-.338c.68.254 1.388.43 2.108.526a1.5 1.5 0 0 1 1.29 1.522Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M.25 0h18v18h-18z" />
          </clipPath>
        </defs>
      </svg>
      Call us
    </a>
    <a
      href="mailto:members@shepherdsfriendly.co.uk"
      class="text-primary-sf-navy dark:text-sf-white bg-sf-white dark:bg-sf-white-10-opacity text-sm font-semibold no-underline px-4 py-2 w-full flex justify-center items-center gap-2 rounded-full"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" fill="none">
        <path
          stroke="#F8AB00"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M14.65 4.5H3.85a.9.9 0 0 0-.9.9v7.2a.9.9 0 0 0 .9.9h10.8a.9.9 0 0 0 .9-.9V5.4a.9.9 0 0 0-.9-.9Z"
        />
        <path
          stroke="#F8AB00"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M15.347 4.83 10.46 8.588a1.985 1.985 0 0 1-2.42 0L3.154 4.83"
        />
      </svg>
      Email us
    </a>
  </div>
</app-alert>
