<app-content-header [title]="'My profile'" [subtitle]="'Change mobile number'"></app-content-header>
<div class="sf-card">
  <div [ngSwitch]="step">
    <ng-container *ngSwitchCase="stepOpts.UPDATE">
      <div
        class="flex flex-col border-b border-primary-sf-navy/10 dark:border-sf-white/10 md:flex-row"
      >
        <p class="mb-0">Your mobile number is currently:&nbsp;&nbsp;</p>
        <p class="max-md:whitespace-pre font-semibold mb-3">{{ memberProfile.mobileNumber }}</p>
      </div>

      <form
        #form="ngForm"
        [formGroup]="changeMobileNumberForm"
        (ngSubmit)="goToNextStep()"
        class="mt-4 md:mt-6"
      >
        <label for="mobile" class="form-label">New mobile number</label>

        <div class="input-group mb-4 md:mb-6">
          <input
            id="mobile"
            name="mobile"
            formControlName="mobile"
            type="text"
            class="form-control"
            [ngClass]="{
              'is-invalid': mobileCtrl.touched && mobileCtrl.errors,
            }"
          />
        </div>

        <app-button
          text="Update"
          buttonStyle="secondary"
          [disabled]="!form.valid"
          [noBorder]="true"
          [isSubmitButton]="true"
        ></app-button>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="stepOpts.VERIFY">
      <app-verify-identity (complete)="handleVerification($event)"></app-verify-identity>
    </ng-container>

    <ng-container *ngSwitchCase="stepOpts.CONFIRM">
      <app-verify-code
        [emailOrMobile]="mobileCtrl.value"
        (complete)="handleCode($event)"
      ></app-verify-code>
    </ng-container>

    <ng-container *ngSwitchCase="stepOpts.COMPLETE">
      <h1 class="mb-4">Mobile number changed</h1>
      <p>Your mobile number has been changed to:</p>
      <p class="text-center text-lg">{{ mobileCtrl.value }}</p>

      <p>
        If you have any questions regarding this, please call
        <a href="tel:0800526249">0800 526 249</a>
        and speak to our Member Services Team.
      </p>

      <app-button text="Return home" buttonStyle="primary" [routerLink]="url.HOME"></app-button>
    </ng-container>
  </div>
</div>
