<form [formGroup]="consentForm">
  <h5 class="mb-4 text-lg font-bold">Communication preferences</h5>
  <p>
    Your privacy is of the highest importance to us. All data captured at this point of application
    is necessary, as it allows us to process your plan. We will never release your personal
    information, unless you give us permission to do so, to anyone outside the company for their
    mailing or marketing purposes. We will never knowingly send marketing messages or make a
    deliberate attempt to contact anyone under the age of 16 years old. To ensure that we send you
    the right information, please let us know your communication preferences.
  </p>

  <p>I'm happy to receive marketing communications through the following:</p>
  <div class="flex">
    <div>
      <div class="checkbox-row mb-2">
        <input
          type="checkbox"
          class="form-check-input form-check-input--large peer"
          formControlName="consentPost"
          id="consentPost"
        />
        <label for="consentPost" class="peer-checked:text-sf-white">Post</label>
      </div>

      <div class="checkbox-row mb-2">
        <input
          type="checkbox"
          class="form-check-input form-check-input--large peer"
          formControlName="consentEmail"
          id="consentEmail"
        />
        <label for="consentEmail" class="peer-checked:text-sf-white">Email</label>
      </div>

      <div class="checkbox-row mb-2">
        <input
          type="checkbox"
          class="form-check-input form-check-input--large peer"
          formControlName="consentTelephone"
          id="consentTelephone"
        />
        <label for="consentTelephone" class="peer-checked:text-sf-white">Telephone</label>
      </div>
    </div>

    <div>
      <div class="checkbox-row mb-2">
        <input
          type="checkbox"
          class="form-check-input form-check-input--large peer"
          formControlName="consentTextMessage"
          id="consentTextMessage"
        />
        <label for="consentTextMessage" class="peer-checked:text-sf-white">Text message</label>
      </div>

      <div class="checkbox-row mb-2">
        <input
          type="checkbox"
          class="form-check-input form-check-input--large peer"
          formControlName="consentNotGiven"
          id="consentNotGiven"
        />
        <label for="consentNotGiven" class="peer-checked:text-sf-white">
          I don't want to receive marketing communications
        </label>
      </div>
    </div>
  </div>
</form>
