import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { NotificationItem, Notifications } from '../models/member-portal';
import { NotificationsService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class NotificationsSummaryResolver {
  private notificationId: string;

  constructor(private notificationsService: NotificationsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NotificationItem> {
    this.notificationId = route.params['notificationId'];

    return this.notificationsService
      .getNotification(this.notificationId)
      .pipe(catchError((error) => throwError(() => `NotificationsSummaryResolver -> ${error}`)));
  }
}
