import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegexUtility } from '@app/shared/utilities';

@Component({
    selector: 'app-register-account-step-one',
    templateUrl: './register-account-step-one.component.html',
    styleUrls: ['./register-account-step-one.component.scss'],
    standalone: false
})
export class RegisterAccountStepOneComponent implements OnInit {
  public registerForm: FormGroup;

  @Input() showJisaLabelText: boolean = false;
  @Output() onUpdate = new EventEmitter<any>();
  @Output() onValid = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder) {}

  get planNumberCtrl() {
    return this.registerForm.get('planNumber');
  }

  get formLabel(): string {
    if (this.showJisaLabelText) {
      return 'To register your Shepherds Friendly Stocks & Shares ISA and a Junior ISA, please fill in the boxes below.';
    } else {
      return 'To register your Shepherds Friendly Stocks, please fill in the box below.';
    }
  }

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      planNumber: [
        '',
        [Validators.required, Validators.pattern(RegexUtility.isaAndSustainablePrefix)],
      ],
    });

    this.registerForm.valueChanges.subscribe((value) => {
      const planNumber = this.planNumberCtrl.value;

      if (planNumber !== planNumber.toUpperCase()) {
        this.planNumberCtrl.setValue(planNumber.toUpperCase(), {
          emitEvent: false,
        });
      }

      if (this.registerForm.valid) {
        this.onUpdate.emit(value);
      } else {
        this.onUpdate.emit(value);
      }

      this.onValid.emit(this.registerForm.valid);
    });
  }
}
