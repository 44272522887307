import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormGroupDirective,
} from '@angular/forms';

import { DirectDebit, SavingsPlan } from '@app/models/member-portal';
import { CommonValidators } from '@app/shared/validators';
import { MaskUtility, RegexUtility } from '@app/shared/utilities';
import { DirectDebitService, FeatureFlagService } from '@app/services';
import { catchError, EMPTY, from, ReplaySubject, take } from 'rxjs';

type RouteStateData = { plan: SavingsPlan; directDebit?: DirectDebit };

@Component({
  selector: 'app-direct-debit-setup',
  templateUrl: './direct-debit-setup.component.html',
  styleUrls: ['./direct-debit-setup.component.scss'],
})
export class DirectDebitSetupComponent implements OnInit {
  public readonly url = {
    REVIEW_DIRECT_DEBIT: `/direct-debit/plan/review`,
    PLAN_CHOICE: `/plans/plan-choice/direct-debit`,
  };

  public readonly MIN_MAX = this.directDebitService.MIN_MAX;
  public readonly DIVISIBLE_AMOUNT = this.directDebitService.AMOUNT_IN_MULTIPLES_OF_VALUE;

  public readonly sortCodeMask = MaskUtility.sortCode;

  public directDebitForm: FormGroup;
  public accountNameCtrl: AbstractControl;
  public accountNumberCtrl: AbstractControl;
  public sortCodeCtrl: AbstractControl;
  public dayDueCtrl: AbstractControl;
  public periodCtrl: AbstractControl;
  public periodDueCtrl: AbstractControl;
  public isPayersNameCtrl: AbstractControl;
  public isAllowedCtrl: AbstractControl;
  public amountCtrl: AbstractControl;

  public plan: SavingsPlan;
  public directDebit: DirectDebit;

  public directDebitDetailsAPIFailed: boolean;
  public directDebitDetailsVerificationFailed: boolean;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    private directDebitService: DirectDebitService,
    private featureFlagService: FeatureFlagService
  ) {
    this.loadPlanDetailsFromRoute();
  }

  ngOnInit(): void {
    this.buildForm();

    this.patchForm();

    // Cache form controls
    this.accountNameCtrl = this.directDebitForm.controls['acName'];
    this.accountNumberCtrl = this.directDebitForm.controls['acNumber'];
    this.sortCodeCtrl = this.directDebitForm.controls['sortCode'];

    this.dayDueCtrl = this.directDebitForm.controls['dayDue'];
    this.periodCtrl = this.directDebitForm.controls['period'];
    this.periodDueCtrl = this.directDebitForm.controls['periodDue'];

    this.isPayersNameCtrl = this.directDebitForm.controls['payersName'];
    this.isAllowedCtrl = this.directDebitForm.controls['allowed'];
    this.amountCtrl = this.directDebitForm.controls['amount'];

    this.amountValidators();
  }

  /**
   * Returns true if period is set to 12 monthly payments
   */
  public get isMonthly(): boolean {
    return this.periodCtrl.value.toString() === '12';
  }

  /**
   * Returns true if period is set to 1 annual payment
   */
  public get isAnnual(): boolean {
    return this.periodCtrl.value.toString() === '1';
  }

  /**
   * Returns text string 'monthly' or 'annual' based on period that has been set
   */
  public get paymentPeriod(): 'monthly' | 'annual' {
    return this.isMonthly ? 'monthly' : 'annual';
  }

  /**
   * Selects correct min / max values based on plan type and payment period
   */
  public get minMaxValues(): { min: number; max: number } {
    return this.MIN_MAX[this.paymentPeriod][this.planPrefix];
  }

  /**
   * Returns uppercase plan prefix
   */
  public get planPrefix(): string {
    return this.plan.planTypePrefix.toUpperCase();
  }

  /**
   * Returns true if period is set to monthly or annual and a day or month is selected
   */
  public get hasPaymentPeriod(): boolean {
    return !!(
      (this.isMonthly && this.dayDueCtrl.value) ||
      (this.isAnnual && this.periodDueCtrl.value)
    );
  }

  /**
   * Checks form validity and redirects to direct debit review
   * @param {FormGroupDirective} [form] the form viewRef
   */
  public async onSubmit(form: FormGroupDirective): Promise<void> {
    let raiseError = (fn) => {
      fn();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    if (form.valid) {
      const featureEnabled = await this.featureFlagService.isEnabled(
        'appVerifyBankDetailsDDEnabled'
      );

      if (!featureEnabled) {
        // fall-back to no validation
        this.router.navigate([this.url.REVIEW_DIRECT_DEBIT], {
          state: { plan: this.plan, directDebit: form.value },
        });
        return;
      }

      this.directDebitDetailsAPIFailed = false;
      this.directDebitDetailsVerificationFailed = false;

      this.directDebitService
        .validateDirectDebitDetails(
          this.directDebitForm.value.acName,
          this.directDebitForm.value.acNumber,
          this.directDebitForm.value.sortCode,
          this.directDebitForm.value.payersName,
          this.directDebitForm.value.allowed,
          true,
          this.directDebitForm.value.dayDue
        )
        .pipe(
          take(1),
          catchError((error) => {
            raiseError(() => (this.directDebitDetailsAPIFailed = true));
            return EMPTY;
          })
        )
        .subscribe((response) => {
          if (response.ok) {
            this.router.navigate([this.url.REVIEW_DIRECT_DEBIT], {
              state: { plan: this.plan, directDebit: form.value },
            });
          } else {
            raiseError(() => (this.directDebitDetailsVerificationFailed = true));
          }
        });
    }
  }

  /**
   * Sets the validators around direct debit payment amount
   */
  public amountValidators(): void {
    this.amountCtrl.setValidators([
      Validators.required,
      CommonValidators.isDivisible(this.DIVISIBLE_AMOUNT),
      CommonValidators.isInRange(this.minMaxValues.min, this.minMaxValues.max),
    ]);
  }

  /**
   * Builds the form configuration with angulars form builder service
   */
  private buildForm(): void {
    this.directDebitForm = this.fb.group({
      acName: ['', Validators.compose([Validators.required])],
      acNumber: [
        '',
        Validators.compose([Validators.required, Validators.pattern(RegexUtility.bankAccNo)]),
      ],
      sortCode: ['', Validators.compose([Validators.required])],
      dayDue: ['', Validators.compose([Validators.required])],
      period: ['12'],
      periodDue: [''],
      payersName: ['', Validators.compose([Validators.requiredTrue])],
      allowed: ['', Validators.compose([Validators.requiredTrue])],
      amount: ['', Validators.compose([Validators.required])],
    });
  }

  private patchForm(): void {
    this.directDebitForm.patchValue(this.directDebit);
  }

  /**
   * Retrieves plan / direct debit details data from the route state, if non exists
   * redirect back to the plan choice page.
   */
  private loadPlanDetailsFromRoute(): void {
    const state = this.router.getCurrentNavigation()?.extras?.state as RouteStateData;

    if (state) {
      this.plan = state.plan;
      this.directDebit = state.directDebit;
    } else {
      this.router.navigate([this.url.PLAN_CHOICE]);
    }
  }
}
