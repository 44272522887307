<app-content-header
  [title]="'Deposit'"
  [subtitle]="'Which plan do you want to make a deposit into?'"
></app-content-header>

<app-alert
  *ngIf="hasUnissuedPlans"
  type="warning"
  message="One or more of your plans are not yet available."
  additionalClass="mb-4 [&_p]:mb-0"
></app-alert>

<div *ngFor="let plan of memberDashboard?.memberPlans?.savingsPlans">
  <app-plan-overview
    [disabled]="!plan.isIssued"
    (click)="plan.isIssued && navigateWithState(plan)"
    [plan]="plan"
    [member]="memberDashboard.member"
    [concise]="true"
  />
</div>
<div *ngFor="let asp of memberDashboard?.authorisedPlans?.authorisedSavingsPlans">
  <app-plan-overview
    [disabled]="!asp.plan.isIssued"
    (click)="asp.plan.isIssued && navigateWithState(asp.plan)"
    [plan]="asp.plan"
    [member]="asp.member"
    [concise]="true"
  />
</div>
