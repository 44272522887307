import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { DocRow } from '@app/models/member-portal/Pdf/member-document-list.model';
import { DocumentService } from '@app/services';
import { PaginationComponent } from 'ngx-bootstrap/pagination';
import { Subscription } from 'rxjs';
import { MemberRow } from 'src/app/models/member-portal';

@Component({
    selector: 'app-documents-home',
    templateUrl: './documents-home.component.html',
    styleUrls: ['./documents-home.component.scss'],
    standalone: false
})
export class DocumentsHomeComponent implements OnDestroy {
  @ViewChild('paginator') paginator: PaginationComponent;
  @Input('documentsByMember') memberRow?: MemberRow[];
  @Input('planNumber') currentPlan?: string;

  private subscribeUntilDestroyed = new Subscription();
  public currentFilter: Set<string> = new Set();
  public currentDocuments: DocRow[];
  public allDocuments: DocRow[];
  public numFilteredDocuments: number;
  public page: number = 1;
  public itemsPerPage: number = 20;
  public showFilter: boolean;

  constructor(public documentService: DocumentService) {}

  ngOnInit(): void {
    this.applyFilter();
  }

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  hasFilteredPlan(plan: string): boolean {
    return this.currentFilter.has(plan);
  }

  /**
   * Toggle the "all" filter
   */
  allCheckChange(event: Event) {
    this.currentFilter = new Set();
    this.applyFilter();
  }

  /**
   * Toggle a specific plan filter
   */
  filterCheckChange(event: Event, planNumber: string) {
    // toggle plan filter
    let input = event.target as HTMLInputElement;

    if (input.checked) {
      this.currentFilter.add(planNumber);
      this.applyFilter();
    } else {
      this.currentFilter.delete(planNumber);
      this.applyFilter();
    }

    // this.processMemberRows();
  }

  /**
   * Toggle the filter UI on/off
   */
  toggleFilter() {
    this.showFilter = !this.showFilter;
    if (!this.showFilter) {
      // remove filter
      this.currentFilter = new Set();
      this.applyFilter();
    }
  }

  /**
   * Applies the current UI filter settings
   */
  applyFilter() {
    // apply filter from current UI settings
    this.page = 1;
    if (this.paginator) {
      this.paginator.selectPage(this.page);
    }
    this.filterDocuments();
  }

  /**
   * Filters and paginates the document set
   */
  filterDocuments() {
    // reset document lists
    this.currentDocuments = [];
    this.allDocuments = [];

    // compute pagination indexes
    let startDocumentIndex = (this.page - 1) * this.itemsPerPage;
    let endDocumentIndex = startDocumentIndex + (this.itemsPerPage - 1);

    this.numFilteredDocuments = 0;

    // filter documents
    for (let member of this.memberRow) {
      for (let plan of member.planRows) {
        for (let document of plan.docRows) {
          // remove the date that has been embedded into the title
          document.title = document.title.replace(document.timeStamp, '');

          // apply UI filter
          let allowDocumentUI =
            this.currentFilter?.size === 0 || this.currentFilter.has(plan.planNumber);

          // apply pagination filter
          let allowDocumentPage =
            this.numFilteredDocuments >= startDocumentIndex &&
            this.numFilteredDocuments <= endDocumentIndex;

          if (allowDocumentUI && allowDocumentPage) {
            // add filtered document
            this.currentDocuments.push(document);
          }

          if (allowDocumentUI) {
            this.numFilteredDocuments++;
          }

          // add unfiltered document
          this.allDocuments.push(document);
        }
      }
    }
  }

  /**
   * Callback method for when a page event is fired
   * @param {{ page: number }} [event]
   */
  public pageChanged(event: { page: number }): void {
    this.page = event.page;
    this.filterDocuments();
  }
}
