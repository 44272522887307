import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { Notifications } from '../models/member-portal';
import { NotificationsService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class NotificationsResolver {
  constructor(private notificationsService: NotificationsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Notifications> {
    return this.notificationsService
      .getNotifications()
      .pipe(catchError((error) => throwError(() => `NotificationsResolver -> ${error}`)));
  }
}
