import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Login } from 'src/app/models/security/log-in.model';
import { AuthService } from 'src/app/services/auth.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css'],
})
export class LogInComponent implements OnInit {
  public email: string;
  public error: string;
  private returnUrl: string;
  public submitted = false;
  public sserror = false;
  public loginForm = this.fb.group({
    username: ['', Validators.compose([Validators.required, Validators.email])],
    password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
  });

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.error = '';
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params['destination'] || '/';
      this.email = params['email'];
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.valid) {
      let formContents = this.loginForm.value as Login;
      this.auth.login(formContents.username, formContents.password).subscribe({
        next: (n) => {
          this.router.navigateByUrl(this.returnUrl);
        },
        error: (e) => {
          this.sserror = true;
        },
      });
    }
    return;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  fillEmailAddressAndPassword() {
    if (environment.hasOwnProperty('devUsername') && environment.hasOwnProperty('devPW')) {
      this.loginForm.get('username').setValue((<any>environment).devUsername);
      this.loginForm.get('password').setValue((<any>environment).devPW);
    }
  }
}
