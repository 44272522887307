<header
  class="grid grid-cols-auto-auto items-center mb-4 mx:mb-6 xl:mb-8 {{ customClassContainer }}"
>
  <h1
    class="mb-0 text-2xl xl:text-4xl font-bold text-primary-sf-navy dark:text-sf-white {{
      customClassTitle
    }}"
  >
    {{ title }}
  </h1>
  <app-button
    *ngIf="!isButtonOff"
    [text]="button_text"
    [buttonStyle]="button_style"
    [btnSmall]="button_small"
    [alignRight]="button_alignRight"
    [noBorder]="button_noBorder"
    [isButtonShadow]="button_isShadow"
    [customClass]="button_customClass"
    [isSubmitButton]="button_isSubmit"
    [icon]="button_icon"
    [iconSize]="button_iconSize"
    [iconColor]="button_iconColor"
    (click)="handleButtonClick()"
    [btnHover]="button_hover"
  ></app-button>

  <p class="col-start-1 col-span-2 mb-0 {{ customClassTitle }}" *ngIf="subtitle">
    {{ subtitle }}
  </p>
  <p class="mb-0 {{ customClassTitle }}" *ngIf="subSubtitle">
    {{ subSubtitle }}
  </p>
</header>
