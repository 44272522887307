<h3>{{ title }}</h3>

<section class="flex flex-col gap-10 mt-8 mb-4 md:flex-row md:gap-12">
  <div class="flex flex-1">
    <div class="flex flex-col flex-1">
      <div class="bar-chart">
        <!-- Top section -->
        <div
          class="bar-section interest"
          [style.height.px]="getInterestWithoutWithdrawalHeight()"
          title="Interest Without Withdrawal"
        >
          <span>{{ withdrawalProjection.interestWithoutWithdrawal | currency : 'GBP' }}</span>
        </div>

        <!-- Middle section -->
        <div
          *ngIf="withdrawalProjection.totalDDContributions !== 0"
          class="bar-section dd"
          [style.height.px]="getTotalDDContributionsHeight()"
          title="Total DD Contributions"
        >
          <span>{{ withdrawalProjection.totalDDContributions | currency : 'GBP' }}</span>
        </div>

        <!-- Bottom section -->

        <div
          class="bar-section balance"
          [style.height.px]="getCurrentBalanceHeight()"
          title="Current Balance"
        >
          <span>{{ withdrawalProjection.currentBalance | currency : 'GBP' }}</span>
        </div>
      </div>
      <div>
        <span class="font-semibold text-center block my-2 max-md:text-sm break-words">
          No
          <br class="xl:hidden" />
          withdrawal
        </span>
      </div>
    </div>

    <!-- The "VS" divider -->
    <div class="relative w-full max-xl:max-w-14 max-w-20 h-[350px] flex justify-center">
      <div class="w-px h-1/3 bg-gray-300 dark:bg-sf-white/20"></div>
      <div
        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 -my-2.5 flex items-center justify-center w-10 h-10 rounded-full bg-sf-light-grey dark:bg-sf-light-grey/20 text-sm font-semibold text-gray-700 dark:text-sf-white"
      >
        VS
      </div>
      <div class="w-px h-1/3 bg-gray-300 dark:bg-sf-white/20 relative -bottom-[63%]"></div>
    </div>

    <div class="flex flex-col flex-1">
      <div class="bar-chart">
        <!-- Top section -->
        <div
          class="bar-section interest"
          [style.height.px]="getInterestWithWithdrawalHeight()"
          title="Interest Without Withdrawal"
        >
          <span>{{ withdrawalProjection.interestWithWithdrawal | currency : 'GBP' }}</span>
        </div>

        <!-- Middle section -->
        <div
          *ngIf="withdrawalProjection.totalDDContributions !== 0"
          class="bar-section dd"
          [style.height.px]="getTotalDDContributionsHeight()"
          title="Total DD Contributions"
        >
          <span>{{ withdrawalProjection.totalDDContributions | currency : 'GBP' }}</span>
        </div>

        <!-- Bottom section -->

        <div
          class="bar-section balance"
          [style.height.px]="getBalanceAfterWithdrawalHeight()"
          title="Current Balance"
        >
          <span>{{ withdrawalProjection.balanceAfterWithdrawal | currency : 'GBP' }}</span>
        </div>
      </div>
      <div>
        <span
          class="font-semibold text-center block my-2 text-sf-red dark:text-red-300 max-md:text-sm"
        >
          {{ withdrawalProjection.requestedWithdrawal | currency : 'GBP' }}
          <br class="xl:hidden" />
          withdrawal
        </span>
      </div>
    </div>
  </div>

  <div class="flex flex-col flex-1 md:justify-center">
    <div class="flex md:flex-col">
      <div class="flex flex-col items-center flex-1 md:flex-row md:gap-3 md:align-center md:pb-4">
        <span class="block w-8 h-8 bg-primary-sf-yellow rounded-full"></span>
        <span class="text-center max-md:pt-2 max-md:text-sm">
          Example: {{ this.withdrawalProjection.interestRate }}% AER over
          {{ this.withdrawalProjection.investmentPeriod }} years
        </span>
      </div>
      <div
        class="flex flex-col items-center flex-1 md:flex-row md:gap-3 md:align-center md:pb-4 max-md:px-4"
        *ngIf="withdrawalProjection.totalDDContributions !== 0"
      >
        <span class="block w-8 h-8 bg-secondary-sf-blue-light rounded-full"></span>
        <span class="text-center max-md:pt-2 max-md:text-sm">
          {{ this.withdrawalProjection.monthlyDD | currency : 'GBP' }} monthly Direct Debit
        </span>
      </div>
      <div class="flex flex-col items-center flex-1 md:flex-row md:gap-3 md:align-center">
        <span
          class="block w-8 h-8 bg-primary-sf-navy dark:bg-secondary-sf-blue-dark rounded-full"
        ></span>
        <span class="text-center max-md:pt-2 max-md:text-sm">Current balance</span>
      </div>
    </div>
    <p
      class="text-sm border-t border-gray-300 dark:border-sf-white/10 pt-4 mt-6 mb-0 md:mt-4 max-md:text-center"
    >
      For illustration purposes only. Rates and bonuses not guaranteed. Rates subject to change.
    </p>
  </div>
</section>
