import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from '@app/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-quick-action-button-item',
  standalone: false,
  templateUrl: './quick-action-button-item.component.html',
  styleUrls: ['./quick-action-button-item.component.scss'],
})
export class QuickActionButtonItemComponent {
  @Input() quickActionBtnClass: string;
  @Input() quickActionBtnIcon: string;
  @Input() quickActionBtnAlt: string;
  @Input() quickActionBtnText: string;
  @Input() quickActionBtnLink?: string;
  @Input() quickActionBtnWrapperClass?: boolean = false;

  public svg: string;
  private subscribeUntilDestroyed = new Subscription();

  constructor(private themeService: ThemeService, private router: Router) {}

  public ngOnInit(): void {
    this.subscribeUntilDestroyed = this.themeService.mode$.subscribe(() => this.processSVG());
  }

  public ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  // Navigate to the given URL
  public navigateTo(url: string) {
    if (!url) {
      return;
    }

    this.router.navigate([url]);
  }

  /*
   * Processes an SVG image using the theme service's colour conversion.
   */
  public processSVG(): void {
    this.themeService
      .processSVGFromImageUrl(`/assets/icons/${this.quickActionBtnIcon}.svg`)
      .then((convertedSVG) => {
        this.svg = convertedSVG;
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
