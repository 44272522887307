import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface RadioOption {
  label: string;
  value: any;
}

@Component({
    selector: 'app-radio-list',
    templateUrl: './radio-list.component.html',
    styleUrls: ['./radio-list.component.scss'],
    standalone: false
})
export class RadioListComponent {
  @Input() options: RadioOption[] = [];
  @Output() selectionChange = new EventEmitter<any>();

  selectedValue: any;

  onSelectionChange(selectedOption: RadioOption) {
    this.selectedValue = selectedOption.value;
    this.selectionChange.emit(this.selectedValue);
  }
}
