import { Injectable } from '@angular/core';

import { AppConstantsService } from './app-constants.service';
import { HttpService } from './http.service';

import { Observable } from 'rxjs';

import {
  DirectDebit,
  NewDirectDebitRequest,
  SetupDirectDebitResult,
  VaryDirectDebitResult,
  ValidateDirectDebitResult,
} from '@app/models/member-portal';

@Injectable({ providedIn: 'root' })
export class DirectDebitService {
  public readonly api = {
    VALIDATE_DD_AMOUNT: `${this.configuration.server}/api/Member/ValidateDirectDebitAmount`,
    VALIDATE_DD_DETAILS: `${this.configuration.server}/api/Member/ValidateDirectDebitDetails`,
    VARY_DD: `${this.configuration.server}/api/Member/VaryDirectDebit`,
    SETUP_DD: `${this.configuration.server}/api/Member/SetupDirectDebit`,
  };

  public readonly MIN_MAX = {
    annual: {
      JISA: {
        min: 100,
        max: 9000,
      },
      SJISA: {
        min: 100,
        max: 9000,
      },
      ISA: {
        min: 500,
        max: 20000,
      },
      SISA: {
        min: 500,
        max: 20000,
      },
    },
    monthly: {
      JISA: {
        min: 10,
        max: 750,
      },
      SJISA: {
        min: 10,
        max: 750,
      },
      ISA: {
        min: 30,
        max: 1660,
      },
      SISA: {
        min: 30,
        max: 1660,
      },
    },
  };

  public readonly AMOUNT_IN_MULTIPLES_OF_VALUE: number = 10;

  public readonly MAX_CHANGES_30_DAYS: number = 1;

  constructor(private http: HttpService, private configuration: AppConstantsService) {}

  public validateDirectDebitAmount(
    amount: string,
    directDebitType: number,
    planType: number
  ): Observable<any> {
    let data = {
      validate: amount,
      period: directDebitType,
      planType: planType,
    };

    return this.http.post<any>(this.api.VALIDATE_DD_AMOUNT, data);
  }

  public validateDirectDebitDetails(
    nameOfAccountHolder: string,
    accountNumber: number,
    sortCode: string,
    bankAccountHeldInPayersName: boolean,
    allowedFromAccount: boolean,
    validateCollectionDay: boolean,
    collectionDay: number
  ): Observable<ValidateDirectDebitResult> {
    let data = {
      nameOfAccountHolder: nameOfAccountHolder,
      accountNumber: accountNumber,
      sortCode: sortCode,
      bankAccountHeldInPayersName: bankAccountHeldInPayersName,
      allowedFromAccount: allowedFromAccount,
      validateCollectionDay: validateCollectionDay,
      collectionDay: collectionDay,
    };

    return this.http.post<any>(this.api.VALIDATE_DD_DETAILS, data);
  }

  public varyDirectDebit(
    currentDirectDebit: DirectDebit,
    planNumber: string,
    planIdentifier: string,
    amount: number
  ): Observable<VaryDirectDebitResult> {
    let data = {
      DirectDebit: currentDirectDebit,
      PlanNumber: planNumber,
      PlanIdentifier: planIdentifier,
      Amount: amount,
    };

    return this.http.post<any>(this.api.VARY_DD, data);
  }

  public setupDirectDebit(
    newDirectDebit: NewDirectDebitRequest,
    planNumber: string,
    planIdentifier: string,
    planId: number
  ): Observable<SetupDirectDebitResult> {
    let data = {
      NewDirectDebitRequest: newDirectDebit,
      PlanNumber: planNumber,
      PlanIdentifier: planIdentifier,
      PlanId: planId,
    };

    return this.http.post<any>(this.api.SETUP_DD, data);
  }
}
