import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-ctf-declaration',
    templateUrl: './ctf-declaration.component.html',
    styleUrls: ['./ctf-declaration.component.scss'],
    standalone: false
})
export class CTFDeclarationComponent {
  @Output() hasCompleted: EventEmitter<boolean> = new EventEmitter();

  public checked: boolean = false;

  /**
   * Emits result of checkbox state back to parent components
   */
  public completed() {
    this.hasCompleted.emit(true);
  }
}
