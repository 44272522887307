<ng-container *ngIf="loading$ | async">
  <div class="fixed top-0 left-0 right-0 bottom-0 z-10 backdrop-blur-sm"></div>
  <div class="absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 z-30 text-center">
    <div class="animate-rotate origin-[50%_50%] w-12 h-12" role="status">
      <img
        src="/assets/images/loading-logo.svg"
        class="max-w-min"
        alt="Shepherds Friendly Shield"
      />
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-container>
