export interface Address {
  $id: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  postCode: string;
}

export class MemberProfile {
  $id: string;
  address: Address;
  emailAddress: string;
  mobileNumber: string;
  name: string;
  Password?: string;

  constructor(
    $id: string,
    address: Address,
    emailAddress: string,
    mobileNumber: string,
    name: string
  ) {
    this.$id = $id;
    this.address = address;
    this.emailAddress = emailAddress;
    this.mobileNumber = mobileNumber;
    this.name = name;
  }
}
