import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-registration-already-registered',
    templateUrl: './registration-already-registered.component.html',
    styleUrls: ['./registration-already-registered.component.css'],
    standalone: false
})
export class RegistrationAlreadyRegisteredComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
