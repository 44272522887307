import { Injectable } from '@angular/core';
import { AppConstantsService } from '@app/services/app-constants.service';
import { map, Observable } from 'rxjs';
import { HttpService } from '@app/services/http.service';

@Injectable()
export class CTFOverviewService {
  public readonly api = {
    GET_CTF_OVERVIEW_BY_PLAN_NO: (planNumber) =>
      `${this.configuration.server}/api/Member/GetCTFOverview?urn=${planNumber.toUpperCase()}`,
  };

  constructor(
    private configuration: AppConstantsService,
    private http: HttpService
  ) {}

  public getCTFOverviewByNino(nino: string): Observable<any> {
    return this.http
      .get(this.api.GET_CTF_OVERVIEW_BY_PLAN_NO(nino))
      .pipe(map((response) => response));
  }
}
