import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-register-consent',
  templateUrl: './register-consent.component.html',
  styleUrls: ['./register-consent.component.scss'],
})
export class RegisterConsentComponent implements OnInit {
  public consentForm: FormGroup;

  @Output() onUpdateConsent = new EventEmitter<any>();
  @Output() onValid = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.consentForm = this.fb.group(
      {
        consentPost: [false],
        consentEmail: [false],
        consentTelephone: [false],
        consentTextMessage: [false],
        consentNotGiven: [false],
      },
      { validators: this.checkAtLeastOneChecked }
    );

    this.consentForm.valueChanges.subscribe((value) => {
      if (value.consentNotGiven) {
        this.consentForm.patchValue(
          {
            consentPost: false,
            consentEmail: false,
            consentTelephone: false,
            consentTextMessage: false,
          },
          { emitEvent: false }
        );
      }

      this.onUpdateConsent.emit(value);
      this.onValid.emit(this.consentForm.valid);
    });

    const consentNotGivenControl = this.consentForm.get('consentNotGiven');
    const otherControls = [
      'consentPost',
      'consentEmail',
      'consentTelephone',
      'consentTextMessage',
    ].map((controlName) => this.consentForm.get(controlName));

    otherControls.forEach((control) => {
      control.valueChanges.subscribe(() => {
        if (consentNotGivenControl.value) {
          consentNotGivenControl.setValue(false, { emitEvent: false });
        }
      });
    });
  }

  checkAtLeastOneChecked(group: FormGroup): { [key: string]: boolean } | null {
    const controls = Object.values(group.controls);
    const isAtLeastOneChecked = controls.some((control) => control.value === true);

    return isAtLeastOneChecked ? null : { noOptionSelected: true };
  }
}
