<ng-container *ngIf="isCTF; else nonCTFContent">
  <app-content-header
    [title]="'Child Trust Fund (CTF)'"
    [subtitle]="'Registered contact sign-up'"
    customClassTitle="text-sf-white"
    customClass="text-sf-white"
  ></app-content-header>

  <app-card-with-image
    title="Account creation unsuccessful"
    titleStyle="text-sf-white"
    customClass="sf-card--ctf h-auto"
    imageSrc="doing-the-right-thing-red-white"
    imageAlt="doing-the-right-thing-red"
    [isButtonOff]="false"
    [button_text]="'Call us'"
    [button_fullWidth]="false"
    [button_small]="false"
    (click)="callPhoneNumber()"
    additionalContent="Return to log in page"
    [routerLink]="'/log-in'"
  >
    <div>
      <p>
        There has been an issue creating your account but don’t worry, we will be in touch within 5
        working days to fix this.
      </p>
    </div>
  </app-card-with-image>
</ng-container>

<ng-template #nonCTFContent>
  <div class="sf-card sf-card--ctf">
    <div class="mb-2">
      <h3 class="text-2xl font-bold">Registration not completed</h3>
      <p class="mt-4">Sorry, we couldn’t complete your online account registration.</p>
      <p>There may be a number of reasons for this:</p>
      <ul>
        <li>You may already have an account set up with us under the same email address</li>
        <li>You may already have an account set up with us under a different email address</li>
        <li>Something may have gone wrong</li>
      </ul>
      <hr />
      <p class="mt-4 font-bold">What should you do next</p>
      <p>
        We suggest that you try logging in, and you may need to reset your password. If you are
        still unable to access your account or register, please contact us on
        <strong>0800 526 249</strong>
        or via email:
        <a href="mailto:members@shepherdsfriendly.co.uk">members&#64;shepherdsfriendly.co.uk</a>
      </p>
    </div>
  </div>
</ng-template>
