<div *ngIf="!allDocuments || !allDocuments.length; else documentList">
  <app-card-with-image
    title="No Documents"
    message="You currently don’t have any documents in your Shepherds Friendly account."
    [titleStyle]="'my-4'"
    imageSrc="feedback-survey"
    imageAlt="feedback-survey"
    [isButtonOff]="false"
    [button_text]="'Return to home'"
    routerLink="/home"
    [button_fullWidth]="false"
    [button_small]="false"
    customClass="max-md:h-auto"
  ></app-card-with-image>
</div>

<ng-template #documentList>
  <app-content-header
    *ngIf="allDocuments?.length"
    [title]="'My documents'"
    [customClassContainer]="' p-0 mb-4 md:mb-6 xl:mb-8 '"
    [isButtonOff]="false"
    [button_text]="'Filter'"
    [button_icon]="'far fa-filter'"
    [button_style]="'white'"
    [button_iconColor]="'text-primary-sf-yellow '"
    [button_customClass]="
      'hover:bg-primary-sf-yellow dark:hover:text-primary-sf-navy focus:bg-primary-sf-yellow dark:focus:text-primary-sf-navy'
    "
    (buttonClick)="toggleFilter()"
    [button_hover]="true"
  ></app-content-header>
  <div *ngFor="let member of memberRow">
    <div
      class="flex flex-col justify-center shadow-sm bg-sf-white dark:bg-primary-sf-navy rounded-md p-4 mb-4 md:mb-6 xl:mb-8"
      *ngIf="showFilter"
    >
      <p
        class="text-sm font-semibold pb-3 mb-3 border-b border-primary-sf-navy/10 dark:border-sf-white/10"
      >
        Filter Documents
      </p>
      <div
        class="grid grid-cols-auto-1fr items-center gap-2 pb-3 border-b border-primary-sf-navy/10 dark:border-sf-white/10"
      >
        <app-checkbox
          label="All plans"
          (change)="allCheckChange($event)"
          [disabled]="currentFilter.size === 0"
          [checked]="currentFilter.size === 0"
        ></app-checkbox>
      </div>
      <div *ngFor="let planRow of member.planRows">
        <div
          class="grid grid-cols-auto-1fr items-center gap-2 py-3 border-b border-primary-sf-navy/10 dark:border-sf-white/10"
        >
          <app-checkbox
            label="{{ planRow.planType }} - {{ planRow.planNumber }}"
            (change)="filterCheckChange($event, planRow.planNumber)"
            [checked]="hasFilteredPlan(planRow.planNumber)"
          ></app-checkbox>
        </div>
      </div>
    </div>
    <div class="mb-4 md:mb-6 xl:mb-8">
      <div
        class="flex flex-col justify-center bg-sf-white dark:bg-primary-sf-navy relative shadow-sm py-2 px-4 md:py-4 md:px-6 xl:px-8 after:content-[''] after:absolute after:bg-[url('/assets/icons/download-upload.svg')] dark:after:bg-[url('/assets/icons/download-upload-dark.svg')] after:self-end after:w-8 after:h-8 [&:not(:last-of-type)]:border-b dark:[&:not(:last-of-type)]:border-b-sf-white/10 [&:not(:last-of-type)]:border-b-primary-sf-navy/10 first:rounded-t-md last:rounded-b-md"
        *ngFor="let document of currentDocuments; first as isFirst"
      >
        <div
          id="{{ document.reference }}"
          role="tabpanel"
          (click)="documentService.downloadDocument(document.fileName)"
        >
          <!-- docRow.reference should be an ID when data contract allows for it. -->
          <p class="mb-0 font-semibold">
            {{ document.title }}
          </p>
          <span *ngIf="document.timeStamp" class="font-normal text-sm">
            {{ document.timeStamp }} - {{ document.planNumber }}
          </span>
          <!-- <p>{{ document.planNumber }}</p> -->
          <!-- <p>{{ document.fileName }}</p>  -->
        </div>
      </div>
    </div>

    <div class="mb-4 md:mb-6 xl:mb-8">
      <pagination
        #paginator
        *ngIf="numFilteredDocuments > itemsPerPage"
        class="pagination"
        [customPreviousTemplate]="prevTemplate"
        [customNextTemplate]="nextTemplate"
        [totalItems]="numFilteredDocuments"
        [itemsPerPage]="itemsPerPage"
        (pageChanged)="pageChanged($event)"
      ></pagination>

      <ng-template #nextTemplate>
        <ng-container>
          <i class="fal fa-angle-right fa-lg"></i>
        </ng-container>
      </ng-template>

      <ng-template #prevTemplate>
        <ng-container>
          <i class="fal fa-angle-left fa-lg"></i>
        </ng-container>
      </ng-template>
    </div>
  </div>
</ng-template>
