<div class="sf-card mb-4">
  <div>
    <p class="font-bold">I declare that:</p>

    <ng-container *ngIf="['ISA', 'SISA'].includes(planTypePrefix)">
      <ul>
        <li class="text-sm pb-4 marker:text-[10px]">
          I hereby apply to top up my Shepherds Friendly Society
          {{ planType }}
        </li>
        <li class="text-sm pb-4 marker:text-[10px]">
          I have read the
          <a [attr.href]="importantInfoGuideLink" target="_blank" class="underline">
            Important Information Guide
          </a>
          , and agree that this variation shall form a basis of contract with Shepherds Friendly
          Society Limited
        </li>
        <li class="text-sm pb-4 marker:text-[10px]">
          I agree to the
          {{ planType }} terms and conditions and declare that this application form has been
          completed to the best of my knowledge and belief
        </li>
        <li class="text-sm pb-4 marker:text-[10px]">
          I confirm that I have made my own decision to top up my Stocks and Shares ISA with
          Shepherds Friendly. I have not asked for, nor received any financial advice from Shepherds
          Friendly therefore takes no responsibility for the product's suitability to my
          circumstances.
        </li>
        <li class="text-sm pb-4 marker:text-[10px]">
          I confirm payment is being made from a debit/credit card registered in my name.
        </li>
        <li class="text-sm pb-4 marker:text-[10px]">
          I am resident in the United Kingdom for tax purposes or, if not so resident, either
          perform duties which, by virtue of Section 28 of Income Tax (Earnings & Pensions) Act 2003
          (Crown employees serving overseas), are treated as being performed in the United Kingdom,
          or I am married to, or in a civil partnership with, a person who performs such duties. I
          will inform Shepherds Friendly Society if I cease to be so resident or to perform such
          duties or be married to, or in a civil partnership with, a person who performs such duties
        </li>
        <li class="text-sm marker:text-[10px]">
          A copy of the completed application and The Shepherds Friendly Society Limited’s
          memorandum and Rules are available on request.
        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="['JISA', 'SJISA'].includes(planTypePrefix)">
      <ul>
        <li class="text-sm pb-4 marker:text-[10px]">
          All subscriptions made, and to be made, belong to the child;
        </li>
        <li class="text-sm pb-4 marker:text-[10px]">I am 16 years of age or over;</li>
        <li class="text-sm pb-4 marker:text-[10px]">
          I am the child/I have parental responsibility for the child;
        </li>
        <li class="text-sm pb-4 marker:text-[10px]">
          I/the child does not have a Child Trust Fund;
        </li>
        <li class="text-sm pb-4 marker:text-[10px]">
          I will be the registered contact for the Junior ISA;
        </li>
        <li class="text-sm pb-4 marker:text-[10px]">
          The child is resident in the United Kingdom, or is a UK crown servant, a dependant of a UK
          crown servant, or is married to/in a civil partnership with a UK crown servant;
        </li>
        <li class="text-sm pb-4 marker:text-[10px]">
          I have not subscribed and will not subscribe to another Junior ISA of this type for this
          child;
        </li>
        <li class="text-sm pb-4 marker:text-[10px]">
          I am not aware that this child has another Junior ISA of this type;
        </li>
        <li class="text-sm pb-4 marker:text-[10px]">
          I am not aware of other Junior ISA subscriptions that will result in this child exceeding
          the annual limit;
        </li>
        <li class="text-sm marker:text-[10px]">
          I will not knowingly make subscriptions to Junior ISAs for this child that will result in
          the subscription limit being exceeded;
        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="['CTF'].includes(planTypePrefix)">
      <ul>
        <li class="text-sm pb-4 marker:text-[10px]">
          I hereby apply to top up my Child Trust Fund;
        </li>
        <li class="text-sm pb-4 marker:text-[10px]">
          All subscriptions made, and to be made, belong to me
        </li>
        <li class="text-sm pb-4 marker:text-[10px]">I am 16 years of age or over;</li>
        <li class="text-sm pb-4 marker:text-[10px]">
          I will be the registered contact for the Child Trust Fund
        </li>
        <li class="text-sm pb-4 marker:text-[10px]">
          I am resident in the United Kingdom, or is a UK crown servant, a dependant of a UK crown
          servant, or is married to/in a civil partnership with a UK crown servant;
        </li>
        <li class="text-sm marker:text-[10px]">
          I confirm that I have made my own decision to top up my Child Trust Fund. I have not asked
          for, nor received any financial advice from Shepherds Friendly therefore takes no
          responsibility for the product's suitability to my circumstances.
        </li>
      </ul>
    </ng-container>

    <ng-content></ng-content>
  </div>
</div>
