<section id="manageAuthnTitle">
  <app-content-header [title]="'Biometric authentication'"></app-content-header>
</section>

<section id="manageAuthnContent">
  <div class="pt-0">
    <section *ngIf="!isWebAuthnAvailable">
      <app-alert
        type="warning"
        additionalClass="mb-4 md:mb-6 xl:mb-8"
        iconClass="far fa-exclamation-triangle"
        title="Not supported"
        message="Sorry, your device does not yet support biometric authentication"
        htmlMessage=""
      ></app-alert>
    </section>

    <section *ngIf="isWebAuthnAvailable">
      <div *ngIf="isDeviceRegistered">
        <app-alert
          type="success"
          additionalClass="mb-4 md:mb-6 xl:mb-8"
          iconClass="far fa-check-circle"
          title="Biometrics enabled"
          message="Your device is configured for Biometric authentication! If you would rather return to using password
                            authentication use 'Disable'."
        ></app-alert>

        <div class="sf-card">
          <app-button
            text="Disable"
            [noBorder]="true"
            [fullWidth]="true"
            buttonStyle="warning"
            (click)="unregister()"
            btnWrapperClasses="mb-4 md:mb-6 "
          ></app-button>

          <app-need-help imageSmallSrc="exclamation-triangle"></app-need-help>
        </div>
      </div>

      <div *ngIf="!isDeviceRegistered">
        <app-alert
          type="success"
          iconClass="far fa-fingerprint"
          title="Biometric is supported"
          message="Your device supports biometric authentication,
                            such as face or fingerprint ID. To enable this
                            method of authentication please enter your
                            password and tap ‘Enable’."
        ></app-alert>
      </div>
    </section>

    <div class="sf-card mt-4 md:mt-6 xl:mt-8" *ngIf="isWebAuthnAvailable && !isDeviceRegistered">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="mt-4">
        <div>
          <label for="password" class="form-label">Password</label>
          <div class="input-group">
            <input
              type="{{ showPassword ? 'text' : 'password' }}"
              class="form-control password-input"
              id="password"
              name="password"
              placeholder="Password"
              autocomplete="off"
              required
              formControlName="password"
              [ngClass]="{
                'is-invalid': submitted && f['password'].errors,
              }"
            />
            <span class="password-toggle input-group-text" (click)="togglePasswordVisibility()">
              <i class="far" [class.fa-eye]="showPassword" [class.fa-eye-slash]="!showPassword"></i>
            </span>
          </div>
          <div *ngIf="submitted && f['password'].errors">
            <app-validation-message
              *ngIf="f['password'].errors['required']"
              message="Please enter your password"
            ></app-validation-message>
          </div>
        </div>

        <app-validation-message
          *ngIf="authnError"
          message=" Oops! There was an issue with your Biometric
                            registration - please try again."
        ></app-validation-message>

        <app-button
          text="Enable"
          buttonStyle="secondary"
          [isSubmitButton]="true"
          [noBorder]="true"
          btnWrapperClasses="my-4 md:my-4"
        ></app-button>

        <app-need-help imageSmallSrc="exclamation-triangle"></app-need-help>
      </form>
    </div>
  </div>
</section>
