export class DirectDebit {
  public id: number;
  public sortCode: number | string;
  public acNumber: string;
  public acName: string;
  public amount: number;
  public period: number;
  public periodDue: number;
  public dayDue: number;
  public startDate: Date | string;
  public isLive: boolean;
  public nextPaymentDate: Date | string;
  public changeCount: number;
  public followingPaymentAmount: number;
  public followingPaymentDate: Date | string;

  constructor(
    id: number,
    sortCode: number,
    acNumber: string,
    acName: string,
    amount: number,
    period: number,
    periodDue: number,
    dayDue: number,
    startDate: Date | string,
    isLive: boolean,
    nextPaymentDate: Date | string,
    changeCount: number,
    followingPaymentAmount: number,
    followingPaymentDate: Date | string
  ) {
    this.id = id;
    this.sortCode = sortCode;
    this.acNumber = acNumber;
    this.acName = acName;
    this.amount = amount;
    this.period = period;
    this.periodDue = periodDue;
    this.dayDue = dayDue;
    this.startDate = startDate;
    this.isLive = isLive;
    this.nextPaymentDate = nextPaymentDate;
    this.changeCount = changeCount;
    this.followingPaymentAmount = followingPaymentAmount;
    this.followingPaymentDate = followingPaymentDate;
  }
}
