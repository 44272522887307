<div
  class="group"
  *ngFor="let option of options"
  [ngClass]="{ active: selectedValue === option.value }"
>
  <label
    class="border border-primary-sf-navy p-6 mb-4 w-full rounded-md group-[.active]:bg-primary-sf-yellow group-[active.]:border-transparent group-[.active]:text-primary-sf-navy group-[.active]:font-bold"
  >
    <input
      type="radio"
      name="radioOptions"
      [value]="option.value"
      [(ngModel)]="selectedValue"
      (change)="onSelectionChange(option)"
      class="mr-2"
    />
    {{ option.label }}
  </label>
</div>
