import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PlanOverviewComponent,
  DirectDebitOverviewComponent,
  AddressPickerComponent,
  AlertComponent,
  ButtonComponent,
  CardWithImageComponent,
  ContentHeaderComponent,
  LoginHeaderComponent,
  QuickActionButtonComponent,
  StepsComponent,
  ValidationMessageComponent,
  SurveyPromptComponent,
  AccordionComponent,
  RadioListComponent,
  CheckboxComponent,
  LoginInfoMessageComponent,
  NeedHelpComponent,
} from './components';
import { InterpolatePipe, JoinPipe, SafePipe } from './pipes';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const PIPES = [InterpolatePipe, JoinPipe, SafePipe];
const COMPONENTS = [
  PlanOverviewComponent,
  DirectDebitOverviewComponent,
  LoginHeaderComponent,
  AddressPickerComponent,
  AlertComponent,
  ButtonComponent,
  StepsComponent,
  CheckboxComponent,
  ContentHeaderComponent,
  QuickActionButtonComponent,
  CardWithImageComponent,
  ValidationMessageComponent,
  SurveyPromptComponent,
  AccordionComponent,
  RadioListComponent,
  LoginInfoMessageComponent,
  NeedHelpComponent,
];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgxMaskModule.forRoot()],
  declarations: [PIPES, COMPONENTS],
  exports: [PIPES, COMPONENTS, NgxMaskModule],
})
export class SharedModule {}
