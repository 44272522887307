<div class="ssi-fund-performance-calculator">
  <div class="plan-overview pointer-events-none squared-bottom">
    <header>
      <h2 class="text-xl font-medium mb-1">Potential ISA value in 10 years:</h2>
    </header>

    <div class="plan-card__overview">
      <p class="card-full-value">£7,947.45</p>
      <p class="text-base opacity-80 mb-1">With your £7,000.00 contributions.</p>
    </div>
  </div>

  <div
    class="bg-sf-white dark:bg-primary-sf-navy p-4 rounded-md shadow-sm w-full flex flex-col relative"
  >
    <div class="md:grid md:grid-cols-2 md:gap-8">
      <div>
        <p class="flex justify-between items-start text-gray-700 mb-1 md:mb-0">
          <span>Strong market performance:</span>
          <span class="font-medium">£8,592.46</span>
        </p>
        <p
          class="bg-sf-light-green rounded text-tertiary-sf-pale-green w-full p-2 text-center md:mt-1"
        >
          £10,447.50 vs current plan
        </p>
      </div>

      <div>
        <p class="flex justify-between items-start text-gray-700 mb-1 md:m-0">
          <span>Poor market performance:</span>
          <span class="font-medium">£7,454.98</span>
        </p>
        <p
          class="bg-sf-light-green rounded text-tertiary-sf-pale-green w-full p-2 text-center md:mt-1"
        >
          £1,938.03 vs current plan
        </p>
      </div>
    </div>
    <form
      #form="ngForm"
      id="ssi-fund-performance-calculator-form"
      [formGroup]="ssiFundPerformanceCalculatorForm"
      (ngSubmit)="onSubmit(form)"
    >
      <div class="mb-4">
        <label for="projectionInYears" class="form-label">
          How many years would you like to project?
        </label>
        <div class="input-group mb-4">
          <input
            id="projectionInYears"
            name="projectionInYears"
            formControlName="projectionInYears"
            type="text"
            min="0"
            class="form-control"
            [ngClass]="{
        'is-invalid': projectionInYearsCtrl.touched && projectionInYearsCtrl.errors,
      }"
          />
        </div>
      </div>

      <app-button
        text="Change Investment Style"
        buttonStyle="secondary"
        [noBorder]="true"
        [alignRight]="true"
        [disabled]="!form.valid"
        [isSubmitButton]="true"
      ></app-button>
    </form>
  </div>
</div>
