import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MemberProfile } from '@app/models/profile';
import { catchError, Observable, throwError } from 'rxjs';
import { ProfileService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class ProfileResolver {
  constructor(private profileService: ProfileService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MemberProfile> {
    return this.profileService
      .getMemberProfile()
      .pipe(catchError((error) => throwError(() => `ProfileResolver -> ${error}`)));
  }
}
