import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SavingsPlan } from '@app/models/member-portal';
import { Subscription } from 'rxjs';
import { MemberDashboard } from 'src/app/models/member-portal/member-dashboard.model';

@Component({
  selector: 'app-plan-choice',
  templateUrl: './plan-choice.component.html',
  styleUrls: ['./plan-choice.component.scss'],
})
export class PlanChoiceComponent implements OnInit, OnDestroy {
  readonly url = {
    TOP_UP: (formattedPlanNumber) => `/plans/deposit/${formattedPlanNumber.toLowerCase()}`,
    DIRECT_DEBIT: `/direct-debit/plan`,
  };
  private subscribeUntilDestroyed = new Subscription();
  public memberDashboard: MemberDashboard;
  public intent: string;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.intent = this.activatedRoute.snapshot.params['intent'];
  }

  ngOnInit(): void {
    this.loadMemberDashboardFromRoute();
  }

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  /**
   * Navigates to the top up url and passes a plan and member object in router state
   * @param {SavingsPlan} [plan]
   */
  public navigateWithState(plan: SavingsPlan): void {
    const url =
      this.intent === 'deposit' ? this.url.TOP_UP(plan.formattedPlanNumber) : this.url.DIRECT_DEBIT;

    this.router.navigate([url], {
      state: { plan, member: this.memberDashboard.member },
    });
  }

  /**
   * Retrieves member dashboard data from the activated route data
   */
  private loadMemberDashboardFromRoute(): void {
    this.subscribeUntilDestroyed.add(
      this.activatedRoute.data.subscribe(
        (routeData) => (this.memberDashboard = routeData['memberDashboard'])
      )
    );
  }

  /**
   * Checks if any plans have 'isIssued' set to false.
   */
  get hasUnissuedPlans(): boolean {
    const savingsPlans = this.memberDashboard?.memberPlans?.savingsPlans || [];
    const authorisedSavingsPlans =
      this.memberDashboard?.authorisedPlans?.authorisedSavingsPlans || [];

    return (
      savingsPlans.some((plan) => !plan.isIssued) ||
      authorisedSavingsPlans.some((asp) => !asp.plan.isIssued)
    );
  }
}
