import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PlanOverviewComponent,
  DirectDebitOverviewComponent,
  AddressPickerComponent,
  AlertComponent,
  ButtonComponent,
  CardWithImageComponent,
  ContentHeaderComponent,
  LoginHeaderComponent,
  QuickActionButtonComponent,
  QuickActionButtonListComponent,
  QuickActionButtonMoreComponent,
  QuickActionButtonItemComponent,
  StepsComponent,
  ValidationMessageComponent,
  SurveyPromptComponent,
  AccordionComponent,
  RadioListComponent,
  CheckboxComponent,
  LoginInfoMessageComponent,
  NeedHelpComponent,
} from './components';
import { InterpolatePipe, JoinPipe, SafePipe } from './pipes';
import { FeatureFlagDirective } from './directives';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

const PIPES = [InterpolatePipe, JoinPipe, SafePipe];
const COMPONENTS = [
  PlanOverviewComponent,
  DirectDebitOverviewComponent,
  LoginHeaderComponent,
  AddressPickerComponent,
  AlertComponent,
  ButtonComponent,
  StepsComponent,
  CheckboxComponent,
  ContentHeaderComponent,
  QuickActionButtonComponent,
  QuickActionButtonListComponent,
  QuickActionButtonItemComponent,
  QuickActionButtonMoreComponent,
  CardWithImageComponent,
  ValidationMessageComponent,
  SurveyPromptComponent,
  AccordionComponent,
  RadioListComponent,
  LoginInfoMessageComponent,
  NeedHelpComponent,
];
const DIRECTIVES = [FeatureFlagDirective];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgxMaskDirective, NgxMaskPipe],
  declarations: [PIPES, COMPONENTS, DIRECTIVES],
  exports: [PIPES, COMPONENTS, DIRECTIVES],
  providers: [provideNgxMask()],
})
export class SharedModule {}
