import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WP_REST_API_Post_Override } from 'src/app/models/wp-types-overrides/WP_REST_API_Post_Override';
import { HttpService } from 'src/app/services/http.service';
import { WordpressApiService } from 'src/app/services/wordpress-api.service';

@Component({
    selector: 'app-help-page',
    templateUrl: './help-page.component.html',
    styleUrls: ['./help-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class HelpPageComponent implements OnInit {
  public postId: string;
  public page: WP_REST_API_Post_Override;

  constructor(
    private httpService: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private wpService: WordpressApiService
  ) {}

  ngOnInit(): void {
    this.postId = this.route.snapshot.paramMap.get('id')?.toString() ?? '';

    if (this.postId === '') {
      this.router.navigateByUrl('/help');
    } else {
      this.wpService.getPage(this.postId).subscribe((response) => {
        this.page = response;
      });
    }
  }
}
