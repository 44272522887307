<app-content-header
  *ngIf="ssiDetails"
  [title]="'Stocks and Shares ISA'"
  [subtitle]="ssiDetails.formattedPlanNumber"
/>
<div *ngIf="ssiDetails">
  <div class="ssi-graph p-4 mb-4 bg-primary-sf-navy rounded-md lg:grid lg:grid-tem">
    <div class="maturity">
      <h2 class="text-lg lg:text-xl text-white font-normal mb-1 lg:mb-2">Current maturity value</h2>
      <h3 class="text-4xl lg:text-5xl text-primary-sf-yellow font-bold mb-2">£2,534.23</h3>
    </div>

    <div class="change text-white">
      <div class="flex justify-between">
        <h4>Change</h4>
        <p class="font-bold mb-1 lg:mb-2 text-base lg:text-xl">
          <i class="fas fa-plus text-white mr-1"></i>
          <span>£911.05 (6.36%)</span>
        </p>
      </div>
      <div class="flex justify-between">
        <h4>Total invested</h4>
        <p class="font-bold mb-1 text-base lg:text-xl">
          <i class="fas fa-caret-up text-green-500 mr-1"></i>
          <span>£13,951.12</span>
        </p>
      </div>
    </div>

    <div class="graph">
      <img
        src="/assets/images/ssi-graph-placeholder.png"
        width="100%"
        height="auto"
        alt="Graph"
        class="mt-4"
      />
    </div>

    <div class="controls mt-4 flex rounded-md bg-black bg-opacity-25 p-1 justify-between">
      <button class="date-toggle-button !text-black bg-primary-sf-yellow">3W</button>
      <button class="date-toggle-button">6M</button>
      <button class="date-toggle-button">1Y</button>
      <button class="date-toggle-button">Max</button>
    </div>
  </div>

  <app-quick-action-button-list [moreLinks]="quickActionMoreLinks">
    <app-quick-action-button-item
      class="flex-1"
      [quickActionBtnIcon]="'fal fa-arrow-circle-down'"
      [quickActionBtnText]="'Deposit'"
      (click)="navigateWithState(url.TOP_UP(ssiDetails.formattedPlanNumber))"
    ></app-quick-action-button-item>

    <app-quick-action-button-item
      class="flex-1"
      [quickActionBtnIcon]="'fal fa-credit-card'"
      [quickActionBtnText]="'Direct Debit'"
      (click)="navigateWithState(url.DIRECT_DEBIT)"
    ></app-quick-action-button-item>

    <app-quick-action-button-item
      class="flex-1"
      [quickActionBtnIcon]="'fal fa-file-alt'"
      [quickActionBtnText]="'Documents'"
      (click)="navigateWithState(url.DOCUMENTS(ssiDetails.formattedPlanNumber))"
    ></app-quick-action-button-item>
  </app-quick-action-button-list>

  <app-risk-level-asset-allocation></app-risk-level-asset-allocation>

  <h2 class="text-2xl my-4">Useful Information</h2>

  <app-accordion
    [items]="[
    {
      title: 'Changing investment style',
      content: [
        'When you turn 18, money can no longer be deposited into your plan.  Alternatively, you can chapp-risk-levelr fund out of your CTF once it matures.',
        'To make the most of tax-free savings, once your bond matures you can simply choose to reinvest it into an adult Stocks & Shares ISA with Shepherds Friendly.',
        'Alternatively, you can choose to take part or all of your fund out of your CTF once it matures.',
      ],
    },
    {
      title: 'Shepherds Friendly fees',
      content: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      ],
    },
    {
      title: 'Why does the value change?',
      content: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      ],
    },
    {
      title: 'Why does the value change?',
      content: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      ],
    },
    {
      title: 'Why does the value change?',
      content: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      ],
    },
    {
      title: 'Why does the value change?',
      content: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      ],
    },
  ]"
  ></app-accordion>
</div>
