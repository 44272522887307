import { Injectable } from '@angular/core';
import { AppConstantsService } from './app-constants.service';
import {
  Observable,
  map,
  catchError,
  throwError,
  shareReplay,
  BehaviorSubject,
  tap,
  take,
  filter,
} from 'rxjs';
import { HttpService } from './http.service';
import { AuthService } from './auth.service';
import { NotificationItem, Notifications } from '@app/models/member-portal';
import { NotificationsCacheService } from './cache-concrete.service';
import { StorageKey } from '@app/models/security';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {
  isSupported,
  getMessaging,
  getToken,
  deleteToken,
  MessagePayload,
  onMessage,
} from 'firebase/messaging';
import { environment } from '@env/environment';
import { NotificationValidate } from '@app/models/profile/notifications.model';

// extension declarations for running in WebView under iOS/Android
declare function interop_android(): boolean;
declare function interop_ios(): boolean;
declare function invoke_DoRequestToken(): string;
declare function invoke_DoDeleteToken(): string;

@Injectable()
export class NotificationsService {
  public readonly api = {
    REGISTER_DEVICE: `${this.configuration.server}/api/Notifications/RegisterDevice`,
    VALIDATE_DEVICE: `${this.configuration.server}/api/Notifications/ValidateDevice`,
    UNREGISTER_DEVICE: `${this.configuration.server}/api/Notifications/UnregisterDevice`,
    NOTIFICATION: (id: string): string =>
      `${this.configuration.server}/api/notifications/GetNotification/${id}`,
    NOTIFICATIONS: (page: number): string =>
      `${
        this.configuration.server
      }/api/notifications/GetNotificationsForUser/${this.filterTransactions(page)}`,
  };
  public readonly maxItemsPerPage = 100;
  public notificationPublished$ = new BehaviorSubject<MessagePayload>(undefined);
  public notificationsUnread$ = new BehaviorSubject<boolean>(undefined);
  private token: string;
  private apiSupportAvailable: boolean;

  constructor(
    private configuration: AppConstantsService,
    private http: HttpService,
    private auth: AuthService,
    private notificationsCacheService: NotificationsCacheService,
    private toastr: ToastrService,
    private router: Router
  ) {
    // check if we are supporting the necessary API (mainly for iOS when not installed to home page)
    this.checkIfSupported().then((result) => {
      this.apiSupportAvailable = result;

      if (!this.apiSupportAvailable) {
        console.warn('Notifications: API support not available.');
      } else {
        // read any stored token
        this.token = localStorage.getItem(StorageKey.SF_NOTIFICATIONS_REG);

        // validate token
        if (this.token) {
          console.log(`Notifications: Service initialise - we have token = ${this.token}`);

          // enable in-application notification handling
          this.listen();
        } else {
          console.log(`Notifications: Service initialise - we have no token.`);
        }

        // hook TFA authentication point
        this.auth.authenticated$.pipe(filter(Boolean)).subscribe((isAuthenticated) => {
          if (!this.token) {
            console.warn('Notifications: Could not perform device update we have no token.');
          } else if (isAuthenticated) {
            // update device token against user
            let userAgent = window.navigator.userAgent;
            this.updateDevice(this.token, userAgent).subscribe();
          }
        });
      }
    });
  }

  checkIfSupported(): Promise<boolean> {
    // check for native support
    var requestPromise = null;
    if (
      (typeof interop_android === 'function' && interop_android()) ||
      (typeof interop_ios === 'function' && interop_ios())
    ) {
      // it can be assumed native always has notifications support
      return Promise.resolve(true);
    } else {
      return isSupported();
    }
  }

  /**
   * Do we have API support for notifications.
   */
  public get isSupported(): boolean {
    return this.apiSupportAvailable;
  }

  /**
   * Register for notifications.
   */
  public register(): Promise<boolean> {
    // we need permission & token from firebase
    return this.requestToken().then((token) => {
      if (token) {
        return new Promise<boolean>((resolve, reject) => {
          // register device token against user
          const userAgent = window.navigator.userAgent;
          this.registerDevice(token, userAgent).subscribe({
            next: (r) => {
              console.log('Notifications: Device registered.');

              // assign token
              this.token = token;

              // save token
              localStorage.setItem(StorageKey.SF_NOTIFICATIONS_REG, token);

              // enable in-application notification handling
              this.listen();

              resolve(r);
            },
            error: () => {
              console.warn('Notifications: Device registration failed.');

              reject(false);
            },
          });
        });
      } else {
        return Promise.reject(false);
      }
    });
  }

  /**
   * Are we registered for notifications.
   */
  public get isRegistered(): boolean {
    return this.token != undefined;
  }

  /**
   * Validate our registration for notifications.
   */
  public validate(): Promise<boolean> {
    // validate device token against user
    return new Promise((resolve, reject) => {
      this.validateDevice(this.token).subscribe({
        next: (r) => {
          if (r) {
            console.log('Notifications: Device validated.');
          } else {
            console.warn('Notifications: Token failed validation (token invalidated).');

            // unassign token
            this.token = undefined;

            // remove token
            localStorage.removeItem(StorageKey.SF_NOTIFICATIONS_REG);
          }

          resolve(r);
        },
        error: () => {
          console.warn('Notifications: Device validation failed.');

          reject(false);
        },
      });
    });
  }

  /**
   * Unregister for notifications.
   */
  public unregister(): Promise<boolean> {
    // delete token from firebase
    return this.deleteToken().then((result) => {
      if (result) {
        // unregister device token against user
        return new Promise((resolve, reject) => {
          this.unregisterDevice(this.token).subscribe({
            next: (r) => {
              console.log('Notifications: Device unregistered.');

              // unassign token
              this.token = undefined;

              // remove token
              localStorage.removeItem(StorageKey.SF_NOTIFICATIONS_REG);

              resolve(r);
            },
            error: () => {
              console.warn('Notifications: Device unregistration failed.');

              reject(false);
            },
          });
        });
      } else {
        return Promise.reject(false);
      }
    });
  }

  /**
   * Request permission to show notifications and obtain a token for it (Firebase).
   */
  private requestToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      // check for native support
      var requestPromise = null;
      if (
        (typeof interop_android === 'function' && interop_android()) ||
        (typeof interop_ios === 'function' && interop_ios())
      ) {
        requestPromise = this.invoke_native_request_token();
      } else {
        const messaging = getMessaging();
        requestPromise = getToken(messaging, {
          vapidKey: environment.firebase.vapidKey,
        });
      }

      requestPromise
        .then((token) => {
          if (token) {
            console.log(`Firebase: Obtained token ${token}`);

            resolve(token);
          } else {
            console.warn(
              'Firebase: No registration token available. Request permission to generate one.'
            );
            reject(undefined);
          }
        })
        .catch((err) => {
          console.error('Firebase: An error occurred while retrieving the token. ', err);
          reject(undefined);
        });
    });
  }

  /**
   * Request token deletion (Firebase).
   */
  private deleteToken(): Promise<boolean> {
    // check for native support
    var deletePromise = null;
    if (
      (typeof interop_android === 'function' && interop_android()) ||
      (typeof interop_ios === 'function' && interop_ios())
    ) {
      deletePromise = this.invoke_native_delete_token();
    } else {
      const messaging = getMessaging();
      deletePromise = deleteToken(messaging);
    }

    return deletePromise
      .then(() => {
        console.log(`Firebase: Deleted token ${this.token}`);

        return true;
      })
      .catch((err) => {
        console.error('Firebase: An error occurred while deleting the token. ', err);
        return false;
      });
  }

  /**
   * Register for in-application notifications (Firebase).
   */
  listen() {
    // check for native support
    var requestPromise = null;
    if (
      (typeof interop_android === 'function' && interop_android()) ||
      (typeof interop_ios === 'function' && interop_ios())
    ) {
      (window as any).OnMessage = (jsonIn: string) => {
        // JSON -> obj
        if (!jsonIn) {
          return;
        }

        let payload = JSON.parse(jsonIn);
        console.log('Notifications: Payload =', payload);
        this.onNotificationPublished(payload);
      };
    } else {
      const messaging = getMessaging();
      onMessage(messaging, (payload: MessagePayload) => this.onNotificationPublished(payload));
    }
  }

  /**
   * Checks state management for a recent copy of `Notifications`, if outdated or non existant,
   * a new copy is retrieved and recached.
   * @param {number} [page]
   * @return Observable that emits the latest Notifications
   */
  public getNotifications(page: number = 0): Observable<Notifications> {
    let notifications$ = this.notificationsCacheService.getValue();
    if (!notifications$ || page) {
      notifications$ = this.http.get<Notifications>(this.api.NOTIFICATIONS(page)).pipe(
        shareReplay(1),
        tap((notifications) => this.hasUnreadNotifications(notifications.items)),
        catchError((error) => throwError(() => `getNotifications -> ${error}`))
      );
      this.notificationsCacheService.setValue(notifications$);
    }
    return notifications$;
  }

  /**
   * Checks state management for a recent copy of `Notifications`, if outdated or non existant,
   * a new copy is retrieved and recached.
   * @param {number} [page]
   * @return Observable that emits the latest Notifications
   */
  public getNotification(id: string): Observable<NotificationItem> {
    return this.http.get<NotificationItem>(this.api.NOTIFICATION(id)).pipe(
      shareReplay(1),
      catchError((error) => throwError(() => `getNotification -> ${error}`))
    );
  }

  /**
   * Clears down the notification service cache, signals a new notificiation event and shows a toastr notification
   * The notification on click will take the user to the defined URL if it exists.
   * @param {MessagePayload} [payload]
   */
  public onNotificationPublished(payload: MessagePayload): void {
    this.clearCache();
    this.notificationPublished$.next(payload);
    this.notificationsUnread$.next(true);
    this.toastr
      .info(payload.notification.title, payload.notification.body)
      .onTap.pipe(take(1))
      .subscribe(() => {
        if (payload.fcmOptions) {
          const notificationId = payload.fcmOptions.link.split('/').pop();
          this.router.navigate(['notifications', notificationId]);
        }
      });
  }

  /**
   * Sets notificationsUnread$ true if 1 or more notifications returned are not in localStorage read array
   * @param {NotificationItem} [notifications]
   */
  public hasUnreadNotifications(notifications: NotificationItem[]): void {
    const notificationIds = JSON.parse(localStorage.getItem(StorageKey.SF_NOTIFICATIONS)) || [];

    const hasReadAllNotifications = notifications.every((notification: NotificationItem) =>
      notificationIds.includes(notification.id)
    );

    this.notificationsUnread$.next(!hasReadAllNotifications);
  }

  /**
   * Takes a page number and returns as a decorated query url
   * @param {number} [page]
   * @return A string consisting of query parameters
   */
  public filterTransactions(page: number): string {
    let qs = '?$top=' + this.maxItemsPerPage;
    qs += '&$skip=' + page * this.maxItemsPerPage;
    qs += '&$orderby=SentDate desc';
    return qs;
  }

  /**
   * Sets a single notification as read in local storage
   * @param {string} [notificationId]
   */
  public setNotificationRead(notificationId: string): void {
    const currentNotificationIds =
      JSON.parse(localStorage.getItem(StorageKey.SF_NOTIFICATIONS)) || [];

    localStorage.setItem(
      StorageKey.SF_NOTIFICATIONS,
      JSON.stringify([...new Set([...currentNotificationIds, notificationId])])
    );
  }

  /**
   * Sets a single notification as unread in local storage
   * @param {string} [notificationId]
   */
  public setNotificationUnread(notificationId: string): void {
    const currentNotificationIds =
      JSON.parse(localStorage.getItem(StorageKey.SF_NOTIFICATIONS)) || [];

    localStorage.setItem(
      StorageKey.SF_NOTIFICATIONS,
      JSON.stringify(currentNotificationIds.filter((id: string) => id !== notificationId))
    );
  }

  /**
   * Sets all notifications as read in local storage
   * @param {string} [notificationId]
   */
  public setAllNotificationsRead(notificationIds: string[]): void {
    localStorage.setItem(StorageKey.SF_NOTIFICATIONS, JSON.stringify(notificationIds));
  }

  /**
   * Sets all notifications as unread in local storage
   */
  public setAllNotificationsUnread(): void {
    localStorage.removeItem(StorageKey.SF_NOTIFICATIONS);
  }

  /**
   * Clears the current cache for this service
   */
  public clearCache(): void {
    this.notificationsCacheService.clearCache();
  }

  /**
   * Register device with API server for user
   * @param {string} [token]
   * @param {string} [description]
   */
  private registerDevice(token: string, description: string): Observable<boolean> {
    // register device token against user
    const host = window.location.origin;
    const data = { token: token, description: description, host: host };
    return this.http
      .post<boolean>(this.api.REGISTER_DEVICE, data, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return true;
        })
      );
  }

  /**
   * Update device on API server
   * @param {string} [token]
   * @param {string} [description]
   */
  private updateDevice(token: string, description: string): Observable<boolean> {
    // update device against user
    const host = window.location.origin;
    const data = { token: token, description: description, host: host };
    return this.http
      .post<boolean>(this.api.REGISTER_DEVICE, data, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return true;
        })
      );
  }

  /**
   * Validate device with API server for user
   * @param {string} [token]
   */
  private validateDevice(token: string): Observable<boolean> {
    // validate device token against user
    const data = { token: token };
    return this.http
      .post<NotificationValidate>(this.api.VALIDATE_DEVICE, data, {
        observe: 'body',
      })
      .pipe(
        map((response) => {
          return response.validated;
        })
      );
  }

  /**
   * Unregister device with API server for user
   * @param {string} [token]
   */
  private unregisterDevice(token: string): Observable<boolean> {
    // unregister device token against user
    const data = { token: token };
    return this.http
      .post<boolean>(this.api.UNREGISTER_DEVICE, data, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return true;
        })
      );
  }

  invoke_native_request_token(): Promise<string> {
    // resolve promise on native callback
    return new Promise((resolve, reject) => {
      (window as any).DoneRequestToken = (token: string) => {
        if (!token) {
          reject();
        }

        resolve(token);
      };

      // call native function
      invoke_DoRequestToken();
    });
  }

  invoke_native_delete_token(): Promise<void> {
    // resolve promise on native callback
    return new Promise((resolve, reject) => {
      (window as any).DoneDeleteToken = (rv: string) => {
        if (!rv) {
          reject();
        }

        resolve();
      };

      // call native function
      invoke_DoDeleteToken();
    });
  }
}
