<div class="sf-card">
  <p class="font-semibold dark:text-sf-white">{{ accordionHeader }}</p>
  <p>{{ accordionContent }}</p>
  <div
    *ngFor="let item of items; let i = index"
    class="last:border-b last:border-primary-sf-navy/10 dark:border-sf-white/10"
  >
    <div
      class="flex justify-between items-center py-3 px-0 dark:text-sf-white border-t border-primary-sf-navy/10 font-semibold dark:border-sf-white/10"
      (click)="toggleItem(i)"
    >
      {{ item.title }}

      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="33"
          fill="none"
          *ngIf="!isOpen(i)"
        >
          <g opacity=".75">
            <rect width="32" height="32" y=".87" fill="#F5F6F6" rx="16" />
            <path
              stroke="#25282B"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M16 9.87v14M9 16.87h14"
            />
          </g>
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="33"
          fill="none"
          *ngIf="isOpen(i)"
        >
          <rect width="32" height="32" y=".87" fill="#F8AB00" rx="16" />
          <path
            stroke="#25282B"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 16.87h14"
          />
        </svg>
      </div>
    </div>
    <div [@openClose]="isItemOpen(i)">
      <div *ngIf="isOpen(i)">
        <ng-container *ngIf="isContentArray(item)">
          <ng-container *ngFor="let paragraph of item.content">
            <p>{{ paragraph }}</p>
          </ng-container>
        </ng-container>
        <p *ngIf="!isContentArray(item)">{{ item.content }}</p>
      </div>
    </div>
  </div>
</div>
