<div class="sf-card mb-4">
  <h5 class="text-primary-sf-navy dark:text-sf-white font-semibold mb-2 lg:text-xl">
    I declare that:
  </h5>
  <div>
    <app-checkbox
      label="I confirm that I have sent the required ID verification details via email"
      [(ngModel)]="checked"
      id="accept"
      name="accept"
      hasBorderTop="true"
      [additionalClass]="'py-4'"
    ></app-checkbox>

    <app-button
      text="Continue"
      [disabled]="!checked"
      (click)="completed()"
      buttonStyle="secondary"
      [isSubmitButton]="false"
      customClass="mt-4"
    />
  </div>
</div>
