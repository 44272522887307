<app-content-header [title]="'Mature your fund'" [subtitle]="ctfDetails.plan.formattedPlanNumber" />
<app-steps
  [currentStep]="step"
  [enumType]="stepOpts"
  [customClass]="'[&_li]:max-md:basis-full [&_li]:md:basis-2/4 [&_li]:md:p-0'"
></app-steps>

<div
  class="step"
  [ngClass]="{
    'hidden-step': !(step === stepOpts['YOUR_DETAILS']),
  }"
>
  <h5 class="mb-4">Confirm your details</h5>
  <div class="mb-4">
    <ul class="p-0">
      <li
        class="flex flex-col list-none bg-sf-white dark:bg-primary-sf-navy dark:text-sf-white p-4 border-b border-primary-sf-navy/10 dark:border-sf-white/10 rounded-t-md"
      >
        <strong>Name</strong>
        <span>
          {{ ctfDetails.member.title }}
          {{ ctfDetails.member.firstname }}
          {{ ctfDetails.member.surname }}
        </span>
      </li>
      <li
        class="flex flex-col list-none bg-sf-white dark:bg-primary-sf-navy dark:text-sf-white p-4 border-b border-primary-sf-navy/10 dark:border-sf-white/10"
      >
        <strong>Email address</strong>
        <span>{{ memberProfile.emailAddress }}</span>
      </li>
      <li
        class="flex flex-col list-none bg-sf-white dark:bg-primary-sf-navy dark:text-sf-white p-4 border-b border-primary-sf-navy/10 dark:border-sf-white/10"
      >
        <strong>Mobile number</strong>
        <span>
          {{ ctfDetails.member.mobileTelephone }}
        </span>
      </li>
      <li
        class="flex flex-col list-none bg-sf-white dark:bg-primary-sf-navy dark:text-sf-white p-4 border-b border-primary-sf-navy/10 dark:border-sf-white/10"
      >
        <strong>Residential address</strong>
        <span>{{ formattedCurrentAddress }}</span>
      </li>
      <li
        class="flex flex-col list-none bg-sf-white dark:bg-primary-sf-navy dark:text-sf-white p-4 border-b border-primary-sf-navy/10 dark:border-sf-white/10"
      >
        <strong>Date of birth</strong>
        <span>{{ ctfDetails.member.dob | date }}</span>
      </li>
      <li
        class="flex flex-col list-none bg-sf-white dark:bg-primary-sf-navy dark:text-sf-white p-4 border-none rounded-b-md"
      >
        <strong>National Insurance number</strong>
        <span>
          {{ ctfDetails.plan.formattedPlanNumber }}
        </span>
      </li>
    </ul>
  </div>

  <app-button
    text="Proceed to step 2"
    (click)="goToStep('ID_VERIFICATION')"
    buttonStyle="secondary"
    [isSubmitButton]="false"
    customClass="mt-4"
  />

  <app-alert
    additionalClass="my-4"
    iconClass="far fa-question-circle"
    type="info"
    title="Details incorrect?"
  >
    <p>
      If any of the details are incorrect please call
      <a href="tel:0800 526 249" class="dark:text-sf-white">0800 526 249</a>
      to speak to our Member Services Team.
    </p>
  </app-alert>
</div>

<div class="step" [ngClass]="{ 'hidden-step': !(step === stepOpts['ID_VERIFICATION']), }">
  <div class="sf-card mb-4 mature-ctf-verification">
    <h5 class="text-primary-sf-navy dark:text-sf-white font-semibold mb-2 lg:text-xl">
      How to verify your IDs
    </h5>
    <div>
      <p>To confirm your identity, please send the following to us using the instructions below:</p>
      <ul>
        <li>A photograph of your driving licence or passport.</li>
        <li>An image of a bank statement from the last 3 months showing your name and address.</li>
      </ul>
      <p>
        Uploaded images
        <strong>must follow the guidance</strong>
        below. If not they will have to be redone.
      </p>
      <div class="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" class="mr-2">
          <circle cx="11" cy="11" r="11" fill="#F8AB00" />
          <path
            fill="#fff"
            d="M8.37 5.848a.75.75 0 1 0-.74 1.304l.74-1.304Zm-.74 9-.653.37.741 1.305.653-.37-.742-1.305Zm7.907-4.065.37-.652-.37.652Zm0 .434.37.652-.37-.652ZM7.63 7.152l7.538 4.283.74-1.305-7.536-4.282-.742 1.304Zm7.538 3.413-7.538 4.283.742 1.304 7.537-4.283-.741-1.304Zm0 .87a.5.5 0 0 1 0-.87l.74 1.305a1 1 0 0 0 0-1.74l-.74 1.305Z"
          />
        </svg>

        <span class="btn-link" (click)="openModal(template)" class="underline cursor-pointer">
          View image guidance
        </span>

        <ng-template #template>
          <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div
              class="fixed inset-0 bg-black bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            <div class="fixed inset-0 z-10 w-screen">
              <div class="flex min-h-full justify-center p-4 text-center items-center">
                <div
                  class="relative transform overflow-hidden rounded-md transition-all w-full max-w-lg"
                >
                  <div class="flex flex-col bg-white dark:bg-primary-sf-navy">
                    <div class="flex flex-col items-center">
                      <div class="self-end pr-2 pt-2" (click)="modalRef.hide()">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          class="cursor-pointer"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M18 6 6 18M6 6l12 12"
                            class="stroke-primary-sf-navy dark:stroke-sf-white"
                          />
                        </svg>
                      </div>
                      <div class="text-center">
                        <h4
                          class="text-xl text-center font-semibold leading-6 text-gray-900 dark:text-sf-white"
                          id="modal-title"
                        >
                          Image guidance
                        </h4>

                        <div class="mt-2">
                          <p class="max-sm:text-sm">
                            Please follow this guidance when taking photos.
                          </p>
                        </div>
                      </div>
                    </div>
                    <carousel [interval]="0" [isAnimated]="true" [noWrap]="true">
                      <slide>
                        <div class="flex flex-row">
                          <div class="relative ml-2.5 flex-1 h-40 first:ml-0">
                            <div
                              class="relative bg-[url('/assets/images/image-guidance/backdrop.jpg')] bg-cover bg-no-repeat bg-center rounded-md overflow-hidden h-40"
                            >
                              <img
                                src="/assets/images/image-guidance/valid-drivers-licence.png"
                                style="display: block; width: 100%"
                              />
                            </div>

                            <svg
                              class="absolute left-[50%] bottom-[-11px] ml-[-9px]"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="11.75"
                                cy="11.75"
                                r="10.75"
                                fill="#118C7F"
                                stroke="white"
                                stroke-width="2"
                              />
                              <path
                                d="M16.5833 8L10.1667 14.4167L7.25 11.5"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          <div class="relative ml-2.5 flex-1 h-40 first:ml-0">
                            <div
                              class="relative bg-[url('/assets/images/image-guidance/backdrop.jpg')] bg-cover bg-no-repeat bg-center rounded-md overflow-hidden h-40"
                            >
                              <img
                                src="/assets/images/image-guidance/step-1-invalid-drivers-licence.png"
                                style="display: block; width: 100%"
                              />
                            </div>

                            <svg
                              class="absolute left-[50%] bottom-[-11px] ml-[-9px]"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="12"
                                cy="11.75"
                                r="10.75"
                                fill="#DC3545"
                                stroke="white"
                                stroke-width="2"
                              />
                              <path
                                d="M15.2497 8L8.25 15"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.25031 8L15.25 15"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <p
                          class="max-sm:text-sm text-primary-sf-navy pt-4 px-12 mb-0 text-center font-bold"
                        >
                          Make sure the photo is in focus so that the information is readable
                        </p>
                      </slide>
                      <slide>
                        <div class="flex flex-row">
                          <div class="relative ml-2.5 flex-1 h-40 first:ml-0">
                            <div
                              class="relative bg-[url('/assets/images/image-guidance/backdrop.jpg')] bg-cover bg-no-repeat bg-center rounded-md overflow-hidden h-40"
                            >
                              <img
                                src="/assets/images/image-guidance/valid-drivers-licence.png"
                                style="display: block; width: 100%"
                              />
                            </div>

                            <svg
                              class="absolute left-[50%] bottom-[-11px] ml-[-9px]"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="11.75"
                                cy="11.75"
                                r="10.75"
                                fill="#118C7F"
                                stroke="white"
                                stroke-width="2"
                              />
                              <path
                                d="M16.5833 8L10.1667 14.4167L7.25 11.5"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          <div class="relative ml-2.5 flex-1 h-40 first:ml-0">
                            <div
                              class="relative bg-[url('/assets/images/image-guidance/backdrop.jpg')] bg-cover bg-no-repeat bg-center rounded-md overflow-hidden h-40"
                            >
                              <img
                                src="/assets/images/image-guidance/step-2-invalid-drivers-licence.png"
                                style="display: block; width: 120%"
                              />
                            </div>

                            <svg
                              class="absolute left-[50%] bottom-[-11px] ml-[-9px]"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="12"
                                cy="11.75"
                                r="10.75"
                                fill="#DC3545"
                                stroke="white"
                                stroke-width="2"
                              />
                              <path
                                d="M15.2497 8L8.25 15"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.25031 8L15.25 15"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <p
                          class="max-sm:text-sm text-primary-sf-navy pt-4 px-12 mb-0 text-center font-bold"
                        >
                          Make sure all edges of the ID are visible and no part of the ID is cut off
                        </p>
                      </slide>
                      <slide>
                        <div class="flex flex-row">
                          <div class="relative ml-2.5 flex-1 h-40 first:ml-0">
                            <div
                              class="relative bg-[url('/assets/images/image-guidance/backdrop.jpg')] bg-cover bg-no-repeat bg-center rounded-md overflow-hidden h-40"
                            >
                              <img
                                src="/assets/images/image-guidance/valid-drivers-licence.png"
                                style="display: block; width: 100%"
                              />
                            </div>

                            <svg
                              class="absolute left-[50%] bottom-[-11px] ml-[-9px]"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="11.75"
                                cy="11.75"
                                r="10.75"
                                fill="#118C7F"
                                stroke="white"
                                stroke-width="2"
                              />
                              <path
                                d="M16.5833 8L10.1667 14.4167L7.25 11.5"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          <div class="relative ml-2.5 flex-1 h-40 first:ml-0">
                            <div
                              class="relative bg-[url('/assets/images/image-guidance/backdrop.jpg')] bg-cover bg-no-repeat bg-center rounded-md overflow-hidden h-40"
                            >
                              <img
                                src="/assets/images/image-guidance/step-3-invalid-drivers-licence.png"
                                style="display: block; width: 100%"
                              />
                              <div
                                class="bg-black opacity-70 absolute top-0 left-0 bottom-0 right-0"
                              ></div>
                            </div>

                            <svg
                              class="absolute left-[50%] bottom-[-11px] ml-[-9px]"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="12"
                                cy="11.75"
                                r="10.75"
                                fill="#DC3545"
                                stroke="white"
                                stroke-width="2"
                              />
                              <path
                                d="M15.2497 8L8.25 15"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.25031 8L15.25 15"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <p
                          class="max-sm:text-sm text-primary-sf-navy pt-4 px-12 mb-0 text-center font-bold"
                        >
                          Ensure the image isn't too dark and the information on your documents is
                          clear
                        </p>
                      </slide>
                    </carousel>
                    <div class="bg-white dark:bg-primary-sf-navy px-4 py-3 flex flex-col">
                      <app-alert
                        type="info"
                        additionalClass="mt-2 mb-4 text-left"
                        iconClass="far fa-exclamation-circle"
                        title="Important"
                        message="Photos that don't meet the criteria will be rejected and will have to be redone."
                      ></app-alert>

                      <app-button
                        class="w-100"
                        [fullWidth]="true"
                        text="I understand"
                        buttonStyle="secondary"
                        [isSubmitButton]="false"
                        (click)="modalRef.hide()"
                      ></app-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="sf-card mb-4">
    <div>
      <h5 class="text-primary-sf-navy dark:text-sf-white font-semibold mb-2 lg:text-xl">
        Submit via email
      </h5>
      <p>
        Please use the following instructions to send us an email from your registered email
        address, {{ memberProfile.emailAddress }}
      </p>

      <ul class="pl-0 ml-0 mt-4">
        <li
          class="list-none flex items-center border-t border-primary-sf-navy/10 dark:border-sf-white/10 py-3 px-0 gap-4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            class="self-start min-w-6"
          >
            <circle cx="11.75" cy="12.12" r="11.75" fill="#87D0F1" />
            <path
              fill="#13243A"
              d="M11.388 16.604V9.898l-1.232.266c-.126.028-.266-.056-.28-.182l-.084-.686c-.014-.112.014-.196.07-.252l2.016-1.694h.98c.14 0 .252.126.252.266v8.988c0 .14-.112.266-.252.266h-1.204a.266.266 0 0 1-.266-.266Z"
            />
          </svg>
          <p class="mb-0 flex-grow flex-shrink basi-[80%]">
            Make the subject line
            <strong>{{ ctfDetails.plan.formattedPlanNumber }}</strong>
          </p>
        </li>

        <li
          class="list-none flex items-center border-t border-b border-primary-sf-navy/10 dark:border-sf-white/10 py-3 px-0 gap-4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            class="self-start min-w-6"
          >
            <circle cx="11.75" cy="11.87" r="11.75" fill="#87D0F1" />
            <path
              fill="#13243A"
              d="M8.727 16.354v-.322c.924-1.12 2.688-3.416 3.626-4.662.434-.532.714-1.078.714-1.554 0-.63-.476-1.274-1.484-1.274-.672 0-1.246.476-1.568.826a.255.255 0 0 1-.378 0l-.672-.672c-.084-.084-.112-.252-.014-.364 0 0 1.092-1.372 2.758-1.372 2.002 0 3.122 1.274 3.122 2.786 0 .91-.392 1.484-.938 2.268-.602.854-1.666 2.17-2.408 3.108h3.024c.14 0 .266.126.266.266v.966c0 .14-.126.266-.266.266H8.993a.266.266 0 0 1-.266-.266Z"
            />
          </svg>
          <p class="mb-0 flex-grow flex-shrink basi-[80%]">
            Attach an image of your
            <strong>driving licence</strong>
            or
            <strong>passport</strong>
          </p>
        </li>
        <li
          class="list-none flex items-center border-b border-primary-sf-navy/10 dark:border-sf-white/10 py-3 px-0 gap-4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            class="self-start min-w-6"
          >
            <circle cx="11.75" cy="11.87" r="11.75" fill="#87D0F1" />
            <path
              fill="#13243A"
              d="M8.763 15.514c-.084-.098-.07-.252.014-.35l.714-.686c.126-.112.252-.112.364 0 .042.056.868.756 1.722.756.756 0 1.484-.546 1.484-1.316 0-.728-.672-1.386-1.932-1.386h-.784c-.154 0-.266-.112-.266-.28v-.882c0-.182.098-.28.266-.28h.784c1.148 0 1.736-.574 1.736-1.288 0-.77-.644-1.288-1.358-1.288-.728 0-1.176.392-1.414.672-.098.112-.252.126-.378.014l-.686-.644c-.112-.098-.098-.266 0-.364 0 0 1.064-1.232 2.632-1.232 1.624 0 2.982.994 2.982 2.562 0 1.078-.812 1.89-1.442 2.184v.042c.658.28 1.694 1.036 1.694 2.282 0 1.582-1.288 2.73-3.22 2.73-1.75 0-2.604-.882-2.912-1.246Z"
            />
          </svg>
          <p class="mb-0 flex-grow flex-shrink basi-[80%]">
            Attach an image of a recent
            <strong>bank statement</strong>
            from the past 3 months
          </p>
        </li>
      </ul>

      <app-button
        text="Open my email"
        buttonStyle="primary"
        [isSubmitButton]="false"
        (buttonClick)="openEmailClient()"
        customClass="mt-4"
      />
      <div class="flex flex-col justify-center items-center">
        <p class="mt-2 mb-0">or send an email to:</p>
        <a
          href="#"
          (click)="openEmailClient()"
          class="underline text-primary-sf-navy dark:text-sf-white"
        >
          members&#64;shepherdsfriendly.co.uk
        </a>
      </div>
    </div>
  </div>

  <app-ctf-declaration
    (hasCompleted)="navigateToWithState(url.CTF_SUBMITTED(ctfDetails.plan.formattedPlanNumber))"
  />
</div>
