<form #form="ngForm" id="register-step-one" [formGroup]="registerForm">
  <div>
    <div class="mb-2">
      <p class="mb-4">
        Have you already registered for online access to any other Shepherds Friendly plan you may
        have?
      </p>

      <div class="flex items-center gap-3 justify-between pb-3" role="toolbar">
        <div class="flex-1">
          <input
            type="radio"
            formControlName="hasAccount"
            [value]="'true'"
            id="true"
            class="opacity-0 absolute peer"
          />
          <label
            for="true"
            class="cursor-pointer w-full p-3 text-center font-semibold self-stretch text-sf-body-font bg-sf-white rounded-md peer-checked:bg-primary-sf-yellow peer-checked:text-primary-sf-navy"
          >
            Yes
          </label>
        </div>

        <div class="flex-1">
          <input
            type="radio"
            formControlName="hasAccount"
            [value]="'false'"
            id="false"
            class="opacity-0 absolute peer"
          />
          <label
            for="false"
            class="cursor-pointer w-full p-3 text-center font-semibold self-stretch text-sf-body-font bg-sf-white rounded-md peer-checked:bg-primary-sf-yellow peer-checked:text-primary-sf-navy"
          >
            No
          </label>
        </div>
      </div>
    </div>

    <div *ngIf="hasAccount.value === 'true'">
      <div class="mb-4">
        <app-alert
          type="info"
          title="You already have an account"
          iconClass="far fa-exclamation-circle"
          message="Simply log in to your current Shepherds Friendly account to see the details about your Child Trust Fund.
                    If you experience any issues, please get in touch with our Member Services team on 0800 526 249."
        ></app-alert>
      </div>

      <app-button text="Return to log in" buttonStyle="primary" routerLink="/log-in"></app-button>
    </div>

    <div *ngIf="hasAccount.value === 'false'">
      <div class="mb-2">
        <label for="firstName" class="form-label">First name</label>
        <div class="input-group mb-4">
          <input
            formControlName="firstName"
            type="text"
            class="form-control"
            [ngClass]="{
              'is-invalid': firstNameCtrl.touched && firstNameCtrl.errors,
            }"
          />
        </div>
      </div>

      <div class="mb-2">
        <label for="surname" class="form-label">Surname</label>
        <div class="input-group mb-4">
          <input
            formControlName="surname"
            type="text"
            class="form-control"
            [ngClass]="{
              'is-invalid': surnameCtrl.touched && surnameCtrl.errors,
            }"
          />
        </div>
      </div>

      <div class="mb-4">
        <label class="form-label">Address</label>
        <app-address-picker (onSelectedAddress)="onUpdateAddress($event)"></app-address-picker>
      </div>

      <div class="mb-2">
        <label for="dob" class="form-label">Date of birth</label>
        <div class="input-group mb-4">
          <input
            formControlName="dob"
            type="date"
            class="form-control"
            [ngClass]="{
              'is-invalid': dobCtrl.touched && dobCtrl.errors,
            }"
            min="1900-01-01"
            max="2099-12-31"
          />
        </div>
      </div>

      <div class="mb-2">
        <label class="form-label">Child Trust Fund plan number</label>
        <br />
        <label for="planNumber" class="mb-1">(your National Insurance number)</label>
        <div class="input-group">
          <input
            formControlName="planNumber"
            type="text"
            class="form-control"
            maxlength="9"
            [mask]="ninoMask"
            [ngClass]="{
              'is-invalid': planNumberCtrl.touched && planNumberCtrl.errors,
            }"
          />
        </div>
        <app-validation-message
          *ngIf="planNumberCtrl.touched && planNumberCtrl.errors"
          [message]="'Please enter a valid plan number'"
        ></app-validation-message>
      </div>

      <app-alert
        additionalClass="my-4"
        iconClass="far fa-question-circle"
        type="info"
        title="How to find your plan number"
        message="Your 9 character plan number can be found on any Child Trust Fund documents you have received from us. It is the same as your National Insurance number. Please enter in the format AB123456C."
      ></app-alert>

      <div class="mb-4 checkbox-row">
        <input
          type="checkbox"
          class="form-check-input form-check-input--large peer"
          formControlName="hasIndicatedOwnPlan"
          id="hasIndicatedOwnPlan"
        />
        <label for="hasIndicatedOwnPlan" class="peer-checked:text-sf-white">
          I am the account holder applying to become the registered contact for my Child Trust Fund
          (not a parent or legal guardian)
        </label>
      </div>
    </div>
  </div>
</form>
