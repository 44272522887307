import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormGroupDirective,
} from '@angular/forms';

import { DirectDebit, SavingsPlan } from '@app/models/member-portal';
import { CommonValidators } from '@app/shared/validators';
import { DirectDebitService } from '@app/services';
import { PlanCapacity } from '@app/models/member-portal/plan-capacity.enum';

type RouteStateData = { plan: SavingsPlan; directDebit?: DirectDebit };

enum ErrorOptions {
  'NOT_LIVE',
  'ANNUAL',
  'MAX_CHANGES_REACHED',
}

@Component({
  selector: 'app-direct-debit-vary',
  templateUrl: './direct-debit-vary.component.html',
  styleUrls: ['./direct-debit-vary.component.scss'],
})
export class DirectDebitVaryComponent implements OnInit {
  public readonly url = {
    REVIEW_DIRECT_DEBIT: `/direct-debit/plan/vary/review`,
  };

  public readonly MAX_CHANGES_30_DAYS = this.directDebitService.MAX_CHANGES_30_DAYS;
  public readonly MIN_MAX = this.directDebitService.MIN_MAX;
  public readonly DIVISIBLE_AMOUNT = this.directDebitService.AMOUNT_IN_MULTIPLES_OF_VALUE;

  public errorOpts = ErrorOptions;
  public directDebitForm: FormGroup;
  public amountCtrl: AbstractControl;

  public plan: SavingsPlan;
  public directDebit: DirectDebit;
  public error: ErrorOptions;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    private directDebitService: DirectDebitService
  ) {
    this.loadPlanDetailsFromRoute();
  }

  ngOnInit(): void {
    this.buildForm();

    this.setErrors();

    this.amountCtrl = this.directDebitForm.controls['amount'];
  }

  /**
   * Returns true if direct debit is live
   */
  public get isLive(): boolean {
    return this.directDebit.isLive;
  }

  /**
   * Returns true if period is set to 12 monthly payments
   */
  public get isMonthly(): boolean {
    return String(this.directDebit.period) === '12';
  }

  /**
   * Returns true if period is set to 1 annual payment
   */
  public get isAnnual(): boolean {
    return String(this.directDebit.period) === '1';
  }

  /**
   * Returns true if the 30 day change limit has been reached
   */
  public get isAtMaxChangeLimit(): boolean {
    return this.directDebit.changeCount >= this.MAX_CHANGES_30_DAYS;
  }

  /**
   * Returns text string 'monthly' or 'annual' based on period that has been set
   */
  public get paymentPeriod(): 'monthly' | 'annual' {
    return this.isMonthly ? 'monthly' : 'annual';
  }

  /**
   * Selects correct min / max values based on plan type and payment period
   */
  public get minMaxValues(): { min: number; max: number } {
    return this.MIN_MAX[this.paymentPeriod][this.planPrefix];
  }

  /**
   * Returns uppercase plan prefix
   */
  public get planPrefix(): string {
    return this.plan.planTypePrefix.toUpperCase();
  }

  /**
   * Returns true if multiple Direct Debits are allowed on the plan
   */
  public get allowsMultiple(): boolean {
    return this.plan.capacity === PlanCapacity.AuthorisedContact;
  }

  /**
   * Determines if there are any flags set that need to trigger an error, and sets said error
   */
  public setErrors(): void {
    if (!this.isLive) {
      this.error = this.errorOpts.NOT_LIVE;
    }

    if (this.isAtMaxChangeLimit) {
      this.error = this.errorOpts.MAX_CHANGES_REACHED;
    }

    if (this.isLive && this.isAnnual) {
      this.error = this.errorOpts.ANNUAL;
    }
  }

  /**
   * Checks form validity and redirects to direct debit review
   * @param {FormGroupDirective} [form] the form viewRef
   */
  public onSubmit(form: FormGroupDirective): void {
    if (form.valid) {
      this.router.navigate([this.url.REVIEW_DIRECT_DEBIT], {
        state: {
          plan: this.plan,
          directDebit: this.directDebit,
          newPremiumAmount: this.amountCtrl.value,
        },
      });
    }
  }

  /**
   * Builds the form configuration with angulars form builder service
   */
  private buildForm(): void {
    this.directDebitForm = this.fb.group({
      amount: [
        '',
        Validators.compose([
          Validators.required,
          CommonValidators.isNotEqualToValue(this.directDebit.amount),
          CommonValidators.isDivisible(this.DIVISIBLE_AMOUNT),
          CommonValidators.isInRange(this.minMaxValues.min, this.minMaxValues.max),
        ]),
      ],
    });
  }

  /**
   * Retrieves plan / direct debit details data from the route state, if non exists
   * redirect back to app root page.
   */
  private loadPlanDetailsFromRoute(): void {
    const state = this.router.getCurrentNavigation()?.extras?.state as RouteStateData;

    if (state) {
      this.plan = state.plan;
      this.directDebit = state.directDebit;
    } else {
      this.router.navigate(['/']);
    }
  }
}
