import { Pipe, PipeTransform } from '@angular/core';

/*
 * Example usage: {{ ['Shepherds', 'Friendly', 'Est', '1826'] | join: '_' }}
 * Example output: Shepherds_Friendly_Est_1826
 */
@Pipe({
    name: 'join',
    standalone: false
})
export class JoinPipe implements PipeTransform {
  transform(values: Array<string>, separator?: string): string {
    return values && values.filter(Boolean).join(separator);
  }
}
