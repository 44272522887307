<section *ngIf="plan">
  <app-content-header
    [title]="'Review your updated Direct Debit'"
    [subtitle]="plan.formattedPlanNumber"
  ></app-content-header>

  <div *ngIf="directDebit">
    <app-direct-debit-overview
      [plan]="plan"
      [directDebit]="directDebit"
      [hasAmend]="true"
      (onAmend)="navigateWithState(url.DIRECT_DEBIT)"
    />

    <app-direct-debit-declaration
      [planTypePrefix]="plan.planTypePrefix"
      (hasAcceptedConditions)="handleHasAcceptedConditions($event)"
    >
      <div class="flex items-center justify-between">
        <blockquote class="text-sm">
          The company name showing on your bank statement will be SHEPHERDS FRIENDLY
        </blockquote>
        <app-button
          text="Continue"
          buttonStyle="secondary"
          [disabled]="!hasAcceptedConditions"
          [noBorder]="true"
          (click)="onSubmit()"
          class="ml-4"
        ></app-button>
      </div>
    </app-direct-debit-declaration>
  </div>
</section>
