<div class="mt-4 sf-card">
    <h3 class="font-bold">Risk level 1 : Defensive fund</h3>
    <p>
      A lower-risk fund that is focused on cash, gilts, as well as government and corporate bonds,
      with limited exposure to the stock market. Aims for steady growth, with reduced risk of loss.
    </p>
  
    <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-8">
      <section class="asset-class mb-6">
        <header class="flex justify-between">
          <p class="font-bold mb-1">Credit</p>
          <p class="mb-1">27.30%</p>
        </header>
        <div class="asset-allocation-bars-container">
          <div class="asset-allocation-bars-bg"></div>
          <div class="asset-allocation-bars-colour w-[32%] bg-primary-sf-navy dark:bg-gray-200"></div>
        </div>
      </section>
  
      <section class="asset-class mb-6">
        <header class="flex justify-between">
          <p class="font-bold mb-1">Gilts</p>
          <p class="mb-1">22.30%</p>
        </header>
        <div class="asset-allocation-bars-container">
          <div class="asset-allocation-bars-bg"></div>
          <div class="asset-allocation-bars-colour w-[22%] bg-primary-sf-yellow"></div>
        </div>
      </section>
  
      <section class="asset-class mb-6">
        <header class="flex justify-between">
          <p class="font-bold mb-1">Cash and Absolute Return</p>
          <p class="mb-1">13.30%</p>
        </header>
        <div class="asset-allocation-bars-container">
          <div class="asset-allocation-bars-bg"></div>
          <div class="asset-allocation-bars-colour w-[13%] bg-secondary-sf-blue-dark"></div>
        </div>
      </section>
  
      <section class="asset-class mb-6">
        <header class="flex justify-between">
          <p class="font-bold mb-1">Global High Yield</p>
          <p class="mb-1">10.30%</p>
        </header>
        <div class="asset-allocation-bars-container">
          <div class="asset-allocation-bars-bg"></div>
          <div class="asset-allocation-bars-colour w-[10%] bg-secondary-sf-blue-mid"></div>
        </div>
      </section>
  
      <section class="asset-class mb-6">
        <header class="flex justify-between">
          <p class="font-bold mb-1">Index Linked</p>
          <p class="mb-1">8.30%</p>
        </header>
        <div class="asset-allocation-bars-container">
          <div class="asset-allocation-bars-bg"></div>
          <div class="asset-allocation-bars-colour w-[8%] bg-blue-300"></div>
        </div>
      </section>
  
      <section class="asset-class mb-6">
        <header class="flex justify-between">
          <p class="font-bold mb-1">Overseas Equities</p>
          <p class="mb-1">7.30%</p>
        </header>
        <div class="asset-allocation-bars-container">
          <div class="asset-allocation-bars-bg"></div>
          <div class="asset-allocation-bars-colour w-[7%] bg-tertiary-sf-bright-pink"></div>
        </div>
      </section>
  
      <section class="asset-class mb-6">
        <header class="flex justify-between">
          <p class="font-bold mb-1">Properties</p>
          <p class="mb-1">5.30%</p>
        </header>
        <div class="asset-allocation-bars-container">
          <div class="asset-allocation-bars-bg"></div>
          <div class="asset-allocation-bars-colour w-[5%] bg-tertiary-sf-pale-pink"></div>
        </div>
      </section>
  
      <section class="asset-class mb-6">
        <header class="flex justify-between">
          <p class="font-bold mb-1">Commodities</p>
          <p class="mb-1">4.30%</p>
        </header>
        <div class="asset-allocation-bars-container">
          <div class="asset-allocation-bars-bg"></div>
          <div class="asset-allocation-bars-colour w-[4%] bg-tertiary-sf-dark-green"></div>
        </div>
      </section>
  
      <section class="asset-class mb-6">
        <header class="flex justify-between">
          <p class="font-bold mb-1">UK Equities</p>
          <p class="mb-1">3.30%</p>
        </header>
        <div class="asset-allocation-bars-container">
          <div class="asset-allocation-bars-bg"></div>
          <div class="asset-allocation-bars-colour w-[3%] bg-tertiary-sf-pale-green"></div>
        </div>
      </section>
  
      <section class="asset-class mb-6">
        <header class="flex justify-between">
          <p class="font-bold mb-1">Other</p>
          <p class="mb-1">2.30%</p>
        </header>
        <div class="asset-allocation-bars-container">
          <div class="asset-allocation-bars-bg"></div>
          <div class="asset-allocation-bars-colour w-[2%] bg-sf-mid-grey"></div>
        </div>
      </section>
      <p>Breakdown correct as of: [date]</p>
    </div>
  </div>