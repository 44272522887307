<app-content-header
  [title]="'Hello ' + (memberDashboard?.registrant?.firstname || 'Guest') + ','"
  [isButtonOff]="false"
  [button_text]="'Log out'"
  [button_style]="'white'"
  [button_iconColor]="'text-primary-sf-yellow'"
  (buttonClick)="logout()"
/>

<h2>You will need to enter the verification code sent to you in the post.</h2>
<br />

<p>You'll only need to do this the first time you log in.</p>
<br />

<form [formGroup]="addressVerificationForm" (ngSubmit)="onSubmit()">
  <label for="addressVerificationCode" class="form-label">Verification code</label>
  <div class="input-group">
    <input
      type="text"
      class="form-control"
      id="code"
      name="code"
      placeholder="Enter your eight digit code"
      autocomplete="one-time-code"
      required
      formControlName="code"
      [ngClass]="{
        'is-invalid': submittedCode && codeCtrl.errors,
      }"
    />
  </div>

  <!-- Validation Error -->
  <app-validation-message
    *ngIf="submittedCode && getErrorMessage()"
    [message]="getErrorMessage()"
  ></app-validation-message>

  <!-- API Error -->
  <app-validation-message *ngIf="error" [message]="error"></app-validation-message>

  <app-button
    text="Confirm"
    buttonStyle="primary"
    [disabled]="loading$ | async"
    [noBorder]="true"
    customClass="my-4"
    [isSubmitButton]="true"
  ></app-button>
</form>
