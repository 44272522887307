import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorType, ErrorMessages } from '@app/models/errors';

@Component({
    selector: 'app-page-error',
    templateUrl: './page-error.component.html',
    styleUrls: ['./page-error.component.scss'],
    standalone: false
})
export class PageErrorComponent implements OnInit {
  public errorMessage: string;
  public errorType: ErrorType | undefined = history.state?.errorType;
  public errorTitle: string;

  readonly url = {
    HOME: `/home`,
  };

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.errorMessage = this.getErrorMessage();
    this.errorTitle = this.getErrorTitle();
  }

  // Return an error message passed in the route state, or else return generic error
  private getErrorMessage(): string {
    if (this.errorType && ErrorMessages[this.errorType]) {
      return ErrorMessages[this.errorType]();
    }

    // Fallback to a generic error message
    return ErrorMessages[ErrorType.GENERIC]();
  }

  private getErrorTitle(): string {
    const errorTitle: ErrorType | undefined = history.state?.errorTitle;

    if (errorTitle) {
      return errorTitle;
    }

    return 'Sorry but there has been a problem.';
  }
}
