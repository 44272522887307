import { PlanType } from '@app/models/plans';

export class PlanUtility {
  /**
   * Checks `planNumber` corresponds to an authorised plan
   * @param {string} [planNumber] the plan number
   * @return true if `planNumber` includes `JISA`
   */
  static isAuthorisedPlan(planNumber: string): boolean {
    return !!planNumber.toUpperCase().includes('JISA');
  }

  /**
   * Checks if the provided plan's type matches the specified type.
   *
   * @param plan - An object representing the plan
   * @param type - The plan type to compare against (can be an enum value or string).
   * @returns true if the plan's type (case-insensitive) equals the specified type, false otherwise.
   */
  static isPlanType(plan: { planTypePrefix: string }, type: PlanType | string): boolean {
    return plan.planTypePrefix.toUpperCase() === type.toString().toUpperCase();
  }

  /**
   * Checks `planStatusId` corresponds to an issued status
   * @param {number} [planStatusId] the plan status identifier
   * @return true if `planStatusId` equals `10||11`
   */
  static isPlanIssued(planStatusId: number): boolean {
    return planStatusId === 10 || planStatusId === 11;
  }

  /**
   * Checks `planStatusId` corresponds to a pending status
   * @param {number} [planStatusId] the plan status identifier
   * @return true if `planStatusId` equals `6||7||14||23||24||99`
   */
  static isPlanPending(planStatusId: number): boolean {
    return (
      planStatusId === 6 ||
      planStatusId === 7 ||
      planStatusId === 14 ||
      planStatusId === 23 ||
      planStatusId === 24 ||
      planStatusId === 99
    );
  }

  /**
   * Checks `planStatusId` corresponds to a status where we
   * allow the initial deposit to be made.
   * @param {number} [planStatusId] the plan status identifier
   * @return true if `planStatusId` equals `99`
   */
  static isInitialPlanDepositValid(planStatusId: number): boolean {
    return planStatusId == 99;
  }
}
