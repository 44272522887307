export class NewDirectDebitRequest {
  public acName: string;
  public sortCode: string;
  public acNumber: string;

  public payersName: boolean;
  public allowed: boolean;

  public period: number;
  public dayDue: number;
  public periodDue: number;

  public amount: number;

  constructor() {}
}
