import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResendRegistrationEmail, ResendRegistrationEmailResult } from '@app/models/registration';
import { RegistrationService } from '@app/services';
import { EmailUtility } from '@app/shared/utilities';
import { EMPTY, catchError } from 'rxjs';

type RouteStateData = { ctf: boolean };

@Component({
  selector: 'app-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss'],
})
export class RegisterSuccessComponent {
  public readonly url = {
    LOGIN: () => `/log-in?email=${this.emailAddress}`,
  };

  public resentEmail: boolean = false;
  public emailAddress: string;
  public registrationId: string;
  public isCTF: boolean = false;

  constructor(
    private registrationService: RegistrationService,
    public router: Router,
    private route: ActivatedRoute
  ) {
    this.loadStateFromRoute();
  }

  public ngOnInit(): void {
    this.loadRegisterResultFromURL();
  }

  public openEmailClient() {
    EmailUtility.openEmailInbox();
  }

  public resendRegistrationEmail() {
    this.registrationService
      .resendRegistrationEmail({
        Id: this.registrationId,
        EmailAddress: this.emailAddress,
      })
      .pipe(
        catchError((e) => {
          throw new Error(`Unable to resend verification email.`);
        })
      )
      .subscribe((r) => (this.resentEmail = true));
  }

  private loadRegisterResultFromURL(): void {
    this.emailAddress = atob(this.route.snapshot.paramMap.get('email'));
    this.registrationId = this.route.snapshot.paramMap.get('registrationId');
  }

  private loadStateFromRoute(): void {
    const state = this.router.getCurrentNavigation()?.extras?.state as RouteStateData;

    if (state) {
      this.isCTF = state.ctf;
    }
  }
}
