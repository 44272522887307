import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormGroupDirective,
} from '@angular/forms';

import { Member, SavingsPlan } from '@app/models/member-portal';
import { CommonValidators } from '@app/shared/validators';
import { catchError, EMPTY, filter, Subscription, take } from 'rxjs';
import { DepositStepOptions, Payment } from '@app/models/payments';
import { DocumentService, StripeService } from '@app/services';
import { CurrencyPipe } from '@angular/common';
import { TopUpRouteState } from '@app/models/pages';

const stepOpts: Record<string, string> = {
  AMOUNT: 'Amount',
  TERMS: 'Terms',
  PAY: 'Pay',
};

@Component({
  selector: 'app-top-up',
  templateUrl: './top-up.component.html',
  styleUrls: ['./top-up.component.scss'],
})
export class TopUpComponent implements OnInit {
  readonly url = {
    THANK_YOU_PAGE: (formattedPlanNumber) =>
      `/plans/deposit/${formattedPlanNumber.toLowerCase()}/thank-you`,
    ERROR_PAGE: (formattedPlanNumber) =>
      `/plans/deposit/${formattedPlanNumber.toLowerCase()}/error`,
    PLAN_CHOICE: `/plans/deposit`,
  };

  private subscribeUntilDestroyed = new Subscription();

  public cardElement: any;
  public topUpForm: FormGroup;
  public amountCtrl: AbstractControl;
  public cardHolderNameCtrl: AbstractControl;

  public paymentError: string = '';
  public submittingPayment: boolean = false;
  public showParentContent: boolean = true;
  public hideStepper: boolean = false;

  public plan: SavingsPlan;
  public member: Member;
  public importantInfoGuideLink: string;

  public stepOpts = stepOpts;
  public step: string;

  private presetTopUpAmount: number;
  private presetLandingStep: string;
  private isInitialDeposit: boolean = false;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    public stripeService: StripeService,
    private documentService: DocumentService,
    private currencyPipe: CurrencyPipe
  ) {
    this.step = this.stepOpts['AMOUNT'];
    this.loadPlanDetailsFromRoute();
    this.buildForm();

    // Cache form controls
    this.amountCtrl = this.topUpForm.controls['amount'];
    this.cardHolderNameCtrl = this.topUpForm.controls['cardHolderName'];
  }

  ngOnInit(): void {
    this.importantInfoGuideLink = this.documentService.getImportantInformationGuideDocumentLink(
      this.plan.planTypePrefix
    );

    this.presetStateValues();
    this.handleStripeWalletPayment();
    this.handleRouteVisibility();
  }

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  public handleRouteVisibility(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = this.router.url;
        this.showParentContent = !(
          currentUrl.includes('thank-you') || currentUrl.includes('error')
        );
      }
    });
  }

  /**
   * Formats a numeric amount to a GBP currency string with currency symbol and two decimal places
   */
  public formatCurrency(amount: number): string {
    return this.currencyPipe.transform(amount, 'GBP', 'symbol', '1.2-2');
  }

  /**
   * Callback for viewRef card element emitted from child component
   * @param {any} [cardElement]
   */
  public handleSetCardElement(cardElement: any): void {
    this.cardElement = cardElement;
  }

  /**
   * Resets payment error message back to default state
   */
  public resetPaymentError(): void {
    this.paymentError = '';
  }

  /**
   * Resets payment error message back to default state
   * @param {any} [event]
   */
  public amountChanged(event: any): void {
    this.stripeService.changePaymentRequestAmount(event.target.value);
  }

  /**
   * Handles a success or fail from a stripe wallet payment and navigates to the thank you or error page
   */
  public handleStripeWalletPayment(): void {
    const form = this.topUpForm;

    this.stripeService.initialPaymentModel({
      MC_SFCustomerPlanId: form.value.MC_SFCustomerPlanId,
      amount: form.value.amount,
      name: form.value.name,
      email: form.value.email,
    });

    this.subscribeUntilDestroyed.add(
      this.stripeService.walletPayment$
        .pipe(filter(Boolean))
        .subscribe((response: 'success' | 'fail') => {
          if (response === 'fail') {
            this.router.navigate([this.url.ERROR_PAGE(this.plan.formattedPlanNumber)], {
              state: {
                plan: this.plan,
                error: 'There has been an error taking payment.',
              },
            });
          } else {
            this.router.navigate([this.url.THANK_YOU_PAGE(this.plan.formattedPlanNumber)], {
              state: {
                plan: this.plan,
              },
            });
          }
        })
    );
  }

  /**
   * If amount ctrl is valid, proceeds to the terms step
   */
  public goToTermsStep(): void {
    if (this.amountCtrl.valid) {
      this.step = this.stepOpts['TERMS'];
    }
  }

  /**
   * If terms accepted, proceeds to the pay step
   */
  public goToPayStep(): void {
    this.step = this.stepOpts['PAY'];
  }

  /**
   * Checks form validity and attempts to take payment
   * @param {FormGroupDirective} [form] the form viewRef
   */
  public onSubmit(form: FormGroup): void {
    this.submittingPayment = true;

    if (form.valid) {
      const payment: Payment = {
        MC_SFCustomerPlanId: form.value.MC_SFCustomerPlanId,
        amount: parseInt(form.value.amount),
        name: form.value.name,
        email: form.value.email,
        instId: 'Stripe',
        transId: null,
        paymentIntentId: null,
        currency: 'GBP',
        dataId: this.plan.planId,
        cardElement: this.cardElement,
      };

      this.stripeService
        .takeTopUpPayment(payment, this.isInitialDeposit)
        .pipe(
          take(1),
          catchError((response) => {
            this.router.navigate([this.url.ERROR_PAGE(this.plan.formattedPlanNumber)], {
              state: {
                plan: this.plan,
                error: response.errorMessage,
              },
            });

            this.submittingPayment = false;
            return EMPTY;
          })
        )
        .subscribe((response: any) => {
          if (response.status === 'succeeded') {
            this.router.navigate([this.url.THANK_YOU_PAGE(this.plan.formattedPlanNumber)], {
              state: { plan: this.plan, payment: payment.amount },
            });
          }
        });
    }
  }

  /**
   * Builds the form configuration with angulars form builder service
   */
  private buildForm(): void {
    this.topUpForm = this.fb.group({
      amount: [
        '',
        Validators.compose([
          Validators.required,
          CommonValidators.isInRange(this.plan.topUpMin, this.plan.taxAllowanceRemaining),
        ]),
      ],
      cardHolderName: ['', Validators.compose([Validators.required])],
      desc: [''],
      MC_SFCustomerPlanId: [this.plan.formattedPlanNumber],
      cartId: [''],
      signature: [''],
      name: [`${this.member.firstname} ${this.member.surname}`],
      email: [this.member.email],
      IAcceptConditions: [true],
    });
  }

  /**
   * Retrieves plan / member details data from the route state, if none exists
   * redirect back to the plan choice page.
   */
  private loadPlanDetailsFromRoute(): void {
    const state = this.router.getCurrentNavigation()?.extras?.state as TopUpRouteState;

    if (state) {
      this.plan = state.plan;
      this.member = state.member;
      this.isInitialDeposit = state.isInitialDeposit;
      this.presetTopUpAmount = state.topUpAmount;
      this.presetLandingStep = state.landingStep;
      this.hideStepper = state.hideStepper;
    } else {
      this.router.navigate([this.url.PLAN_CHOICE]);
    }
  }

  /**
   * Preset state values after the form is created
   */
  private presetStateValues(): void {
    if (this.presetTopUpAmount !== undefined) {
      this.topUpForm.controls['amount'].setValue(this.presetTopUpAmount);
    }
    if (this.presetLandingStep !== undefined) {
      this.step = this.stepOpts[this.presetLandingStep];
    }
  }
}
