export class ObjectUtility {
  /**
   * Safely serializes an object to JSON, avoiding circular references.
   * @param obj - The object to serialize.
   * @returns A JSON string or '[Unserializable Object]' if serialization fails.
   */
  static safeStringify(obj: any): string {
    try {
      return JSON.stringify(obj, this.circularReplacer(), 2);
    } catch {
      return '[Unserializable Object]';
    }
  }

  /**
   * Detect circular references in objects.
   * @returns A replacer function for JSON.stringify.
   */
  private static circularReplacer(): (key: string, value: any) => any {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) return '[Circular]';
        seen.add(value);
      }
      return value;
    };
  }
}
