<app-content-header [title]="'Mature your fund'" [subtitle]="plan.formattedPlanNumber" />
<app-card-with-image
  title="Maturity request submitted"
  imageSrc="doing-the-right-thing-yellow"
  imageAlt="doing-the-right-thing-successful"
  [isButtonOff]="false"
  [routerLink]="url.HOME"
  [button_text]="'Return to Home'"
  [button_fullWidth]="false"
  [button_small]="false"
  [customClass]="'max-md:h-auto'"
>
  <div>
    <p>
      Your request to mature {{ plan.formattedPlanNumber }} has been successfully submitted to our
      team.
    </p>
  </div>
</app-card-with-image>
