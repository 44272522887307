/**
 * Represents a request to connect to a specific plan.
 * The plan number must be formatted correctly, depending on the prefix.
 * Supports padding the numeric part of the plan number to 5 digits
 * for specific prefixes ('ISA', 'SISA', 'JISA', 'SJISA').
 */
export class PlanConnectionRequest {
  public requestFirstName: string;
  public requestSurname: string;
  public requestDOB: string;
  private _requestPlanNumber: string;

  // List of valid plan prefixes that require numeric padding.
  private validPrefixes = ['ISA', 'SISA', 'JISA', 'SJISA'];

  get requestPlanNumber(): string {
    return this._requestPlanNumber;
  }

  set requestPlanNumber(value: string) {
    const matchingPrefix = this.validPrefixes.find((prefix) =>
      value.toUpperCase().startsWith(prefix)
    );

    if (matchingPrefix) {
      // Extract the numeric part
      const numericPart = value.slice(matchingPrefix.length);
      // Pad numeric part to 6 digits, ignoring non-digits
      const paddedNumericPart = numericPart.replace(/\D/g, '').padStart(6, '0');
      this._requestPlanNumber = matchingPrefix + paddedNumericPart;
    } else {
      this._requestPlanNumber = value;
    }
  }

  toJSON(): any {
    return {
      requestFirstName: this.requestFirstName,
      requestSurname: this.requestSurname,
      requestDOB: this.requestDOB,
      requestPlanNumber: this.requestPlanNumber,
    };
  }
}
