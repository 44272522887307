import { Observable } from 'rxjs';
import hash from 'hash-it';
import * as dayjs from 'dayjs';

export abstract class AbstractCacheService<T> {
  readonly CACHE_DURATION_IN_MINUTES = 10;
  readonly DEFAULT_KEY = 'DEFAULT';

  private cache: {
    [id: string]: {
      expires: Date;
      value: Observable<T>;
    };
  } = {};

  /**
   * Stores an Observable<type> with an expiration date. Expiration date calculated by CACHE_DURATION_IN_MINUTES from current time.
   * @param {any} object - unsure why this is an object, but it gets hashed to a string and used as a storage key
   * @return {Observable<T>} cached value if it exists and it hasn't expired
   */
  getValue(object?: any): Observable<T> {
    const key = object ? hash(object).toString() : this.DEFAULT_KEY;
    const item = this.cache[key];
    if (!item) {
      return null;
    }

    if (dayjs(new Date()).isAfter(item.expires)) {
      return null;
    }

    return item.value;
  }

  /**
   * Stores an Observable<type> with an expiration date. Expiration date calculated by CACHE_DURATION_IN_MINUTES from current time.
   * @param {Observable<T>} value - observable to cache
   * @param {any} object - unsure why this is an object, but it gets hashed to a string and used as a storage key
   */
  setValue(value: Observable<T>, object?: any): void {
    const key = object ? hash(object).toString() : this.DEFAULT_KEY;
    const expires = dayjs(new Date()).add(this.CACHE_DURATION_IN_MINUTES, 'minutes').toDate();
    this.cache[key] = { expires, value };
  }

  /**
   * Sets `cache` back to an empty object
   */
  clearCache(): void {
    this.cache = {};
  }
}
