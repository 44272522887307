<div
  *ngIf="isSurveyVisible"
  class="flex flex-col rounded-md relative max-xs:p-2 p-4 text-center md:text-left md:p-8 border-2 border-primary-sf-yellow text-sf-body-font bg-sf-light-yellow dark:bg-sf-info-a-tint-dark
  {{ customClass }}"
>
  <div class="absolute top-3 right-3 flex items-center" (click)="close()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      class="cursor-pointer"
    >
      <path
        class="stroke-primary-sf-navy dark:stroke-sf-white"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M18 6 6 18M6 6l12 12"
      />
    </svg>
  </div>
  <div class="md:flex md:gap-4 md:items-center">
    <div class="md:flex md:gap-4 md:items-center xl:flex-1">
      <div
        *ngIf="imageSrc"
        class="w-[100px] h-auto overflow-hidden max-w-full max-h-full mt-0 mb-4 mx-auto md:mb-0 xl:m-0 xl:grow-0"
        [innerHTML]="svg | safe"
      ></div>
      <div>
        <h2 class="text-primary-sf-navy dark:text-sf-white">{{ title }}</h2>
        <p class="md:mb-0 dark:text-sf-white">{{ content }}</p>
      </div>
    </div>

    <div class="md:grow xl:grow-0">
      <app-button
        [text]="button_text"
        [buttonStyle]="button_style"
        [btnSmall]="button_small"
        [alignRight]="button_alignRight"
        [noBorder]="button_noBorder"
        [isButtonShadow]="button_isShadow"
        [customClass]="button_customClass"
        [isSubmitButton]="button_isSubmit"
        [icon]="button_icon"
        [fullWidth]="button_fullWidth"
        (buttonClick)="openSurveyPromptUrl()"
      ></app-button>
    </div>
  </div>
</div>
