<app-content-header [title]="'My profile'" [subtitle]="'Change your email'"></app-content-header>

<div class="sf-card">
  <div [ngSwitch]="step">
    <ng-container *ngSwitchCase="stepOpts.UPDATE">
      <div
        class="flex flex-col border-b border-primary-sf-navy/10 dark:border-sf-white/10 md:flex-row"
      >
        <p class="mb-0">Your email address is currently:&nbsp;&nbsp;</p>
        <p class="max-md:whitespace-pre font-semibold mb-3">{{ memberProfile.emailAddress }}</p>
      </div>

      <form #form="ngForm" [formGroup]="changeEmailForm" (ngSubmit)="goToNextStep()">
        <div class="my-4 md:my-4">
          <label for="email" class="form-label">New email address</label>
          <div class="input-group">
            <input
              id="email"
              name="email"
              formControlName="email"
              type="email"
              class="form-control"
              [ngClass]="{
                'is-invalid': emailCtrl.touched && emailCtrl.errors,
              }"
            />
          </div>
        </div>

        <div class="mb-4 md:mb-4">
          <label for="emailConfirm" class="form-label">Confirm your new email address</label>
          <div class="input-group mb-4">
            <input
              id="confirmEmail"
              name="confirmEmail"
              formControlName="confirmEmail"
              type="email"
              class="form-control"
              [ngClass]="{
                'is-invalid': confirmEmailCtrl.touched && confirmEmailCtrl.errors,
              }"
            />
          </div>
        </div>

        <div class="mb-4">
          <div *ngIf="emailCtrl.touched && confirmEmailCtrl.touched && changeEmailForm.errors">
            <app-alert
              *ngIf="changeEmailForm.errors['emailMismatch']"
              type="warning"
              additionalClass="mt-2 mb-4"
              iconClass="far fa-exclamation-circle"
              title="Error"
              message="Your email address does not match the
                              confirmation field"
            ></app-alert>
          </div>
        </div>

        <app-button
          text="Update email address"
          buttonStyle="secondary"
          [isSubmitButton]="true"
          [disabled]="!form.valid"
          [noBorder]="true"
        ></app-button>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="stepOpts.VERIFY">
      <app-verify-identity (complete)="handleVerification($event)"></app-verify-identity>
    </ng-container>

    <ng-container *ngSwitchCase="stepOpts.CONFIRM">
      <app-verify-code
        [emailOrMobile]="emailCtrl.value"
        (complete)="handleCode($event)"
      ></app-verify-code>
    </ng-container>

    <ng-container *ngSwitchCase="stepOpts.COMPLETE">
      <h1 class="mb-4">Email address changed</h1>
      <p>
        Your email has been changed to:
        <span>{{ emailCtrl.value }}</span>
      </p>

      <p>
        If you have any questions regarding this, please call
        <a href="tel:0800526249">0800 526 249</a>
        and speak to our Member Services Team.
      </p>

      <app-button text="Return home" buttonStyle="primary" [routerLink]="url.HOME"></app-button>
    </ng-container>
  </div>
</div>
