import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

type RouteStateData = { ctf: boolean };

@Component({
  selector: 'app-register-failure',
  templateUrl: './register-failure.component.html',
  styleUrls: ['./register-failure.component.scss'],
})
export class RegisterFailureComponent {
  public phoneNumber: string = '0800526249';
  public isCTF: boolean = false;

  constructor(public router: Router) {
    this.loadStateFromRoute();
  }

  callPhoneNumber() {
    window.location.href = 'tel:' + this.phoneNumber;
  }

  private loadStateFromRoute(): void {
    const state = this.router.getCurrentNavigation()?.extras?.state as RouteStateData;

    if (state) {
      this.isCTF = state.ctf;
    }
  }
}
