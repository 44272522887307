<form #form="ngForm" id="register-step-one-extended" [formGroup]="registerForm">
  <div>
    <hr class="my-4" *ngIf="showSeparator" />

    <div class="mb-2">
      <h5 class="text-lg font-bold">Your child’s plan details</h5>
      <label class="my-4">
        Please fill in your child's details to connect their Junior ISA to your account.
      </label>
      <br />
      <label for="childsPlanNumber" class="form-label">Child's Junior ISA plan number</label>
      <div class="input-group">
        <input
          formControlName="childsPlanNumber"
          type="text"
          class="form-control"
          placeholder="e.g. JISA000123 or SJISA000123"
          [ngClass]="{
            'is-invalid': childsPlanNumberCtrl.touched && childsPlanNumberCtrl.errors,
          }"
        />
      </div>
      <app-validation-message
        *ngIf="childsPlanNumberCtrl.touched && childsPlanNumberCtrl.errors"
        [message]="'Please enter a valid plan number'"
      ></app-validation-message>

      <div class="mb-2 mt-2">
        <label for="childsFirstName" class="form-label">Child's first name</label>
        <div class="input-group mb-4">
          <input
            formControlName="childsFirstName"
            type="text"
            class="form-control"
            [ngClass]="{
              'is-invalid': childsFirstNameCtrl.touched && childsFirstNameCtrl.errors,
            }"
          />
        </div>
      </div>

      <div class="mb-2">
        <label for="childsSurname" class="form-label">Child's surname</label>
        <div class="input-group mb-4">
          <input
            formControlName="childsSurname"
            type="text"
            class="form-control"
            [ngClass]="{
              'is-invalid': childsSurnameCtrl.touched && childsSurnameCtrl.errors,
            }"
          />
        </div>
      </div>

      <div class="mb-2">
        <label for="dob" class="form-label">Child's date of birth</label>
        <div class="input-group mb-4">
          <input
            formControlName="childsDob"
            type="date"
            class="form-control"
            [ngClass]="{
              'is-invalid': childsDobCtrl.touched && childsDobCtrl.errors,
            }"
            min="1900-01-01"
            max="2099-12-31"
          />
        </div>
      </div>
    </div>

    <app-alert
      additionalClass="my-4"
      iconClass="far fa-question-circle"
      type="info"
      title="Our privacy promise"
      message="At Shepherds Friendly, we are fully committed to ensuring that children's personal data never falls into the wrong hands. All child data we capture at this point of registering for your account is necessary, as it allows us to make essential identity checks. Without providing this information, you won't be able to view their plan online."
    ></app-alert>
  </div>
</form>
