<app-content-header
  *ngIf="plan && showParentContent"
  [title]="
    'Make a deposit' +
    (step !== stepOpts['AMOUNT'] ? ': ' + formatCurrency(topUpForm.value.amount) : '')
  "
  [isButtonOff]="true"
  [subtitle]="plan.planTypeName + ' - ' + plan.formattedPlanNumber"
></app-content-header>

<app-steps
  *ngIf="showParentContent && !hideStepper"
  [currentStep]="step"
  [enumType]="stepOpts"
></app-steps>

<form
  *ngIf="showParentContent"
  [ngClass]="{ hidden: submittingPayment }"
  id="member-payment-form"
  [formGroup]="topUpForm"
  (ngSubmit)="onSubmit(topUpForm)"
>
  <div [ngClass]="{ 'hidden-step': !(step === stepOpts['AMOUNT']) }">
    <div class="sf-card mb-4">
      <div>
        <div class="mb-2">
          <label for="amount" class="form-label">Enter deposit amount (£)</label>
          <div>
            <input
              id="amount"
              name="amount"
              formControlName="amount"
              type="number"
              min="0"
              step="0.01"
              class="form-control"
              placeholder="£"
              required
              (input)="amountChanged($event)"
              [ngClass]="{
                'is-invalid': amountCtrl.touched && amountCtrl.errors,
              }"
            />
          </div>

          <div *ngIf="amountCtrl.touched && amountCtrl.errors">
            <app-validation-message
              *ngIf="amountCtrl.errors['rangeValueToBig']"
              message="The deposit amount entered will exceed your remaining allowance in this tax year. The maximum amount you can deposit is
                                  £{{
                plan.taxAllowanceRemaining
              }}. Please adjust the deposit amount.
                                  "
            ></app-validation-message>

            <app-validation-message
              *ngIf="amountCtrl.errors['rangeValueToSmall']"
              message="The minimum amount you can deposit for an
                                  {{ plan.planTypePrefix }} is £{{ plan.topUpMin }}."
            ></app-validation-message>
          </div>

          <app-button
            text="Proceed to step 2"
            (click)="goToTermsStep()"
            buttonStyle="secondary"
            [noBorder]="true"
            [isSubmitButton]="false"
            [disabled]="!amountCtrl.valid"
            customClass="mt-4"
          ></app-button>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="{ 'hidden-step': !(step === stepOpts['TERMS']) }">
    <app-top-up-declaration
      [planTypePrefix]="plan.planTypePrefix"
      [importantInfoGuideLink]="importantInfoGuideLink"
    >
      <hr class="border-gray-500 border-opacity-25 border-b mb-6" />
      <app-alert
        additionalClass="mt-4 mb-5"
        type="info"
        title="Important: Your ISA Allowance"
        message="If you exceed your annual ISA allowance in any given tax year, you won’t get any tax relief on for any amount paid in excess of the limit. If you have more than one ISA, your ISA allowance takes into account the total you have invested across all of your ISAs. If you are approaching your annual ISA allowance, you may wish to review any Direct Debits you have set up to pay in to your plan with us. It is your responsibility as a member to adjust, stop and restart any Direct Debit payments."
      ></app-alert>
      <app-button
        text="Proceed to payment"
        (click)="goToPayStep()"
        [noBorder]="true"
        buttonStyle="secondary"
        [isSubmitButton]="false"
      ></app-button>
    </app-top-up-declaration>
  </div>

  <div [ngClass]="{ 'hidden-step': !(step === stepOpts['PAY']) }">
    <div id="card-info" #cardInfo></div>

    <div class="sf-card mb-4">
      <div>
        <app-stripe-element (onCardElement)="handleSetCardElement($event)">
          <div class="mb-4">
            <label for="cardHolderName" class="form-label">Account Name?</label>
            <div class="input-group mb-4">
              <input
                id="cardHolderName"
                formControlName="cardHolderName"
                type="text"
                class="form-control"
                placeholder="e.g. Mr John Smith"
                required
                [ngClass]="{
                  'is-invalid': cardHolderNameCtrl.touched && cardHolderNameCtrl.errors,
                }"
              />
            </div>
          </div>
        </app-stripe-element>

        <app-button
          text="Pay"
          buttonStyle="secondary"
          customClass="[&>*]:py-4 [&>*]:px-8"
          [noBorder]="true"
          [isSubmitButton]="true"
          [disabled]="submittingPayment || !topUpForm.valid"
        ></app-button>
      </div>
    </div>
  </div>
</form>

<router-outlet />
