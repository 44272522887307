<h2>Enter your verification code</h2>

<div *ngIf="emailOrMobile && !resentCode">
  <p>We have sent you a one-time verification code to:</p>
  <p class="text-center text-lg">
    {{ emailOrMobile }}
  </p>
</div>

<div *ngIf="resentCode">
  <p>We have just resent your new code to</p>
  <p class="text-center text-lg">
    {{ emailOrMobile }}
  </p>
</div>

<form [formGroup]="tfaForm" (ngSubmit)="onSubmit()">
  <label for="tfacode" class="form-label">Verification code</label>
  <div class="input-group">
    <input
      type="text"
      class="form-control"
      id="code"
      name="code"
      placeholder="Enter your six digit code"
      autocomplete="one-time-code"
      required
      formControlName="code"
      [ngClass]="{
        'is-invalid': submittedCode && codeCtrl.errors,
      }"
    />
  </div>

  <app-validation-message *ngIf="error" message=" {{ error }}"></app-validation-message>

  <div *ngIf="submittedCode && codeCtrl.errors">
    <app-validation-message
      *ngIf="codeCtrl.errors['required']"
      message="Please enter your verification code"
    ></app-validation-message>

    <app-validation-message
      *ngIf="codeCtrl.errors['pattern']"
      message=" Your code should only contain numbers"
    ></app-validation-message>

    <app-validation-message
      *ngIf="codeCtrl.errors['minlength']"
      message=" Your code is too short, it should be 6 numbers long"
    ></app-validation-message>

    <app-validation-message
      *ngIf="codeCtrl.errors['maxlength']"
      message=" Your code is too long, it should be 6 numbers long"
    ></app-validation-message>
  </div>

  <app-button
    text="Confirm"
    buttonStyle="primary"
    [disabled]="loading$ | async"
    [noBorder]="true"
    customClass="my-4"
    [isSubmitButton]="true"
  ></app-button>

  <app-button text="Resend code" buttonStyle="secondary" (click)="resendCode()"></app-button>
</form>
