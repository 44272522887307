<!-- @TODO: Add functionality to not show the below unless logged in -->
<nav
  *ngIf="visibilityService.isMdOrBelow"
  class="bg-sf-white dark:bg-primary-sf-navy flex justify-around shadow-sm py-3.5"
>
  <button
    routerLink="/home"
    routerLinkActive="active"
    aria-label="Home"
    class="flex flex-col gap-1 items-center w-[60px] border-none group"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 20"
      class="w-[20px] h-[20px] sm:w-6 sm:h-6 stroke-primary-sf-navy dark:stroke-sf-white/80 fill-none stroke-[1.5] [stroke-linejoin:round] [stroke-linecap:round] group-[.active]:stroke-primary-sf-navy group-[.active]:fill-primary-sf-yellow dark:group-[.active]:stroke-primary-sf-yellow dark:group-[.active]:fill-none"
    >
      <path
        d="m1 7.5 7.5-5.833L16 7.5v9.167a1.667 1.667 0 0 1-1.667 1.666H2.667A1.666 1.666 0 0 1 1 16.667V7.5Z"
      />
      <path d="M6 18.333V10h5v8.333" />
    </svg>
    <span
      class="text-xs md:text-sm font-medium dark:text-sf-white/80 group-[.active]:font-bold group-[.active]:text-primary-sf-navy group-[.active]:dark:text-sf-white"
    >
      Home
    </span>
  </button>

  <button
    routerLink="/documents"
    routerLinkActive="active"
    aria-label="Documents"
    class="flex flex-col gap-1 items-center w-[60px] border-none group"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 20"
      class="w-[20px] h-[20px] sm:w-6 sm:h-6 stroke-primary-sf-navy dark:stroke-sf-white/80 fill-none stroke-[1.5] [stroke-linejoin:round] [stroke-linecap:round] group-[.active]:stroke-primary-sf-navy group-[.active]:fill-primary-sf-yellow dark:group-[.active]:stroke-primary-sf-yellow dark:group-[.active]:fill-none"
    >
      <path
        d="M9.167 1.667H2.5A1.665 1.665 0 0 0 .833 3.333v13.334A1.666 1.666 0 0 0 2.5 18.333h10a1.668 1.668 0 0 0 1.667-1.666v-10l-5-5Z"
      />
      <path d="M9.167 1.667v5h5M10.833 10.833H4.167M10.833 14.167H4.167M5.833 7.5H4.167" />
    </svg>
    <span
      class="text-xs md:text-sm font-medium text-primary-sf-navy dark:text-sf-white/80 group-[.active]:font-bold group-[.active]:text-primary-sf-navy group-[.active]:dark:text-sf-white"
    >
      Documents
    </span>
  </button>

  <button
    routerLink="/plans/deposit"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: false }"
    aria-label="Deposits"
    class="flex flex-col gap-1 items-center w-[60px] border-none group"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      class="w-[20px] h-[20px] sm:w-6 sm:h-6 stroke-primary-sf-navy dark:stroke-sf-white/80 fill-none stroke-[1.5] [stroke-linejoin:round] [stroke-linecap:round] group-[.active]:stroke-primary-sf-navy group-[.active]:fill-primary-sf-yellow dark:group-[.active]:stroke-primary-sf-yellow dark:group-[.active]:fill-none"
    >
      <path
        d="M12.167 17.917H1.75a.832.832 0 0 1-.833-.834V5.417a.835.835 0 0 1 .833-.834h16.667a.832.832 0 0 1 .833.834V12.5"
      />
      <path
        d="m1.75 4.583 12.446-3.112a.719.719 0 0 1 .815.382.71.71 0 0 1 .072.314v2.416M16.333 20.333a4.5 4.5 0 1 0 0-8.999 4.5 4.5 0 0 0 0 9ZM16.333 14.167V17.5M18 15.833h-3.333"
      />
    </svg>
    <span
      class="text-xs md:text-sm font-medium text-primary-sf-navy dark:text-sf-white/80 group-[.active]:font-bold group-[.active]:text-primary-sf-navy group-[.active]:dark:text-sf-white"
    >
      Deposit
    </span>
  </button>

  <button
    routerLink="/notifications"
    routerLinkActive="active"
    aria-label="Notifications"
    class="flex flex-col gap-1 items-center w-[60px] border-none group"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 19"
      class="w-[20px] h-[20px] sm:w-6 sm:h-6 stroke-primary-sf-navy dark:stroke-sf-white/80 fill-none stroke-[1.5] [stroke-linejoin:round] [stroke-linecap:round] group-[.active]:stroke-primary-sf-navy group-[.active]:fill-primary-sf-yellow dark:group-[.active]:stroke-primary-sf-yellow dark:group-[.active]:fill-none"
    >
      <path
        d="M13.25 6.333a4.75 4.75 0 1 0-9.5 0c0 5.542-2.375 7.125-2.375 7.125h14.25s-2.375-1.583-2.375-7.125ZM9.87 16.625a1.583 1.583 0 0 1-2.74 0"
      />
    </svg>
    <span
      class="text-xs md:text-sm font-medium text-primary-sf-navy dark:text-sf-white/80 group-[.active]:font-bold group-[.active]:text-primary-sf-navy group-[.active]:dark:text-sf-white"
    >
      Notifications
    </span>
  </button>

  <button
    routerLink="/help"
    routerLinkActive="active"
    aria-label="Help"
    class="flex flex-col gap-1 items-center w-[60px] border-none group"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      class="w-[20px] h-[20px] sm:w-6 sm:h-6 stroke-primary-sf-navy dark:stroke-sf-white/80 fill-none stroke-[1.5] [stroke-linejoin:round] [stroke-linecap:round] group-[.active]:stroke-primary-sf-navy group-[.active]:fill-primary-sf-yellow dark:group-[.active]:stroke-primary-sf-yellow dark:group-[.active]:fill-none"
    >
      <path d="M10 18.333a8.333 8.333 0 1 0 0-16.666 8.333 8.333 0 0 0 0 16.666Z" />
      <path d="M7.575 7.5a2.501 2.501 0 0 1 4.858.833c0 1.667-2.5 2.5-2.5 2.5M10 14.167h.008" />
    </svg>
    <span
      class="text-xs md:text-sm font-medium text-primary-sf-navy dark:text-sf-white/80 group-[.active]:font-bold group-[.active]:text-primary-sf-navy group-[.active]:dark:text-sf-white"
    >
      Help
    </span>
  </button>
</nav>
