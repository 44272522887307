import { UrlUtility } from './url.utility';

export class EmailUtility {
  static readonly emailAddress = {
    members: 'members',
    info: 'info',
    withdrawals: 'withdrawals',
  };

  /**
   * Opens users default mailbox
   */
  static openEmailInbox() {
    const mailtoLink = `mailto:?`;
    UrlUtility.goToLink(mailtoLink);
  }

  /**
   * Opens users default mailbox and creates a draft email
   */
  static openEmailDraft(emailUserName: string, subject: string) {
    const validEmail = this.emailAddress[emailUserName];

    if (!validEmail) {
      throw new Error(`Invalid email: ${emailUserName}`);
    }

    const emailAddress = `${emailUserName}@shepherdsfriendly.co.uk`;
    const body = '';

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    UrlUtility.goToLink(mailtoLink);
  }
}
