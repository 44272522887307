import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { PlanConnectionRequest } from '@app/models/registration';
import { AppConstantsService, RegistrationService } from '@app/services';
import { RegexUtility } from '@app/shared/utilities';
import { CommonValidators } from '@app/shared/validators';
import { EMPTY, Subscription, catchError, filter } from 'rxjs';

const stepOpts: Record<string, string> = {
  YOUR_DETAILS: 'Details',
  REVIEW: 'Review',
};

@Component({
  selector: 'app-link-jisa',
  templateUrl: './link-jisa.component.html',
  styleUrls: ['./link-jisa.component.scss'],
})
export class LinkJISAComponent implements OnDestroy {
  readonly url = {
    LINK_JISA_SUBMITTED: `plans/plan-linking/link-jisa/submitted`,
  };

  public linkJISAForm: FormGroup;
  public stepOpts = stepOpts;
  public step = this.stepOpts['YOUR_DETAILS'];

  private subscribeUntilDestroyed = new Subscription();

  get childsPlanNumberCtrl() {
    return this.linkJISAForm.get('childsPlanNumber');
  }
  get childsFirstNameCtrl() {
    return this.linkJISAForm.get('childsFirstName');
  }
  get childsSurnameCtrl() {
    return this.linkJISAForm.get('childsSurname');
  }
  get childsDobCtrl() {
    return this.linkJISAForm.get('childsDob');
  }

  constructor(
    public appConstants: AppConstantsService,
    private fb: FormBuilder,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private registrationService: RegistrationService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.linkJISAForm = this.fb.group({
      childsPlanNumber: [
        '',
        [Validators.required, Validators.pattern(RegexUtility.jisaAndSustainablePrefix)],
      ],
      childsFirstName: ['', [Validators.required]],
      childsSurname: ['', [Validators.required]],
      childsDob: ['', [Validators.required, CommonValidators.under18Validator()]],
    });

    this.linkJISAForm.valueChanges.subscribe((value) => {
      const planNumber = this.childsPlanNumberCtrl.value;

      if (planNumber !== planNumber.toUpperCase()) {
        this.childsPlanNumberCtrl.setValue(planNumber.toUpperCase(), {
          emitEvent: false,
        });
      }
    });

    this.subscribeUntilDestroyed.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe((event: any) => {
          if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
            if (event.restoredState) {
              this.goOneStepBack();
            }
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  public goOneStepBack(): void {
    const curStep = window.location.hash.substring(1);
    if (this.step === this.stepOpts[curStep]) {
      this.location.back();
    } else {
      this.step = this.stepOpts[curStep];
    }
    this.scrollToTop();
  }

  public goToStep(step: string): void {
    if (this.stepOpts[step]) {
      this.step = this.stepOpts[step];
      this.updateUrlHash(step);
      this.scrollToTop();
    }
  }

  private updateUrlHash(step: string) {
    const currentHash = `#${step}`;
    window.location.hash = currentHash;
  }

  public scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  public link(): void {
    const registerPayload = {
      requestFirstName: this.childsFirstNameCtrl.value,
      requestSurname: this.childsSurnameCtrl.value,
      requestDOB: this.childsDobCtrl.value,
      requestPlanNumber: this.childsPlanNumberCtrl.value,
    } as PlanConnectionRequest;

    this.registrationService.planConnectionRequest(registerPayload).subscribe(() => {
      this.router.navigate([this.url.LINK_JISA_SUBMITTED]);
    });
  }
}
