import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthorisedSavingsPlan } from '@app/models/member-portal/authorised-savings-plan.model';
import { catchError, map, Observable, throwError } from 'rxjs';
import { MemberDashboard } from '../models/member-portal';
import { MemberPortalService } from '../services';
import { TypeUtility } from '../shared/utilities';

@Injectable({
  providedIn: 'root',
})
export class CTFDetailsResolver {
  public planNumber: string;

  constructor(private memberPortalService: MemberPortalService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<AuthorisedSavingsPlan> {
    this.planNumber = route.params['planNumber'];

    return this.memberPortalService
      .getMemberDashboard()
      .pipe(catchError((error) => throwError(() => `MemberDashboardResolver -> ${error}`)))
      .pipe(
        map((memberDashboard) => this.getCTFDetails(memberDashboard)),
        catchError((error) => throwError(() => `CTFDetailsResolver -> ${error}`))
      );
  }

  /**
   * Finds matching CTF AuthorisedSavingsPlan or throws an error
   * @param {MemberDashboard} [memberDashboard]
   * @return {AuthorisedSavingsPlan}
   */
  private getCTFDetails(memberDashboard: MemberDashboard): AuthorisedSavingsPlan {
    const result = memberDashboard.authorisedPlans.authorisedSavingsPlans.find(
      (authorisedSavingsPlan) =>
        authorisedSavingsPlan.plan.formattedPlanNumber.toUpperCase() ==
        this.planNumber.toUpperCase()
    );

    if (TypeUtility.isNullOrUndefined(result)) {
      throw new Error('No CTF plan data');
    }

    return result;
  }
}
