import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SavingsPlan } from '@app/models/member-portal';

type RouteStateData = { plan: SavingsPlan; error: string };

@Component({
    selector: 'app-top-up-error',
    templateUrl: './top-up-error.component.html',
    styleUrls: ['./top-up-error.component.css'],
    standalone: false
})
export class TopUpErrorComponent {
  readonly url = {
    HOME: `/home`,
    PLAN_CHOICE: `/plans/deposit`,
  };

  public plan: SavingsPlan;
  public error: string;

  constructor(public router: Router) {
    this.loadErrorFromRoute();
  }

  /**
   * Retrieves error from the route state, if it does not exist
   * redirect back to the plan choice page.
   */
  private loadErrorFromRoute(): void {
    const state = this.router.getCurrentNavigation()?.extras?.state as RouteStateData;

    if (state) {
      this.plan = state.plan;
      this.error = state.error;
    } else {
      this.router.navigate([this.url.PLAN_CHOICE]);
    }
  }
}
