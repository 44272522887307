import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notifcation-meta-info',
  templateUrl: './notifcation-meta-info.component.html',
  styleUrls: ['./notifcation-meta-info.component.scss'],
})
export class NotifcationMetaInfoComponent {
  @Input() type: string;
  @Input() date: string;
  @Input() status: string;
  @Input() typeMessage: string;
  @Input() isDetail: boolean = false;
  @Input() important: boolean;
}
