import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService, RouterHelperService } from '@app/services';
import { map, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private authService: AuthService, private routerHelperService: RouterHelperService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.validateToken().pipe(
      take(1),
      map((status) => {
        return this.actionStatus(status, state);
      })
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.validateToken().pipe(
      take(1),
      map((status) => {
        return this.actionStatus(status, state);
      })
    );
  }

  private actionStatus(status: number, state: RouterStateSnapshot): boolean {
    if (status === 200) {
      // Token fully-validated, allow routing
      return true;
    } else if (status === 4012) {
      // Token partially-validated, request TFA auth
      this.routerHelperService.redirectWithDestination('log-in-tfa', state.url);
      return false;
    } else {
      // Token not valid, request full auth
      this.authService.logout();
      this.routerHelperService.redirectWithDestination('log-in', state.url);
      return false;
    }
  }
}
