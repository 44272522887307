import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MemberDashboard, SavingsPlan } from 'src/app/models/member-portal';

export enum FundTypeOptions {
  'Defensive' = 'Defensive',
  'Balanced' = 'Balanced',
  'Growth' = 'Growth',
  'Adventurous' = 'Adventurous',
  'Dynamic' = 'Dynamic',
}

@Component({
  selector: 'app-ssi-fund-transfer',
  templateUrl: './ssi-fund-transfer.component.html',
  styleUrls: ['./ssi-fund-transfer.component.scss'],
  standalone: false,
})
export class SSIFundTransferComponent implements OnInit, OnDestroy {
  @Input() ssiDetails?: SavingsPlan;
  @Input() memberDashboard?: MemberDashboard;
  public fundType = FundTypeOptions.Defensive;
  public fundTypeOpts = FundTypeOptions;

  private subscribeUntilDestroyed = new Subscription();

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  get arrowPosition() {
    return `fund-controls-arrow-${this.fundType.toLowerCase()}`;
  }

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  public fundTypeChange(event: FundTypeOptions = this.fundType): void {
    this.fundType = event;
  }
}
