import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SavingsPlan } from '@app/models/member-portal';
import { MaskUtility, TextUtility } from '@app/shared/utilities';

@Component({
  selector: 'app-direct-debit-overview',
  templateUrl: './direct-debit-overview.component.html',
  styleUrls: ['./direct-debit-overview.component.scss'],
})
export class DirectDebitOverviewComponent {
  @Input() plan: SavingsPlan;
  @Input() directDebit: any;
  @Input() hasAmend: boolean = false;
  @Input() concise: boolean = true;
  @Output() onAmend: EventEmitter<boolean> = new EventEmitter();

  public readonly sortCodeMask = MaskUtility.sortCode;

  constructor(public router: Router) {}

  /**
   * Returns text string 'monthly' or 'annual' based on period that has been set
   */
  get paymentPeriod(): 'monthly' | 'annual' {
    return this.directDebit && String(this.directDebit.period) === '12' ? 'monthly' : 'annual';
  }

  /**
   * Returns text string 'monthly' or 'annual' based on period that has been set
   */
  get paymentDayMonth(): string {
    const day = TextUtility.numberWithOrdinal(this.directDebit.dayDue);
    let month = 'of the month';

    if (this.paymentPeriod === 'annual') {
      month = `of ${TextUtility.monthNameByIndex(this.directDebit.periodDue)}`;
    }

    return `${day} ${month}`;
  }

  /**
   * Returns true if sortCode passed back from server has already been masked.
   */
  get hasAlreadyMaskedSortCode(): boolean {
    return this.directDebit.sortCode.includes('*');
  }

  /**
   * Emits true when amend is clicked
   */
  public handleOnClickAmend(): void {
    this.onAmend.emit(true);
  }
}
