import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DirectDebit, SavingsPlan } from '@app/models/member-portal';

type RouteStateData = { plan: SavingsPlan; directDebits?: DirectDebit[] };

@Component({
    selector: 'app-direct-debit-view-all',
    templateUrl: './direct-debit-view-all.component.html',
    styleUrls: ['./direct-debit-view-all.component.scss'],
    standalone: false
})
export class DirectDebitViewAllComponent implements OnInit {
  public readonly url = {
    DIRECT_DEBIT_VARY: `/direct-debit/plan/vary`,
  };

  public plan: SavingsPlan;
  public directDebits: DirectDebit[];

  constructor(
    public router: Router,
    public route: ActivatedRoute
  ) {
    this.loadPlanDetailsFromRoute();
  }

  ngOnInit(): void {}

  /**
   * Retrieves plan / direct debit details data from the route state, if non exist
   * redirect back to app root page.
   */
  private loadPlanDetailsFromRoute(): void {
    const state = this.router.getCurrentNavigation()?.extras?.state as RouteStateData;

    if (state) {
      this.plan = state.plan;
      this.directDebits = state.directDebits;
    } else {
      this.router.navigate(['/']);
    }
  }
}
