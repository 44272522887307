import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, catchError, take } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css'],
})
export class VerifyEmailComponent implements OnInit {
  public submitted: boolean = false;
  public validated: boolean = false;
  public alreadyVerified: boolean = false;
  public failed: boolean = false;

  public verifyEmailForm: FormGroup;

  public id: string;
  public emailAddress: string;
  public code: string;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.buildForm();

    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
      this.emailAddress = params['emailAddress'];
      this.code = params['emailCode'];

      if (this.emailAddress !== undefined && this.emailAddress.length > 0) {
        this.verifyEmailForm.get('username').setValue(this.emailAddress);
      }

      if (
        !this.verifyEmailForm.valid ||
        this.id == undefined ||
        this.emailAddress == undefined ||
        this.code == undefined
      ) {
        // failed due to missing/incorrect URL parameter input
        this.submitted = true;
        this.failed = true;
      }
    });
  }

  /**
   * Submits user email to forgotten password endpoint
   */
  public onSubmit(): void {
    this.submitted = true;

    if (this.verifyEmailForm.valid) {
      this.authService
        .verifyEmail({
          Id: this.id,
          EmailAddress: this.emailAddress,
          Code: this.code,
        })
        .pipe(
          take(1),
          catchError((error, caught) => {
            this.failed = true;
            return EMPTY;
          })
        )
        .subscribe((response) => {
          this.validated = response.verifiedEmailAddress;
          this.alreadyVerified = response.alreadyVerifiedEmailAddress;
        });
    }
  }

  /**
   * Builds the form configuration with angulars form builder service
   */
  private buildForm(): void {
    this.verifyEmailForm = this.fb.group({
      username: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }
}
