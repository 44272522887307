import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  DirectDebit,
  MemberDashboard,
  SavingsPlan,
  TransactionsSummary,
} from 'src/app/models/member-portal';

@Component({
  selector: 'app-isa-details',
  templateUrl: './isa-details.component.html',
  styleUrls: ['./isa-details.component.css'],
  standalone: false,
})
export class IsaDetailsComponent implements OnDestroy {
  @Input('isaDetails') plan?: SavingsPlan;
  @Input() memberDashboard?: MemberDashboard;
  @Input() transactionsSummary?: TransactionsSummary;

  readonly url = {
    TOP_UP: (formattedPlanNumber) => `/plans/deposit/${formattedPlanNumber.toLowerCase()}`,
    TRANSACTIONS: ({ planId, formattedPlanNumber }) =>
      `/transactions/${planId}/${formattedPlanNumber.toLowerCase()}`,
    DOCUMENTS: `/documents/plan/{0}`,
    DIRECT_DEBIT: `/direct-debit/plan`,
    DIRECT_DEBIT_VARY: `/direct-debit/plan/vary`,
    WITHDRAWAL: (formattedPlanNumber) => `/plans/withdrawal/${formattedPlanNumber.toLowerCase()}`,
  };
  private subscribeUntilDestroyed = new Subscription();

  constructor(private router: Router) {}

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  /**
   * Returns true if the plan has 'singleActiveFound' set to true
   */
  public get hasDirectDebit(): boolean {
    return this.plan.directDebitInformation.singleActiveFound;
  }

  /**
   * Returns the first direct debit available
   */
  public get directDebit(): DirectDebit {
    return this.plan.directDebitInformation.directDebits[0];
  }

  /**
   * Navigates to a url and passes a plan and member object in router state
   * @param {string} [url] the url to navigate to
   * @param {object} [additionalState] optional object to pass along with the route state
   */
  public navigateWithState(url: string, additionalState?: object): void {
    this.router.navigate([url], {
      state: {
        plan: this.plan,
        member: this.memberDashboard.member,
        ...additionalState,
      },
    });
  }
}
