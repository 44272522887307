<form #form="ngForm" id="register-step-two" [formGroup]="registerForm">
  <div>
    <div class="mb-2">
      <p>Please fill in your details below.</p>
    </div>

    <hr />

    <div class="mb-2">
      <label for="firstName" class="form-label">First name</label>
      <div class="input-group mb-4">
        <input
          formControlName="firstName"
          type="text"
          class="form-control"
          [ngClass]="{
            'is-invalid': firstNameCtrl.touched && firstNameCtrl.errors,
          }"
        />
      </div>
    </div>

    <div class="mb-2">
      <label for="surname" class="form-label">Surname</label>
      <div class="input-group mb-4">
        <input
          formControlName="surname"
          type="text"
          class="form-control"
          [ngClass]="{
            'is-invalid': surnameCtrl.touched && surnameCtrl.errors,
          }"
        />
      </div>
    </div>

    <div class="mb-2">
      <label class="form-label">Address</label>
      <app-address-picker (onSelectedAddress)="onUpdateAddress($event)"></app-address-picker>
    </div>

    <div class="mb-2">
      <label for="dob" class="form-label">Date of birth</label>
      <div class="input-group mb-4">
        <input
          formControlName="dob"
          type="date"
          class="form-control"
          [ngClass]="{
            'is-invalid': dobCtrl.touched && dobCtrl.errors,
          }"
          min="1900-01-01"
          max="2099-12-31"
        />
      </div>
    </div>

    <app-alert
      additionalClass="my-4"
      iconClass="far fa-question-circle"
      type="info"
      title="Your date of birth"
      message="Your date of birth is required as it allows us to complete essential security checks."
    ></app-alert>
  </div>
</form>
