import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MaskUtility, RegexUtility } from '@app/shared/utilities';

@Component({
    selector: 'app-register-ctf-step-one',
    templateUrl: './register-ctf-step-one.component.html',
    styleUrls: ['./register-ctf-step-one.component.scss'],
    standalone: false
})
export class RegisterCTFStepOneComponent implements OnInit {
  public registerForm: FormGroup;
  public readonly ninoMask = MaskUtility.nino;

  @Output() onUpdate = new EventEmitter<any>();
  @Output() onValid = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder) {}

  get hasAccount() {
    return this.registerForm.get('hasAccount');
  }
  get firstNameCtrl() {
    return this.registerForm.get('firstName');
  }
  get surnameCtrl() {
    return this.registerForm.get('surname');
  }
  get addressLine1() {
    return this.registerForm.get('addressLine1');
  }
  get addressLine2() {
    return this.registerForm.get('addressLine2');
  }
  get addressLine3() {
    return this.registerForm.get('addressLine3');
  }
  get addressLine4() {
    return this.registerForm.get('addressLine4');
  }
  get dobCtrl() {
    return this.registerForm.get('dob');
  }
  get postcodeCtrl() {
    return this.registerForm.get('postcode');
  }
  get planNumberCtrl() {
    return this.registerForm.get('planNumber');
  }

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      hasAccount: [false],
      firstName: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      addressLine1: [''],
      addressLine2: [''],
      addressLine3: [''],
      addressLine4: [''],
      postcode: ['', [Validators.required]],
      planNumber: ['', [Validators.required, Validators.pattern(RegexUtility.nino)]],
      hasIndicatedOwnPlan: [false, [Validators.requiredTrue]],
    });

    this.registerForm.valueChanges.subscribe((value) => {
      this.onUpdate.emit(value); // Emit the form value whether it's valid or not
      this.onValid.emit(this.registerForm.valid); // Emit the validity status
    });
  }

  public onUpdateAddress(address: any): void {
    this.addressLine1.setValue(address.line1);
    this.addressLine2.setValue(address.line2);
    this.addressLine3.setValue(address.city);
    this.addressLine4.setValue(address.county);
    this.postcodeCtrl.setValue(address.postcode);
  }
}
