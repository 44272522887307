import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '@app/services';
import { LoadingService } from '@app/services';

@Component({
    selector: 'app-manage-authn',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.css'],
    standalone: false
})
export class NotificationsComponent implements OnInit {
  public isSupported: boolean;
  public isDeviceRegistered: boolean;
  public registerError: boolean;
  public unregisterError: boolean;

  constructor(
    private notificationsService: NotificationsService,
    private loader: LoadingService
  ) {}

  ngOnInit(): void {
    // get initial state
    this.isSupported = this.notificationsService.isSupported;
    this.isDeviceRegistered = this.isSupported && this.notificationsService.isRegistered;

    // validate
    if (this.isDeviceRegistered) {
      this.notificationsService
        .validate()
        .then((r) => {
          if (!r) {
            // no longer registered
            this.isDeviceRegistered = false;
          }
        })
        .catch(() => {
          console.warn(`Notifications: Could not validate token?`);
        });
    }
  }

  register() {
    this.loader.show();
    this.registerError = this.unregisterError = false;

    // register with firebase & our server
    this.notificationsService
      .register()
      .catch(() => {
        this.registerError = true;
      })
      .finally(() => {
        this.isDeviceRegistered = this.notificationsService.isRegistered;
        this.loader.hide();
      });
  }

  unregister() {
    this.loader.show();
    this.unregisterError = this.registerError = false;

    // unregister with firebase & our server
    this.notificationsService
      .unregister()
      .catch(() => {
        this.unregisterError = true;
      })
      .finally(() => {
        this.isDeviceRegistered = this.notificationsService.isRegistered;
        this.loader.hide();
      });
  }
}
