import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { MemberDashboard, SavingsPlan } from '../models/member-portal';
import { MemberPortalService } from '../services';
import { TypeUtility } from '../shared/utilities';

@Injectable({
  providedIn: 'root',
})
export class IsaDetailsResolver {
  public planNumber: string;

  constructor(private memberPortalService: MemberPortalService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SavingsPlan> {
    this.planNumber = route.params['planNumber'];

    return this.memberPortalService
      .getMemberDashboard()
      .pipe(catchError((error) => throwError(() => `MemberDashboardResolver -> ${error}`)))
      .pipe(
        map((memberDashboard) => this.getIsaDetails(memberDashboard)),
        catchError((error) => throwError(() => `IsaDetailsResolver -> ${error}`))
      );
  }

  /**
   * Finds matching ISA SavingsPlan or throws an error
   * @param {MemberDashboard} [memberDashboard]
   * @return {SavingsPlan}
   */
  private getIsaDetails(memberDashboard: MemberDashboard): SavingsPlan {
    const result = memberDashboard.memberPlans.savingsPlans.find(
      (plan) => plan.formattedPlanNumber.toUpperCase() == this.planNumber.toUpperCase()
    );

    if (TypeUtility.isNullOrUndefined(result)) {
      throw new Error('No ISA plan data');
    }

    return result;
  }
}
