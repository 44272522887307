import { ErrorType } from './error-type.enum';

/**
 * A collection of error messages mapped to defined `ErrorType` values.
 * Pass params in if required to control or modify the string output
 */
export const ErrorMessages: Record<ErrorType, (params?: Record<string, any>) => string> = {
  [ErrorType.GENERIC]: () => 'An error occurred. Please try again',
  [ErrorType.INVALID_LINK]: () => 'Invalid password reset link.',
  [ErrorType.EXPIRED_LINK]: () =>
    '<p>To keep your account safe, password reset links only last 24 hours.</p><p>You’ll need to request a new link to re-set your password. Simply click the button and we’ll send this straight to your inbox to help get you back on track in no time.</p><a class="underline" href="/forgotten-password">Request a New Link</a>',
};
