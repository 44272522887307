import { Component, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { StripeService, ThemeService } from '@app/services';
import { filter, Subscription } from 'rxjs';
import { Mode } from '@app/services/theme.service';

@Component({
    selector: 'app-stripe-element',
    templateUrl: './stripe-element.component.html',
    styleUrls: ['./stripe-element.component.scss'],
    standalone: false
})
export class StripeElementComponent implements AfterViewInit {
  @Output() onCardElement: EventEmitter<any> = new EventEmitter<any>();

  public cardElement: any;
  public prButton: any;
  selectedTheme: Mode;

  public error: string;
  public nativeError: string;

  public hideWalletOptions = true;
  public hideNativeGooglePay = true;

  public readonly cardElementId = '#card-element';

  private subscribeUntilDestroyed = new Subscription();

  constructor(private stripeService: StripeService, private themeService: ThemeService) {}

  ngAfterViewInit() {
    this.stripeService.initialiseStripe();

    this.stripeService.setUpPaymentButton();

    this.mountStripeComponent();

    this.setupStripeOnChangeListener();

    this.setupStripeWalletOptions();
  }

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  ngOnInit(): void {
    this.selectedTheme = this.themeService.theme;
  }

  /**
   * Subscribes for apple / google pay support off of the stripe service and then shows or hides wallet pay button
   */
  private setupStripeWalletOptions(): void {
    // support for stripe "payment request" buttons
    this.subscribeUntilDestroyed.add(
      this.stripeService.walletOptions$.pipe(filter(Boolean)).subscribe((options) => {
        if (options.applePay || options.googlePay) {
          this.hideWalletOptions = false;
        } else {
          this.hideWalletOptions = true;
        }
      })
    );

    // native Google Pay support (native application only)
    this.subscribeUntilDestroyed.add(
      this.stripeService.nativeGooglePay$.pipe(filter(Boolean)).subscribe((available) => {
        this.hideNativeGooglePay = !available;
      })
    );
  }

  /**
   * Mounts the stripe card component to #card-element
   */
  private mountStripeComponent(): void {
    const elements = this.stripeService.getElements();

    this.cardElement = elements.create('card', {
      hidePostalCode: true,

      style: {
        base: {
          color: this.selectedTheme === Mode.LIGHT ? 'black' : 'white',
          fontSize: '16px',
          fontFamily: '"Brandon Text", Helvetica, sans-serif',
          '::placeholder': {
            fontSize: '16px',
          },
        },
      },
    });

    this.cardElement.mount(this.cardElementId);
    this.onCardElement.emit(this.cardElement);
  }

  /**
   * Listens for stripe errors and sets an error variable if an error exists
   */
  private setupStripeOnChangeListener(): void {
    this.cardElement.addEventListener(
      'change',
      (event: { error: { message: string } }) =>
        (this.error = event.error ? event.error.message : '')
    );
  }

  /**
   * React to the click on the native Google Pay button
   */
  googlePayClick = (ev: Event): void => {
    // prevent the built-in Google Pay component handling the click
    ev.preventDefault();

    // clear any previous error
    this.nativeError = undefined;

    // invoke native Google Pay
    this.stripeService
      .invoke_native_ExecuteGooglePay()
      .then((rv) => {
        if (!rv) {
          this.nativeError = 'An error occured with Google Pay';
        }
      })
      .catch((e) => {
        this.nativeError = 'A unexpected failure occured with Google Pay';
      });
  };
}
