import { Injectable } from '@angular/core';

import { AppConfigurationClient } from '@azure/app-configuration';
import { environment } from '@env/environment';

@Injectable()
export class FeatureFlagService {
  private client = new AppConfigurationClient(environment.azure.appConfig);

  /**
   * Checks enabled status of provided feature flag key inside current environment
   *
   * Local builds will bypass feature flag retrieval (always truthy), however you can still simulate other environments
   * by tweaking the `environment.ts` files `azure.appLabel` value.
   *
   * Builds on any other environment than local will not show the feature if the configuration key 404s.
   *
   * @param {string} [key]
   */
  async isEnabled(key: string): Promise<boolean> {
    const isLocal = environment.azure.appLabel === 'local';
    let parsed = { enabled: isLocal };

    if (!isLocal) {
      const getFlagConfig = await this.client
        .getConfigurationSetting({
          key: `.appconfig.featureflag/${key}`,
          label: environment.azure.appLabel,
        })
        .catch((e) => {
          console.log(`Feature flag "${key}" could not be retrieved.`);
        });

      if (getFlagConfig && getFlagConfig.value) {
        parsed = JSON.parse(getFlagConfig.value);
      }
    }

    return parsed.enabled;
  }

  /**
   * Lists all settings from the Azure App Configuration service
   */
  async listConfigurationSettings(): Promise<any> {
    const getAllConfigurationSettings = await this.client.listConfigurationSettings();

    return getAllConfigurationSettings;
  }
}
