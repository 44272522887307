import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstantsService, LoadingService } from '@app/services';
import { Login } from 'src/app/models/security/log-in.model';
import { AuthService } from 'src/app/services/auth.service';
import { FidoService } from 'src/app/services/fido.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-log-in-password',
    templateUrl: './log-in.password.component.html',
    styleUrls: ['./log-in.password.component.css'],
    standalone: false
})
export class LogInPasswordComponent implements OnInit {
  private returnUrl: string;
  public submitted = false;
  public sserror = false;
  public canUseAuthn = false;
  public useAuthn = false;
  public authnError = false;
  public loginForm = this.fb.group({
    username: ['', Validators.compose([Validators.required, Validators.email])],
    password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
  });
  public autoSetupBio: boolean = false;
  public showPassword: boolean = false;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private route: ActivatedRoute,
    private loader: LoadingService,
    private router: Router,
    private fido: FidoService,
    public appConstants: AppConstantsService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params['destination'] || '/';
    });

    const userName = this.auth.loginVarUserName;
    if (userName) {
      this.loginForm.get('username').setValue(userName);
    } else {
      // guard against missing user name (should be set by log-in.user)
      this.router.navigateByUrl('/log-out');
    }

    // check authn is platform available and also if we have previously registered or not
    const fidoIsAvailable = this.fido.isWebAuthnAvailable;
    const isDeviceRegistered = this.fido.isDeviceRegistered(userName);
    this.useAuthn = !this.auth.loginUsePassword && fidoIsAvailable && isDeviceRegistered;
    this.canUseAuthn = fidoIsAvailable && !isDeviceRegistered && this.fido.promptUser(userName);

    // don't prompt for auto authn setup if we already have a post-login target
    if (this.returnUrl != '/') {
      this.canUseAuthn = false;
    }
  }

  async authorise() {
    let login = false;
    try {
      this.loader.show();
      this.authnError = false;

      let formContents = this.loginForm.value as Login;
      let password = await this.fido.authorise(formContents.username);

      // if authorise() is successful we can use the temporary password for submission
      this.loginForm.get('password').setValue(password);
      login = true;
    } catch (e) {
      this.authnError = true;
    } finally {
      this.loader.hide();
    }

    // continue to attempt login with temporary password?
    if (login) {
      this.onSubmit();
    }
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  usePassword() {
    // fall-back to using traditional password authentication
    this.useAuthn = false;
    this.auth.loginUsePassword = true;
  }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.valid) {
      // attempt factor of email+password or email+biometric authentication
      let formContents = this.loginForm.value as Login;
      this.auth.login(formContents.username, formContents.password).subscribe({
        next: (n) => {
          let returnUrl = this.returnUrl;

          if (this.autoSetupBio) {
            // attempt to redirect into biometric setup once authorised
            returnUrl = '/profile/options/authn?deeplink';
          }

          // proceed to next factor of authentication (or application if authentication process complete)
          this.router.navigateByUrl(returnUrl).then((rv) => {
            if (rv) {
              // we can't tell if we are TFA validated from the access token, so we need to perform
              // further secondary testing after navigation
              this.auth.validateTFA();
            }
          });
        },
        error: (e) => {
          this.sserror = true;
        },
      });
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  fillPassword() {
    if (environment.hasOwnProperty('devPW')) {
      this.loginForm.get('password').setValue((<any>environment).devPW);
    }
  }
}
