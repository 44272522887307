<label
  class="grid grid-cols-auto-1fr items-center gap-2.5 text-base {{ additionalClass }}"
  [ngClass]="{ 'border-t border-t-primary-sf-navy/10 dark:border-t-sf-white/10': hasBorderTop }"
>
  <input
    type="checkbox"
    (change)="handleChange($event)"
    [checked]="value"
    [disabled]="isDisabled"
  />
  <span>{{ label }}</span>
</label>
