<div class="w-full max-w-[500px] my-0 mx-auto lg:max-w-[600px]">
  <header class="mb-6">
    <h1 class="text-3xl md:text-5xl 2xl:text-6xl font-bold">Welcome</h1>
    <p class="mb-2">Enter your log-in details below</p>
  </header>

  <hr class="border-gray-500 border-opacity-25 border-b mb-6" />

  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <label for="username" class="form-label">Email address</label>
    <input
      readonly
      type="text"
      class="form-control mb-2 !border-sf-mid-grey text-sf-black focus:border-sf-mid-grey focus:text-sf-mid-grey dark:text-sf-mid-grey"
      id="username"
      name="username"
      placeholder="Email address"
      required
      formControlName="username"
      [ngClass]="{
        'is-invalid': submitted && f['username'].errors,
      }"
    />

    <div *ngIf="submitted && f['username'].errors">
      <app-validation-message
        *ngIf="f['username'].errors['required']"
        message="Please enter your email address"
      ></app-validation-message>

      <app-validation-message
        *ngIf="f['username'].errors['email']"
        message="Please enter a valid email address"
      ></app-validation-message>
    </div>

    <section *ngIf="useAuthn">
      <app-alert
        type="success"
        additionalClass="my-4"
        iconClass="far fa-check-circle"
        title="Biometrics enabled"
        message="This device is now configured to use biometric authentication to verify your identity."
        htmlMessage=" Choose 'Continue' and follow the instructions in order to log in using
              this method or alternatively use password authentication."
      ></app-alert>

      <div *ngIf="authnError">
        <app-alert
          type="warning"
          additionalClass="my-4"
          iconClass="far fa-times-circle"
          title="Biometric authentication failure"
          message="Biometric authentication failure, you can retry or alternatively use password authentication."
        ></app-alert>
      </div>

      <app-button
        *ngIf="!authnError"
        text="Continue"
        customClass="mb-4"
        buttonStyle="primary"
        [noBorder]="true"
        (click)="authorise()"
      ></app-button>

      <app-button
        *ngIf="authnError"
        text="Retry"
        customClass="mb-4"
        buttonStyle="primary"
        [noBorder]="true"
        (click)="authorise()"
      ></app-button>

      <app-button
        text="Use password"
        buttonStyle="secondary"
        [noBorder]="true"
        (click)="usePassword()"
      ></app-button>
    </section>

    <section *ngIf="!useAuthn">
      <div class="mb-4">
        <label for="password" class="form-label">Password</label>
        <div class="input-group">
          <input
            type="{{ showPassword ? 'text' : 'password' }}"
            class="form-control password-input"
            id="password"
            name="password"
            placeholder="Password"
            autocomplete="off"
            required
            formControlName="password"
            [ngClass]="{
              'is-invalid': submitted && f['password'].errors,
            }"
          />

          <span
            class="password-toggle input-group-text w-12 flex justify-center"
            (click)="togglePasswordVisibility()"
          >
            <i class="far" [class.fa-eye]="showPassword" [class.fa-eye-slash]="!showPassword"></i>
          </span>
        </div>

        <div *ngIf="submitted && f['password'].errors">
          <app-validation-message
            *ngIf="f['password'].errors['required']"
            message="Please enter your password"
          ></app-validation-message>
        </div>
      </div>

      <app-alert
        *ngIf="submitted && sserror"
        type="warning"
        additionalClass="mt-4 mb-4"
        iconClass="far fa-exclamation-circle"
        title="Error"
        message="You have entered an incorrect email address or password, please try again."
      ></app-alert>

      <app-button
        btnWrapperClasses="my-4"
        text="Log in"
        buttonStyle="primary"
        [disabled]="false"
        [noBorder]="true"
        [inline]="false"
        [alignRight]="true"
        [isSubmitButton]="true"
        [fullWidth]="true"
      ></app-button>

      <app-login-info-message
        *ngIf="canUseAuthn"
        additionalClass="grid items-center grid-cols-24 gap-2"
        [showCheckbox]="true"
        checkboxChecked="autoSetupBio"
        (checkboxChange)="autoSetupBio = !autoSetupBio"
        title=" Enable biometric authentication"
        message="This device is capable of using biometric authentication and
                  replaces entering a password. To get set up, tick the box below or log in and then select 'Biometrics' from
                  the main menu."
      ></app-login-info-message>
    </section>
  </form>
</div>
<div *ngIf="!useAuthn" class="text-center mt-4 md:mt-5">
  <a class="btn-link text-secondary-sf-blue-light" routerLink="/forgotten-password">
    Forgotten your password?
  </a>
</div>
<p class="text-center mt-4 md:mt-5">App version: {{ appConstants.version }}</p>
