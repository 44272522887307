import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { LoginHeaderService } from '@app/services';
import { AppConstantsService, AuthService } from '@app/services';
import { Subscription, filter, take } from 'rxjs';

const stepOpts: Record<string, string> = { 'LOG_IN-AUTH': 'LOGIN-AUTH' };

@Component({
    selector: 'app-forgotten-password',
    templateUrl: './forgotten-password.component.html',
    styleUrls: ['./forgotten-password.component.css'],
    standalone: false
})
export class ForgottenPasswordComponent implements OnInit {
  private subscribeUntilDestroyed = new Subscription();

  public submitted: boolean = false;
  public validated: boolean = false;

  public forgottenPasswordForm: FormGroup;
  public usernameCtrl: AbstractControl;
  public stepOpts = stepOpts;
  public step = this.stepOpts['LOGIN-AUTH'];

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private location: Location,
    public appConstants: AppConstantsService,
    private loginHeaderService: LoginHeaderService
  ) {}

  ngOnInit(): void {
    this.loginHeaderService.buttonClick$.subscribe(() => {
      this.goOneStepBack();
    });

    this.buildForm();

    this.usernameCtrl = this.forgottenPasswordForm.controls['username'];

    this.subscribeUntilDestroyed.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe((event: any) => {
          if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
            if (event.restoredState) {
              this.goOneStepBack();
            }
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }
  /**
   * Submits user email to forgotten password endpoint
   */
  public onSubmit(): void {
    this.submitted = true;

    if (this.forgottenPasswordForm.valid) {
      this.authService
        .forgotPassword({
          email: this.usernameCtrl.value,
          callbackUri: `${document.location.origin}/reset-password`,
        })
        .pipe(take(1))
        .subscribe(() => (this.validated = true));
    }
  }

  /**
   * Builds the form configuration with angulars form builder service
   */
  private buildForm(): void {
    this.forgottenPasswordForm = this.fb.group({
      username: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  public goOneStepBack(): void {
    const curStep = window.location.hash.substring(1);
    if (this.step === this.stepOpts[curStep]) {
      this.location.back();
    } else {
      this.step = this.stepOpts[curStep];
    }
    this.scrollToTop();
  }

  public scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
