import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Login } from '@app/models/security/log-in.model';
import { LoadingService } from '@app/services';
import { AuthService } from 'src/app/services/auth.service';
import { FidoService } from 'src/app/services/fido.service';

@Component({
    selector: 'app-manage-authn',
    templateUrl: './manage-authn.component.html',
    styleUrls: ['./manage-authn.component.css'],
    standalone: false
})
export class ManageAuthnComponent implements OnInit {
  public submitted = false;
  public isWebAuthnAvailable: boolean;
  public isDeviceRegistered: boolean;
  public authnError: boolean;
  public loginForm = this.fb.group({
    password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
  });
  public showPassword: boolean = false;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private loader: LoadingService,
    private fido: FidoService
  ) {}

  ngOnInit(): void {
    this.isWebAuthnAvailable = this.fido.isWebAuthnAvailable;
    this.isDeviceRegistered = this.fido.isDeviceRegistered(this.auth.loginVarUserName);
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  unregister() {
    // clear state
    this.fido.clearRegistered(this.auth.loginVarUserName);

    // update UI
    this.isDeviceRegistered = this.fido.isDeviceRegistered(this.auth.loginVarUserName);
  }

  async onSubmit() {
    this.submitted = true;

    let userToken = undefined;
    try {
      this.authnError = false;

      if (this.loginForm.valid) {
        this.loader.show();

        // get a user token
        userToken = await this.fido.obtainUserToken();

        // attempt to register device
        const formContents = this.loginForm.value as Login;
        const userName = this.auth.loginVarUserName;
        const password = formContents.password;
        await this.fido.register(userName, password, userToken);
      }
    } catch (e) {
      if (this.fido.isAlreadyRegisteredException(e)) {
        // this can happen if the device has previously been registered and we retry registration - in this case we simply correct
        // our local state to match the device state

        // set state
        this.fido.setRegistered(userToken, this.auth.loginVarUserName);
      } else {
        this.authnError = true;
      }
    } finally {
      this.loader.hide();
    }

    // update UI
    this.isDeviceRegistered = this.fido.isDeviceRegistered(this.auth.loginVarUserName);
  }

  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }
}
