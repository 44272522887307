import { Injectable } from '@angular/core';

import { ProfileService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class ProfileGuard {
  constructor(private profileService: ProfileService) {}

  canDeactivate(): boolean {
    this.profileService.clearCache();
    return true;
  }
}
