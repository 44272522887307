<div
  class="sf-card p-4 mb-4"
  *ngFor="let row of this.notifications.items"
  [class.sf-card--read]="row.read"
  [class.sf-card--important]="row.important"
  routerLink="{{ url.NOTIFICATION | interpolate : row.id }}"
>
  <div class="" [class.sf-card--read]="row.read" [class.sf-card--important]="row.important">
    <app-notifcation-meta-info
      [type]="row.typeId"
      [important]="row.important"
      date="{{ row.sentDate | date : 'd MMM y' }}"
      status="STATUS"
    ></app-notifcation-meta-info>

    <div class="pl-4">
      <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white my-1">
        {{ row.headline }}
      </h4>
      <p class="mb-0">{{ row.detail }}</p>
    </div>
  </div>
</div>
