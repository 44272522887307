<div class="mb-4">
  <div class="register-ctf">
    <app-content-header
      [title]="'Child Trust Fund (CTF)'"
      subtitle="Registered contact sign-up"
      customClassTitle="text-sf-white"
      customClassContainer=" p-0"
    />

    <app-steps
      [currentStep]="step"
      [enumType]="stepOpts"
      (click)="goOneStepBack()"
      customClass="sf-card--ctf"
    />
  </div>

  <form #form="ngForm" [formGroup]="registerForm">
    <div class="mb-4">
      <div class="sf-card sf-card--ctf">
        <div
          class="step"
          [ngClass]="{
            'hidden-step': !(step === stepOpts['YOUR_DETAILS']),
          }"
        >
          <app-register-ctf-step-one
            (onValid)="stepOneComplete = $event"
            (onUpdate)="onUpdateStepOne($event)"
          />
          <app-button
            *ngIf="registerStepOne.hasAccount === 'false'"
            text="Continue"
            [disabled]="!stepOneComplete"
            (buttonClick)="goToStep('ACCOUNT')"
            buttonStyle="primary"
            [isSubmitButton]="false"
          ></app-button>
        </div>
        <div
          class="step"
          [ngClass]="{
            'hidden-step': !(step === stepOpts['ACCOUNT']),
          }"
        >
          <app-register-ctf-step-two
            (onValid)="stepTwoComplete = $event"
            (onUpdate)="onUpdateStepTwo($event)"
          />
          <app-button
            text="Continue"
            [disabled]="!stepTwoComplete"
            (buttonClick)="goToStep('DECLARATION')"
            buttonStyle="primary"
            [isSubmitButton]="false"
          ></app-button>
        </div>
        <div
          class="step mb-4"
          [ngClass]="{
            'hidden-step': !(step === stepOpts['DECLARATION']),
          }"
        >
          <div class="mb-4">
            <label class="mb-4 form-label">Finally, can you confirm a couple of things</label>
            <br />
            <div class="checkbox-row mb-4">
              <input
                type="checkbox"
                formControlName="isOver16"
                class="form-check-input form-check-input--large peer"
                id="isOver16"
              />
              <label for="isOver16" class="peer-checked:text-sf-white">
                I am over 16 years of age and the Account Holder (you will be the registered contact
                for the Child Trust Fund)
              </label>
            </div>
            <div class="checkbox-row mb-4">
              <input
                type="checkbox"
                formControlName="authoriseShepherdsFriendly"
                class="form-check-input form-check-input--large peer"
                id="authoriseShepherdsFriendly"
              />
              <label for="authoriseShepherdsFriendly" class="peer-checked:text-sf-white">
                I authorise Shepherds Friendly to hold my HM Revenue & Customs contributions,
                subscriptions, CTF investments, interest, dividends and any other rights or
                proceeds.
              </label>
            </div>
          </div>
          <hr />
          <app-register-consent
            (onValid)="stepConsentComplete = $event"
            (onUpdateConsent)="onUpdateConsent($event)"
          ></app-register-consent>
        </div>

        <app-button
          *ngIf="step === stepOpts['DECLARATION']"
          [disabled]="!stepConsentComplete || !registerForm.valid"
          text="Register"
          (buttonClick)="register()"
          buttonStyle="primary"
          [noBorder]="true"
          [isSubmitButton]="false"
        ></app-button>
      </div>
    </div>
  </form>
</div>
