import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService, RouterHelperService } from '@app/services';
import { map, Observable, of, tap } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TFAGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
    private routerHelperService: RouterHelperService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.validateToken().pipe(
      take(1),
      map((status) => {
        return this.actionStatus(status);
      })
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.validateToken().pipe(
      take(1),
      map((status) => {
        return this.actionStatus(status);
      })
    );
  }

  private actionStatus(status: number): boolean {
    if (status === 200) {
      // Token fully-validated, navigate to application home
      this.router.navigate(['/']);
      return false;
    } else if (status === 4012) {
      // Token partially-validated, allow TFA auth with this route to complete validation
      return true;
    } else {
      // Token not valid, request full auth
      this.routerHelperService.redirectWithDestination('log-in');
      return false;
    }
  }
}
