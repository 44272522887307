import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { HttpService } from './http.service';
import { AppConstantsService } from './app-constants.service';
import { Address, PostcodeSearch } from '@app/models/profile';

@Injectable()
export class PostcodeService {
  public readonly api = {
    FIND_ADDRESS: (params) => `${this.configuration.server}/utilities/findaddresses?${params}`,
    GET_ADDRESS_BY_ID: (params) =>
      `${this.configuration.server}/utilities/retrieveaddressbyid?${params}`,
  };

  constructor(
    private http: HttpService,
    private configuration: AppConstantsService
  ) {}

  public search = (
    searchTerm: string,
    preferredLanguage: string,
    filter: string
  ): Observable<Array<PostcodeSearch>> => {
    // Remove all spaces from searchTerm
    searchTerm = searchTerm.replace(/\s+/g, '');

    let params: string = [
      `SearchTerm=${searchTerm}`,
      `PreferredLanguage=${preferredLanguage}`,
      `Filter=${filter}`,
    ].join('&');

    return this.http.get<PostcodeSearch[]>(this.api.FIND_ADDRESS(params)).pipe(
      map((response) => {
        return JSON.parse(response.toString()).map((item) => {
          return new PostcodeSearch(
            <string>item.Id,
            <string>item.StreetAddress,
            <string>item.Place
          );
        });
      })
    );
  };

  public getById = (id: string, preferredLanguage: string): Observable<Address> => {
    let params: string = [`Id=${id}`, `PreferredLanguage=${preferredLanguage}`].join('&');

    return this.http.get<Address>(this.api.GET_ADDRESS_BY_ID(params)).pipe(
      map((response) => {
        return JSON.parse(response.toString()).map((item) => {
          return new Address(
            <string>item.Line1,
            <string>item.Line2,
            <string>item.PostTown,
            <string>item.County,
            <string>item.Postcode
          );
        });
      })
    );
  };
}
