<div class="w-full max-w-[500px] my-0 mx-auto lg:max-w-[600px]">
  <app-alert
    type="info"
    additionalClass="mt-4 mb-4"
    iconClass="far fa-exclamation-circle"
    title="Enter verification code"
    message="To log in to your account, you will need to enter the code sent to your phone."
  ></app-alert>

  <form [formGroup]="loginTFAForm" (ngSubmit)="onSubmit()">
    <div class="mb-4">
      <label for="tfacode" class="form-label">Verification code</label>
      <div class="input-group">
        <input
          type="number"
          class="form-control"
          id="tfacode"
          name="tfacode"
          placeholder="Enter your six digit code"
          autocomplete="one-time-code"
          required
          formControlName="tfacode"
          [ngClass]="{
            'is-invalid': submitted && f['tfacode'].errors,
          }"
        />
      </div>
      <div *ngIf="submitted && f['tfacode'].errors">
        <app-validation-message
          *ngIf="f['tfacode'].errors['required']"
          message="Please enter your verification code"
        ></app-validation-message>

        <app-validation-message
          *ngIf="f['tfacode'].errors['pattern']"
          message="Your code should only contain numbers"
        ></app-validation-message>

        <app-validation-message
          *ngIf="f['tfacode'].errors['minlength']"
          message="Your code is too short, it should be six numbers long"
        ></app-validation-message>

        <app-validation-message
          *ngIf="f['tfacode'].errors['maxlength']"
          message="Your code is too long, it should be six numbers long"
        ></app-validation-message>

        <app-validation-message
          *ngIf="f['tfacode'].errors['invalid']"
          message="Your code is not valid"
        ></app-validation-message>
      </div>
    </div>

    <app-button
      text="Submit code"
      buttonStyle="primary"
      [noBorder]="true"
      [isSubmitButton]="true"
      btnWrapperClasses="mb-4"
      [fullWidth]="true"
    ></app-button>

    <p class="text-center">
      Re-send code via
      <a (click)="resendCode()" class="underline text-sf-white cursor-pointer">text message</a>
      or to your
      <a (click)="resendCodeEmail(true)" class="underline text-sf-white cursor-pointer">
        email address
      </a>
    </p>
  </form>

  <div *ngIf="resentCode">
    <app-alert
      type="success"
      additionalClass="my-4"
      iconClass="far fa-check-circle"
      title="Code sent"
      message="To log in to your account, you will need to enter the code sent to your phone/email."
    ></app-alert>
  </div>
  <p class="text-center mt-4 md:mt-5">App version: {{ appConstants.version }}</p>
</div>
