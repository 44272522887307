<h1 class="font-bold text-xl mb-2">Verify your account</h1>
<p>For your protection, please verify your password</p>
<form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
  <div class="mb-4">
    <div>
      <label for="password" class="form-label">Password</label>
      <div class="input-group">
        <input
          type="password"
          class="form-control"
          id="password"
          name="password"
          placeholder="Password"
          autocomplete="off"
          required
          formControlName="password"
          [ngClass]="{
            'is-invalid': submittedPassword && passwordCtrl.errors,
          }"
        />
      </div>
      <div *ngIf="submittedPassword && passwordCtrl.errors">
        <app-validation-message
          *ngIf="passwordCtrl.errors['required']"
          message="Please enter your password"
        ></app-validation-message>
      </div>

      <app-alert
        title="Error"
        *ngIf="submittedPassword && error"
        type="warning"
        iconClass="fas fa-exclamation-triangle"
        additionalClass="mt-4 mb-2"
        message=" {{ error }}"
      ></app-alert>

      <app-button
        text="Verify"
        buttonStyle="primary"
        [disabled]="loading$ | async"
        [noBorder]="true"
        customClass="mt-4"
        [isSubmitButton]="true"
      ></app-button>
    </div>
  </div>
</form>
