import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { CTFOverviewService } from '@app/components/plans/ctf/services';

@Injectable({
  providedIn: 'root',
})
export class CTFTransactionsSummaryResolver {
  public planNumber: string;

  constructor(private ctfOverviewService: CTFOverviewService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    this.planNumber = route.params['planNumber'];

    return this.ctfOverviewService
      .getCTFOverviewByNino(this.planNumber)
      .pipe(catchError((error) => throwError(() => `CTFDetailsResolver -> ${error}`)));
  }
}
