import { DirectDebit } from './direct-debit.model';

export class DirectDebitInformation {
  public noActiveFound: boolean;
  public singleActiveFound: boolean;
  public multipleActiveFound: boolean;
  public directDebits: Array<DirectDebit>;

  constructor() {
    this.noActiveFound = false;
    this.singleActiveFound = false;
    this.multipleActiveFound = false;
  }
}
