import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { WithdrawalProjectionMock } from '../mocks';
import { Member, SavingsPlan } from '@app/models/member-portal';
import { MaskUtility, RegexUtility } from '@app/shared/utilities';

const stepOpts: Record<string, string> = {
  AMOUNT: 'Amount',
  INFO: 'Info',
  DETAILS: 'Details',
  CONFIRM: 'Confirm',
};

@Component({
  selector: 'app-withdrawal-home',
  templateUrl: './withdrawal-home.component.html',
  styleUrls: ['./withdrawal-home.component.scss'],
  standalone: false,
})
export class WithdrawalHomeComponent implements OnInit {
  readonly url = {
    HOME: `/home`,
    PLAN: ({ planTypePrefix, formattedPlanNumber }) =>
      `/plans/${planTypePrefix.toLowerCase()}/${formattedPlanNumber.toLowerCase()}`,
    THANK_YOU_PAGE: (formattedPlanNumber: string) =>
      `/plans/withdrawal/${formattedPlanNumber.toLowerCase()}/thank-you`,
    ERROR_PAGE: (formattedPlanNumber: string) =>
      `/plans/withdrawal/${formattedPlanNumber.toLowerCase()}/error`,
  };

  private subscribeUntilDestroyed = new Subscription();

  public withdrawalForm: FormGroup;
  public amountCtrl: AbstractControl;
  public withdrawalError: string = '';
  public submittingWithdrawal: boolean = false;
  public showParentContent: boolean = true;
  public hideStepper: boolean = false;

  public plan: SavingsPlan;
  public member: Member;

  public stepOpts = stepOpts;
  public step: string;

  public frictionQuestionsCompleted: boolean = false;
  public useDifferentAccount: boolean = false;

  public readonly sortCodeMask = MaskUtility.sortCode;

  // Temporary mocks for projection bar graph
  public withdrawalProjectionA = WithdrawalProjectionMock.withdrawalProjectionA;
  public withdrawalProjectionB = WithdrawalProjectionMock.withdrawalProjectionB;
  public withdrawalProjectionC = WithdrawalProjectionMock.withdrawalProjectionC;
  public withdrawalProjectionD = WithdrawalProjectionMock.withdrawalProjectionD;
  public withdrawalProjectionE = WithdrawalProjectionMock.withdrawalProjectionE;
  public withdrawalProjectionF = WithdrawalProjectionMock.withdrawalProjectionF;
  public withdrawalProjectionG = WithdrawalProjectionMock.withdrawalProjectionG;

  /**
   * Creates an instance of WithdrawalHomeComponent.
   */
  constructor(private fb: FormBuilder, public router: Router, private cd: ChangeDetectorRef) {
    this.step = this.stepOpts['AMOUNT'];
    this.loadPlanDetailsFromRoute();
    this.buildForm();

    // Cache form controls
    this.amountCtrl = this.withdrawalForm.controls['amount'];
  }

  ngOnInit(): void {
    this.handleRouteVisibility();

    // Set the initial amount based on the default projection
    this.withdrawalForm.get('amount')?.patchValue(this.selectedProjection.requestedWithdrawal);

    // Update amount whenever the projection selection changes
    this.withdrawalForm.get('projectionChoice')?.valueChanges.subscribe(() => {
      this.withdrawalForm.get('amount')?.patchValue(this.selectedProjection.requestedWithdrawal);
    });
  }

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  public handleRouteVisibility(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = this.router.url;
        this.showParentContent = !(
          currentUrl.includes('thank-you') || currentUrl.includes('error')
        );
      }
    });
  }

  /**
   * Resets the withdrawal error message back to its default state.
   */
  public resetWithdrawalError(): void {
    this.withdrawalError = '';
  }

  /**
   * Proceeds to the Info step if the amount control is valid.
   */
  public goToInfoStep(): void {
    if (this.amountCtrl.valid) {
      this.step = this.stepOpts['INFO'];
    }
  }

  /**
   * Proceeds to the Details step.
   */
  public goToDetailsStep(): void {
    this.step = this.stepOpts['DETAILS'];
  }

  /**
   * Proceeds to the Confirm step.
   */
  public goToConfirmStep(): void {
    this.step = this.stepOpts['CONFIRM'];
  }

  /**
   * Navigates to the plan overview
   *
   * @param {SavingsPlan} plan - The savings plan to make an initial deposit for.
   * @param {Member} member - The member associated with the savings plan.
   */
  public navigateToPlanOverview() {
    const { planTypePrefix, formattedPlanNumber } = this.plan;
    this.router.navigate([this.url.PLAN({ planTypePrefix, formattedPlanNumber })]);
  }

  /**
   * Handles button press to maintain a £30 balance upon a full withdrawal request
   */
  public onMaintainMinimumBalance(): void {
    // This would essentially re-do the API call and return a new calculation, for now, we'll set this as mock F, which is a £30 balance.
    this.withdrawalForm.get('projectionChoice')?.patchValue('F');
  }

  /**
   * Handles the completion of friction questions.
   * @param formData The form data from the friction questions component.
   */
  public onFrictionQuestionsComplete(formData: any): void {
    console.log('Friction questions completed with data:', formData);
    this.frictionQuestionsCompleted = true;
  }

  /**
   * Handles the cancellation of friction questions.
   */
  public onFrictionQuestionsCancel(): void {
    this.router.navigate([this.url.ERROR_PAGE(this.plan.formattedPlanNumber)], {
      state: { plan: this.plan },
    });
  }

  /**
   * Handles the decline of friction questions.
   */
  public onFrictionQuestionsDecline(): void {
    this.navigateToPlanOverview();
  }

  /**
   * Temporary way of switching between the mock graphs, will be removed and based on the amount field when API is integrated
   */
  public get selectedProjection(): any {
    const choice = this.withdrawalForm.get('projectionChoice')?.value;

    switch (choice) {
      case 'A':
        return this.withdrawalProjectionA;
      case 'B':
        return this.withdrawalProjectionB;
      case 'C':
        return this.withdrawalProjectionC;
      case 'D':
        return this.withdrawalProjectionD;
      case 'E':
        return this.withdrawalProjectionE;
      case 'F':
        return this.withdrawalProjectionF;
      case 'G':
        return this.withdrawalProjectionG;
      default:
        return this.withdrawalProjectionA; // fallback
    }
  }

  /**
   * Returns the account details FormGroup.
   */
  public get accountDetails(): FormGroup {
    return this.withdrawalForm.get('accountDetails') as FormGroup;
  }

  /**
   * Returns true if the sort code passed back from the server has already been masked.
   */
  get hasAlreadyMaskedSortCode(): boolean {
    const sortCode = this.accountDetails.controls['sortCode'].value;
    return (sortCode || '').includes('*');
  }

  /**
   * Toggles the use of a different account.
   * Resets the account details and, if enabling, applies validators to the fields.
   * Defers the update to the next microtask and triggers change detection.
   */
  public toggleAccountDetails(): void {
    Promise.resolve().then(() => {
      this.useDifferentAccount = !this.useDifferentAccount;

      // Reset account details to clear any previous values.
      this.accountDetails.reset();

      const accountNameCtrl = this.withdrawalForm.get('accountDetails.accountName');
      const accountNumberCtrl = this.withdrawalForm.get('accountDetails.accountNumber');
      const sortCodeCtrl = this.withdrawalForm.get('accountDetails.sortCode');
      const confirmDetailsCtrl = this.withdrawalForm.get('accountDetails.confirmDetails');

      if (this.useDifferentAccount) {
        accountNameCtrl?.setValidators([Validators.required]);
        accountNumberCtrl?.setValidators([
          Validators.required,
          Validators.pattern(RegexUtility.bankAccNo),
        ]);
        sortCodeCtrl?.setValidators([Validators.required]);
        confirmDetailsCtrl?.setValidators([Validators.requiredTrue]);
      } else {
        accountNameCtrl?.clearValidators();
        accountNumberCtrl?.clearValidators();
        sortCodeCtrl?.clearValidators();
        confirmDetailsCtrl?.clearValidators();
      }

      accountNameCtrl?.updateValueAndValidity();
      accountNumberCtrl?.updateValueAndValidity();
      sortCodeCtrl?.updateValueAndValidity();
      confirmDetailsCtrl?.updateValueAndValidity();

      // Trigger change detection to update the view.
      this.cd.detectChanges();
    });
  }

  /**
   * Checks form validity and attempts to process the withdrawal submission.
   * @param form The withdrawal form.
   */
  public onSubmit(form: FormGroup): void {
    this.submittingWithdrawal = true;
    if (form.valid) {
      this.router.navigate([this.url.THANK_YOU_PAGE(this.plan.formattedPlanNumber)], {
        state: { plan: this.plan, withdrawal: this.withdrawalForm.value },
      });
    }
  }

  /**
   * Builds the form configuration with angulars form builder service
   */
  private buildForm(): void {
    this.withdrawalForm = this.fb.group({
      amount: ['', Validators.compose([Validators.required])],
      projectionChoice: ['A'], // Temp to allow for different graph options
      accountDetails: this.fb.group({
        accountName: ['Test User'],
        accountNumber: ['12345678'],
        sortCode: ['000000'],
        confirmDetails: [false],
      }),
    });
  }

  /**
   * Retrieves plan / member details data from the route state, if none exists
   * redirect back to the home page.
   */
  private loadPlanDetailsFromRoute(): void {
    const state = this.router.getCurrentNavigation()?.extras?.state as any;

    if (state) {
      this.plan = state.plan;
      this.member = state.member;
      this.hideStepper = state.hideStepper;
    } else {
      this.router.navigate([this.url.HOME]);
    }
  }
}
