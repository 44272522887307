import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DirectDebit, SavingsPlan } from '@app/models/member-portal';
import { DirectDebitService } from '@app/services';
import { catchError, EMPTY, take } from 'rxjs';

type RouteStateData = {
  plan: SavingsPlan;
  directDebit: DirectDebit;
  newPremiumAmount: number;
};

@Component({
  selector: 'app-direct-debit-vary-review',
  templateUrl: './direct-debit-vary-review.component.html',
  styleUrls: ['./direct-debit-vary-review.component.scss'],
})
export class DirectDebitVaryReviewComponent implements OnInit {
  public readonly url = {
    THANK_YOU_PAGE: `/direct-debit/thank-you`,
    ERROR_PAGE: `/direct-debit/error`,
    PLAN_CHOICE: `/plans/plan-choice/direct-debit`,
    DIRECT_DEBIT: `/direct-debit/plan/vary`,
  };

  public submittingDirectDebit: boolean = false;
  public hasAcceptedConditions: boolean = false;

  public plan: SavingsPlan;
  public originalDirectDebit: DirectDebit;
  public newPremiumAmount: number;

  constructor(
    public router: Router,
    private directDebitService: DirectDebitService
  ) {
    this.loadDirectDebitDetailsFromRoute();
  }

  ngOnInit(): void {}

  /**
   * Returns direct debit with the new premium amount set
   */
  public get directDebit(): DirectDebit {
    return {
      ...this.originalDirectDebit,
      amount: this.newPremiumAmount,
    };
  }

  /**
   * Callback for the accepted conditions event
   */
  public handleHasAcceptedConditions(event: boolean): void {
    this.hasAcceptedConditions = event;
  }

  /**
   * Posts Direct Debit information to server, and redirect to either thank you or error pages.
   */
  public onSubmit(): void {
    this.submittingDirectDebit = true;

    this.directDebitService
      .varyDirectDebit(
        this.originalDirectDebit,
        this.plan.formattedPlanNumber,
        this.plan.planIdentifier,
        this.newPremiumAmount
      )
      .pipe(
        take(1),
        catchError((error) => {
          this.router.navigate([this.url.ERROR_PAGE], {
            state: {
              plan: this.plan,
              error: error.modelState,
            },
          });

          this.submittingDirectDebit = false;
          return EMPTY;
        })
      )
      .subscribe((response) => {
        this.router.navigate([this.url.THANK_YOU_PAGE], {
          state: {
            plan: this.plan,
            directDebit: this.directDebit,
            amended: true,
          },
        });
      });
  }

  /**
   * Caches result of checkbox state
   */
  public processChange(event: boolean) {
    this.hasAcceptedConditions = event;
  }

  /**
   * Retrieves plan / direct debit details data from the route state, if non exists
   * redirect back to the plan choice page.
   */
  private loadDirectDebitDetailsFromRoute(): void {
    const state = this.router.getCurrentNavigation()?.extras?.state as RouteStateData;

    if (state) {
      this.plan = state.plan;
      this.originalDirectDebit = state.directDebit;
      this.newPremiumAmount = state.newPremiumAmount;
    } else {
      this.router.navigate([this.url.PLAN_CHOICE]);
    }
  }

  /**
   * Navigates to a url and passes a plan and direct debit object in router state
   * @param {string} [url] the url to navigate to
   */
  public navigateWithState(url: string): void {
    this.router.navigate([url], {
      state: { plan: this.plan, directDebit: this.originalDirectDebit },
    });
  }
}
