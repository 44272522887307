import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MemberDashboard } from '@app/models/member-portal';
import { LoadingService, MemberPortalService, RegistrationService } from '@app/services';
import { catchError, take } from 'rxjs';

@Component({
  selector: 'app-address-verification',
  templateUrl: './address-verification.component.html',
  styleUrls: ['./address-verification.component.css'],
})
export class AddressVerification implements OnInit {
  public readonly url = {
    HOME: `/home`,
    LOG_OUT: `/log-out`,
  };

  public readonly loading$ = this.loadingService.loading$;
  public memberDashboard: MemberDashboard;
  public addressVerificationForm: FormGroup;
  public codeCtrl: AbstractControl;
  public submittedCode: boolean = false;
  public error: string = '';

  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private router: Router,
    private registrationService: RegistrationService,
    private memberPortalService: MemberPortalService
  ) {}

  ngOnInit(): void {
    this.buildForm();

    this.codeCtrl = this.addressVerificationForm.controls['code'];

    // Clear API error on typing
    this.codeCtrl.valueChanges.subscribe(() => {
      this.error = '';
    });

    this.memberPortalService
      .getMemberDashboard()
      .pipe(take(1))
      .subscribe((response) => {
        this.memberDashboard = response;
      });
  }

  /**
   * Attempts to submit the user id/email and verification code to the verify address code api service
   */
  public onSubmit(): void {
    this.submittedCode = true;

    if (this.addressVerificationForm.valid) {
      this.registrationService
        .verifyAddressCode({
          id: this.memberDashboard.registrant.registrationId,
          code: this.codeCtrl.value,
          emailAddress: this.memberDashboard.registrant.emailAddress,
        })
        .pipe(
          take(1),
          catchError((errorResponse) => {
            if (errorResponse.status === 406) {
              this.error = `The verification code is incorrect. Please try again.`;
            } else {
              this.error = `An unknown error occurred. Please try again.`;
            }
            return [];
          })
        )
        .subscribe((response) => {
          if (!this.error) {
            this.memberPortalService.clearCache();
            this.router.navigate([this.url.HOME]);
          }
        });
    }
  }

  /**
   * Builds the form configuration with angular's form builder service
   */
  private buildForm(): void {
    this.addressVerificationForm = this.fb.group({
      code: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(8),
          Validators.maxLength(8),
        ]),
      ],
    });
  }

  /**
   * Builds the form configuration with angular's form builder service
   */
  public getErrorMessage(): string | null {
    const errors = this.codeCtrl.errors;
    if (!errors) return null;

    if (errors['required']) {
      return 'Please enter your verification code';
    } else if (errors['pattern']) {
      return 'Your code should only contain numbers';
    } else if (errors['minlength']) {
      return 'Your code is too short, it should be 8 numbers long';
    } else if (errors['maxlength']) {
      return 'Your code is too long, it should be 8 numbers long';
    }

    return null;
  }

  logout(): void {
    this.router.navigate([this.url.LOG_OUT]);
  }
}
