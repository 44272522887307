export class TrackingInformation {
  $id: string;
  utmCampaign: string | null;
  utmMedium: string | null;
  utmTerm: string | null;

  constructor(
    $id: string,
    utmCampaign: string | null,
    utmMedium: string | null,
    utmTerm: string | null
  ) {
    this.$id = $id;
    this.utmCampaign = utmCampaign;
    this.utmMedium = utmMedium;
    this.utmTerm = utmTerm;
  }
}
