import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationItem, Notifications } from '@app/models/member-portal';
import { StorageKey } from '@app/models/security';
import { NotificationsService } from '@app/services';
import { NotificationUtility } from '@app/shared/utilities';
import { filter, Subscription, take } from 'rxjs';

type NotificationItemWithReadStatus = NotificationItem & { read: boolean };

interface NotificationsWithReadStatus extends Notifications {
  items: NotificationItemWithReadStatus[];
}

@Component({
    selector: 'app-notifications-home',
    templateUrl: './notifications-home.component.html',
    styleUrls: ['./notifications-home.component.scss'],
    standalone: false
})
export class NotificationsHomeComponent implements OnInit {
  public readonly url = {
    NOTIFICATION: `/notifications/{0}`,
    PROFILE_NOTIFICATIONS: '/profile/options/notifications',
  };

  private subscribeUntilDestroyed = new Subscription();
  public notifications: NotificationsWithReadStatus;
  public page: number = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private notificationsService: NotificationsService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.loadNotificationsFromRoute();
    this.watchNewNotifications();
  }

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  /**
   * Helper to check if all notifications are read
   */
  get hasAllRead(): boolean {
    return this.notifications.items.every((n) => n.read === true);
  }

  /**
   * Helper to check if all notifications are unread
   */
  get hasAllUnread(): boolean {
    return this.notifications.items.every((n) => n.read === false);
  }

  /**
   * Helper to get count of unread messages
   */
  get numberUnread(): number {
    return this.notifications.items.reduce((p, n) => (n.read === false ? p + 1 : p), 0);
  }

  /**
   * Navigates user to the notification settings area of profile
   */
  public navigateToSettings(): void {
    this.router.navigate([this.url.PROFILE_NOTIFICATIONS]);
  }

  /**
   * Watches for events emitted by notificationPublished$ and loads new notifications
   */
  public watchNewNotifications(): void {
    this.subscribeUntilDestroyed.add(
      this.notificationsService.notificationPublished$
        .pipe(filter(Boolean))
        .subscribe(() => this.loadNotifications())
    );
  }

  /**
   * Maps a typeId string back to an icon class
   * @param {string} [typeId]
   */
  public typeIdToIconString(typeId: string): string {
    return NotificationUtility.typeIdToIconString(typeId);
  }

  /**
   * Callback method for when a page event is fired
   * @param {{ page: number }} [event]
   */
  public pageChanged(event: { page: number }): void {
    this.page = event.page;
    this.loadNotifications();
  }

  /**
   * Sets all notifications as read and reloads notification data
   */
  public setAllNotificationsRead(): void {
    const notificationIds = this.notifications.items.map((notifications) => notifications.id);

    this.notificationsService.setAllNotificationsRead(notificationIds);

    this.loadNotifications();
  }

  /**
   * Sets all notifications as unread and reloads notification data
   */
  public setAllNotificationsUnread(): void {
    this.notificationsService.setAllNotificationsUnread();

    this.loadNotifications();
  }

  /**
   * Maps a TransactionItem to NotificationItemWithReadStatus and sets read property based on if it has been read
   * @param {Notifications} [notifications]
   */
  public notificationsWithReadStatus(notifications: Notifications): void {
    const notificationIds = JSON.parse(localStorage.getItem(StorageKey.SF_NOTIFICATIONS)) || [];

    this.notifications = {
      ...notifications,
      items: notifications.items.map((notification: NotificationItem) => ({
        ...notification,
        read: notificationIds.includes(notification.id),
      })),
    };
  }

  /**
   * Retrieves notification data from the activated route data
   */
  private loadNotifications(): void {
    this.notificationsService
      .getNotifications(this.page)
      .pipe(take(1))
      .subscribe((response: Notifications) => this.notificationsWithReadStatus(response));
  }

  /**
   * Retrieves notification data from the activated route data
   */
  private loadNotificationsFromRoute(): void {
    this.subscribeUntilDestroyed.add(
      this.activatedRoute.data.subscribe((routeData) =>
        this.notificationsWithReadStatus(routeData['notifications'])
      )
    );
  }
}
