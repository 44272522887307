import { Component, OnInit } from '@angular/core';
import { WP_REST_API_Post_Override } from 'src/app/models/wp-types-overrides/WP_REST_API_Post_Override';
import { HttpService } from 'src/app/services/http.service';
import { WordpressApiService } from 'src/app/services/wordpress-api.service';

@Component({
    selector: 'app-help-home',
    templateUrl: './help-home.component.html',
    styleUrls: ['./help-home.component.scss'],
    standalone: false
})
export class HelpHomeComponent implements OnInit {
  displayMode = '';
  public postList: Array<WP_REST_API_Post_Override>;

  constructor(private httpService: HttpService, private wpService: WordpressApiService) {}

  ngOnInit(): void {
    // this.displayMode = GlobalComponent.displayMode;
    this.wpService.getPosts(10).subscribe((response) => {
      this.postList = response;
    });
  }
}
