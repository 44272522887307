<app-content-header [title]="'Register to use the app'" customClassTitle="text-sf-white" />

<div class="mb-4">
  <div class="sf-card sf-card--ctf">
    <h5 class="text-lg font-bold">Which plan(s) do you have?</h5>
    <div class="mt-4">
      <app-radio-list [options]="radioOptions" (selectionChange)="onSelectionChange($event)" />
    </div>
    <app-button
      text="Continue"
      [disabled]="selection === undefined"
      (buttonClick)="onContinue()"
      buttonStyle="primary"
      [isSubmitButton]="false"
    />
  </div>
</div>
