import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Member,
  SavingsPlan,
  AuthorisedSavingsPlan,
  MemberDashboard,
} from '@app/models/member-portal';
import { TopUpRouteState } from '@app/models/pages';
import { FeedbackType } from '@app/models/feedback';
import { NotificationsService, FeedbackService } from '@app/services';
import { PlanUtility, UrlUtility } from '@app/shared/utilities';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-home',
  templateUrl: './home-home.component.html',
  styleUrls: ['./home-home.component.css'],
  standalone: false,
})
export class HomeHomeComponent implements OnInit, OnDestroy {
  @Input() memberDashboard?: MemberDashboard;

  public readonly url = {
    TOP_UP: (formattedPlanNumber) => `/plans/deposit/${formattedPlanNumber.toLowerCase()}`,
    PLAN: ({ planTypePrefix, formattedPlanNumber }) =>
      `/plans/${planTypePrefix.toLowerCase()}/${formattedPlanNumber.toLowerCase()}`,
    DEPOSIT: `/plans/deposit`,
    PROFILE_NOTIFICATIONS: '/profile/options/notifications',
    CONNECT_JISA: '/plans/plan-linking/link-jisa',
  };
  public readonly loginFeedbackType: FeedbackType = FeedbackType.LOGIN;

  public notificationsEnabled: boolean;
  public savingsPlanWithPendingTransaction: SavingsPlan | undefined;
  public authorisedSavingsPlanWithPendingTransaction: AuthorisedSavingsPlan | undefined;

  private subscribeUntilDestroyed = new Subscription();

  constructor(
    private feedbackService: FeedbackService,
    private router: Router,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.notificationsEnabled = this.notificationsService.isRegistered;

    this.findSavingsPlanWithPendingTransaction();
    this.findAuthorisedSavingsPlanWithPendingTransaction();
  }

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  /**
   * Navigates to the plan url
   * @param {SavingsPlan} [plan]
   */
  public navigateToPlan(plan: SavingsPlan): void {
    const { planTypePrefix, formattedPlanNumber } = plan;

    this.router.navigate([this.url.PLAN({ planTypePrefix, formattedPlanNumber })]);
  }

  /**
   * Navigates to the top up page for the given plan, it's expected to be only used when an initial deposit is required
   *
   * @param {SavingsPlan} plan - The savings plan to make an initial deposit for.
   * @param {Member} member - The member associated with the savings plan.
   */
  public navigateToInitialDeposit(plan: SavingsPlan, member: Member) {
    const state: TopUpRouteState = {
      plan,
      member,
      topUpAmount: plan.pendingTransactionAmount,
      landingStep: 'PAY',
      hideStepper: true,
      isInitialDeposit: true,
    };

    this.router.navigate([this.url.TOP_UP(plan.formattedPlanNumber)], {
      state,
    });
  }

  /**
   * Obtains a generated feedback URL for the current page and redirects off of the app.
   */
  public getSurveyUrl(): string {
    return this.feedbackService.createFeedbackURL(FeedbackType.GENERIC);
  }

  public goToLink(url: string) {
    UrlUtility.goToLink(url);
  }

  /**
   * Sets `savingsPlanWithPendingTransaction` to the first savings plan with a > 0 pending transaction.
   */
  public findSavingsPlanWithPendingTransaction(): void {
    this.savingsPlanWithPendingTransaction = this.memberDashboard?.memberPlans?.savingsPlans?.find(
      (plan: SavingsPlan) =>
        plan.pendingTransactionAmount !== undefined &&
        plan.pendingTransactionAmount !== 0 &&
        PlanUtility.isInitialPlanDepositValid(plan.planStatusId)
    );
  }

  /**
   * Sets `authorisedSavingsPlanWithPendingTransaction` to the first authorised savings plan with a > 0 pending transaction.
   */
  public findAuthorisedSavingsPlanWithPendingTransaction(): void {
    this.authorisedSavingsPlanWithPendingTransaction =
      this.memberDashboard?.authorisedPlans?.authorisedSavingsPlans?.find(
        (authorisedPlan: AuthorisedSavingsPlan) =>
          authorisedPlan.plan.pendingTransactionAmount !== undefined &&
          authorisedPlan.plan.pendingTransactionAmount !== 0 &&
          PlanUtility.isInitialPlanDepositValid(authorisedPlan.plan.planStatusId)
      );
  }
}
