<section id="registerHomeTitle" class="my-4 mx-0">
  <h1>Choose your plan</h1>
</section>

<section id="registerContent">
  <div class="sf-card">
    <ul class="list-group list-group-flush">
      <li class="list-group-item" routerLink="/register-not-available/ctf">Child Trust Fund</li>
      <li class="list-group-item" routerLink="/register-not-available/ip">Income Protection</li>
      <li class="list-group-item">Junior ISA</li>
      <li class="list-group-item" routerLink="/register-not-available/o50">
        Over 50s Life Insurance
      </li>
      <li class="list-group-item">Stocks & Shares ISA</li>
      <li class="list-group-item">Sustainable Stocks & Shares ISA</li>
      <li class="list-group-item">Sustainable Junior ISA</li>
    </ul>
  </div>
</section>

<nav class="fixed-bottom bg-light -lg pb-4" style="display: block">
  <div class="grid gap-2 p-2">
    <app-button text="Next" buttonStyle="secondary" [isSubmitButton]="true"></app-button>
  </div>
</nav>
