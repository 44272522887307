import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeedbackType } from '@app/models/feedback';
import { SavingsPlan } from '@app/models/member-portal';

type RouteStateData = { plan: SavingsPlan };

@Component({
  selector: 'app-withdrawal-error',
  templateUrl: './withdrawal-error.component.html',
  styleUrls: ['./withdrawal-error.component.scss'],
  standalone: false,
})
export class WithdrawalErrorComponent {
  public readonly url = {
    HOME: `/home`,
  };

  public plan: SavingsPlan;

  constructor(public router: Router) {
    this.loadPlanDetailsFromRoute();
  }

  /**
   * Retrieves plan data from the route state, if non exists
   * redirect back to the home page.
   */
  private loadPlanDetailsFromRoute(): void {
    const state = this.router.getCurrentNavigation()?.extras?.state as RouteStateData;

    if (state) {
      this.plan = state.plan;
    } else {
      this.router.navigate([this.url.HOME]);
    }
  }
}
