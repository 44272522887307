import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MemberDashboard } from 'src/app/models/member-portal/member-dashboard.model';
import { MemberPortalService } from 'src/app/services/member-portal.service';

@Component({
    selector: 'app-staging',
    templateUrl: './staging.component.html',
    styleUrls: ['./staging.component.css'],
    standalone: false
})
export class StagingComponent implements OnInit {
  public memberDashboard: MemberDashboard;

  constructor(
    public memberPortalService: MemberPortalService,
    public router: Router
  ) {}

  // Load any API data here after login required for the rest of the app
  ngOnInit(): void {
    // Hitting this end point here forces the redirect to the TFA
    // stage if applicable, rather than hitting the homepage and seeing
    // it briefly without any data in
    this.memberPortalService.getMemberDashboard().subscribe((response) => {
      this.memberDashboard = response;
      this.router.navigateByUrl('/home');
    });
  }
}
