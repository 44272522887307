export class NotificationItem {
  public $id: string;
  public id: string;
  public sentDate: Date | string;
  public headline: string;
  public typeId: string;
  public important: boolean;
  public detail: string;
  public imageURL: string;
}

export interface Notifications {
  $id: string;
  items: NotificationItem[] | null;
  page: number;
  pageMax: number;
  pageSize: number;
  total: number;
}
