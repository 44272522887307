<section id="forgottenPasswordContent">
  <div class="mb-4">
    <div *ngIf="!validated">
      <form [formGroup]="verifyEmailForm" (ngSubmit)="onSubmit()">
        <div *ngIf="emailAddress !== undefined && emailAddress.length > 0" class="mb-4">
          <label for="email" class="form-label">Email address</label>
          <div class="input-group">
            <input
              type="email"
              class="form-control"
              id="username"
              name="username"
              placeholder="Email address"
              required
              formControlName="username"
              readonly
            />
          </div>
        </div>

        <div *ngIf="!submitted" class="grid gap-2 mt-4">
          <app-button
            text="Verify email address"
            buttonStyle="primary"
            [noBorder]="true"
            [isSubmitButton]="true"
          ></app-button>
        </div>
      </form>
    </div>
    <div *ngIf="validated">
      <app-alert
        type="success"
        additionalClass="my-4"
        iconClass="far fa-check-circle"
        title="Thank you for verifying your email address!"
        message="Please log in to view the details of your Child Trust Fund on our app."
      ></app-alert>

      <div class="grid gap-2">
        <app-button
          text="Return to log in"
          buttonStyle="primary"
          [routerLink]="['/log-in']"
          [queryParams]="{ email: emailAddress }"
        ></app-button>
      </div>
    </div>
    <div *ngIf="alreadyVerified">
      <app-alert
        type="success"
        additionalClass="my-4"
        iconClass="far fa-check-circle"
        title="Your email address has already been verified!"
        message="Please log in to view your Child Trust Fund account."
      ></app-alert>

      <div class="grid gap-2">
        <app-button
          text="Return to log in"
          buttonStyle="primary"
          [routerLink]="['/log-in']"
          [queryParams]="{ email: emailAddress }"
        ></app-button>
      </div>
    </div>
    <div *ngIf="failed">
      <app-alert
        type="warning"
        additionalClass="my-4"
        iconClass="far fa-times-circle"
        title="Sorry, your email address could not be verified."
        message="Please get in touch with one of the options below so that we can fix the problem."
      ></app-alert>
    </div>
  </div>

  <app-need-help />
</section>
