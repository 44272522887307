// extension declarations for running in WebView under iOS/Android
declare function interop_android(): boolean;
declare function interop_ios(): boolean;

export class UrlUtility {
  /**
   * Opens a URL taking account of host (e.g. Native, installed PWA or Browser)
   */
  static goToLink(url: string) {
    // check for native support
    if (
      (typeof interop_android === 'function' && interop_android()) ||
      (typeof interop_ios === 'function' && interop_ios())
    ) {
      // for Native, window.open() does nothing, so what we need to do is generate a "link" event internally so the native code
      // can pick this up and then decide what to do with the event depending on the URL protocol (e.g. mailto: or https:) and also
      // if the URL is internal/external.
      window.location.href = url;
    } else {
      // for PWA & Browser we want to open the link as either a new tab (Browser) or as a new browser (PWA)
      window.open(url, '_blank');
    }
  }
}
