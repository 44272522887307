export class Member {
  memberId: number;
  title: string;
  firstname: string;
  surname: string;
  email: string;
  dob: string;
  telephone: string;
  mobileTelephone: string;
  businessTelephone: string;
  address1: string;
  address2: string;
  address3: string;
  townCity: string;
  postcode: string;
  isMapped: boolean;

  constructor(
    memberId: number,
    title: string,
    firstname: string,
    surname: string,
    email: string,
    dob: string,
    telephone: string,
    mobileTelephone: string,
    businessTelephone: string,
    address1: string,
    address2: string,
    address3: string,
    townCity: string,
    postcode: string,
    isMapped: boolean
  ) {
    this.memberId = memberId;
    this.title = title;
    this.firstname = firstname;
    this.surname = surname;
    this.email = email;
    this.dob = dob;
    this.telephone = telephone;
    this.mobileTelephone = mobileTelephone;
    this.businessTelephone = businessTelephone;
    this.address1 = address1;
    this.address2 = address2;
    this.address3 = address3;
    this.townCity = townCity;
    this.postcode = postcode;
    this.isMapped = isMapped;
  }
}
