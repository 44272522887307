<section *ngIf="plan" class="my-4 mx-0">
  <app-card-with-image
    title="Your withdrawal request was unsuccessful"
    imageSrc="unsuccessful"
    imageAlt="unsuccessful"
    [customClass]="'mb-4 md:mb-6 xl:mb-8 max-md:h-max'"
    [titleStyle]="'text-primary-sf-navy dark:text-sf-white my-4'"
    [isButtonOff]="false"
    [routerLink]="url.HOME"
    [button_text]="'Return to home'"
    [button_fullWidth]="false"
    [button_small]="false"
  >
    <p class="md:pb-4">
      Your withdrawal request from
      <span class="font-bold">{{ plan.formattedPlanNumber }}</span>
      has unfortunately been unsuccessful.
    </p>
    <p class="md:pb-4">
      If you'd like help with this, please call our Member Services team on
      <a href="tel:0800526249"><strong>0800 526 249</strong></a>
      .
    </p>
  </app-card-with-image>
</section>
