import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-link-plan-submitted',
  templateUrl: './link-plan-submitted.component.html',
  styleUrls: ['./link-plan-submitted.component.scss'],
})
export class LinkPlanSubmittedComponent {
  public readonly url = {
    HOME: `/home`,
  };

  constructor(public router: Router) {}
}
