<app-content-header
  [title]="'My notifications'"
  [subtitle]="'You have ' + numberUnread + ' unread notifications'"
  [isButtonOff]="false"
  [button_style]="'white'"
  [button_text]="'Settings'"
  [button_icon]="'fal fa-cog'"
  [button_iconSize]="'18px'"
  [button_iconColor]="'text-primary-sf-yellow'"
  (buttonClick)="navigateToSettings()"
></app-content-header>

<div class="mb-3">
  <div class="flex justify-between sf-card py-3 px-4 md:py-4 mb-4 md:mb-6">
    <div (click)="setAllNotificationsRead()" class="flex justify-center items-center flex-1 gap-3">
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="20" fill="none">
        <path
          stroke="#13243A"
          class="dark:stroke-primary-sf-yellow"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M18.95 1.5H2.15a1.4 1.4 0 0 0-1.4 1.4v11.2a1.4 1.4 0 0 0 1.4 1.4h16.8a1.4 1.4 0 0 0 1.4-1.4V2.9a1.4 1.4 0 0 0-1.4-1.4Z"
        />
        <path
          stroke="#13243A"
          class="dark:stroke-primary-sf-yellow"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M20.034 2.013 12.433 7.86a3.087 3.087 0 0 1-3.766 0l-7.6-5.847"
        />
        <path
          fill="#fff"
          stroke="#13243A"
          class="dark:stroke-primary-sf-yellow dark:fill-primary-sf-navy"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1.5"
          d="M19.75 18.5a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
        />
        <path
          stroke="#13243A"
          class="dark:stroke-primary-sf-yellow"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1.5"
          d="m21.754 12.05-2.912 3.098-1.368-1.309"
        />
      </svg>
      <span
        class="font-semibold text-primary-sf-navy dark:text-sf-white cursor-pointer max-xs:text-sm"
      >
        Mark all read
      </span>
    </div>

    <span class="w-0.5 bg-sf-body-font/20 dark:bg-sf-white/20"></span>

    <div
      (click)="setAllNotificationsUnread()"
      class="flex justify-center items-center flex-1 gap-3"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" fill="none">
        <path
          stroke="#13243A"
          class="dark:stroke-primary-sf-yellow"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M19.15 1H2.35a1.4 1.4 0 0 0-1.4 1.4v11.2a1.4 1.4 0 0 0 1.4 1.4h16.8a1.4 1.4 0 0 0 1.4-1.4V2.4a1.4 1.4 0 0 0-1.4-1.4Z"
        />
        <path
          stroke="#13243A"
          class="dark:stroke-primary-sf-yellow"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M20.234 1.513 12.633 7.36a3.087 3.087 0 0 1-3.765 0L1.267 1.513"
        />
      </svg>
      <span
        class="font-semibold text-primary-sf-navy dark:text-sf-white cursor-pointer max-xs:text-sm"
      >
        Mark all unread
      </span>
    </div>
  </div>
</div>

<app-notification-summary [notifications]="notifications"></app-notification-summary>

<span *ngIf="notifications.items.length === 0">You don't have any notifications.</span>

<div *ngIf="notifications.items.length > notifications.pageSize - 1">
  <pagination
    class="pagination"
    [maxSize]="5"
    [customPreviousTemplate]="prevTemplate"
    [customNextTemplate]="nextTemplate"
    [totalItems]="notifications.total"
    [itemsPerPage]="notifications.pageSize"
    (pageChanged)="pageChanged($event)"
  ></pagination>

  <ng-template #nextTemplate>
    <ng-container>
      <i class="fal fa-angle-right fa-lg"></i>
    </ng-container>
  </ng-template>

  <ng-template #prevTemplate>
    <ng-container>
      <i class="fal fa-angle-left fa-lg"></i>
    </ng-container>
  </ng-template>
</div>
