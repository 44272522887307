import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalComponent } from '@app/global.component';
import { StorageKey } from '@app/models/security';
import { MemberDashboard } from 'src/app/models/member-portal/member-dashboard.model';
import { MemberPortalService } from 'src/app/services/member-portal.service';

declare function interop_ios(): boolean;

@Component({
    selector: 'app-install-instructions',
    templateUrl: './install-instructions.component.html',
    styleUrls: ['./install-instructions.component.css'],
    standalone: false
})
export class InstallInstructionsComponent implements OnInit {
  public memberDashboard: MemberDashboard;
  public showInstallPrompt: boolean = false;
  public globalComponent = GlobalComponent;

  @ViewChild('installInstructions') installElement: any;

  constructor(public memberPortalService: MemberPortalService, public router: Router) {}

  // Load any API data here after login required for the rest of the app
  ngOnInit(): void {
    // hook the PWA install event (Android ONLY - no support under iOS as of 16.5)
    window.addEventListener('beforeinstallprompt', (e) => {
      // prevents the default mini-infobar or install dialog from appearing on mobile
      e.preventDefault();

      // we save the event because we need it to continue any install later, this assignment also
      // allows our custom install UI to be shown.
      console.log('App: Install Event: ', e);
      GlobalComponent.installEvent = e;
    });

    window.addEventListener('DOMContentLoaded', () => {
      let displayMode = 'browser tab';
      if (window.matchMedia('(display-mode: fullscreen)').matches) {
        displayMode = 'fullscreen';
      }

      // Log and show launch display mode to analytics
      console.log(`App: DISPLAY_MODE_LAUNCH: ${displayMode}`);
      GlobalComponent.displayMode = displayMode;
    });

    // show the install prompt
    this.showInstallPrompt =
      !(typeof interop_ios === 'function' && interop_ios()) &&
      !localStorage.getItem(StorageKey.SF_INSTALL_PROMPT);
  }

  hideInstallPrompt() {
    let prompt = this.installElement.nativeElement;
    if (prompt) {
      // hide element
      prompt.style.display = 'none';

      // don't show again
      localStorage.setItem(StorageKey.SF_INSTALL_PROMPT, 'hidden');
    }
  }

  beginInstall() {
    this.hideInstallPrompt();

    if (this.globalComponent.installEvent) {
      // continue install
      (this.globalComponent.installEvent as any).prompt();
    } else {
      console.error('Install: No install event?');
    }
  }
}
