<section *ngIf="plan">
  <div class="sf-alert warning mb-4 md:mb-6 xl:mb-8" role="alert">
    <div *ngIf="errors?.length === 1; else listErrors">
      {{ errors[0] }}
    </div>

    <ng-template #listErrors>
      <ul class="mb-0">
        <li *ngFor="let error of errors; let i = index">
          {{ error }}
        </li>
      </ul>
    </ng-template>
  </div>

  <app-card-with-image
    title="Direct Debit unsuccessful"
    imageSrc="unsuccessful"
    imageAlt="unsuccessful"
    [customClass]="'mb-4 md:mb-6 xl:mb-8 h-[calc(100vh-150px)]'"
    [titleStyle]="'text-primary-sf-navy dark:text-sf-white'"
    [isButtonOff]="false"
    [routerLink]="url.HOME"
    [button_text]="'Return to home'"
    [button_fullWidth]="false"
    [button_small]="false"
  >
    <p class="md:pb-4 text-xs">
      Sorry, the Direct Debit request for
      <span class="font-bold">{{ plan.formattedPlanNumber }}</span>
      has been unsuccessful.
    </p>
    <p class="md:pb-4 text-xs">
      If you'd like help with this, please call our Member Services team on
      <a href="tel:0800526249">0800 526 249</a>
      .
    </p>
  </app-card-with-image>
</section>
