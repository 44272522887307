import { Component, Input, OnDestroy, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SavingsPlan, Member } from '@app/models/member-portal';
import { MemberProfile } from '@app/models/profile';
import { AppConstantsService } from '@app/services';
import { EmailUtility } from '@app/shared/utilities';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

const stepOpts: Record<string, string> = {
  YOUR_DETAILS: 'Your details',
  ID_VERIFICATION: 'ID Verification',
};

@Component({
    selector: 'app-mature-ctf',
    templateUrl: './mature-ctf.component.html',
    styleUrls: ['./mature-ctf.component.scss'],
    standalone: false
})
export class MatureCTFComponent implements OnDestroy {
  @Input() ctfDetails?: { plan: SavingsPlan; member: Member };
  @Input() memberProfile?: MemberProfile;

  readonly url = {
    PLAN_CHOICE: `/home`,
    CTF_SUBMITTED: (formattedPlanNumber: string) =>
      `plans/ctf/${formattedPlanNumber.toLowerCase()}/mature-ctf/submitted`,
  };

  public stepOpts = stepOpts;
  public step = this.stepOpts['YOUR_DETAILS'];
  public modalRef?: BsModalRef;
  public config = {
    backdrop: true,
    ignoreBackdropClick: false,
  };

  private subscribeUntilDestroyed = new Subscription();

  constructor(
    public appConstants: AppConstantsService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private modalService: BsModalService
  ) {}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);

    // XXX: The carousel won't allow gestures until interacted with
    // Ghost click the first indicator and swipe gestures will work.
    setTimeout(() => {
      const firstIndicator = document.querySelector(
        '.carousel-indicators button:first-child'
      ) as any;
      firstIndicator.click();
    }, 100);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  public get formattedCurrentAddress(): string {
    return [
      this.ctfDetails.member.address1,
      this.ctfDetails.member.address2,
      this.ctfDetails.member.address3,
      this.ctfDetails.member.postcode.toUpperCase(),
    ]
      .filter(Boolean)
      .join('\r\n');
  }

  public goToStep(step: string): void {
    if (this.stepOpts[step]) {
      this.step = this.stepOpts[step];
      this.scrollToTop();
    }
  }

  public scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  /**
   * Navigates to a url and passes a plan in router state
   * @param {string} [url] the url to navigate to
   */
  public navigateToWithState(url: string): void {
    this.router.navigate([url], {
      state: { plan: this.ctfDetails.plan },
    });
  }

  public openEmailClient() {
    const emailAddress = EmailUtility.emailAddress.members;
    const subject = this.ctfDetails.plan.formattedPlanNumber;

    EmailUtility.openEmailDraft(emailAddress, subject);
  }
}
