<section *ngIf="plan && withdrawal" class="my-4 mx-0">
  <app-card-with-image
    title="Your withdrawal request has been received"
    imageSrc="doing-the-right-thing"
    imageAlt="doing-the-right-thing"
    [customClass]="'mb-4 md:mb-6 xl:mb-8 max-md:h-max'"
    [titleStyle]="'my-4'"
    [isButtonOff]="false"
    [button_text]="'Return to home'"
    [routerLink]="url.HOME"
    [button_noBorder]="true"
    [button_fullWidth]="false"
    [button_small]="false"
  >
    <p class="md:mb-6 xl:mb-8">
      You have successfully completed your
      {{ withdrawal.amount | currency : 'GBP' : 'symbol' : '1.2-2' }} withdrawal request from
      {{ plan.formattedPlanNumber }}. We have received your request and it will be processed within
      5 working days.
    </p>
  </app-card-with-image>
</section>
