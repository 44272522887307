import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PlansRegisteringFor } from '@app/models/registration';
import { FeatureFlagService, LoginHeaderService } from '@app/services';
import { RadioOption } from '@app/shared/components/radio-list/radio-list.component';

@Component({
  selector: 'app-register-home',
  templateUrl: './register-home.component.html',
  styleUrls: ['./register-home.component.scss'],
  standalone: false,
})
export class RegisterHomeComponent {
  public radioOptions: RadioOption[] = [
    {
      label: 'Stocks & Shares ISA and Junior ISA',
      value: PlansRegisteringFor.AdultAndChild,
    },
    {
      label: 'Stocks & Shares ISA',
      value: PlansRegisteringFor.Adult,
    },
    {
      label: 'Junior ISA',
      value: PlansRegisteringFor.Child,
    },
  ];

  public selection: PlansRegisteringFor = undefined;

  constructor(
    private router: Router,
    private featureFlagService: FeatureFlagService,
    private loginHeaderService: LoginHeaderService
  ) {}

  async ngOnInit() {
    this.loginHeaderService.buttonClick$.subscribe(() => {
      this.handleBackButtonClick();
    });

    const featureEnabled = await this.featureFlagService.isEnabled('appCTFEnabled');

    /* TODO: Add CTF to the above `radioOptions` list when it's released and no longer needed behind a feature flag. */
    if (featureEnabled) {
      this.radioOptions.push({
        label: 'My Child Trust Fund',
        value: PlansRegisteringFor.None,
      });
    }
  }

  public onSelectionChange(option: PlansRegisteringFor) {
    this.selection = option;
  }

  public onContinue(): void {
    if (this.selection === PlansRegisteringFor.None) {
      this.router.navigate([`/register/ctf`], {
        fragment: 'YOUR_DETAILS',
      });
    } else {
      this.router.navigate([`/register/account/${this.selection}`], {
        fragment: 'PLAN',
      });
    }
  }

  public handleBackButtonClick(): void {
    this.router.navigate(['/log-in']);
  }
}
