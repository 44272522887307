import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  DirectDebit,
  Member,
  MemberDashboard,
  SavingsPlan,
  TransactionsSummary,
} from 'src/app/models/member-portal';

@Component({
  selector: 'app-ssi-details',
  templateUrl: './ssi-details.component.html',
  styleUrls: ['./ssi-details.component.scss'],
  standalone: false,
})
export class SSIDetailsComponent implements OnInit, OnDestroy {
  @Input() ssiDetails?: SavingsPlan;
  @Input() memberDashboard?: MemberDashboard;

  readonly url = {
    TOP_UP: (formattedPlanNumber) => `/plans/deposit/${formattedPlanNumber.toLowerCase()}`,
    TRANSACTIONS: ({ planId, formattedPlanNumber }) =>
      `/transactions/${planId}/${formattedPlanNumber.toLowerCase()}`,
    DOCUMENTS: (formattedPlanNumber) => `/documents/plan/${formattedPlanNumber.toLowerCase()}`,
    DIRECT_DEBIT: `/direct-debit/plan`,
    DIRECT_DEBIT_VARY: `/direct-debit/plan/vary`,
    DIRECT_DEBIT_VIEW_ALL: `/direct-debit/plan/view-all`,
    FUND_TRANSFER: (formattedPlanNumber) =>
      `/plans/ssi/${formattedPlanNumber.toLowerCase()}/fund-transfer`,
  };

  public quickActionMoreLinks: {
    label: string;
    url?: string;
    icon: string;
    onClick?: () => void;
  }[];

  private subscribeUntilDestroyed = new Subscription();

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.quickActionMoreLinks = [
      {
        label: 'Set up Direct Debit',
        icon: 'fal fa-credit-card',
        onClick: () => this.navigateWithState(this.url.DIRECT_DEBIT),
      },
      {
        label: 'Make a withdrawal',
        url: '/',
        icon: 'fa-regular fa-arrow-down-to-bracket',
      },

      {
        label: 'Switch fund',
        icon: 'fa-kit fa-settings-svg',
        onClick: () =>
          this.navigateWithState(this.url.FUND_TRANSFER(this.ssiDetails.formattedPlanNumber)),
      },
    ];
  }

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  /**
   * Returns true if the plan has 'singleActiveFound' set to true
   */
  public get hasDirectDebit(): boolean {
    return this.ssiDetails.directDebitInformation.singleActiveFound;
  }

  /**
   * Returns true if the plan has 'multipleActiveFound' set to true
   */
  public get hasMultipleDirectDebits(): boolean {
    return this.ssiDetails.directDebitInformation.multipleActiveFound;
  }

  /**
   * Returns true if there are no active Direct Debits exist
   */
  public get hasNoDirectDebits(): boolean {
    return !this.hasDirectDebit && !this.hasMultipleDirectDebits;
  }

  /**
   * Returns all direct debits available
   */
  public get directDebits(): DirectDebit[] {
    return this.ssiDetails.directDebitInformation.directDebits;
  }

  /**
   * Navigates to a url and passes a plan and member object in router state
   * @param {string} [url] the url to navigate to
   * @param {object} [additionalState] optional object to pass along with the route state
   */
  public navigateWithState(url: string, additionalState?: object): void {
    this.router.navigate([url], {
      state: {
        plan: this.ssiDetails,
        member: this.memberDashboard.member,
        ...additionalState,
      },
    });
  }
}
