<section *ngIf="plan" class="my-4 mx-0">
  <app-card-with-image
    title=" Direct Debit {{ amended ? 'update' : 'set-up' }} successful"
    imageSrc="doing-the-right-thing"
    imageAlt="doing-the-right-thing"
    [customClass]="'mb-4 md:mb-6 xl:mb-8 h-[calc(100vh-150px)]'"
    [titleStyle]="'my-4'"
    [isButtonOff]="false"
    [button_text]="'Return to home'"
    [routerLink]="url.HOME"
    [button_noBorder]="true"
    [button_fullWidth]="false"
    [button_small]="false"
  >
    <p class="md:mb-6 xl:mb-8">
      Your {{ amended ? 'updated' : 'new' }} Direct Debit for
      <span class="font-semibold">{{ plan.formattedPlanNumber }}</span>
      has been successfully submitted.
    </p>
    <p class="md:mb-6 xl:mb-8">
      We have sent confirmation of the
      {{ amended ? 'change' : 'set-up' }} by email. We recommend that you check through the changes,
      if you have any questions or would like to discuss your Direct Debit, please call
      <strong><a href="tel:0800526249">0800 526 249</a></strong>
      and speak to our Member Services Team.
    </p>
  </app-card-with-image>
  <app-survey-prompt
    imageSrc="survey-prompt"
    imageAlt="survey-prompt"
    title="How was your experience updating your Direct Debit today?"
    content="Please take our short survey if you have any feedback."
    button_text="Give Feedback"
    [button_small]="false"
    [button_fullWidth]="true"
    [feedbackType]="directDebitFeedbackType"
    [additionalData]="{ plan_type: plan.planTypeName, plan_id: plan.planId }"
  ></app-survey-prompt>
</section>
