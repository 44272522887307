import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, filter } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpService } from './http.service';
import { StorageKey } from '@app/models/security';
import { Meta } from '@angular/platform-browser';

// extension declarations for running in WebView under iOS/Android
declare function interop_android(): boolean;
declare function interop_ios(): boolean;
declare function invoke_DoDarkTheme(): void;
declare function invoke_DoLightTheme(): void;

// Express the two theme types
export enum Mode {
  LIGHT = 'light',
  DARK = 'dark',
}

// Map the colour versions (light to dark, dark to light)
type ColourConversionMap = {
  light: Record<string, string>;
  dark: Record<string, string>;
};

const colourMap: ColourConversionMap = {
  light: {
    // If required
  },
  dark: {
    '#454E50': '#ffffff',
    '#13243A': '#ffffff',
    '#353E3F': '#ffffff',
  },
};

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private _mode = new BehaviorSubject<Mode>(undefined);
  public readonly mode$ = this._mode.asObservable();
  private isLoggedIn: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private httpService: HttpService,
    private authService: AuthService,
    private meta: Meta
  ) {
    this._mode.next(this.theme);

    document.documentElement.classList.add(this.theme);

    this.mode$.pipe(filter(Boolean)).subscribe((mode: Mode) => {
      this.theme = mode;
      this.updateThemeColor(undefined);
    });

    this.checkAuthentication();
  }

  get mode(): Mode {
    return this._mode.value;
  }

  get theme(): Mode {
    const storedTheme = localStorage.getItem(StorageKey.SF_THEME);

    if (storedTheme) {
      return storedTheme as Mode;
    } else {
      return window.matchMedia('(prefers-color-scheme: dark)').matches ? Mode.DARK : Mode.LIGHT;
    }
  }

  set theme(mode: Mode) {
    localStorage.setItem(StorageKey.SF_THEME, mode);
  }

  /**
   * Converts colour values within an SVG string using a colour map based on the current theme.
   *
   * We also update the SVG's viewBox attribute for aspect ratio control.
   *
   * @param input - The SVG string to be color-converted.
   *
   * @returns The modified SVG string with color conversions applied.
   */
  public convertColoursUsingMap(input: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'image/svg+xml');

    if (!doc) {
      throw new Error('Failed to parse SVG');
    }

    const svgElement = doc.querySelector('svg');

    if (svgElement) {
      const width = svgElement.getAttribute('width');
      const height = svgElement.getAttribute('height');

      if (width && height) {
        svgElement.setAttribute('width', '100%');
        svgElement.setAttribute('height', '100%');
        svgElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
      }
    }

    const elementsWithColours = doc.querySelectorAll('[fill], [stroke]');

    elementsWithColours.forEach((element) => {
      if (element instanceof SVGElement) {
        const fillValue = element.getAttribute('fill');
        const strokeValue = element.getAttribute('stroke');

        if (fillValue) {
          const newFillValue = colourMap[this.theme][fillValue] || fillValue;
          element.setAttribute('fill', newFillValue);
        }

        if (strokeValue) {
          const newStrokeValue = colourMap[this.theme][strokeValue] || strokeValue;
          element.setAttribute('stroke', newStrokeValue);
        }
      }
    });

    return new XMLSerializer().serializeToString(doc);
  }

  /**
   * Fetches an SVG image from the provided URL, converts its colors using a colour map.
   *
   * @param imageUrl - The URL of the SVG image to process.
   * @returns Promise<string> that resolves after the processing is complete.
   */
  async processSVGFromImageUrl(imageUrl: string): Promise<string> {
    if (imageUrl.includes('undefined.svg')) {
      // You can return an error message or handle it as you see fit
      return 'Invalid image URL provided.';
    }

    try {
      const svgString = await this.httpService.getSVGStringFromImageUrl(imageUrl);
      return this.convertColoursUsingMap(svgString);
    } catch (error) {
      return String(error);
    }
  }

  /**
   * Removes body class, toggles theme light/dark and adds body class back.
   */
  public switchMode(newMode: Mode) {
    // Toggle the theme based on the new mode using the enum values
    if (newMode === Mode.DARK) {
      document.documentElement.classList.add(Mode.DARK);
      document.documentElement.classList.remove(Mode.LIGHT);
    } else {
      document.documentElement.classList.add(Mode.LIGHT);
      document.documentElement.classList.remove(Mode.DARK);
    }

    // Update the observable with the new mode
    this._mode.next(newMode);

    // Set the theme property and update the body id attribute with the new mode
    this.theme = newMode;
  }

  /**
   * Update the theme colour based on the user's authentication status and current theme mode.
   *
   * @param {boolean} isLoggedIn - A boolean indicating whether the user is authenticated.
   */
  private updateThemeColor(isLoggedIn: boolean): void {
    const isLoggedInState = isLoggedIn ?? this.isLoggedIn;
    const themeColour =
      this.theme === Mode.LIGHT ? (isLoggedInState ? '#fff' : '#13243a') : '#13243a';

    this.meta.updateTag({
      name: 'theme-color',
      content: themeColour,
    });

    if (this.nativeSupport) {
      if (this.theme === Mode.LIGHT && isLoggedInState) {
        invoke_DoLightTheme();
      } else {
        invoke_DoDarkTheme();
      }
    }

    this.isLoggedIn = isLoggedInState;
  }

  /**
   * Subscribe to the authentication status and perform UI updates based on the user's authentication state.
   */
  private checkAuthentication(): void {
    this.authService.authenticated$.subscribe((status: boolean) => {
      this.updateThemeColor(status);

      // Add `not-authenticated` class if we are not logged in.
      this.document.body.classList.toggle('not-authenticated', status === false);

      // Add `dark` theme if not logged in
      this.document.body.setAttribute('id', status === false ? Mode.DARK : this.mode);
    });
  }

  /**
   * Check if native support is available
   */
  get nativeSupport() {
    var nativeSupport = false;

    // check for native support
    if (
      (typeof interop_android === 'function' && interop_android()) ||
      (typeof interop_ios === 'function' && interop_ios())
    ) {
      nativeSupport = true;
    }

    return nativeSupport;
  }
}
