<div
  *ngIf="plan"
  class="p-4 border-none text-sf-whtie mb-3 rounded-md text-[10px] bg-[url('/assets/images/sf-faint-logo.svg')] bg-primary-sf-navy bg-right-bottom bg-no-repeat bg-[50px_auto] sm:text-xs md:text-sm"
  [ngClass]="{ '!bg-[180px_auto] group full': fullView }"
>
  <ng-template #positiveImageTemplate>
    <svg width="18" height="16" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 0.186523L12.1292 8.71226H0.870835L6.5 0.186523Z" fill="#20C997" />
    </svg>
  </ng-template>

  <ng-template #negativeImageTemplate>
    <svg width="18" height="16" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.32645 7.25977L8.50628 0.982265H0.146622L4.32645 7.25977Z" fill="#DC2626" />
    </svg>
  </ng-template>

  <div class="text-sf-white">
    <header>
      <h5 class="text-inherit mb-0 text-xl font-medium">Current maturity value</h5>
      <p class="text-primary-sf-yellow text-5xl font-bold mx-0 mt-0.5 mb-1.5">
        {{ currentMaturityValue | currency : 'GBP' }}
      </p>
    </header>

    <ng-container>
      <div *ngIf="totalContributions" class="flex justify-between">
        <p class="group-[.full]:mb-0 group-[.full]:text-xl group-[.full]:font-bold text-lg mb-3">
          <svg
            *ngIf="fullView"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="align-[-2px]"
          >
            <rect y="0.870117" width="12" height="12" rx="5" fill="#FB9800" />
          </svg>
          Total value
        </p>
        <p class="group-[.full]:mb-0 group-[.full]:text-xl group-[.full]:font-bold text-lg mb-3">
          <ng-container *ngIf="+valueIncreasePerc >= 0; else negativeImage">
            <ng-container *ngTemplateOutlet="positiveImageTemplate"></ng-container>
          </ng-container>

          <ng-template #negativeImage>
            <ng-container *ngTemplateOutlet="negativeImageTemplate"></ng-container>
          </ng-template>

          {{ valueIncreasePerc }}%
        </p>
      </div>

      <div *ngIf="totalContributions" class="flex justify-between">
        <p class="text-lg mb-3 group-[.full]:mb-0 group-[.full]:text-xl group-[.full]:font-bold">
          <svg
            *ngIf="fullView"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="align-[-2px]"
          >
            <rect y="0.870117" width="12" height="12" rx="5" fill="#1199D6" />
          </svg>
          Total contributions
        </p>
        <p class="group-[.full]:mb-0 group-[.full]:text-xl group-[.full]:font-bold text-lg mb-3">
          {{ totalContributions | currency : 'GBP' }}
        </p>
      </div>

      <div *ngIf="plan.startDate && !fullView" class="flex justify-between mb-4">
        <p class="group-[.full]:mb-0 group-[.full]:text-xl group-[.full]:font-bold text-lg mb-3">
          Plan start date
        </p>
        <p class="group-[.full]:mb-0 group-[.full]:text-xl group-[.full]:font-bold text-lg mb-3">
          {{ plan.startDate | date : 'longDate' }}
        </p>
      </div>
    </ng-container>

    <ng-content></ng-content>
  </div>
</div>
