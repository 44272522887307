import { Component } from '@angular/core';
import { FeedbackType } from '@app/models/feedback';
import { FeedbackService, ThemeService } from '@app/services';
import { Mode } from '@app/services/theme.service';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrl: './side-menu.component.scss',
    standalone: false
})
export class SideMenuComponent {
  public selectedTheme: Mode;
  public Mode = Mode;

  constructor(public themeService: ThemeService, private feedbackService: FeedbackService) {
    this.selectedTheme = this.themeService.theme;
  }

  public switchMode(newMode: Mode) {
    this.themeService.switchMode(newMode);
  }

  public navigateToFeedbackUrl() {
    this.feedbackService.navigateToFeedbackUrl(FeedbackType.GENERIC);
  }
}
