import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnimationsService {
  private _animating = new BehaviorSubject<boolean>(false);
  public readonly animating$ = this._animating.asObservable();

  constructor() {}

  start() {
    this._animating.next(true);
  }

  done() {
    this._animating.next(false);
  }
}
