import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Form, FormGroup } from '@angular/forms';
import { environment } from '@env/environment';

@Component({
  selector: 'app-direct-debit-guarantee',
  templateUrl: './direct-debit-guarantee.component.html',
  styleUrls: ['./direct-debit-guarantee.component.scss'],
})
export class DirectDebitGuaranteeComponent {
  public readonly directDebitLogoUrl = `${environment.blobStorage}/image-media/ddlogo.png`;
}
