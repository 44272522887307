export class BasePlan {
  public planId: number;
  public planTypeId: number;
  public planTypeName: string;
  public planStatusId: number;
  public planStatusName: string;
  public planNumber: number;
  public formattedPlanNumber: string;
  public startDate: Date | string;
  public premium: number;
  public isNetPremium: boolean;
  public planTypePrefix: string;
  public planIdentifier: string;
  public capacity: number;
  public planGroup: string;
  public schemeType: number;
  public pendingTransactionAmount: number;
}
