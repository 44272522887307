import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-problem',
  templateUrl: './account-problem.component.html',
  styleUrls: ['./account-problem.component.scss'],
})
export class AccountProblemComponent implements OnInit {
  public readonly url = {
    LOG_OUT: `/log-out`,
  };

  constructor(private router: Router) {}

  ngOnInit(): void {}

  logout(): void {
    this.router.navigate([this.url.LOG_OUT]);
  }
}
