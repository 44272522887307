<ng-container>
  <div class="mb-4">
    <div [ngClass]="{ hidden: hideNativeGooglePay }">
      <!-- 'paymentRequest' data is dummy data so the button is shown by passing the internal component checks (we don't use this data) -->
      <google-pay-button
        environment="TEST"
        buttonType="pay"
        buttonColor="black"
        buttonSizeMode="fill"
        style="width: 100%; height: 45px"
        [clickCallback]="googlePayClick"
        [paymentRequest]="{
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: 'CARD',
              parameters: {
                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                allowedCardNetworks: ['VISA', 'MASTERCARD'],
              },
              tokenizationSpecification: {
                type: 'PAYMENT_GATEWAY',
                parameters: { gateway: 'example', gatewayMerchantId: 'exampleGatewayMerchantId' },
              },
            },
          ],
          merchantInfo: { merchantId: '0' },
          transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPriceLabel: 'Total',
            totalPrice: '10.00',
            currencyCode: 'GBP',
            countryCode: 'UK',
          },
        }"
      ></google-pay-button>

      <app-validation-message
        *ngIf="nativeError"
        message=" {{ nativeError }}"
      ></app-validation-message>

      <div
        class="text-xs md:text-base flex basis-full items-center mb-0 mt-2 mx-2 before:flex-grow before:bg-sf-grey-light before:h-px before:content-[''] before:my-0 after:bg-sf-grey-light after:ml-2 after:mr-1 after:leading-[0px] after:text-[0px] after:flex-grow after::bg-sf-grey-light after:h-px after:content-[''] after:my-0 before:ml-2 before:mr-1 before:leading-[0px] before:text-[0px]"
      >
        Or pay by card
      </div>
    </div>

    <div [ngClass]="{ hidden: hideWalletOptions }">
      <div id="payment-request-button"></div>
      <div
        class="text-xs md:text-base flex basis-full items-center mb-0 mt-2 mx-2 before:flex-grow before:bg-sf-grey-light before:h-px before:content-[''] before:my-0 after:bg-sf-grey-light after:ml-2 after:mr-1 after:leading-[0px] after:text-[0px] after:flex-grow after::bg-sf-grey-light after:h-px after:content-[''] after:my-0 before:ml-2 before:mr-1 before:leading-[0px] before:text-[0px]"
      >
        Or pay by card
      </div>
    </div>

    <ng-content></ng-content>

    <label class="form-label" for="card-element">Credit or debit card</label>

    <div
      id="card-element"
      class="block w-full p-4 text-2xl font-normal rounded-md transition ease-in-out border border-primary-sf-navy dark:bg-primary-sf-navy-darker dark:border-sf-white-20-opacity dark:text-sf-white"
    ></div>

    <app-validation-message *ngIf="error" message=" {{ error }}"></app-validation-message>
  </div>
</ng-container>
