import { Injectable } from '@angular/core';

import { MemberDashboardCacheService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class DirectDebitGuard {
  constructor(private memberDashboardCacheService: MemberDashboardCacheService) {}

  canDeactivate(): boolean {
    this.memberDashboardCacheService.clearCache();
    return true;
  }
}
