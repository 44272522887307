<ng-template #completedState>
  <svg
    class="steps-icon--completed steps-icon steps-icon--selected"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.6045" cy="11.9496" r="11.75" />
    <path
      d="M17.4368 8.19958L11.0202 14.6163L8.10352 11.6996"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>

<!-- <div class="steps sf-card mb-4">
    <ul class="steps__list">
        <li class="steps__list-item">
            <ng-template [ngIf]="step === stepOpts.AMOUNT" [ngIfElse]="completedState">
                <svg class="step-one steps-icon" [ngClass]="{ 'steps-icon--selected': step === stepOpts.AMOUNT}"
                    width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11.833" cy="12.25" r="11.75" />
                    <path
                        d="M11.472 16.734C11.472 16.874 11.584 17 11.738 17H12.942C13.082 17 13.194 16.874 13.194 16.734V7.746C13.194 7.606 13.082 7.48 12.942 7.48H11.962L9.94605 9.174C9.89005 9.23 9.86205 9.314 9.87605 9.426L9.96005 10.112C9.97405 10.238 10.114 10.322 10.24 10.294L11.472 10.028V16.734Z" />
                </svg>
            </ng-template>
            <p class="steps__list-item-content">Amount</p>
        </li>
        <hr class="steps__list-item-line" />
        <li class="steps__list-item">
            <ng-template [ngIf]="step === stepOpts.AMOUNT || step === stepOpts.TERMS" [ngIfElse]="completedState">
                <svg class="step-two steps-icon" [ngClass]="{ 'steps-icon--selected': step === stepOpts.TERMS }"
                    width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.5" cy="11.75" r="11.75" />
                    <path
                        d="M9.47795 16.234C9.47795 16.374 9.58995 16.5 9.74395 16.5H15.2599C15.3999 16.5 15.5259 16.374 15.5259 16.234V15.268C15.5259 15.128 15.3999 15.002 15.2599 15.002H12.2359C12.9779 14.064 14.0419 12.748 14.6439 11.894C15.1899 11.11 15.5819 10.536 15.5819 9.626C15.5819 8.114 14.4619 6.84 12.4599 6.84C10.7939 6.84 9.70195 8.212 9.70195 8.212C9.60395 8.324 9.63195 8.492 9.71595 8.576L10.3879 9.248C10.4999 9.36 10.6539 9.36 10.7659 9.248C11.0879 8.898 11.6619 8.422 12.3339 8.422C13.3419 8.422 13.8179 9.066 13.8179 9.696C13.8179 10.172 13.5379 10.718 13.1039 11.25C12.1659 12.496 10.4019 14.792 9.47795 15.912V16.234Z" />
                </svg>
            </ng-template>
            <p class="steps__list-item-content">Terms</p>
        </li>
        <hr class="steps__list-item-line" />
        <li class="steps__list-item">
            <ng-template
                [ngIf]="step === stepOpts.AMOUNT || step === stepOpts.TERMS || step === stepOpts.PAY && !completed"
                [ngIfElse]="completedState">
                <svg class="step-three steps-icon" [ngClass]="{ 'steps-icon--selected': step === stepOpts.PAY }"
                    width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.166" cy="11.75" r="11.75" />
                    <path
                        d="M9.17978 15.394C9.48778 15.758 10.3418 16.64 12.0918 16.64C14.0238 16.64 15.3118 15.492 15.3118 13.91C15.3118 12.664 14.2758 11.908 13.6178 11.628V11.586C14.2478 11.292 15.0598 10.48 15.0598 9.402C15.0598 7.834 13.7018 6.84 12.0778 6.84C10.5098 6.84 9.44578 8.072 9.44578 8.072C9.34778 8.17 9.33378 8.338 9.44578 8.436L10.1318 9.08C10.2578 9.192 10.4118 9.178 10.5098 9.066C10.7478 8.786 11.1958 8.394 11.9238 8.394C12.6378 8.394 13.2818 8.912 13.2818 9.682C13.2818 10.396 12.6938 10.97 11.5458 10.97H10.7618C10.5938 10.97 10.4958 11.068 10.4958 11.25V12.132C10.4958 12.3 10.6078 12.412 10.7618 12.412H11.5458C12.8058 12.412 13.4778 13.07 13.4778 13.798C13.4778 14.568 12.7498 15.114 11.9938 15.114C11.1398 15.114 10.3138 14.414 10.2718 14.358C10.1598 14.246 10.0338 14.246 9.90778 14.358L9.19378 15.044C9.10978 15.142 9.09578 15.296 9.17978 15.394Z"
                        fill="#13243A" />
                </svg>
            </ng-template>
            <p class="steps__list-item-content">Pay</p>
        </li>
    </ul>
</div> -->

<div
  class="flex flex-col sf-card mb-4 md:mb-6 xl:mb-8 lg:px-8 lg:py-5 group"
  [ngClass]="[customClass ? customClass : '']"
>
  <ul class="flex flex-row text-center p-0 mb-0 group-sf-card--ctf">
    <ng-container
      *ngFor="let stepKey of enumTypeKeys(); let i = index; let last = last"
      class="step"
    >
      <li class="list-none flex-1 px-2 py-0 steps__list-item--{{ i + 1 }}">
        <ng-template
          [ngIf]="
            enumType[stepKey] === currentStep ||
            getIndexInEnum(enumType[stepKey]) > getIndexInEnum(currentStep)
          "
          [ngIfElse]="completedState"
        >
          <svg
            class="steps-icon"
            [ngClass]="{ 'steps-icon--selected': enumType[stepKey] === currentStep }"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12.166" cy="11.75" r="11.75" />
            <path [attr.d]="svgNumberPath[i]" fill="#13243A" />
          </svg>
        </ng-template>
        <p
          class="text-normal mb-0 mt-1 text-primary-sf-navy dark:text-sf-white group-[.sf-card--ctf]:text-sf-white"
          [ngClass]="{ 'font-bold': enumType[stepKey] === currentStep }"
        >
          {{ getStepLabel(stepKey) }}
        </p>
      </li>
      <hr
        *ngIf="!last"
        class="h-[3px] border-0 w-full bg-sf-body-font/10 dark:bg-sf-white/10 rounded-md group-[.sf-card--ctf]:bg-sf-white/10"
      />
    </ng-container>
  </ul>
</div>
