<button
  class="grid grid-cols-46 justify-center items-center 
bg-sf-white  dark:bg-primary-sf-navy border-none px-4 py-3 md:px-5 rounded-md shadow-sm w-full
{{ quickActionBtnClass }}"
>
  <div
    class="w-auto max-w-full min-h-full overflow-hidden py-0 px-1 h-11"
    [innerHTML]="svg | safe"
  ></div>
  <p
    class="font-semibold mb-0 justify-self-start pl-3 text-primary-sf-navy dark:text-sf-white xl:text-base"
  >
    {{ quickActionBtnText }}
  </p>
  <i class="fas fa-chevron-right text-primary-sf-navy dark:text-sf-white bg-opacity-65"></i>
</button>
