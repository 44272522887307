import { Injectable } from '@angular/core';
import { AppConstantsService } from './app-constants.service';
import {
  DocumentListViewModel,
  PlanRow,
} from '../models/member-portal/Pdf/member-document-list.model';
import { map, Observable } from 'rxjs';
import { HttpService } from './http.service';
import { environment } from '@env/environment';

// extension declarations for running in WebView under Android
declare var DownloadHandler: any;

@Injectable()
export class DocumentService {
  public readonly api = {
    GET_MEMBER_DOCUMENTS: `${this.configuration.server}/api/Member/GetMemberDocuments`,
    GET_PDF_LIST: `${this.configuration.server}/Pdf/GetMemberPortalPdfList`,
    GET_PDF_BY_PLAN_NO: (planNumber) =>
      `${this.configuration.server}/Pdf/GetPdfListByPlanNumber?PlanNumber=${planNumber}`,
    GET_PDF_BY_FILENAME: (fileName) =>
      `${this.configuration.server}/Pdf/ViewMemberPortalPdf?fileName=${fileName}`,
  };

  constructor(private configuration: AppConstantsService, private http: HttpService) {}

  public getPdfList(): Observable<DocumentListViewModel> {
    return this.http
      .get(this.api.GET_PDF_LIST)
      .pipe(map((response) => response as DocumentListViewModel));
  }

  public getMemberDocuments(continuationToken: string): Observable<any> {
    return this.http
      .post(this.api.GET_MEMBER_DOCUMENTS, { continuationToken })
      .pipe(map((response) => response as any));
  }

  public getPdfListByPlanNumber(planNumber: string): Observable<PlanRow> {
    return this.http
      .get(this.api.GET_PDF_BY_PLAN_NO(planNumber))
      .pipe(map((response) => response as PlanRow));
  }

  public getDocument(fileName: string): Observable<any> {
    return this.http.get(this.api.GET_PDF_BY_FILENAME(fileName), {
      responseType: 'blob',
    });
  }

  public downloadDocument(fileName: string): void {
    this.downloadPdf(fileName);
  }

  public downloadPdf(fileName: string): void {
    this.getDocument(fileName).subscribe((pdf) => {
      const a = document.createElement('a');
      a.href = (window.URL || window['webkitURL']).createObjectURL(pdf);
      a.download = fileName;
      // when run under Android WebView the 'download' attribute is not yet supported so we have to
      // run our own emulation of this feature.
      if (typeof DownloadHandler !== 'undefined') {
        DownloadHandler.invoke_SetFilename(fileName);
      }
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }

  /**
   * Returns a url string for a given plan prefix if available
   * @param {string} [planPrefix]
   */
  public getImportantInformationGuideDocumentLink(planPrefix: string): string {
    let importantInformationGuideDocument;

    switch (planPrefix.toLocaleLowerCase()) {
      case 'isa':
        importantInformationGuideDocument = `${environment.blobStorage}/wp-media/2021/12/ISA_Important-Information-Guide_2021.12.pdf`;
        break;
      case 'sisa':
        importantInformationGuideDocument = `${environment.blobStorage}/wp-media/2021/12/Sustainable-ISA_Important-Information-Guide_2021.12.pdf`;
        break;
      case 'jisa':
        importantInformationGuideDocument = `${environment.blobStorage}/wp-media/2021/09/Junior-ISA_Important-Information-Guide_2021.05.pdf`;
        break;
      case 'sjisa':
        importantInformationGuideDocument = `${environment.blobStorage}/wp-media/2021/09/Sustainable-Junior-ISA_Important-Information-Guide_2021.05.pdf`;
        break;
      default:
        break;
    }

    return importantInformationGuideDocument;
  }
}
