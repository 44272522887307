export class VerifyTransformRegistration {
  public Id: string;
  public EmailAddress: string;
  public Code: string;
}

export class VerifyTransformRegistrationResponse {
  public verifiedEmailAddress: boolean;
  public alreadyVerifiedEmailAddress: boolean;
}
