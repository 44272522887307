import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  private scripts: any = {};

  constructor() {}

  /**
   * Dynamically loads a script by URL if it has not been loaded before.
   *
   * @param url The URL of the external script to be loaded.
   * @returns A Promise that resolves when the script is loaded successfully,
   * or rejects if the script fails to load.
   */
  public loadScript(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      // Avoid re-loading the script
      if (this.scripts[url]) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.src = url;
      script.onload = () => {
        this.scripts[url] = true;
        resolve();
      };

      script.onerror = (error: any) => reject(error);
      document.head.appendChild(script);
    });
  }
}
