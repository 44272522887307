import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegexUtility } from '@app/shared/utilities';

@Component({
  selector: 'app-register-account-step-one-extended',
  templateUrl: './register-account-step-one-extended.component.html',
  styleUrls: ['./register-account-step-one-extended.component.scss'],
})
export class RegisterAccountStepOneExtendedComponent implements OnInit {
  public registerForm: FormGroup;

  @Input() showSeparator: boolean = true;
  @Output() onUpdate = new EventEmitter<any>();
  @Output() onValid = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder) {}

  get childsPlanNumberCtrl() {
    return this.registerForm.get('childsPlanNumber');
  }
  get childsFirstNameCtrl() {
    return this.registerForm.get('childsFirstName');
  }
  get childsSurnameCtrl() {
    return this.registerForm.get('childsSurname');
  }
  get childsDobCtrl() {
    return this.registerForm.get('childsDob');
  }

  public childsPlanNumber: string;
  public childsFirstName: string;
  public childsSurname: string;
  public childsDob: string;

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      childsPlanNumber: [
        '',
        [Validators.required, Validators.pattern(RegexUtility.jisaAndSustainablePrefix)],
      ],
      childsFirstName: ['', [Validators.required]],
      childsSurname: ['', [Validators.required]],
      childsDob: ['', [Validators.required]],
    });

    this.registerForm.valueChanges.subscribe((value) => {
      const planNumber = this.childsPlanNumberCtrl.value;

      if (planNumber !== planNumber.toUpperCase()) {
        this.childsPlanNumberCtrl.setValue(planNumber.toUpperCase(), {
          emitEvent: false,
        });
      }

      if (this.registerForm.valid) {
        this.onUpdate.emit(value);
      } else {
        this.onUpdate.emit(value);
      }

      this.onValid.emit(this.registerForm.valid);
    });
  }
}
