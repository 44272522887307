import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-registration-not-available',
    templateUrl: './registration-not-available.component.html',
    styleUrls: ['./registration-not-available.component.css'],
    standalone: false
})
export class RegistrationNotAvailableComponent implements OnInit {
  public planType: string;
  public planName: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.planType = this.activatedRoute.snapshot.params['planType']?.toString() ?? '';

    switch (this.planType) {
      case 'ctf':
        this.planName = 'Child Trust Fund';
        break;
      case 'ip':
        this.planName = 'Income Protection';
        break;
      case 'o50':
        this.planName = 'Over 50s Life Insurance';
        break;
      default:
        this.router.navigateByUrl('/log-in');
    }
  }
}
