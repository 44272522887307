import { Injectable } from '@angular/core';
import { AppConstantsService } from './app-constants.service';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { Observable, of, map, catchError, EMPTY, BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
import { HttpOptions } from '../models/general/http-options.model';
import { HttpHeaders } from '@angular/common/http';
import {
  Authorization,
  RequestPasswordReset,
  ResetPassword,
  RetryTwoFactorAuth,
  StorageKey,
  VerifyPasswordReset,
  VerifyTransformRegistration,
} from '@app/models/security';
import { HttpResponse } from '@app/models/general/http-response.model';
import { environment } from '../../environments/environment';
import { VerifyTransformRegistrationResponse } from '@app/models/security/verify-transform-registration.model';
import { CacheManagerService } from './cache-manager.service';

// extension declarations for running in WebView under iOS/Android
declare function interop_android(): boolean;
declare function interop_ios(): boolean;

@Injectable()
export class AuthService {
  private _authenticated = new BehaviorSubject<boolean>(false);
  public readonly authenticated$ = this._authenticated.asObservable();

  public readonly api = {
    TOKEN: `${this.configuration.server}/token`,
    VERIFY_TFA_CODE: `${this.configuration.server}/api/Account/VerifyTFACode`,
    RESEND_TFA_CODE: `${this.configuration.server}/api/Account/ResendPhoneCode`,
    RESEND_PENDING_TFA_CODE: `${this.configuration.server}/api/Account/ResendPendingPhoneCode`,
    VALIDATE_TOKEN: `${this.configuration.server}/api/Account/ValidateTokenTFA`,
    RESET_PASSWORD: `${this.configuration.server}/api/Account/ResetPassword`,
    VERIFY_RESET_PASSWORD: `${this.configuration.server}/api/Account/VerifyResetPassword`,
    FORGOTTEN_PASSWORD: `${this.configuration.server}/api/Account/ForgottenMemberLoginPassword`,
    VERIFY_EMAIL: `${this.configuration.server}/api/Member/VerifyTransformRegistration`,
  };

  private cookieOptions: CookieOptions;
  private usePassword: boolean;

  private readonly AWAY_TIME_GRACE_SEC: number = environment.application.autoLogoutGraceSeconds;
  private lastSeen = Date.now();
  private isLocal = this.configuration.isLocal;

  constructor(
    private configuration: AppConstantsService,
    private http: HttpService,
    private cookie: CookieService,
    private cacheManagerService: CacheManagerService
  ) {
    this.cookieOptions = {
      secure: !this.isLocal,
    } as CookieOptions;

    // NOTE: only enable this in production builds (because it will be really annoying for development)
    if (environment.production) {
      this.hookVisibilityChange();
    }
  }

  hookVisibilityChange() {
    // hook the visibilitychange event so that we can automatically logout the user
    document.addEventListener('visibilitychange', () => {
      // only listen to event once we have passed through TFA stage
      if (
        document.visibilityState === 'visible' &&
        this.cookie.hasKey(StorageKey.SF_2FA_AUTHENTICATED)
      ) {
        // check for user exceed our "grace period" of being out of focus
        let timeAway = (Date.now() - this.lastSeen) / 1000;
        if (timeAway > this.AWAY_TIME_GRACE_SEC) {
          console.warn(
            `Authentication: Away time of ${timeAway} seconds exceeded limit of ${this.AWAY_TIME_GRACE_SEC}`
          );

          // auto-logout
          this.logout();

          // force page refresh
          location.reload();
        }
      }
    });

    setInterval(() => {
      if (document.visibilityState === 'visible') {
        // update "last seen" date whenever the application is in focus
        this.lastSeen = Date.now();
      }
    }, 1000);
  }

  get clientSecret(): string {
    let clientSecret = environment.auth.clientSecret;

    // check for native applications
    if (typeof interop_android === 'function' && interop_android()) {
      // android
      clientSecret = environment.auth.clientSecretAndroid;
    } else if (typeof interop_ios === 'function' && interop_ios()) {
      // iOS
      clientSecret = environment.auth.clientSecretiOS;
    }

    return clientSecret;
  }

  private get clientBasicAuthorisation(): string {
    // create client identifier
    let clientId = environment.auth.clientId;
    let clientSecret = this.clientSecret;

    // check for native applications
    if (typeof interop_android === 'function' && interop_android()) {
      // android
      clientId = environment.auth.clientIdAndroid;
    } else if (typeof interop_ios === 'function' && interop_ios()) {
      // iOS
      clientId = environment.auth.clientIdiOS;
    }

    var client = window.btoa(`${clientId}:${clientSecret}`);
    return `BASIC ${client}`;
  }

  set loginVarUserName(userName: string) {
    localStorage.setItem(StorageKey.SF_USERNAME, userName);
  }

  get loginVarUserName(): string {
    return localStorage.getItem(StorageKey.SF_USERNAME);
  }

  set loginUsePassword(usePassword: boolean) {
    this.usePassword = usePassword;
  }

  get loginUsePassword(): boolean {
    return this.usePassword;
  }

  login(username: string, password: string): Observable<Authorization> {
    this.cacheManagerService.clearAllCaches();

    const data = `grant_type=password&userName=${encodeURIComponent(
      username
    )}&password=${encodeURIComponent(password)}`;
    return this.token(data);
  }

  refresh(refreshToken: string): Observable<Authorization> {
    const data = `grant_type=refresh_token&refresh_token=${refreshToken}`;
    return this.token(data);
  }

  token(data: string): Observable<Authorization> {
    let requestOptions: HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-urlencoded',
        Authorization: this.clientBasicAuthorisation,
      }),
    };

    return this.http.post<Authorization>(this.api.TOKEN, data, requestOptions).pipe(
      map((response) => {
        // Store access token in localstorage
        this.cookie.put(StorageKey.SF_ACCESS_TOKEN, response.access_token, this.cookieOptions);
        // if we have 'expires_in' we can determine when the token will expire
        if (response.expires_in) {
          var expiresDt = new Date(Date.now() + parseInt(response.expires_in) * 1000); // 'expires_in' is in seconds
          // store token expiry date
          this.cookie.put(StorageKey.SF_EXPIRY_TOKEN, expiresDt.toISOString(), this.cookieOptions);
        }
        // if we have a 'refresh_token' we can store this to use later to automatically keep authorization working without needing a (re)log-in
        if (response.refresh_token) {
          // store refresh token
          this.cookie.put(StorageKey.SF_REFRESH_TOKEN, response.refresh_token, this.cookieOptions);
        }
        return response;
      })
    );
  }

  loginTwoFactorAuth(code: string): Observable<Authorization> {
    this.cacheManagerService.clearAllCaches();

    // we need to provide the current refresh token (if we have one) when calling to vertify the TFA
    const refreshToken = this.cookie.get(StorageKey.SF_REFRESH_TOKEN);
    const data = { code: code, refreshtokenid: refreshToken };
    return this.http.post<Authorization>(this.api.VERIFY_TFA_CODE, data).pipe(
      map((response) => {
        // Store access token in localstorage
        this.cookie.put(StorageKey.SF_ACCESS_TOKEN, response.access_token, this.cookieOptions);

        this.cookie.put(StorageKey.SF_2FA_AUTHENTICATED, 'true', this.cookieOptions);

        // clear login use password
        this.usePassword = false;

        this._authenticated.next(true);

        // return reponse
        return response;
      })
    );
  }

  /**
   * Sends 2FA code to currently stored phone number or email if chosen
   */
  resendTwoFactorAuth(resendViaSecondaryProvider: boolean): Observable<RetryTwoFactorAuth> {
    const data = { resendViaSecondaryProvider: resendViaSecondaryProvider };

    return this.http
      .post<RetryTwoFactorAuth>(this.api.RESEND_TFA_CODE, data)
      .pipe(map((response) => response));
  }

  /**
   * Sends 2FA code to newly requested Phone number or email if chosen
   */
  resendPendingTwoFactorAuth(resendViaSecondaryProvider: boolean): Observable<RetryTwoFactorAuth> {
    const data = { resendViaSecondaryProvider: resendViaSecondaryProvider };

    return this.http
      .post<RetryTwoFactorAuth>(this.api.RESEND_PENDING_TFA_CODE, data)
      .pipe(map((response) => response));
  }

  validateTFA() {
    this.http
      .get<HttpResponse>(this.api.VALIDATE_TOKEN, { observe: 'response' })
      .pipe(catchError(() => EMPTY))
      .subscribe(() => {
        this.cookie.put(StorageKey.SF_2FA_AUTHENTICATED, 'true', this.cookieOptions);

        // clear login use password
        this.usePassword = false;

        this._authenticated.next(true);
      });
  }

  validateToken(): Observable<number> {
    const accessToken = this.cookie.get(StorageKey.SF_ACCESS_TOKEN);

    // if we don't have an access token to validate return 401 Unauthorised
    if (!accessToken) {
      return of(401);
    }

    // if we have an token expiry we can check locally if it is expired
    if (this.cookie.hasKey(StorageKey.SF_EXPIRY_TOKEN)) {
      const expiresDt = new Date(this.cookie.get(StorageKey.SF_EXPIRY_TOKEN));
      if (new Date() > expiresDt) {
        // token has expired and is of no further use
        this.cookie.remove(StorageKey.SF_ACCESS_TOKEN);
        this.cookie.remove(StorageKey.SF_EXPIRY_TOKEN);

        // if we have a refresh token we can try to obtain a new access token using it and prevent interruption
        if (this.cookie.hasKey(StorageKey.SF_REFRESH_TOKEN)) {
          const refreshToken = this.cookie.get(StorageKey.SF_REFRESH_TOKEN);
          return this.refresh(refreshToken).pipe(
            map((responseData) => {
              // we were able to re-auth via the refresh token
              return 200;
            }),
            catchError((error) => {
              // remove invalid refresh token
              this.cookie.remove(StorageKey.SF_REFRESH_TOKEN);

              // we were not able to re-auth via the refresh token
              return of(401);
            })
          );
        } else {
          // if we don't have a refresh token return 401 Unauthorised
          return of(401);
        }
      }
    }

    return this.http.get<HttpResponse>(this.api.VALIDATE_TOKEN, { observe: 'response' }).pipe(
      map((r) => {
        this._authenticated.next(true);

        // handle 200
        return r.status;
      }),
      catchError((error) => {
        // handle 401
        if (error.status == 401) {
          if (error.error && error.error.code == 4012) {
            // return a custom status code to indicate still requires TFA authentication
            return of(4012);
          }
        }
        return of(error.status as number);
      })
    );
  }

  logout(): Promise<void> {
    this.cacheManagerService.clearAllCaches();

    return new Promise((resolve) => {
      this._authenticated.next(false);

      const removeCookie = (key: string) => this.cookie.remove(key, this.cookieOptions);

      const cookieKeys = [
        StorageKey.SF_ACCESS_TOKEN,
        StorageKey.SF_2FA_AUTHENTICATED,
        StorageKey.SF_REFRESH_TOKEN,
        StorageKey.SF_EXPIRY_TOKEN,
        StorageKey.SF_RETURN_URL,
        StorageKey.SF_USE_PASSWORD,
      ];

      cookieKeys.forEach(removeCookie);

      resolve();
    });
  }

  /**
   * Posts user email to forgotten password endpoint
   */
  forgotPassword(data: RequestPasswordReset) {
    return this.http.post(this.api.FORGOTTEN_PASSWORD, data);
  }

  /**
   * Posts users new password to password reset endpoint
   */
  resetPassword(data: ResetPassword) {
    return this.http.post(this.api.RESET_PASSWORD, data);
  }

  /**
   * Posts user email to verify email endpoint
   */
  verifyEmail(data: VerifyTransformRegistration): Observable<VerifyTransformRegistrationResponse> {
    return this.http.post(this.api.VERIFY_EMAIL, data);
  }

  /**
   * Posts user email and token to verify password reset endpoint
   */
  verifyResetPassword(data: VerifyPasswordReset): Observable<void> {
    return this.http.post<void>(this.api.VERIFY_RESET_PASSWORD, data);
  }
}
