import type { AbstractControl, ValidatorFn, ValidationErrors, FormGroup } from '@angular/forms';

export class CommonValidators {
  private static isNotPresent(control: AbstractControl): boolean {
    const value = control.value;
    if (value === undefined || value === null) {
      return true;
    }
    return value !== '' ? false : true;
  }

  public static isNotEqualToValue(value: number | string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (this.isNotPresent(control)) return null;
      if (String(control.value) === String(value)) {
        return {
          valueIsEqual: {
            requiredValueToNotEqual: value,
            actual: control.value,
          },
        };
      } else {
        return null;
      }
    };
    return validator;
  }

  public static isInRange(minValue: number, maxValue: number): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (this.isNotPresent(control)) return null;
      if (isNaN(control.value)) {
        return { numberRequired: true };
      }
      if (+control.value < minValue) {
        return {
          rangeValueToSmall: {
            requiredMinValue: minValue,
            requiredMaxValue: maxValue,
            actual: control.value,
          },
        };
      }

      if (+control.value > maxValue) {
        return {
          rangeValueToBig: {
            requiredMinValue: minValue,
            requiredMaxValue: maxValue,
            actual: control.value,
          },
        };
      } else {
        return null;
      }
    };
    return validator;
  }

  public static isDivisible(value: number): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (this.isNotPresent(control)) return null;
      if (isNaN(control.value)) {
        return { numberRequired: true };
      }
      if (control.value % value !== 0) {
        return {
          valueNotDivisible: {
            requiredDivisibleValue: value,
            actual: control.value,
          },
        };
      } else {
        return null;
      }
    };
    return validator;
  }

  public static under18Validator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dob = new Date(control.value);
      const today = new Date();

      let age = today.getFullYear() - dob.getFullYear();
      const monthDiff = today.getMonth() - dob.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
        age--;
      }

      return age >= 18 ? { over18: true } : null;
    };
  }

  public static confirmationValidator(
    controlName: string,
    confirmationControlName: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const group = control as FormGroup;
      const formControl = group.controls[controlName];
      const confirmationFormControl = group.controls[confirmationControlName];

      if (confirmationFormControl.touched && formControl.value !== confirmationFormControl.value) {
        return { [`${controlName}Mismatch`]: true };
      }

      return null;
    };
  }
}
