import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, Observable, shareReplay, tap, throwError } from 'rxjs';

import { AppConstantsService } from './app-constants.service';
import { HttpService } from './http.service';

import { MemberProfile } from '@app/models/profile';
import { ProfileCacheService } from './cache-concrete.service';

@Injectable()
export class ProfileService {
  public readonly api = {
    GET_MEMBER_PROFILE: `${this.configuration.server}/api/Member/profile`,
    POST_MEMBER_PROFILE: `${this.configuration.server}/api/Member/profile`,
    CONFIRM_PROFILE: `${this.configuration.server}/api/Member/ProfileConfirm`,
  };

  public updatedDetails: Partial<MemberProfile>;

  constructor(
    private http: HttpService,
    private configuration: AppConstantsService,
    private profileCacheService: ProfileCacheService
  ) {}

  /**
   * Checks state management for a recent copy of `MemberProfile`, if outdated or non existant,
   * a new copy is retrieved and recached.
   * @return Observable that emits the latest MemberProfile
   */
  public getMemberProfile(): Observable<MemberProfile> {
    let memberProfile$ = this.profileCacheService.getValue();
    if (!memberProfile$) {
      memberProfile$ = this.http.get<MemberProfile>(this.api.GET_MEMBER_PROFILE).pipe(
        map((response) => {
          return response;
        }),
        shareReplay(1),
        catchError((error) => throwError(() => `getMemberProfile -> ${error}`))
      );
      this.profileCacheService.setValue(memberProfile$);
    }
    return memberProfile$;
  }

  /**
   * Merges `MemberProfile` state with any profile updates and includes the user password.
   * @param {string} [password]
   */
  public postUpdatedDetails(password: string) {
    return this.getMemberProfile().pipe(
      mergeMap((profile) =>
        this.http.post(this.api.POST_MEMBER_PROFILE, {
          ...profile,
          ...this.updatedDetails,
          Password: password,
        })
      )
    );
  }

  /**
   * Merges `MemberProfile` state with any profile updates and includes OTP code.
   * @param {string} [code]
   */
  public confirmProfile(code: string) {
    return this.getMemberProfile().pipe(
      mergeMap((profile) =>
        this.http.post(`${this.api.CONFIRM_PROFILE}?token=${code}`, {
          ...profile,
          ...this.updatedDetails,
        })
      ),
      tap(() => this.clearCache())
    );
  }

  /**
   * Clears the current cache for this service
   */
  public clearCache(): void {
    this.profileCacheService.clearCache();
  }
}
