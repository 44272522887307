<app-content-header
  *ngIf="ctfDetails.plan"
  [title]="'My Child Trust Fund'"
  [subtitle]="ctfDetails.plan.formattedPlanNumber"
/>

<section *ngIf="ctfDetails.plan">
  <app-ctf-overview
    [plan]="ctfDetails.plan"
    [valueIncreasePerc]="transactionsSummary.valueIncreasePerc"
    [totalContributions]="transactionsSummary.totalContributions"
    [currentMaturityValue]="transactionsSummary.currentMaturityValue"
    [fullView]="true"
  >
    <app-ctf-maturity-graph [transactionsSummary]="transactionsSummary" />
    <br />
    <br />
    <app-button
      class="mt-4"
      (click)="navigateWithState(url.MATURE_CTF(ctfDetails.plan.formattedPlanNumber))"
      text="Mature my Child Trust Fund"
      [noBorder]="true"
      customClass="[&>*]:text-sm"
    />
  </app-ctf-overview>

  <!-- <app-ctf-overview [plan]="ctfDetails.plan" [valueIncreasePerc]="'64.01'"
              [totalContributions]="'1420.00'" [currentMaturityValue]="'2328.90'" [fullView]="false">
              <app-button class="mt-4"
                  (click)="navigateWithState(url.MATURE_CTF(ctfDetails.plan.formattedPlanNumber))"
                  text="Mature my Child Trust Fund" [noBorder]="true" />
          </app-ctf-overview> -->

  <div class="mt-4">
    <h2 class="mb-3 text-primary-sf-navy dark:text-sf-white">Quick actions</h2>

    <div class="md:grid md:grid-cols-2 md:auto-rows-fr md:gap-6 mb-4">
      <app-quick-action-button
        *ngIf="ctfDetails.plan.isIssued"
        (click)="navigateWithState(url.TOP_UP(ctfDetails.plan.formattedPlanNumber))"
        [quickActionBtnClass]="'my-4 md:my-0'"
        [quickActionBtnIcon]="'icon-make-deposit-min'"
        [quickActionBtnAlt]="'Make a deposit icon'"
        [quickActionBtnText]="'Make a deposit'"
      ></app-quick-action-button>

      <app-quick-action-button
        routerLink="{{
          url.TRANSACTIONS({
            planId: ctfDetails.plan.planId.toString(),
            formattedPlanNumber: ctfDetails.plan.formattedPlanNumber,
          })
        }}"
        [quickActionBtnClass]="'my-4 md:my-0'"
        [quickActionBtnIcon]="'icon-view-transactions-min'"
        [quickActionBtnAlt]="'View transactions icon'"
        [quickActionBtnText]="'View transactions'"
      ></app-quick-action-button>

      <app-quick-action-button
        routerLink="{{ url.DOCUMENTS | interpolate : ctfDetails.plan.formattedPlanNumber }}"
        [quickActionBtnClass]="'my-4 md:my-0'"
        [quickActionBtnIcon]="'icon-view-documents-min'"
        [quickActionBtnAlt]="'View documents icon'"
        [quickActionBtnText]="'View documents'"
      ></app-quick-action-button>

      <app-quick-action-button
        *ngIf="!hasNoDirectDebits"
        (click)="navigateWithState(url.DIRECT_DEBIT_VIEW_ALL, { directDebits })"
        [quickActionBtnClass]="'my-4 md:my-0'"
        [quickActionBtnIcon]="'icon-setup-dd-min'"
        [quickActionBtnAlt]="'View direct debit icon'"
        [quickActionBtnText]="'View Direct Debits'"
      ></app-quick-action-button>
    </div>

    <app-accordion
      accordionHeader="Your Child Trust Fund"
      accordionContent="The Government created the CTF savings plan for people born between 1st September 2002 and 2nd January 2011. When account holders turn 18, they can choose to either reinvest the money into an adult savings plan or withdraw it."
      [items]="[
        {
          title: 'Maturing your fund',
          content: [
            'At the age of 18 you cannot deposit any more money into your CTF. However, you can still take advantage of tax-exempt savings by simply tapping the button above to follow the CTF maturity process where you have the option to reinvest your money in a Stocks and Shares ISA, or make a full or partial withdrawal.',
          ],
        },
        {
          title: 'Why does the value change?',
          content: [
            'Your CTF is an investment product, so our expert fund managers invest in a wide range of companies and assets on your behalf with the aim of steadily growing your money. The current value of your CTF depends on how much these companies and assets are currently worth, which can change daily depending on how they perform on the stock market.',
            'When you come to access your plan by submitting a CTF maturity request, it will take up to 10 working days to be finalised. As the value of your investment changes daily, the maturity value may go up or down during this period, but once your request has been fully processed we will contact you to let you know the final value of your CTF. You can then reinvest the money in a Stocks and Shares ISA, or withdraw part or all of this.',
          ],
        },
      ]"
    ></app-accordion>
  </div>
</section>
