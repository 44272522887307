import { Pipe, PipeTransform } from '@angular/core';
import { TypeUtility } from '../../utilities/type.utility';

/*
 * Example usage: {{ 'This {0} an {1} {2}' | interpolate: 'is': 'interpolated': 'string' }}
 * Example output: This is an interpolated string
 */
@Pipe({
    name: 'interpolate',
    standalone: false
})
export class InterpolatePipe implements PipeTransform {
  transform(value: string, ...targetStrings: string[]): string {
    return (
      value &&
      value.replace(/\{(\d+)}/g, (substring: string, index: number): string =>
        TypeUtility.isNullOrUndefined(targetStrings[index]) ? substring : targetStrings[index]
      )
    );
  }
}
