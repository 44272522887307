<header
  class="flex justify-between"
  [ngClass]="{ 'notification-meta__detail': isDetail, 'security-class': important }"
>
  <div class="flex items-center">
    <div class="flex items-baseline">
      <svg class="w-3 h-3 mr-[5px] circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
          class="fill-primary-sf-yellow"
        />
      </svg>
      <p class="mb-0 text-sm text-sf-body-font dark:text-sf-white">{{ date }}</p>
    </div>
    <p class="text-sf-error-border mb-0 text-sm" *ngIf="important">- IMPORTANT</p>
  </div>

  <div>
    <div
      class="tag flex gap-2 font-bold rounded-md text-sm items-center py-1 px-2 bg-sf-yellow-light dark:bg-sf-white-10-opacity"
      [ngClass]="type"
      *ngIf="type != 'default'"
    >
      <svg
        *ngIf="type == 'account'"
        class="w-5 h-5 fill-none"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.834 11.575V10.49a2.167 2.167 0 0 0-2.167-2.166H4.334a2.167 2.167 0 0 0-2.167 2.166v1.084M6.5 6.158a2.167 2.167 0 1 0 0-4.333 2.167 2.167 0 0 0 0 4.333z"
          stroke="#F8AB00"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="scale-x-150 scale-y-150"
        />
      </svg>

      <svg
        *ngIf="type == 'marketing'"
        class="w-5 h-5 fill-none"
        viewBox="0 -2 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#a)">
          <path
            d="m6 1.2 1.545 3.13L11 4.835 8.5 7.27l.59 3.44L6 9.085 2.91 10.71l.59-3.44L1 4.835l3.455-.505L6 1.2z"
            stroke="#F8AB00"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="scale-x-150 scale-y-150"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" transform="translate(0 .2)" d="M0 0h12v12H0z" />
          </clipPath>
        </defs>
      </svg>

      <svg
        *ngIf="type == 'security'"
        class="w-5 h-5 fill-none security"
        viewBox="0 -2 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          clip-path="url(#a)"
          stroke="#F8AB00"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M9.5 5.7h-7a1 1 0 0 0-1 1v3.5a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V6.7a1 1 0 0
          0-1-1zM3.5 5.7v-2a2.5 2.5 0 1 1 5 0v2"
            class="scale-x-150 scale-y-150"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path
              fill="#fff"
              transform="translate(0 .2)"
              d="M0 0h12v12H0z"
              class="scale-x-150 scale-y-150"
            />
          </clipPath>
        </defs>
      </svg>

      <svg
        *ngIf="type == 'plan'"
        class="w-5 h-5 fill-none"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 8.7a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
          stroke="#F8AB00"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="scale-x-150 scale-y-150"
        />
        <path
          d="M4.105 8.145 3.5 12.7 6 11.2l2.5 1.5-.605-4.56"
          stroke="#F8AB00"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="scale-x-150 scale-y-150"
        />
      </svg>

      <p class="mb-0">{{ type | titlecase }}</p>
    </div>
  </div>
</header>
