<app-content-header [title]="'Adult savings FAQs'"></app-content-header>

<div class="help-savings">
  <h2 class="mb-4">Opening a Plan</h2>

  <div>
    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseOne')"
            [ngClass]="getButtonIcon('collapseOne')"
          >
            <span class="flex-1">Am I eligible to open a plan?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseOne"
        data-toggle-id="collapseOne"
        [hidden]="!isToggle('collapseOne')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          To be eligible to open a
          <a href="https://www.shepherdsfriendly.co.uk/stocks-and-shares-isa/">
            Stocks and Shares ISA
          </a>
          or a
          <a href="https://www.shepherdsfriendly.co.uk/5-year-fixed-rate-bond/">
            5 Year Fixed Rate Bond
          </a>
          , you must be aged 18 years old and a UK resident.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseTwo')"
            [ngClass]="getButtonIcon('collapseTwo')"
          >
            <span class="flex-1">Can I have more than one ISA?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseOne"
        data-toggle-id="collapseTwo"
        [hidden]="!isToggle('collapseTwo')"
        [ngClass]="{ visible: isToggle }"
      >
        <div class="card-body">
          <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
            Stocks and Shares ISA
          </h4>
          You can open ISAs with more than one provider within the tax year, providing you don't
          exceed the maximum annual allowance across all the ISAs you hold. The current maximum
          allowance for the 2024/25 tax year is £20,000. The ISAs you hold can all be the same type
          or you can choose a mixture of ISAs that you’re eligible for in a given tax year. It is
          important you check your eligibility for each type of ISA, and remember, you can only pay
          into one Lifetime ISA in a single tax year (up to £4,000).
        </div>
      </div>
    </div>
    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseThree')"
            [ngClass]="getButtonIcon('collapseThree')"
          >
            <span class="flex-1">Can I open an ISA for my child?</span>
            <span class="show-icon relative aself-end w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseThree"
        data-toggle-id="collapseThree"
        [hidden]="!isToggle('collapseThree')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          You must be 18 to have a stocks and shares ISA. However, we do offer a Junior ISA, which
          is the child friendly version of the ISA. When the child turns 18, they can then transfer
          their Junior ISA into an adult ISA.
        </div>
      </div>
    </div>
    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseFour')"
            [ngClass]="getButtonIcon('collapseFour')"
          >
            <span class="flex-1">Can I open a savings plan with a single lump sum payment?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseFour"
        data-toggle-id="collapseFour"
        [hidden]="!isToggle('collapseFour')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          You can open our
          <a
            class="underline text-primary-sf-navy dark:text-secondary-sf-blue-light"
            href="https://www.shepherdsfriendly.co.uk/stocks-and-shares-isa/"
          >
            Stocks and Shares ISA
          </a>
          with a single payment from £100, and can then save flexibly up to the annual allowance.
          <br />
          <br />
          You can also choose to open a
          <a
            class="underline text-primary-sf-navy dark:text-secondary-sf-blue-light"
            href="https://www.shepherdsfriendly.co.uk/5-year-fixed-rate-bond/"
          >
            5 Year Fixed Rate Bond
          </a>
          and get started with a minimum lump sum of £1,000 which has a guaranteed annual return
          over the five-year term.
        </div>
      </div>
    </div>
    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseFive')"
            [ngClass]="getButtonIcon('collapseFive')"
          >
            <span class="flex-1">
              When will I receive my ‘ISA boost’ in my Stocks and Share ISA?
            </span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseFive"
        data-toggle-id="collapseFive"
        [hidden]="!isToggle('collapseFive')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          The ‘ISA Boost’ will be paid within 30 days of your first deposit being received into your
          Stocks and Shares ISA. Please note that this is dependent on the terms and conditions of
          the offer, including that it cannot be used in conjunction with any other offer.
        </div>
      </div>
    </div>
  </div>

  <br />
  <h2 class="mb-4">Information about your plan</h2>
  <div id="accordionHelpTwo">
    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseSix')"
            [ngClass]="getButtonIcon('collapseSix')"
          >
            <span class="flex-1">Will I pay tax on the growth of my savings plan?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseSix"
        data-toggle-id="collapseSix"
        [hidden]="!isToggle('collapseSix')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
            Stocks and Shares ISA
          </h4>
          Our Stocks and Shares ISA is tax-efficient meaning that there will not be any income tax
          or capital gains tax to pay on the growth of the plan. Any money withdrawn can also be
          done so tax-free.
          <br />
          <br />
          <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
            5 Year Fixed Rate Bond
          </h4>
          We pay tax on the fund that generates any returns on the 5 Year Fixed Rate Bond, so if you
          are a basic rate taxpayer, you should not have to pay any further taxes. When your Bond
          matures, we’ll send you a Chargeable Event Certificate. If you are a higher-rate taxpayer,
          you should include this in your tax return as you may have to pay additional tax when the
          Bond matures or if money is withdrawn before the end of the plan.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseSeven')"
            [ngClass]="getButtonIcon('collapseSeven')"
          >
            <span class="flex-1">Is there an interest rate on the savings plans?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseSeven"
        data-toggle-id="collapseSeven"
        [hidden]="!isToggle('collapseSeven')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          We aim to pay a quarterly bonus every three months into ISA plans and an annual bonus into
          other Shepherds Friendly investment plans. The bonus will reflect the performance of the
          With Profits fund over the previous year.
          <br />
          <br />
          Please remember that past performance is not a guide to how the plan could perform in the
          future, and although we can never take bonuses away once they have been paid, we may not
          pay one in years when performance is particularly poor.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseEight')"
            [ngClass]="getButtonIcon('collapseEight')"
          >
            <span class="flex-1">What is the annual allowance of my plan?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseEight"
        data-toggle-id="collapseEight"
        [hidden]="!isToggle('collapseEight')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
            Stocks and Shares ISA
          </h4>
          The annual ISA allowance can vary each tax year. The allowance is set by HM Government and
          can rise in line with the Consumer Prices Index. In the 2024/25 year, the annual ISA
          allowance is £20,000 – this applies across multiple savings plans. Therefore, if you have
          various ISAs, the total amount of money you contribute should not exceed the allowance.
          <br />
          <br />
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseNine')"
            [ngClass]="getButtonIcon('collapseNine')"
          >
            <span class="flex-1">How much can I save each month in my plan?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseNine"
        data-toggle-id="collapseNine"
        [hidden]="!isToggle('collapseNine')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
            Stocks and Shares ISA
          </h4>
          You can save from £30 a month right up to a maximum of £1,666 a month to fulfil your
          annual allowance of £20,000. You can also invest lump sums in your account, as long as you
          don’t exceed your full annual ISA allowance. If you prefer to pay in lump sums, the
          initial deposit must be a minimum of £100, and then you can make additional lump sum
          payments from £10 at any point.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseTen')"
            [ngClass]="getButtonIcon('collapseTen')"
          >
            <span class="flex-1">What is the deadline to use my ISA allowance?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseTen"
        data-toggle-id="collapseTen"
        [hidden]="!isToggle('collapseTen')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          Your ISA allowance resets each year on 6th April, in line with the start of the new tax
          year. Any unused allowance does not roll over to the next year.
          <br />
          <br />
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseEleven')"
            [ngClass]="getButtonIcon('collapseEleven')"
          >
            <span class="flex-1">
              Can I carry any unused ISA allowance over to the next tax year?
            </span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseEleven"
        data-toggle-id="collapseEleven"
        [hidden]="!isToggle('collapseEleven')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          Current Government regulations mean that you cannot carry any unused ISA allowance over to
          the next tax year.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseTwelve')"
            [ngClass]="getButtonIcon('collapseTwelve')"
          >
            <span class="flex-1">How will the money in my savings plan be invested?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseTwelve"
        data-toggle-id="collapseTwelve"
        [hidden]="!isToggle('collapseTwelve')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          We will invest your money in our With Profit Fund, which holds a mix of assets including
          stocks and shares, property, gilts, bonds and cash. The market values of these assets can
          move up and down over time.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseFourteen')"
            [ngClass]="getButtonIcon('collapseFourteen')"
          >
            <span class="flex-1">What happens to my plan if I die?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseFourteen"
        data-toggle-id="collapseFourteen"
        [hidden]="!isToggle('collapseFourteen')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
            Stocks and Shares ISA
          </h4>
          <p>
            you die while your Stocks and Shares ISA is still invested a death benefit will be paid
            to your estate. The amount payable will be 100% of your initial investment plus all
            bonuses which have been added and any final bonus which may be paid, subject to a
            minimum payment of 101% of your initial investment. If you are married or in a civil
            partnership, an ISA can be transferred to your Spouse or Civil Partner.
          </p>
          <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
            5 Year Fixed Rate Bond
          </h4>
          <p>
            The Bond also acts as a life insurance contract, so if you die prior to the maturity
            date of the plan and you are the sole plan holder, your estate receives a lump sum. The
            final amount paid out depends on how far you were into the plan. For example, if death
            occurs within the cancellation period, 100% of the money you invested goes back to your
            estate. This increases to 101% for in the first year of the policy and goes up by 1%
            each year of the policy, so in year five it would be 105%. You can find exact details in
            the plan’s terms and conditions.
          </p>
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseSixteen')"
            [ngClass]="getButtonIcon('collapseSixteen')"
          >
            <span class="flex-1">Is the money I invest with Shepherds Friendly protected?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseSixteen"
        data-toggle-id="collapseSixteen"
        [hidden]="!isToggle('collapseSixteen')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          Yes. Any money invested with Shepherds Friendly Society is covered by the Financial
          Services Compensation Scheme. The maximum level of compensation is currently 100% of the
          claim with no upper limit (correct at time of publishing – April 2017). The actual level
          of compensation you receive will depend on the basis of your claim.
          <br />
          <br />
          Find out more information about the
          <a
            class="underline text-primary-sf-navy dark:text-secondary-sf-blue-light"
            href="https://www.fscs.org.uk/"
          >
            Financial Services Compensation Scheme
          </a>
          .
        </div>
      </div>
    </div>
  </div>

  <br />
  <h2 class="mb-4">Managing your plan</h2>
  <div id="accordionHelpThree">
    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseSeventeen')"
            [ngClass]="getButtonIcon('collapseSeventeen')"
          >
            <span class="flex-1">How can I check the value of my savings plan?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseSeventeen"
        data-toggle-id="collapseSeventeen"
        [hidden]="!isToggle('collapseSeventeen')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          If you phone our Customer Services team 0800 526 249, they will be able to tell you the
          current value of your plan. Alternatively, you can just check your annual statement.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseEighteen')"
            [ngClass]="getButtonIcon('collapseEighteen')"
          >
            <span class="flex-1">Can I withdraw money from my plan at any time?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseEighteen"
        data-toggle-id="collapseEighteen"
        [hidden]="!isToggle('collapseEighteen')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
            Stocks and Shares ISA
          </h4>
          You can withdraw money from your ISA whenever you want. To make a withdrawal please email
          <a
            class="underline text-primary-sf-navy dark:text-secondary-sf-blue-light"
            href="mailto:withdrawals@shepherdsfriendly.co.uk"
          >
            withdrawals&#64;shepherdsfriendly.co.uk
          </a>
          , please remember to include your plan number and send your request from the email address
          you have registered with us. We normally transfer your money directly to your bank
          account. Once we start processing your withdrawal, it usually takes five to seven working
          days for the money to reach your account.
          <br />
          <br />
          Depending on your bank, or during particularly busy periods, this process can take a
          little longer and you may receive your money around 10 working days after we receive your
          request. If you need the money for a particular date or purpose, we recommend allowing
          plenty of time for your withdrawal to be completed, in case of any delays.
          <br />
          <br />
          If you prefer, we can send you a cheque by post upon request.
          <br />
          <br />
          However, you should remember that our Stocks and Shares ISA is a medium to long-term
          investment and that making regular withdrawals could reduce the value.
          <br />
          <br />
          <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
            5 Year Fixed Rate Bond
          </h4>
          You cannot withdraw from your 5 Year Fixed Rate Bond apart from when there are exceptional
          circumstances, such as diagnosis of a terminal illness, bankruptcy, insolvency, or
          sequestration.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseNineteen')"
            [ngClass]="getButtonIcon('collapseNineteen')"
          >
            <span class="flex-1">
              Will I have to pay tax or face a penalty if I withdraw money from my ISA?
            </span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseNineteen"
        data-toggle-id="collapseNineteen"
        [hidden]="!isToggle('collapseNineteen')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          Any money you withdraw from your account will be paid to you tax-free. There is no fixed
          penalty for withdrawing money from your ISA. However, in poor market conditions, we may
          apply a Market Value Reduction (MVR).
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseTwenty')"
            [ngClass]="getButtonIcon('collapseTwenty')"
          >
            <span class="flex-1">If I withdraw money from my ISA, can I pay it back in later?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseTwenty"
        data-toggle-id="collapseTwenty"
        [hidden]="!isToggle('collapseTwenty')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          If you withdraw money from your ISA then this will still count towards your annual ISA
          allowance. Regardless of how much money you withdraw during a single tax year, you cannot
          invest more than your annual allowance in combined payments within that tax year. That
          means if you use your full allowance and then make a withdrawal, you cannot pay that money
          back in during the same tax year.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseTwentyTwo')"
            [ngClass]="getButtonIcon('collapseTwentyTwo')"
          >
            <span class="flex-1">How do I add money to my savings plan?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseTwentyTwo"
        data-toggle-id="collapseTwentyTwo"
        [hidden]="!isToggle('collapseTwentyTwo')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
            Stocks and Shares ISA
          </h4>
          You can add lump sum payments, subject to the maximum limit for any tax year, to your ISA
          by filling in the online
          <a
            class="underline text-primary-sf-navy dark:text-secondary-sf-blue-light"
            href="https://www.shepherdsfriendly.co.uk/your-account"
          >
            form
          </a>
          or by calling our Customer Services team on 0800 526 249.
          <br />
          <br />
          <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
            5 Year Fixed Rate Bond
          </h4>
          Once you have added your single lump sum into your 5 Year Fixed Rate Bond, you cannot make
          any further contributions. However, you can open additional 5 Year Fixed Rate Bonds if you
          want to, provided you do not exceed the maximum amount of £125,000.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseTwentyThree')"
            [ngClass]="getButtonIcon('collapseTwentyThree')"
          >
            <span class="flex-1">When will my Direct Debit show on my plan?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseTwentyThree"
        data-toggle-id="collapseTwentyThree"
        [hidden]="!isToggle('collapseTwentyThree')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          Once your Direct Debit payment has been taken from your bank account, it can take up to
          three working days to show up in your ISA/Junior ISA.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseTwentyFour')"
            [ngClass]="getButtonIcon('collapseTwentyFour')"
          >
            <span class="flex-1">
              What do I do if I can’t afford my monthly payments for a while?
            </span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseTwentyFour"
        data-toggle-id="collapseTwentyFour"
        [hidden]="!isToggle('collapseTwentyFour')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
            Stocks and Shares ISA
          </h4>
          You can suspend or reduce your monthly Direct Debit at any point. Just call our Member
          Services team on 0800 526 249.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseTwentyFive')"
            [ngClass]="getButtonIcon('collapseTwentyFive')"
          >
            <span class="flex-1">How do I inform Shepherds Friendly of change of my address?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseTwentyFive"
        data-toggle-id="collapseTwentyFive"
        [hidden]="!isToggle('collapseTwentyFive')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          We accept changes of home address over the phone from the plan holder, or the registered
          contact of a Child Trust Fund or Junior ISA.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseTwentySix')"
            [ngClass]="getButtonIcon('collapseTwentySix')"
          >
            <span class="flex-1">Is there a charge for managing the plans?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseTwentySix"
        data-toggle-id="collapseTwentySix"
        [hidden]="!isToggle('collapseTwentySix')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
            Stocks and Shares ISA
          </h4>
          We deduct charges from your investment to cover our expenses and the cost of administering
          and managing your investments. Charges are deducted from the investment growth prior to
          bonuses being declared; these are currently 3% as an initial plan charge and an annual
          management charge of 1.5%.
          <br />
          <br />
          <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
            5 Year Fixed Rate Bond
          </h4>
          There are no explicit charges for the 5 Year Fixed Rate Bond. The plan has been created to
          incorporate any charges we may incur.
        </div>
      </div>
    </div>

    <div class="sf-card flex flex-col justify-center md:p-6">
      <div class="mb-0">
        <h2 class="mb-0">
          <button
            class="border-none bg-none p-0 text-sm 2xl:text-lg w-full text-left flex gap-3 font-medium justify-between cursor-pointer items-center"
            type="button"
            (click)="toggle('collapseTwentySeven')"
            [ngClass]="getButtonIcon('collapseSeven')"
          >
            <span class="flex-1">How do I cancel my plan?</span>
            <span class="show-icon relative w-8 h-[33px] bg-sf-plus bg-no-repeat"></span>
          </button>
        </h2>
      </div>
      <div
        id="collapseTwentySix"
        data-toggle-id="collapseTwentySeven"
        [hidden]="!isToggle('collapseTwentySeven')"
        [ngClass]="{ visible: isToggle }"
      >
        <div>
          <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
            Stocks and Shares ISA
          </h4>
          From the day you open your account you have 30 days to cancel your Stocks and Shares ISA.
          If you cancel within this timeframe, 100% of the money you put in will be returned to you.
          If you cancel within the 30 days you can also subscribe to another Stocks and Shares ISA
          in the current tax year.
          <br />
          <br />
          If you cancel your Stocks and Shares ISA after the 30 days, you can request your cash-in
          value and we will organise the lump sum to be paid into your account.
          <br />
          <br />
          Please note: After the 30 days cancellation period, under ISA rules you will be treated as
          though you have subscribed to a Stocks and Shares ISA and will therefore not be able to
          pay in to another one in the current tax year. However, you would be allowed to open a
          cash ISA with any unused allowance.
          <br />
          <br />
          To cancel or cash-in your plan please email our Member Services Team on
          <a
            class="underline text-primary-sf-navy dark:text-secondary-sf-blue-light"
            href="mailto:members@shepherdsfriendly.co.uk"
          >
            members&#64;shepherdsfriendly.co.uk
          </a>
          with your full name and plan number.
          <br />
          <br />
          <h4 class="text-lg font-bold text-primary-sf-navy dark:text-sf-white mb-2">
            5 Year Fixed Rate Bond
          </h4>
          Once your application is accepted, you can cancel your plan within the first 30 days of
          opening it. You can do this by contacting our Member Services team via email on
          <a
            class="underline text-primary-sf-navy dark:text-secondary-sf-blue-light"
            href="mailto:members@shepherdsfriendly.co.uk"
          >
            members&#64;shepherdsfriendly.co.uk
          </a>
          or telephone them on 0800 526 249. If you do so within this period, we will refund all the
          money you have invested. After the 30 days cancellation period you will not be able to
          withdraw money from the Bond unless there are exceptional circumstances.
        </div>
      </div>
    </div>
  </div>
</div>
