<app-content-header
  [title]="'Change your Direct Debit'"
  [subtitle]="plan.formattedPlanNumber"
></app-content-header>

<div class="mb-4 md:mb-6 xl:mb-8" [ngSwitch]="error" *ngIf="error">
  <app-alert
    *ngSwitchCase="errorOpts.NOT_LIVE"
    type="info"
    title="Direct Debit processing in progress"
    imageSmallSrc="exclamation-triangle"
    imageSmallAlt="exclamation-triangle icon"
    additionalClass="mb-4 md:mb-6 xl:mb-8"
    message="Your Direct Debit
              is currently being processed by our Finance Team. It cannot be
              varied at the moment. If you have any queries, feel free to
              contact us on the details below."
  ></app-alert>

  <app-alert
    *ngSwitchCase="errorOpts.MAX_CHANGES_REACHED"
    type="info"
    title="No further changes allowed"
    imageSmallSrc="exclamation-triangle"
    imageSmallAlt="exclamation-triangle icon"
    additionalClass="mb-4 md:mb-6 xl:mb-8"
    message="As you have updated your Direct Debit within the last 30
                  days, you are unable to make any further changes at this
                  time. Please try again at a later date."
  ></app-alert>

  <app-alert
    *ngSwitchCase="errorOpts.ANNUAL"
    type="info"
    title="Contact Member Service Team"
    imageSmallSrc="exclamation-triangle"
    imageSmallAlt="exclamation-triangle icon"
    additionalClass="mb-4 md:mb-6 xl:mb-8"
    htmlMessage="To vary your annual Direct Debit please contact our Member
              Service Team on <a href='tel:0789255654'>0789255654</a>"
  ></app-alert>
</div>
<app-direct-debit-overview
  [plan]="plan"
  [directDebit]="directDebit"
  [concise]="false"
></app-direct-debit-overview>
<div>
  <form
    *ngIf="!error"
    #form="ngForm"
    id="direct-debit-form"
    [formGroup]="directDebitForm"
    (ngSubmit)="onSubmit(form)"
  >
    <div
      class="shadow-none -mt-0.5 border-t border-t-primary-sf-navy/10 dark:border-t-sf-white/10 !rounded-t-none sf-card"
    >
      <fieldset class="mb-4">
        <label for="amount" class="form-label">New premium amount (£)</label>
        <div class="input-group mb-4"></div>
        <input
          id="amount"
          name="amount"
          formControlName="amount"
          type="text"
          min="0"
          [step]="plan.directDebitDivisibleAmount"
          class="form-control"
          placeholder="£"
          [ngClass]="{
            'is-invalid': amountCtrl.touched && amountCtrl.errors,
          }"
        />

        <div *ngIf="amountCtrl.touched && amountCtrl.errors">
          <app-validation-message
            *ngIf="
              amountCtrl.errors['rangeValueToBig'] ||
              amountCtrl.errors['rangeValueToSmall'] ||
              amountCtrl.errors['valueNotDivisible']
            "
            message="Please enter a
                                  {{ paymentPeriod }} amount which is greater
                                  than or equal to £{{ minMaxValues.min }},
                                  less than or equal to £{{
              minMaxValues.max
            }}, and a multiple of £{{ plan.directDebitDivisibleAmount }}."
          ></app-validation-message>

          <app-validation-message
            *ngIf="amountCtrl.errors['valueIsEqual']"
            message="Please enter a value different to your
                                  current {{ paymentPeriod }} amount."
          ></app-validation-message>
        </div>
      </fieldset>

      <app-button
        text="Review Direct Debit"
        buttonStyle="secondary"
        [noBorder]="true"
        [disabled]="!form.valid"
        [isSubmitButton]="true"
      ></app-button>
    </div>
  </form>
  <div *ngIf="!error">
    <app-alert
      *ngIf="allowsMultiple"
      type="info"
      additionalClass="mt-4 mb-4 md:mt-6 xl:mt-8"
      imageSmallSrc="wallet"
      imageSmallAlt="wallet icon"
      title="Set up additional Direct Debits"
      message="To set up additional Direct Debits please call our Member Services team. The option to vary a Direct Debit in our app will be removed if you add multiple Direct Debits to your plan."
    >
      <div class="flex justify-center items-center gap-3 md:max-w-[300px]">
        <a
          href="tel:0800526249"
          class="text-primary-sf-navy dark:text-sf-white bg-sf-white dark:bg-sf-white-10-opacity text-sm font-semibold no-underline px-4 py-2 w-full flex justify-center items-center gap-2 rounded-full"
        >
          Call us
        </a>
        <a
          href="mailto:members@shepherdsfriendly.co.uk"
          class="text-primary-sf-navy dark:text-sf-white bg-sf-white dark:bg-sf-white-10-opacity text-sm font-semibold no-underline px-4 py-2 w-full flex justify-center items-center gap-2 rounded-full"
        >
          Email us
        </a>
      </div>
    </app-alert>
  </div>
</div>
