export enum StorageKey {
  'SF_USERNAME' = 'sfun',
  'SF_RETURN_URL' = 'sfru',
  'SF_REFRESH_TOKEN' = 'sfrt',
  'SF_ACCESS_TOKEN' = 'sfat',
  'SF_EXPIRY_TOKEN' = 'sfeat',
  'SF_2FA_AUTHENTICATED' = 'sfft',
  'SF_NOTIFICATIONS' = 'sfnos',
  'SF_SURVEY' = 'sfsurvey',
  'SF_USE_PASSWORD' = 'sfup',
  'SF_NOTIFICATIONS_REG' = 'sfreg',
  'SF_BIOMETRICS_PROMPT' = 'sfauthnnp',
  'SF_BIOMETRICS_PROMPT_MULTI' = 'sfauthnnps',
  'SF_BIOMETRICS' = 'sfauthn',
  'SF_BIOMETRICS_MULTI' = 'sfauthns',
  'SF_USER_TOKEN' = 'sfbiotkn',
  'SF_USER_TOKEN_MULTI' = 'sfbiotkns',
  'SF_INSTALL_PROMPT' = 'sfin',
  'SF_THEME' = 'sftheme',
}
