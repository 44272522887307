import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ErrorType } from '@app/models/errors';
import { AuthService } from '@app/services';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const email = route.queryParamMap.get('email');
    const code = route.queryParamMap.get('code');

    if (!email || !code) {
      this.router.navigate(['/error'], { state: { errorType: ErrorType.INVALID_LINK } });
      return of(false);
    }

    // TODO: Explore enhancements to retrieve ErrorType codes from the backend.
    return this.authService.verifyResetPassword({ email, code }).pipe(
      map(() => true),
      catchError(() => {
        this.router.navigate(['/error'], {
          state: {
            errorType: ErrorType.EXPIRED_LINK,
            errorTitle: 'Oops! It looks like your link has expired',
          },
        });
        return of(false);
      })
    );
  }
}
