import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NotificationsService, ThemeService } from '@app/services';
import { BehaviorSubject } from 'rxjs';
import { VisibilityService } from '@app/services';
import { Mode } from '@app/services/theme.service';

@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss'],
})
export class MenuHeaderComponent implements OnInit {
  public urlNoParams: string;
  public selectedTheme: Mode;
  public showMenu: boolean;

  constructor(
    private notificationsService: NotificationsService,
    public visibilityService: VisibilityService,
    private router: Router,
    public themeService: ThemeService
  ) {}

  ngOnInit(): void {
    //@TODO: Review before launch
    this.router.events.subscribe((event) => {
      // assign vars
      this.urlNoParams = this.getUrlWithoutParams;

      let navigation: NavigationEnd;
      event instanceof NavigationEnd ? (navigation = event) : null;

      if (navigation) {
        if (
          !navigation.urlAfterRedirects.startsWith('/log-in') && // covers all 'log-in-x' navigations
          !navigation.urlAfterRedirects.startsWith('/register') && // covers all 'register-x' navigations
          !navigation.urlAfterRedirects.startsWith('/forgotten') &&
          !navigation.urlAfterRedirects.startsWith('/verify')
        ) {
          this.showMenu = true;
        } else {
          this.showMenu = false;
        }
      }
    });
  }

  get hasNotifications$(): BehaviorSubject<boolean> {
    return this.notificationsService.notificationsUnread$;
  }

  get getUrlWithoutParams() {
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {};
    urlTree.fragment = null; // optional
    return urlTree.toString();
  }

  switchMode(newMode: Mode) {
    this.themeService.switchMode(newMode);
  }

  toggleTheme() {
    this.selectedTheme = this.selectedTheme === Mode.LIGHT ? Mode.DARK : Mode.LIGHT;
    this.switchMode(this.selectedTheme);
  }

  back(): void {
    history.back();
  }
}
