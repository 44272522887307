import {
  AfterViewInit,
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TransactionsSummary } from '@app/models/member-portal';
import { ThemeService } from '@app/services';
import { Mode } from '@app/services/theme.service';
import { EChartsOption, connect, getInstanceByDom } from 'echarts';
import * as echarts from 'echarts'; // Import echarts to handle the chart instance
import { fromEvent, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-plan-performance-graph',
  templateUrl: './plan-performance-graph.component.html',
  styleUrls: ['./plan-performance-graph.component.scss'],
})
export class PlanPerformanceGraphComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() transactionsSummary: TransactionsSummary;
  @ViewChild('chart', { static: true }) chartElement: ElementRef<HTMLDivElement>;
  private chartInstance: echarts.ECharts;

  public readonly kLastYearsN = 5; // maximum number of last x years to show

  public options: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      confine: true,
      extraCssText: 'z-index: 1000', // ensure the tooltip does not float over either <app-menu-header/footer>
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: ['2019', '2020', '2021', '2022', '2023'] /* demo data */,
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: {
      position: 'right',
      axisLabel: {
        formatter: '£{value}',
      },
      splitLine: {
        lineStyle: {
          color: this.lineColourRGB,
        },
      },
    },
    series: [
      {
        name: 'Deposits & Growth',
        type: 'bar',
        data: [6000, 7000, 7250, 8250, 8250] /* demo data */,
        color: '#a5afbe',
        stack: 'x',
        tooltip: {
          valueFormatter: (value) => `£${value.toLocaleString()}`,
        },
        itemStyle: {
          borderRadius: [100, 100, 0, 0], // Rounded top corners
        },
        barWidth: '18', // Reduce bar width by half
      },
      {
        name: 'Withdrawals',
        type: 'bar',
        data: [500, 500, 500, 500, 500] /* demo data */,
        color: '#e4a106',
        tooltip: {
          valueFormatter: (value) => `£${value.toLocaleString()}`,
        },
        itemStyle: {
          borderRadius: [100, 100, 0, 0], // Rounded top corners
        },
        barWidth: '18', // Reduce bar width by half
      },
    ],
  };

  private subscribeUntilDestroyed = new Subscription();

  constructor(private themeService: ThemeService) {}

  public ngOnInit(): void {
    this.subscribeUntilDestroyed = this.themeService.mode$.subscribe(() => this.setGraphTheme());
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this.initChart();
      this.handleResizeEvent();
    });
  }

  public ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  /*
   * Helper to return correct line colour based on the theme mode
   */
  get lineColourRGB(): string {
    return this.themeService.mode === Mode.LIGHT
      ? 'rgba(19, 36, 58, .1)'
      : 'rgba(255, 255, 255, .1)';
  }

  /*
   * Sets updated lineStyle based on current theme mode, gets called upon theme mode change
   */
  public setGraphTheme() {
    if (this.chartInstance) {
      this.chartInstance.setOption({
        yAxis: {
          splitLine: {
            lineStyle: {
              color: this.lineColourRGB,
            },
          },
        },
      });
    }
  }

  private initChart(): void {
    let years: number[] = [];
    let totalIns: number[] = [];
    let withdrawals: number[] = [];

    const summary = this.transactionsSummary;
    if (!summary) {
      console.warn('plan-performance-graph: no data was provided');
    } else {
      const items = summary.items;

      // Sort items by TaxYear (if necessary)
      items.sort((a, b) => a.taxYear - b.taxYear);

      // Fill the data arrays by extracting values from the items payload
      items.forEach((item) => {
        years.push(Math.floor(item.taxYear));
        totalIns.push(Math.floor(item.taxYearContributions));
        withdrawals.push(Math.floor(Math.abs(item.taxYearWithdrawals)));
      });
    }

    // Patch the options data
    const trimArrayFn = (data: number[]) => {
      while (data.length > this.kLastYearsN) {
        data.shift();
      }
      return data;
    };
    this.options.xAxis[0].data = trimArrayFn(years);
    this.options.series[0].data = trimArrayFn(totalIns);
    this.options.series[1].data = trimArrayFn(withdrawals);

    // Initialize the chart and store the instance
    this.chartInstance = echarts.init(this.chartElement.nativeElement);
    this.chartInstance.setOption(this.options);
    connect([this.chartInstance]);
  }

  private handleResizeEvent(): void {
    fromEvent(window, 'resize')
      .pipe(throttleTime(100))
      .subscribe(() => this.onResize());
  }

  private onResize(): void {
    if (this.chartInstance) {
      this.chartInstance.resize();
    }
  }
}
