import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Member, SavingsPlan } from '@app/models/member-portal';

type RouteStateData = { plan: SavingsPlan };

@Component({
  selector: 'app-ctf-request-submitted',
  templateUrl: './ctf-request-submitted.component.html',
  styleUrls: ['./ctf-request-submitted.component.scss'],
})
export class CTFRequestSubmittedComponent {
  public plan: SavingsPlan;

  public readonly url = {
    HOME: `/home`,
  };

  constructor(public router: Router) {
    this.loadPlanFromRoute();
  }

  /**
   * Retrieves plan from the route state, if it does not exist
   * redirect back to home page.
   */
  private loadPlanFromRoute(): void {
    const state = this.router.getCurrentNavigation()?.extras?.state as RouteStateData;

    if (state) {
      this.plan = state.plan;
    } else {
      this.router.navigate([this.url.HOME]);
    }
  }
}
