import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { StorageKey } from '@app/models/security';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addAuthToken(request));
  }

  addAuthToken(request: HttpRequest<any>): HttpRequest<any> {
    let authToken = this.cookieService.get(StorageKey.SF_ACCESS_TOKEN);
    if (authToken && authToken !== null) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    } else {
      return request;
    }
  }
}
