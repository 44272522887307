import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPassword } from '@app/models/security';
import { RegexUtility } from '@app/shared/utilities';
import { take } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  private code: string;
  private email: string;
  private newUser: string;
  private returnUrl: string = '/log-in';

  public submitted: boolean = false;
  public validated: boolean = false;

  public resetPasswordForm: FormGroup;
  public emailCtrl: AbstractControl;
  public codeCtrl: AbstractControl;
  public passwordCtrl: AbstractControl;
  public passwordConfirmCtrl: AbstractControl;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.code = params['code'];
      this.email = params['email'];
      this.newUser = params['new'];
      this.returnUrl = params['returnurl'] || this.returnUrl;
    });
  }

  ngOnInit(): void {
    this.buildForm();
    this.emailCtrl = this.resetPasswordForm.controls['email'];
    this.codeCtrl = this.resetPasswordForm.controls['code'];
    this.passwordCtrl = this.resetPasswordForm.controls['password'];
    this.passwordConfirmCtrl = this.resetPasswordForm.controls['confirmPassword'];
  }

  /**
   * Submits new password, email and one time pass code to reset password service
   */
  public onSubmit(): void {
    this.submitted = true;

    if (this.resetPasswordForm.valid) {
      const data = this.resetPasswordForm.value as ResetPassword;

      this.authService
        .resetPassword(data)
        .pipe(take(1))
        .subscribe((response) => this.router.navigate([this.returnUrl]));
    }
  }

  /**
   * Validator to ensure both email fields match
   * @param {AbstractControl} [control]
   */
  private validatePasswordsMatch(control: AbstractControl): { passwordMismatch: boolean } | null {
    return control.value.password === control.value.confirmPassword
      ? null
      : { passwordMismatch: true };
  }

  /**
   * Validator to ensure both password field strength
   * @param {AbstractControl} [control]
   */
  private validatePasswordStrength(control: AbstractControl): { passwordStrength: boolean } | null {
    const password = control.value.password;
    return !!RegexUtility.strongPassword.test(password) ? null : { passwordStrength: true };
  }

  /**
   * Builds the form configuration with angulars form builder service
   */
  private buildForm(): void {
    this.resetPasswordForm = this.fb.group({
      email: [this.email, Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      code: [this.code, Validators.required],
    });

    this.resetPasswordForm.setValidators([
      this.validatePasswordsMatch,
      this.validatePasswordStrength,
    ]);
  }
}
