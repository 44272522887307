import { Injectable } from '@angular/core';

import { AppConstantsService } from './app-constants.service';
import { HttpService } from './http.service';

import { Observable } from 'rxjs';

import { Payment, PaymentResult } from '@app/models/payments';

@Injectable()
export class PaymentService {
  readonly api = {
    STRIPE_PAYMENT: `${this.configuration.server}/api/payments/RecordStripePayment`,
    STRIPE_WALLET_PAYMENT: `${this.configuration.server}/api/payments/RecordStripeWalletPayment`,
    PERFORM_TAKE_DEPOSIT: (dataId: number, paymentMethodId: string) =>
      `${this.configuration.server}/api/payments/takeDeposit/${dataId}/${paymentMethodId}`,
  };

  constructor(private http: HttpService, private configuration: AppConstantsService) {}

  /**
   * `POST` payment payload and return response
   * @param {Payment} [data]
   */
  recordStripePayment(data: Payment): Observable<PaymentResult> {
    return this.http.post<PaymentResult>(this.api.STRIPE_PAYMENT, data);
  }

  /**
   * `POST` wallet payment payload and return response
   * @param {Payment} [data]
   */
  recordStripeWalletPayment(data: Partial<Payment>): Observable<PaymentResult> {
    return this.http.post<PaymentResult>(this.api.STRIPE_WALLET_PAYMENT, data);
  }

  /**
   * Sends a `GET` request to initiate an initial deposit on a plan using the provided plan and payment IDs.
   *
   * @param {number} planId - The ID of the plan to deposit into.
   * @param {string} paymentId - The payment ID associated with the deposit.
   */
  performTakeDeposit(planId: number, paymentId: string): Observable<PaymentResult> {
    return this.http.get<PaymentResult>(this.api.PERFORM_TAKE_DEPOSIT(planId, paymentId));
  }
}
