<div
  class="sf-card p-4 text-center md:flex md:flex-col md:justify-center md:p-6 xl:p-8 h-[calc(100vh-264px)] items-center {{
    customClass
  }}"
>
  <span *ngIf="icon">
    <i [class]="icon" [ngStyle]="{ 'font-size': iconSize, color: iconColor }"></i>
  </span>

  <div
    class="w-auto h-[100px] max-w-24 max-h-24 justify-self-center"
    [innerHTML]="svg | safe"
  ></div>
  <h2 class="text-2xl my-2" [ngClass]="titleStyle">
    {{ title }}
  </h2>

  <p *ngIf="message">{{ message }}</p>
  <ng-content></ng-content>
  <ng-content></ng-content>
  <app-button
    *ngIf="!isButtonOff"
    [text]="button_text"
    [buttonStyle]="button_style"
    [btnSmall]="button_small"
    [alignRight]="button_alignRight"
    [noBorder]="button_noBorder"
    [isButtonShadow]="button_isShadow"
    [customClass]="button_customClass"
    [isSubmitButton]="button_isSubmit"
    [icon]="button_icon"
    [fullWidth]="button_fullWidth"
    (buttonClick)="handleButtonClick()"
    (secondButtonClick)="handleSecondButtonClick()"
  ></app-button>

  <p *ngIf="additionalContent" class="pt-4 mb-0">
    <a (click)="handleLinkClick()">{{ additionalContent }}</a>
  </p>
</div>
