<app-content-header
  *ngIf="plan"
  [title]="'My Stocks and Shares ISA'"
  [subtitle]="plan.formattedPlanNumber"
></app-content-header>

<section *ngIf="plan">
  <app-plan-overview [plan]="plan" [fullView]="true"></app-plan-overview>
  <div class="mt-4 md:mt-6 xl:mt-8">
    <h2 class="mb-3 text-primary-sf-navy dark:text-sf-white">Quick actions</h2>
    <div class="md:grid md:grid-cols-2 md:auto-rows-fr md:gap-6">
      <app-quick-action-button
        *ngIf="plan.isIssued"
        (click)="navigateWithState(url.TOP_UP(plan.formattedPlanNumber))"
        [quickActionBtnClass]="'my-4 md:my-0'"
        [quickActionBtnIcon]="'icon-make-deposit-min'"
        [quickActionBtnAlt]="'Make a deposit icon'"
        [quickActionBtnText]="'Make a deposit'"
      ></app-quick-action-button>

      <app-quick-action-button
        routerLink="{{
          url.TRANSACTIONS({
            planId: plan.planId.toString(),
            formattedPlanNumber: plan.formattedPlanNumber,
          })
        }}"
        [quickActionBtnClass]="'my-4 md:my-0'"
        [quickActionBtnIcon]="'icon-view-transactions-min'"
        [quickActionBtnAlt]="'View transactions icon'"
        [quickActionBtnText]="'View transactions'"
      ></app-quick-action-button>

      <app-quick-action-button
        *ngIf="!hasDirectDebit"
        (click)="navigateWithState(url.DIRECT_DEBIT)"
        [quickActionBtnClass]="'my-4 md:my-0'"
        [quickActionBtnIcon]="'icon-setup-dd-min'"
        [quickActionBtnAlt]="'Setup direct debit icon'"
        [quickActionBtnText]="'Set up Direct Debit'"
      ></app-quick-action-button>

      <app-quick-action-button
        *ngIf="hasDirectDebit"
        (click)="navigateWithState(url.DIRECT_DEBIT_VARY, { directDebit })"
        [quickActionBtnClass]="'my-4 md:my-0'"
        [quickActionBtnIcon]="'icon-setup-dd-min'"
        [quickActionBtnAlt]="'Manage direct debit icon'"
        [quickActionBtnText]="'Manage Direct Debit'"
      ></app-quick-action-button>

      <app-quick-action-button
        routerLink="{{ url.DOCUMENTS | interpolate : plan.formattedPlanNumber }}"
        [quickActionBtnClass]="'my-4 md:my-0'"
        [quickActionBtnIcon]="'icon-view-documents-min'"
        [quickActionBtnAlt]="'View documents icon'"
        [quickActionBtnText]="'View documents'"
      ></app-quick-action-button>
    </div>
  </div>

  <div class="mt-4 md:mt-6 xl:mt-8">
    <h2 class="mb-3 text-primary-sf-navy dark:text-sf-white">Plan Performance</h2>
    <app-plan-performance-graph
      [transactionsSummary]="transactionsSummary"
    ></app-plan-performance-graph>
  </div>

  <!-- TODO: For after day 1 release -->
  <div class="mt-4" *ngIf="false">
    <h2>Plan Performance</h2>
    <app-plan-performance-graph
      [transactionsSummary]="transactionsSummary"
    ></app-plan-performance-graph>
    <div class="sf-card text-center mb-2 bg-body">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item">
            <a
              class="nav-link nav-link-graph active"
              id="history-tab"
              data-bs-toggle="tab"
              data-bs-target="#history"
              aria-current="true"
            >
              History
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link nav-link-graph"
              id="remainingAllowance-tab"
              data-bs-toggle="tab"
              data-bs-target="#remainingAllowance"
              aria-current="false"
            >
              Remaining Allowance
            </a>
          </li>
        </ul>
      </div>
      <div>
        <div class="tab-content" id="tab-content">
          <div class="tab-pane fade show active" id="history" role="tabpanel">
            <h5 class="card-title">HISTORY GRAPH HERE</h5>
          </div>
          <div class="tab-pane fade" id="remainingAllowance" role="tabpanel">
            <h5 class="card-title">REMAINING ALLOWANCE GRAPH HERE</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
