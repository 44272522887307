<app-content-header [title]="'My notifications'"></app-content-header>

<div class="sf-card">
  <app-notifcation-meta-info
    [type]="notification.typeId"
    [important]="notification.important"
    date="{{ notification.sentDate | date : 'd MMM y' }}"
    status="STATUS"
    [isDetail]="true"
  ></app-notifcation-meta-info>

  <p class="text-xl font-semibold mb-0">{{ notification.headline }}</p>
  <p class="text-sm">{{ notification.detail }}</p>

  <app-checkbox
    [label]="'Mark this message as unread'"
    [additionalClass]="'py-4'"
    hasBorderTop="true"
    (click)="setNotificationUnread()"
  ></app-checkbox>
</div>
