import { Component } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { VisibilityService } from '@app/services';

@Component({
    selector: 'app-menu-footer',
    templateUrl: './menu-footer.component.html',
    styleUrls: ['./menu-footer.component.scss'],
    standalone: false
})
export class MenuFooterComponent {
  public navigation?: NavigationEnd;

  constructor(public visibilityService: VisibilityService) {}
}
