<div class="flex flex-col justify-center items-center gap-1">
  <button
    type="button"
    aria-label="quick-action-button"
    (click)="navigateTo(quickActionBtnLink)"
    class=" max-md:rounded-full {{
      quickActionBtnClass
    }} md:hover:bg-primary-sf-yellow md:bg-white dark:bg-primary-sf-navy md:px-[13px] md:py-4 md:w-full md:rounded-md md:min-w-[136px] max-md:bg-sf-white max-md:dark:bg-primary-sf-navy max-md:w-[68px] max-md:h-[68px] shadow-sm"
  >
    <i
      class="{{
        quickActionBtnIcon
      }}  text-primary-sf-navy dark:text-sf-white before:text-xl md:before:text-2xl"
    ></i>
    <p class="max-md:hidden font-bold text-primary-sf-navy dark:text-sf-white text-sm mb-0">
      {{ quickActionBtnText }}
    </p>
  </button>
  <p class="md:hidden font-bold text-primary-sf-navy dark:text-sf-white text-sm mb-0">
    {{ quickActionBtnText }}
  </p>
</div>
