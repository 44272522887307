import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginHeaderService {
  private buttonClickSource = new Subject<void>();
  buttonClick$ = this.buttonClickSource.asObservable();

  private showBackBtnSource = new Subject<boolean>();
  showBackBtn$ = this.showBackBtnSource.asObservable();

  emitButtonClick() {
    this.buttonClickSource.next();
  }

  setShowBackBtn(show: boolean) {
    this.showBackBtnSource.next(show);
  }
}
