import { ChangeDetectorRef, Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() additionalClass?: string;
  @Input() hasBorderTop?: string;

  @Input() set disabled(value: boolean) {
    this.isDisabled = value;
    this.changeDetectorRef.markForCheck(); // to ensure Angular detects the change
  }

  @Input() set checked(value: boolean) {
    this.value = value;
    this.changeDetectorRef.markForCheck(); // to ensure Angular detects the change
  }

  value: boolean = false;
  isDisabled: boolean = false;

  onChange = (value: any) => {};
  onTouched = () => {};

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  handleChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.onChange(input.checked);
  }
}
