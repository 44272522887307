import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WithdrawalProjection } from '@app/models/withdrawal';

@Component({
  selector: 'app-withdrawal-projection',
  templateUrl: './withdrawal-projection.component.html',
  styleUrls: ['./withdrawal-projection.component.scss'],
  standalone: false,
})
export class WithdrawalProjectionComponent implements OnInit, OnChanges {
  @Input() withdrawalProjection!: WithdrawalProjection;
  @Input() title!: string;

  // Constants for the graph layout
  private readonly GRAPH_HEIGHT = 300;
  private readonly SECTION_HEIGHT = 22;

  // Helper getter for pixel conversion ratio
  private get poundToPixel(): number {
    return this.GRAPH_HEIGHT / this.withdrawalProjection.valueWithoutWithdrawal;
  }

  // m0: Total DD Contributions Height
  public getTotalDDContributionsHeight(): number {
    return this.withdrawalProjection.balanceAfterWithdrawal > 0
      ? Math.max(
          this.poundToPixel * this.withdrawalProjection.totalDDContributions,
          this.SECTION_HEIGHT
        )
      : 0;
  }

  // m1: Current Balance Height
  public getCurrentBalanceHeight(): number {
    const height = this.poundToPixel * this.withdrawalProjection.currentBalance;
    return height > this.SECTION_HEIGHT ? height : this.SECTION_HEIGHT;
  }

  // m2: Balance After Withdrawal Height
  public getBalanceAfterWithdrawalHeight(): number {
    return this.withdrawalProjection.balanceAfterWithdrawal > 0
      ? Math.max(
          this.poundToPixel * this.withdrawalProjection.balanceAfterWithdrawal,
          this.SECTION_HEIGHT
        )
      : 0;
  }

  // m3: Interest Without Withdrawal Height
  public getInterestWithoutWithdrawalHeight(): number {
    const height = this.poundToPixel * this.withdrawalProjection.interestWithoutWithdrawal;
    return height > this.SECTION_HEIGHT ? height : this.SECTION_HEIGHT;
  }

  // m4: Interest With Withdrawal Height
  public getInterestWithWithdrawalHeight(): number {
    return this.withdrawalProjection.balanceAfterWithdrawal > 0
      ? Math.max(
          this.poundToPixel * this.withdrawalProjection.interestWithWithdrawal,
          this.SECTION_HEIGHT
        )
      : 0;
  }

  constructor(private currencyPipe: CurrencyPipe) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
}
