import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent<T> {
  @Input() currentStep: string | undefined;
  @Input() enumType: { [key: string]: string } | undefined;
  @Input() customClass?: string;

  public svgNumberPath: string[] = [
    'M11.472 16.734C11.472 16.874 11.584 17 11.738 17H12.942C13.082 17 13.194 16.874 13.194 16.734V7.746C13.194 7.606 13.082 7.48 12.942 7.48H11.962L9.94605 9.174C9.89005 9.23 9.86205 9.314 9.87605 9.426L9.96005 10.112C9.97405 10.238 10.114 10.322 10.24 10.294L11.472 10.028V16.734Z',
    'M9.47795 16.234C9.47795 16.374 9.58995 16.5 9.74395 16.5H15.2599C15.3999 16.5 15.5259 16.374 15.5259 16.234V15.268C15.5259 15.128 15.3999 15.002 15.2599 15.002H12.2359C12.9779 14.064 14.0419 12.748 14.6439 11.894C15.1899 11.11 15.5819 10.536 15.5819 9.626C15.5819 8.114 14.4619 6.84 12.4599 6.84C10.7939 6.84 9.70195 8.212 9.70195 8.212C9.60395 8.324 9.63195 8.492 9.71595 8.576L10.3879 9.248C10.4999 9.36 10.6539 9.36 10.7659 9.248C11.0879 8.898 11.6619 8.422 12.3339 8.422C13.3419 8.422 13.8179 9.066 13.8179 9.696C13.8179 10.172 13.5379 10.718 13.1039 11.25C12.1659 12.496 10.4019 14.792 9.47795 15.912V16.234Z',
    'M9.17978 15.394C9.48778 15.758 10.3418 16.64 12.0918 16.64C14.0238 16.64 15.3118 15.492 15.3118 13.91C15.3118 12.664 14.2758 11.908 13.6178 11.628V11.586C14.2478 11.292 15.0598 10.48 15.0598 9.402C15.0598 7.834 13.7018 6.84 12.0778 6.84C10.5098 6.84 9.44578 8.072 9.44578 8.072C9.34778 8.17 9.33378 8.338 9.44578 8.436L10.1318 9.08C10.2578 9.192 10.4118 9.178 10.5098 9.066C10.7478 8.786 11.1958 8.394 11.9238 8.394C12.6378 8.394 13.2818 8.912 13.2818 9.682C13.2818 10.396 12.6938 10.97 11.5458 10.97H10.7618C10.5938 10.97 10.4958 11.068 10.4958 11.25V12.132C10.4958 12.3 10.6078 12.412 10.7618 12.412H11.5458C12.8058 12.412 13.4778 13.07 13.4778 13.798C13.4778 14.568 12.7498 15.114 11.9938 15.114C11.1398 15.114 10.3138 14.414 10.2718 14.358C10.1598 14.246 10.0338 14.246 9.90778 14.358L9.19378 15.044C9.10978 15.142 9.09578 15.296 9.17978 15.394Z',
    'M9.48023 14.47C9.32623 14.47 9.21423 14.344 9.21423 14.204V13.042L14.2542 6.91C14.2962 6.868 14.3662 6.826 14.4642 6.826H15.2202C15.3602 6.826 15.4862 6.938 15.4862 7.092V13.056H16.6062C16.7602 13.056 16.8722 13.168 16.8722 13.308V14.176C16.8722 14.316 16.7602 14.442 16.6062 14.442H15.4862L15.5002 16.234C15.5002 16.374 15.3742 16.5 15.2342 16.5H14.0722C13.9322 16.5 13.8062 16.374 13.8062 16.234V14.47H9.48023ZM11.0622 13.014H13.8622V9.654L11.0622 13.014Z',
  ];

  getIndexInEnum(value: string): number {
    const enumValues = Object.keys(this.enumType).map((key) => this.enumType[key]);
    return enumValues.indexOf(value);
  }

  enumTypeKeys(): string[] {
    return this.enumType ? Object.keys(this.enumType) : [];
  }

  getStepLabel(step: string): string {
    return this.enumType ? this.enumType[step] : '';
  }

  constructor() {}
}
