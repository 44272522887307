import { Component, Input, OnInit } from '@angular/core';
import { Member, SavingsPlan } from 'src/app/models/member-portal';

@Component({
  selector: 'app-plan-overview',
  templateUrl: './plan-overview.component.html',
  styleUrls: ['./plan-overview.component.scss'],
})
export class PlanOverviewComponent implements OnInit {
  @Input() plan: SavingsPlan;
  @Input() member?: Member;
  @Input() concise?: boolean = false;
  @Input() hasWithdrawals: boolean = true;
  @Input() fullView?: boolean = false;
  @Input() disabled?: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
