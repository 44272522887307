import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeedbackType } from '@app/models/feedback';
import { SavingsPlan } from '@app/models/member-portal';

type RouteStateData = { plan: SavingsPlan; payment: number };

@Component({
  selector: 'app-top-up-thank-you',
  templateUrl: './top-up-thank-you.component.html',
  styleUrls: ['./top-up-thank-you.component.css'],
})
export class TopUpThankYouComponent {
  readonly url = {
    HOME: `/home`,
    PLAN_CHOICE: `/plans/deposit`,
  };
  public readonly topUpFeedbackType: FeedbackType = FeedbackType.TOP_UP;

  public plan: SavingsPlan;
  public payment: number;

  constructor(public router: Router) {
    this.loadPlanDetailsFromRoute();
  }

  /**
   * Retrieves plan / member details data from the route state, if non exists
   * redirect back to the plan choice page.
   */
  private loadPlanDetailsFromRoute(): void {
    const state = this.router.getCurrentNavigation()?.extras?.state as RouteStateData;

    if (state) {
      this.plan = state.plan;
      this.payment = state.payment;
    } else {
      this.router.navigate([this.url.PLAN_CHOICE]);
    }
  }
}
