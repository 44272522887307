import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription, take } from 'rxjs';
import { Transactions } from 'src/app/models/member-portal';
import { TransactionsService, VisibilityService } from 'src/app/services';
import { TransactionTypeOptions } from 'src/app/services/transactions.service';

@Component({
  selector: 'app-transactions-home',
  templateUrl: './transactions-home.component.html',
  styleUrls: ['./transactions-home.component.scss'],
  standalone: false,
})
export class TransactionsHomeComponent implements OnInit, OnDestroy {
  @Input() transactions?: Transactions;
  private _formattedPlanNumber: string;

  @Input() set formattedPlanNumber(value: string) {
    this._formattedPlanNumber = value.toUpperCase();
  }

  get formattedPlanNumber(): string {
    return this._formattedPlanNumber;
  }

  private subscribeUntilDestroyed = new Subscription();
  public isDateFilterOpen: boolean = false;
  public transactionType = TransactionTypeOptions.All;
  public transactionTypeOpts = TransactionTypeOptions;

  public dateFilterForm: FormGroup;
  public startDateCtrl: AbstractControl;
  public endDateCtrl: AbstractControl;

  public page: number = 0;

  public date;

  constructor(
    private transactionsService: TransactionsService,
    private formBuilder: FormBuilder,
    private visibilityService: VisibilityService
  ) {}

  ngOnInit(): void {
    this.createDateObject();
    this.buildForm();

    this.startDateCtrl = this.dateFilterForm.get('start');
    this.endDateCtrl = this.dateFilterForm.get('end');
  }

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  get paginationSize(): number {
    return this.visibilityService.isLgOrAbove ? 11 : 5;
  }

  /**
   * Compare current index date to previous index date to check if they have the same day/month/year
   * @param {number} [index]
   * @param {string} [date]
   */
  public isSameDayTransaction(index: number, date: string) {
    if (index === 0) return false;

    const currentTransactionDate = this.subStringDate(date);
    const previousTransactionDate = this.subStringDate(
      this.transactions.items[index - 1].transactionDate
    );

    return currentTransactionDate === previousTransactionDate;
  }

  /**
   * Callback method for when a page event is fired
   * @param {{ page: number }} [event]
   */
  public pageChanged(event: { page: number }): void {
    this.page = event.page;
    this.loadTransactions();
  }

  /**
   * Callback method for when a transaction type change event is fired
   * @param {TransactionTypeOptions} [event]
   */
  public transactionTypeChange(event: TransactionTypeOptions = this.transactionType): void {
    this.page = 0;
    this.transactionType = event;
    this.loadTransactions();
  }

  /**
   * Callback method for when a transaction date change event is fired
   */
  public transactionDateChange(): void {
    this.page = 0;
    this.loadTransactions();
  }

  /**
   * Resets the date filter form values
   */
  public resetDateFilterForm(): void {
    this.dateFilterForm.patchValue(
      {
        start: undefined,
        end: undefined,
      },
      { emitEvent: false }
    );

    this.transactionDateChange();
  }

  /**
   * Toggles and resets the date filter form values
   */
  public toggleDateFilterAndReset(): void {
    this.isDateFilterOpen = !this.isDateFilterOpen;
    this.resetDateFilterForm();
  }

  /**
   * Builds the form configuration with angulars form builder service
   */
  private buildForm(): void {
    this.dateFilterForm = this.formBuilder.group({
      start: undefined,
      end: undefined,
    });
  }

  /**
   * Return the first section of a Date string, ie, 01-01-2000
   * @param {string} [str]
   */
  private subStringDate(str: string): string {
    return str.substring(0, 10);
  }

  /**
   * Sets a date property on the component class with a formatted todays date
   */
  private createDateObject(): void {
    const today = new Date();

    // Force a leading zero on the month, and increment by 1
    const getMonth = (date: Date) => {
      const month = date.getMonth() + 1; // Months are index based
      return month < 10 ? '0' + month : '' + month;
    };

    var dd = today.getDate();
    var mm = getMonth(today);
    var yyyy = today.getFullYear();

    this.date = {
      today: `${yyyy}-${mm}-${dd}`,
    };
  }

  /**
   * Retrieves transaction data from the transactions service
   */
  private loadTransactions(): void {
    this.transactionsService
      .getTransactions(String(this.transactions.planDataId), {
        page: this.page,
        type: this.transactionType,
        ...this.dateFilterForm.value,
      })
      .pipe(take(1))
      .subscribe((response: Transactions) => (this.transactions = response));
  }
}
