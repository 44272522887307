import { Component, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MemberProfile } from '@app/models/profile';
import { AppConstantsService, AuthService, FidoService } from '@app/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile-home',
  templateUrl: './profile-home.component.html',
  styleUrls: ['./profile-home.component.scss'],
})
export class ProfileHomeComponent implements OnDestroy {
  @Input() memberProfile?: MemberProfile;

  public readonly url = {
    CHANGE_EMAIL: `/profile/change/email`,
    CHANGE_PASSWORD: `/profile/change/password`,
    CHANGE_ADDRESS: `/profile/change/address`,
    CHANGE_MOBILE_NUMBER: `/profile/change/mobile-number`,
  };

  private subscribeUntilDestroyed = new Subscription();
  public isWebAuthnAvailable: boolean;
  public isDeviceRegistered: boolean;

  constructor(
    public appConstants: AppConstantsService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public auth: AuthService,
    public fido: FidoService
  ) {}

  ngOnInit(): void {
    this.loadMemberProfileFromRoute();
    this.isWebAuthnAvailable = this.fido.isWebAuthnAvailable;
    this.isDeviceRegistered = this.fido.isDeviceRegistered(this.auth.loginVarUserName);
  }

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  // get switchModeLabel(): string {
  //     return this.themeService.mode === Mode.LIGHT ? 'Dark' : 'Light';
  // }

  public refresh(): void {
    window.location.reload();
  }

  /**
   * Navigates to a url and passes member profile object in router state
   * @param {string} [url] the url to navigate to
   */
  public navigateWithState(url: string): void {
    this.router.navigate([url], {
      state: {
        memberProfile: this.memberProfile,
      },
    });
  }

  public get formattedCurrentAddress(): string {
    return [
      this.memberProfile.address.address1,
      this.memberProfile.address.address2,
      this.memberProfile.address.address3,
      this.memberProfile.address.address4,
      this.memberProfile.address.postCode.toUpperCase(),
    ]
      .filter(Boolean)
      .join('\r\n');
  }

  /**
   * Retrieves member profile data from the activated route data
   */
  private loadMemberProfileFromRoute(): void {
    this.subscribeUntilDestroyed.add(
      this.activatedRoute.data.subscribe((routeData) => {
        this.memberProfile = routeData['memberProfile'];
      })
    );
  }
}
