import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { FeedbackType, FeedbackUrls } from '@app/models/feedback';
import { VisibilityService } from '@app/services/visibility.service'; // Adjust path as needed
import { UrlUtility } from '@app/shared/utilities';

declare function interop_android(): boolean;
declare function interop_ios(): boolean;

@Injectable()
export class FeedbackService {
  constructor(private auth: AuthService, private visibilityService: VisibilityService) {}

  /**
   * Returns a generated url string to provide feedback for the current page.
   */
  public createFeedbackURL(
    feedbackType: FeedbackType,
    additionalData?: { [key: string]: string }
  ): string {
    const member = this.auth.loginVarUserName;
    const title = this.extractTitle();
    const url = document.location.pathname;
    const returnUrl = document.location.href;
    const clientID = this.getClientID();
    const breakpoint = this.getBreakpointLabel();

    const paramsData = {
      member_id: member,
      page_title: title,
      page_url: url,
      return_url: returnUrl,
      client_id: clientID,
      breakpoint: breakpoint,
      ...additionalData,
    };

    const searchParams = new URLSearchParams(paramsData);

    const feedbackUrl = `${this.getFeedbackUrl(feedbackType)}?${searchParams.toString()}`;

    return feedbackUrl;
  }

  /**
   * Navigates to the external feedback URL based on the provided feedback type.
   */
  public navigateToFeedbackUrl(
    feedbackType: FeedbackType,
    additionalData?: { [key: string]: string }
  ): void {
    const feedbackUrl = this.createFeedbackURL(feedbackType, additionalData);
    UrlUtility.goToLink(feedbackUrl);
  }

  // Returns the ClientID based on the platform.
  private getClientID(): string {
    if (typeof interop_android === 'function' && interop_android()) {
      return 'sf-app-android';
    } else if (typeof interop_ios === 'function' && interop_ios()) {
      return 'sf-app-ios';
    } else {
      return 'sf-app';
    }
  }

  // Returns the current Breakpoint based on the viewport width.
  private getBreakpointLabel(): string {
    const width = window.innerWidth;
    if (width >= this.visibilityService.breakpoints.full) return 'full';
    if (width >= this.visibilityService.breakpoints.xxl) return '2xl';
    if (width >= this.visibilityService.breakpoints.xl) return 'xl';
    if (width >= this.visibilityService.breakpoints.lg) return 'lg';
    if (width >= this.visibilityService.breakpoints.md) return 'md';
    return 'default';
  }

  // Returns a feedback URL based on the provided feedback type, or fall back to the generic feedback URL
  private getFeedbackUrl(feedbackType: FeedbackType): string {
    if (feedbackType && FeedbackUrls[feedbackType]) {
      return FeedbackUrls[feedbackType]();
    }

    // Fallback to a generic feedback URL
    return FeedbackUrls[FeedbackType.GENERIC]();
  }

  private extractTitle(): string {
    const titleElements = document.getElementsByTagName('h1');
    return (
      Array.from(titleElements)
        .find((el) => el.textContent.trim())
        ?.textContent.trim() ?? ''
    );
  }
}
