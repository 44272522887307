import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

interface WithdrawalQuestion {
  title: string;
  message: string;
  type: 'radio' | 'checkbox' | 'info';
  formControlName?: string;
}

@Component({
  selector: 'app-withdrawal-friction-questions',
  templateUrl: './withdrawal-friction-questions.component.html',
  styleUrls: ['./withdrawal-friction-questions.component.scss'],
  standalone: false,
})
export class WithdrawalFrictionQuestionsComponent implements OnInit, OnDestroy {
  private subscribeUntilDestroyed = new Subscription();

  public form: FormGroup;
  public step: number;
  public questions: WithdrawalQuestion[] = [
    {
      title: 'Confirm Your Withdrawal',
      message:
        'You are about to request a withdrawal from your Stocks and Shares ISA. Withdrawing funds may have tax implications and could affect your investment growth. Are you sure you want to proceed?',
      type: 'radio',
      formControlName: 'q1',
    },
    {
      title: 'Safeguarding',
      message:
        'Is someone pressuring you to make this withdrawal? If someone has contacted you and asked you to make a withdrawal, it could be a scam. Stop and think before continuing. If you have recently been given any financial advice to withdraw funds please ensure that you check the FCA register to ensure the adviser or firm is authorised to provide investment advice.',
      type: 'info',
    },
    {
      title: 'Impact on Investment Growth',
      message:
        'By withdrawing funds, you may miss out on potential investment growth. The funds you withdraw will no longer benefit from the tax advantages of ISA rules, and you could lose out on future bonuses.',
      type: 'info',
    },
    {
      title: 'Review Current Investments',
      message:
        'It’s important to review your current investments before making a withdrawal. Ensure that you are not withdrawing investments at a loss or disrupting your long-term investment strategy. It may be beneficial to use any instant access funds before considering this withdrawal.',
      type: 'radio',
      formControlName: 'q4',
    },
    {
      title: 'Confirm Your Understanding',
      message:
        'Please confirm that you understand the implications of this withdrawal, including that tax benefits of an ISA can’t be regained once funds are removed, the impact on investment growth, and the possibility of selling investments at a loss.',
      type: 'checkbox',
      formControlName: 'q5',
    },
    {
      title: 'Final Confirmation',
      message:
        'You are about to complete your withdrawal request. Once this has been processed, it cannot be undone. Do you wish to proceed?',
      type: 'radio',
      formControlName: 'q6',
    },
  ];

  @Output() complete = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<void>();

  /**
   * Emits when any radio question is answered "no".
   */
  @Output() decline = new EventEmitter<void>();

  constructor(private fb: FormBuilder, public router: Router) {
    this.step = 0;
    this.buildForm();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  /**
   * Builds the form with controls for the questions that require user input.
   */
  private buildForm(): void {
    this.form = this.fb.group({
      // Question 1, 4, and 6 require a radio selection.
      q1: [null, Validators.required],
      q4: [null, Validators.required],
      q6: [null, Validators.required],
      // Question 5 requires a checkbox.
      q5: [false, Validators.requiredTrue],
    });
  }

  /**
   * Determines whether the Continue button should be disabled.
   * If the current question requires an answer, the button will be disabled until a valid answer is provided.
   * @param question The current question.
   * @returns True if the Continue button should be disabled.
   */
  isContinueDisabled(question: WithdrawalQuestion): boolean {
    if (question.formControlName) {
      const control = this.form.get(question.formControlName);
      return control ? control.invalid : false;
    }
    // If no control is required (informational step), enable the button.
    return false;
  }

  /**
   * Handles clicking on the Continue button.
   * If the current radio question is answered "no", emits the decline event.
   * Otherwise, advances to the next step or submits the form.
   */
  onContinue(): void {
    const currentQuestion = this.questions[this.step];
    // Validate the current answer
    if (currentQuestion.formControlName) {
      const control = this.form.get(currentQuestion.formControlName);
      if (control && control.invalid) {
        control.markAsTouched();
        return; // Prevent moving to next step if invalid.
      }
      // If the current question is a radio type and the answer is "no", emit decline.
      if (currentQuestion.type === 'radio' && control.value === 'no') {
        this.decline.emit();
        return;
      }
    }
    // If we’re not on the final step, move to the next step.
    if (this.step < this.questions.length - 1) {
      this.step++;
    } else {
      this.onSubmit();
    }
  }

  /**
   * Emits the cancel event.
   */
  onCancel(): void {
    this.cancel.emit();
  }

  /**
   * Submits the form if it is valid and emits the complete event.
   * Otherwise, marks all controls as touched to display validation errors.
   */
  onSubmit(): void {
    if (this.form.valid) {
      this.complete.emit(this.form.value);
    } else {
      Object.keys(this.form.controls).forEach((field) => {
        const control = this.form.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }
}
