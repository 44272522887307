import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { MemberPortalService } from '@app/services';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountVerifiedGuard implements CanActivate, CanActivateChild {
  public readonly url = {
    ADDRESS_VERIFICATION: '/address-verification',
    ACCOUNT_PROBLEM: '/account-problem',
    LOG_OUT: '/log-out',
  };

  constructor(private memberPortalService: MemberPortalService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // Allow access if already on the address verification, account problem, or log-out page
    if (
      state.url === this.url.ADDRESS_VERIFICATION ||
      state.url === this.url.ACCOUNT_PROBLEM ||
      state.url === this.url.LOG_OUT
    ) {
      return new Observable<boolean>((observer) => observer.next(true));
    }

    return this.checkVerificationRequirement();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // Allow access if already on the address verification, account problem, or log-out page
    if (
      state.url === this.url.ADDRESS_VERIFICATION ||
      state.url === this.url.ACCOUNT_PROBLEM ||
      state.url === this.url.LOG_OUT
    ) {
      return new Observable<boolean>((observer) => observer.next(true));
    }

    return this.checkVerificationRequirement();
  }

  private checkVerificationRequirement(): Observable<boolean> {
    return this.memberPortalService.getMemberDashboard().pipe(
      map((memberDashboard) => {
        // Check if address verification is required first
        const requiresVerification = memberDashboard.registrant?.awaitingAddressVerification;
        if (requiresVerification) {
          // Redirect to the address verification page if needed
          this.router.navigate([this.url.ADDRESS_VERIFICATION]);
          return false;
        }

        // If address verification is not needed, check if member data is missing
        if (!memberDashboard.hasMember()) {
          // Redirect to the account problem page if data is missing
          this.router.navigate([this.url.ACCOUNT_PROBLEM]);
          return false;
        }

        // If no issues, allow access
        return true;
      })
    );
  }
}
