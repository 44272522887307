<ng-container *ngIf="isCTF; else nonCTFContent">
  <app-content-header
    [title]="'Child Trust Fund (CTF)'"
    [subtitle]="'Registered contact sign-up'"
    customClass="text-sf-white"
  />
  <app-card-with-image
    title="Please confirm your email address"
    titleStyle="text-sf-white"
    imageSrc="doing-the-right-thing-white"
    imageAlt="doing-the-right-thing-successful"
    customClass="sf-card--ctf"
  >
    <div>
      <p class="text-xs">
        <strong class="text-base font-bold">
          We've sent you an email with a verification link to activate your account.
        </strong>
      </p>
      <p class="text-xs">
        If you experience any issues, please get in touch with our Member Services team on 0800 526
        249.
      </p>
      <app-button
        text="Log in here"
        buttonStyle="primary"
        [routerLink]="url.LOGIN()"
        [isSubmitButton]="false"
        customClass="mt-4"
      />
      <app-button
        text="Resend verification email"
        buttonStyle="secondary"
        [isSubmitButton]="false"
        (buttonClick)="resendRegistrationEmail()"
        customClass="mt-4"
      />
      <br />
      <div *ngIf="resentEmail">
        <p>
          We have re-sent your verification email to:
          {{ emailAddress }}
        </p>
      </div>
    </div>
  </app-card-with-image>
</ng-container>

<ng-template #nonCTFContent>
  <div class="sf-card sf-card--ctf">
    <h3 class="text-2xl font-bold">Account created successfully</h3>
    <p class="lead mt-4">Thank you for registering.</p>
    <p>You're nearly ready to view your account online - there's just one last step.</p>

    <hr />
    <p class="lead mt-4 font-bold">Confirm your details</p>
    <p>
      Please verify your account by following the instructions in the email we've just sent you.
      Haven't received it yet? Check your spam or junk folder or try again by clicking 'Resend
      confirmation email' below.
    </p>
    <p>
      If you're having trouble verifying or accessing your online account, please call our Member
      Services Team on
      <strong>0800 526 249</strong>
      or via email:
      <a href="mailto:members@shepherdsfriendly.co.uk">members&#64;shepherdsfriendly.co.uk</a>
    </p>
    <app-button
      text="Return to log in"
      buttonStyle="primary"
      [routerLink]="url.LOGIN()"
      [isSubmitButton]="false"
      customClass="mt-4"
    />
    <app-button
      text="Resend confirmation email"
      buttonStyle="secondary"
      [isSubmitButton]="false"
      (buttonClick)="resendRegistrationEmail()"
      customClass="mt-4"
    />
    <br />
    <div *ngIf="resentEmail">
      <p>
        We have re-sent your confirmation email to:
        {{ emailAddress }}
      </p>
    </div>
  </div>
</ng-template>
