<section id="loginTitle">
  <div class="flex justify-content-center">
    <!-- <img src="/assets/images/logo.svg" alt="Shepherds Friendly logo" routerLink="/home"> -->
  </div>
</section>

<section id="loginHeroImage" class="mt-4 mb-4 pt-4 pb-4">
  <div (click)="fillEmailAddressAndPassword()">
    <div class="flex justify-content-center">
      <img src="/assets/images/signpost.svg" alt="Shepherds Friendly, Simplicity over complexity" />
    </div>
  </div>
</section>

<section id="loginContent">
  <div id="loginContentFormContainer">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="mb-4">
        <label for="username" class="form-label">Email address</label>
        <div class="input-group mb-4">
          <input
            type="text"
            class="form-control"
            id="username"
            name="username"
            placeholder="Email address"
            required
            formControlName="username"
            [ngClass]="{
              'is-invalid': submitted && f['username'].errors,
            }"
          />
        </div>
        <div *ngIf="submitted && f['username'].errors">
          <app-validation-message
            *ngIf="f['username'].errors['required']"
            message="Please enter your email address"
          ></app-validation-message>

          <app-validation-message
            *ngIf="f['username'].errors['email']"
            message=" Please enter a valid email address"
          ></app-validation-message>
        </div>
      </div>

      <div class="mb-4">
        <label for="password" class="form-label">Password</label>
        <div class="input-group mb-4">
          <input
            type="password"
            class="form-control"
            id="password"
            name="password"
            placeholder="Password"
            autocomplete="off"
            required
            formControlName="password"
            [ngClass]="{
              'is-invalid': submitted && f['password'].errors,
            }"
          />
        </div>
        <div *ngIf="submitted && f['password'].errors" class="invalid-feedback block">
          <div *ngIf="f['password'].errors['required']">Please enter your password</div>
        </div>
      </div>

      <app-alert
        *ngIf="submitted && sserror"
        type="warning"
        iconClass="fa fas fa-exclamation-triangle"
        additionalClass="mb-4"
        message="Either your email address or password are
                            incorrect, please try again"
      ></app-alert>

      <div class="grid gap-2 mt-4">
        <app-button
          text="Log in"
          buttonStyle="primary"
          [isSubmitButton]="true"
          routerLink="/log-in"
        ></app-button>
      </div>
    </form>

    <a class="btn-link" routerLink="/forgotten-password">Forgotten your password?</a>
  </div>
</section>
