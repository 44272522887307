import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MemberDashboard, SavingsPlan } from 'src/app/models/member-portal';

@Component({
  selector: 'app-ssi-fund-performance-calculator',
  templateUrl: './ssi-fund-performance-calculator.component.html',
  styleUrls: ['./ssi-fund-performance-calculator.component.scss'],
  standalone: false,
})
export class SSIFundPerformanceCalculatorComponent implements OnInit, OnDestroy {
  @Input() ssiDetails?: SavingsPlan;
  @Input() memberDashboard?: MemberDashboard;

  public ssiFundPerformanceCalculatorForm: FormGroup;
  public projectionInYearsCtrl: AbstractControl;
  private subscribeUntilDestroyed = new Subscription();

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.buildForm();

    // Cache form controls
    this.projectionInYearsCtrl =
      this.ssiFundPerformanceCalculatorForm.controls['projectionInYears'];
  }

  /**
   * Builds the form configuration with angulars form builder service
   */
  private buildForm(): void {
    this.ssiFundPerformanceCalculatorForm = this.fb.group({
      projectionInYears: ['', Validators.compose([Validators.required])],
    });
  }

  public async onSubmit(form: FormGroupDirective): Promise<void> {
    let raiseError = (fn) => {
      fn();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    if (form.valid) {
    }
  }

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }
}
