import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

const { version: appVersion } = require('/package.json');

@Injectable()
export class AppConstantsService {
  public server: string;
  public version: string;

  constructor() {
    this.server = environment.apiKeys.sfApiURL;
    this.version = appVersion;
  }

  public isLocal(): boolean {
    return document.location.hostname === 'localhost';
  }
}
