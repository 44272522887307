import { Component, OnInit } from '@angular/core';
import { ScriptLoaderService, YbugService } from '@app/services';

declare global {
  interface Window {
    ybug_settings: {
      id: string;
    };
  }
}

@Component({
  selector: 'app-ybug',
  template: '',
})
export class YbugComponent implements OnInit {
  constructor(
    private ybugService: YbugService,
    private scriptLoader: ScriptLoaderService
  ) {}

  ngOnInit(): void {
    const ybugId = this.ybugService.ybugId();
    const showYbug = this.ybugService.showYbug();

    if (showYbug) {
      this.scriptLoader
        .loadScript(`https://widget.ybug.io/button/${ybugId}.js`)
        .catch((error) => console.error('Failed to load Ybug script', error));
    }
  }
}
