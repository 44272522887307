import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-login-info-message',
  templateUrl: './login-info-message.component.html',
  styleUrls: ['./login-info-message.component.scss'],
})
export class LoginInfoMessageComponent {
  @Input() iconClass?: string;
  @Input() title: string;
  @Input() message?: string;
  @Input() additionalClass?: string;
  @Input() imageSrc?: string;
  @Input() imageAlt?: string;
  @Input() showCheckbox?: boolean;
  @Input() imageWidth?: string;
  @Input() imageHeight?: string;

  @Output() checkboxChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() checkboxChecked: EventEmitter<void> = new EventEmitter<void>();

  handleCheckboxChange(): void {
    this.checkboxChange.emit();
  }

  handlecheckboxChecked(): void {
    this.checkboxChecked.emit();
  }
}
