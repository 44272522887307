<section id="registerHomeTitle" class="my-4 mx-0">
  <h1>Register to use the app</h1>
</section>

<section id="registerContent">
  <p>
    This is the place you can registered for online access to your Shepherds Friendly plans. It's
    just a few short steps to get started.
  </p>
  <label for="formGroupExampleInput">
    Have you already registered for online access to any other Shepherds Friendly plan you may have?
  </label>
  <div class="btn-group w-100 mt-4">
    <input
      type="radio"
      class="btn-check"
      name="alreadyRegistered"
      id="option1"
      autocomplete="off"
      value="1"
      routerLink="/register-already-registered"
    />
    <label class="btn btn-primary btn-group-label-left" for="option1">Yes</label>

    <input
      type="radio"
      class="btn-check"
      name="alreadyRegistered"
      id="option2"
      autocomplete="off"
      value="0"
      routerLink="/register-choose-plan"
    />
    <label class="btn btn-primary btn-group-label-right" for="option2">No</label>
  </div>
</section>

<nav class="fixed-bottom bg-light -lg pb-4" style="display: block">
  <div class="grid gap-2 p-2">
    <app-button
      text="Next"
      buttonStyle="secondary"
      routerLink="/register-choose-plan"
      [isSubmitButton]="true"
    ></app-button>
  </div>
</nav>
