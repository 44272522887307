import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { combineLatestWith, filter, map } from 'rxjs/operators';
import { LoadingService } from './services/loading.service';
import {
  AuthService,
  LoginHeaderService,
  MemberPortalService,
  ThemeService,
  VisibilityService,
  GoogleAnalyticsService,
} from './services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: false,
})
export class AppComponent implements OnInit {
  public appVersion: string;

  public readonly loading$ = this.loadingService.loading$;

  public readonly authenticated$ = this.authService.authenticated$;
  public readonly hasVerificationRequirement$ =
    this.memberPortalService.hasVerificationRequirement$;
  public readonly isAuthenticatedWithNoVerificationRequirement$: Observable<boolean> =
    this.authenticated$.pipe(
      combineLatestWith(this.hasVerificationRequirement$),
      map(
        ([authenticated, hasVerificationRequirement]) =>
          authenticated && !hasVerificationRequirement
      )
    );

  constructor(
    private swUpdate: SwUpdate,
    private loadingService: LoadingService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private renderer: Renderer2,
    private authService: AuthService,
    private themeService: ThemeService /* Don't remove */,
    private memberPortalService: MemberPortalService,
    private router: Router,
    public visibilityService: VisibilityService,
    private loginHeaderService: LoginHeaderService
  ) {}

  ngOnInit() {
    this.disableScrollOnLoading();
    this.checkHeaderBackBtnVisibility();

    this.googleAnalyticsService.initialiseGTM();

    const updatesAvailable = this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map((evt) => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      }))
    );

    updatesAvailable.subscribe((evt) => {
      if (confirm('Updated version available. Would you like to load the updated version?')) {
        window.location.reload();
      }
    });
  }

  /**
   * Prevents scrolling the app when the loading service has emitted true
   */
  private disableScrollOnLoading(): void {
    this.loading$.subscribe((loading: boolean) => {
      if (loading) {
        this.renderer.setStyle(document.body, 'overflowY', 'hidden');
      } else {
        this.renderer.removeStyle(document.body, 'overflowY');
      }
    });
  }

  public checkHeaderBackBtnVisibility(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const currentRoute = this.router.routerState.root.snapshot.firstChild;
      if (currentRoute && currentRoute.data) {
        const showBackBtn = currentRoute.data['showBackBtn'] || false;
        this.loginHeaderService.setShowBackBtn(showBackBtn);
      }
    });
  }
}
