<div class="rounded gap-1 p-4 bg-sf-white-7-opacity {{ additionalClass }}">
  <input
    *ngIf="showCheckbox"
    type="checkbox"
    class="form-check-input"
    (change)="handleCheckboxChange()"
    [checked]="handlecheckboxChecked()"
  />
  <img
    *ngIf="imageSrc"
    [src]="imageSrc"
    [alt]="imageAlt"
    [width]="imageWidth"
    [height]="imageHeight"
  />

  <i *ngIf="iconClass" class="{{ iconClass }}"></i>
  <p class="text-lg font-bold mb-0">{{ title }}</p>
  <p *ngIf="message" class="col-span-full mb-0">
    {{ message }}
  </p>
  <ng-content></ng-content>
</div>
