<div class="relative">
  <app-quick-action-button-item
    (click)="toggleMenu($event)"
    [quickActionBtnClass]="'relative md:focus:bg-primary-sf-yellow'"
    [quickActionBtnIcon]="'fal fa-ellipsis-h'"
    [quickActionBtnText]="'More'"
  ></app-quick-action-button-item>

  <!-- For isMdOrAbove: display dropdown menu -->
  <div
    *ngIf="visibilityService.isMdOrAbove && isMoreMenuOpen"
    #dropdownMenu
    class="absolute z-50 bg-sf-white dark:bg-primary-sf-navy-darker border border-sf-white/20 shadow-2xl p-6 w-72 rounded-md"
  >
    <ul class="list-none p-0 m-0 flex flex-col gap-4">
      <li
        class="flex gap-2 items-center cursor-pointer"
        *ngFor="let link of links"
        (click)="link.onClick ? link.onClick() : navigateTo(link.url)"
      >
        <i
          class="{{
            link.icon
          }}  bg-sf-background-light dark:bg-sf-background-light/10 w-12 h-12 !flex items-center justify-center rounded-full text-primary-sf-navy dark:text-sf-white before:text-xl"
        ></i>
        <p class="mb-0">{{ link.label }}</p>
      </li>
    </ul>
  </div>

  <!-- For isSmOrBelow: display a dialog menu -->
  <div
    *ngIf="visibilityService.isSmOrBelow && isMoreMenuOpen"
    #dialogMenu
    class="z-50 bg-white p-6 w-full bottom-0 quick-action-button-more__dialog"
  >
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div
        class="fixed inset-0 bg-primary-sf-navy bg-opacity-80 transition-opacity"
        aria-hidden="true"
      ></div>

      <div class="fixed inset-x-0 bottom-[68px] w-full z-10">
        <div class="flex justify-center text-center items-end">
          <div
            class="relative transform overflow-hidden rounded-t-xl transition-all w-full max-w-lg"
          >
            <div class="flex flex-col bg-sf-background-light dark:bg-primary-sf-navy-darker">
              <div class="flex flex-col items-center p-4">
                <div class="w-full items-center flex">
                  <div class="-translate-y-1.5 cursor-grab flex-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="69" height="3" fill="none">
                      <rect width="69" height="3" fill="#9BA5B6" rx="1.5" />
                    </svg>
                  </div>
                  <button
                    class="text-base"
                    (click)="isMoreMenuOpen = false"
                    type="button"
                    aria-label="quick-action-button"
                  >
                    <i class="fa-regular fa-xmark text-2xl relative -top-1.5"></i>
                  </button>
                </div>

                <div
                  class="flex flex-col justify-start gap-5 bg-sf-white dark:bg-primary-sf-navy p-4 rounded-md w-full"
                >
                  <ul class="list-none p-0 m-0 flex flex-col gap-3">
                    <li
                      class="flex items-center gap-2 cursor-pointer"
                      *ngFor="let link of links"
                      (click)="link.onClick ? link.onClick() : navigateTo(link.url)"
                    >
                      <i
                        class="{{
                          link.icon
                        }}  bg-sf-background-light dark:bg-sf-background-light/10 w-12 h-12 !flex items-center justify-center rounded-full text-primary-sf-navy dark:text-sf-white before:text-2xl"
                      ></i>
                      <p class="mb-0 font-bold">{{ link.label }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
