import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-log-out',
    templateUrl: './log-out.component.html',
    styleUrls: ['./log-out.component.css'],
    standalone: false
})
export class LogOutComponent {
  public readonly url = {
    LOG_IN: `/log-in`,
  };

  constructor(private authService: AuthService, public router: Router) {
    this.authService
      .logout()
      .then(() => {
        this.router.navigate([this.url.LOG_IN]);
      })
      .catch((error) => {
        console.error('Error during logout:', error);
      });
  }
}
