<div class="lg:hidden">
  <nav #installInstructions *ngIf="showInstallPrompt">
    <section
      id="ios-install-instructions"
      class="shadow-sm bg-sf-white justify-between contents items-center fixed bottom-0 z-50 w-full text-sf-black py-2 px-4"
    >
      <header class="flex justify-between">
        <h3 class="font-bold mb-1 text-xl">Add our app to your home screen</h3>
        <i (click)="hideInstallPrompt()" class="fa fa-times fa-2x" aria-hidden="true"></i>
      </header>

      <div>
        <p>
          Install the app on your device home screen to easily access it anytime. No app store or
          download required.
        </p>

        <div class="flex items-center">
          <h4 class="mr-1">1 -</h4>
          <span>Tap on the share icon</span>
          <div class="bg-gray-200 px-2 pb-1 inline-block ml-2">
            <svg
              class="fill-current"
              width="18"
              height="auto"
              viewBox="0 0 18 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 7.5H13C12.7 7.5 12.5 7.7 12.5 8C12.5 8.3 12.7 8.5 13 8.5H16.5V23.5H1.5V8.5H5C5.3 8.5 5.5 8.3 5.5 8C5.5 7.7 5.3 7.5 5 7.5H1C0.7 7.5 0.5 7.7 0.5 8V24C0.5 24.3 0.7 24.5 1 24.5H17C17.3 24.5 17.5 24.3 17.5 24V8C17.5 7.7 17.3 7.5 17 7.5Z"
              />
              <path
                d="M5.4 5.4L8.5 2.3V16C8.5 16.3 8.7 16.5 9 16.5C9.3 16.5 9.5 16.3 9.5 16V2.2L12.6 5.3C12.8 5.5 13.1 5.5 13.3 5.3C13.5 5.1 13.5 4.8 13.3 4.6L9.3 0.599997C9.1 0.399997 8.8 0.399997 8.6 0.599997L4.6 4.6C4.4 4.8 4.4 5.1 4.6 5.3C4.8 5.5 5.2 5.5 5.4 5.4Z"
              />
            </svg>
          </div>
        </div>

        <div class="flex items-center mt-2">
          <h4>2 -</h4>
          <span>Tap on</span>
          <div class="bg-gray-200 inline-flex items-center ml-2 py-1 px-2">
            <span class="text-sm mr-2">Add to Home Screen</span>
            <svg
              class="fill-current text-gray-700"
              width="20"
              height="auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                d="M64 64C46.3 64 32 78.3 32 96l0 320c0 17.7 14.3 32 32 32l320 0c17.7 0 32-14.3 32-32l0-320c0-17.7-14.3-32-32-32L64 64zM0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM208 352l0-80-80 0c-8.8 0-16-7.2-16-16s7.2-16 16-16l80 0 0-80c0-8.8 7.2-16 16-16s16 7.2 16 16l0 80 80 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-80 0 0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16z"
              />
            </svg>
          </div>
        </div>
      </div>
    </section>

    <div
      *ngIf="globalComponent.installEvent"
      id="default-install-instructions"
      class="shadow-sm bg-sf-white justify-between contents items-center fixed bottom-0 z-50 w-full text-sf-black py-2 px-4"
    >
      <div (click)="beginInstall()" class="flex gap-2">
        Install application to home screen?
        <img
          src="/assets/icons/icon-add-to-home-screen.svg"
          class="align-top"
          alt="Add to home screen"
        />
      </div>
      <div class="p-3" (click)="hideInstallPrompt()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </div>
    </div>
  </nav>
</div>
