import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { VisibilityService } from '@app/services';
import { computePosition } from '@floating-ui/dom';

@Component({
  selector: 'app-quick-action-button-more',
  templateUrl: './quick-action-button-more.component.html',
  styleUrls: ['./quick-action-button-more.component.scss'],
  standalone: false,
})
export class QuickActionButtonMoreComponent {
  @Input() links: {
    label: string;
    url?: string;
    icon: string;
    onClick?: () => void;
  }[] = [];
  @ViewChild('dropdownMenu') dropdownMenu!: ElementRef;
  @ViewChild('dialogMenu') dialogMenu!: ElementRef;

  public isMoreMenuOpen = false;

  constructor(private router: Router, public visibilityService: VisibilityService) {}

  // Navigate to the given URL
  public navigateTo(url: string, stateObject?: any) {
    if (!url) {
      return;
    }

    this.router.navigate([url], { state: stateObject });
  }

  // Toggle the menu (dropdown or dialog) based on screen size
  public toggleMenu(event: MouseEvent) {
    event.stopPropagation();
    this.isMoreMenuOpen = !this.isMoreMenuOpen;
    if (this.isMoreMenuOpen) {
      this.positionMenu(event);
    }
  }

  // Positions either the dropdown (for larger screens) or the dialog (for smaller screens).
  private positionMenu(event: MouseEvent | HTMLElement) {
    const button = event instanceof MouseEvent ? (event.target as HTMLElement) : event;

    requestAnimationFrame(() => {
      const dropdown = this.dropdownMenu?.nativeElement;
      const dialog = this.dialogMenu?.nativeElement;

      // For large screens, always position dropdown at bottom-end
      if (this.visibilityService.isMdOrAbove && dropdown) {
        computePosition(button, dropdown, {
          placement: 'bottom-end', // Always use bottom-end placement
        }).then(({ x, y }) => {
          Object.assign(dropdown.style, {
            left: `${x}px`,
            top: `${y}px`,
            position: 'absolute',
          });
        });
      }

      // For small screens, ensure the dialog is at the bottom of the viewport
      if (this.visibilityService.isSmOrBelow && dialog) {
        Object.assign(dialog.style, {
          left: '0',
          right: '0',
          bottom: '0',
          top: 'auto',
          position: 'fixed',
          width: '100%',
          height: 'auto',
          transform: 'none',
        });
      }
    });
  }

  // HostListener to listen for clicks, which will close the more menu
  @HostListener('document:click', ['$event'])
  public handleClickOutside() {
    this.isMoreMenuOpen = false;
  }
}
