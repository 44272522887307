export class Payment {
  public MC_SFCustomerPlanId: string;
  public amount: number;
  public name: string;
  public email: string;
  public instId: string;
  public transId: string;
  public currency: string;
  public paymentIntentId: string;
  public dataId: number;
  public isInitialDeposit: boolean;
  public cardElement: any;
}
