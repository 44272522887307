<div
  class="flex items-center relative py-6 px-3 md:px-4"
  [ngClass]="{ 'login__logo--has-back-btn': showBackBtn }"
>
  <button *ngIf="showBackBtn" (click)="handleButtonClick()">
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 13L1 7L7 1"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>

  <img
    src="/assets/images/shepherds-logo-two-line-min.svg"
    alt="Shepherds Friendly logo"
    (click)="navigateToLoginScreen()"
    width="156"
    height="50"
  />
</div>
