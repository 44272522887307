<section id="forgottenPasswordContent">
  <div class="w-full max-w-[500px] my-0 mx-auto lg:max-w-[600px]">
    <header class="mb-5 pb-2">
      <h1 class="text-3xl md:text-5xl 2xl:text-6xl font-bold pb-1">Reset your password</h1>
      <p class="pb-0 mb-0">Enter your details below</p>
    </header>

    <hr class="border-gray-500 border-opacity-25 border-b mb-6" />

    <div *ngIf="!validated">
      <form [formGroup]="forgottenPasswordForm" (ngSubmit)="onSubmit()">
        <div>
          <label for="email" class="form-label">Email address</label>
          <div class="input-group">
            <input
              type="email"
              class="form-control"
              id="username"
              name="username"
              placeholder="Email address"
              required
              formControlName="username"
              [ngClass]="{
                'is-invalid': submitted && usernameCtrl.errors,
              }"
            />
          </div>
          <div *ngIf="submitted && usernameCtrl.errors">
            <app-validation-message
              *ngIf="usernameCtrl.errors['required']"
              message="Please enter your email address"
            ></app-validation-message>

            <app-validation-message
              *ngIf="usernameCtrl.errors['email']"
              message="Please enter a valid email address"
            ></app-validation-message>
          </div>
        </div>

        <div class="grid gap-2 py-4 md:py-5">
          <app-button
            text="Reset password"
            buttonStyle="primary"
            [noBorder]="true"
            [isSubmitButton]="true"
            [fullWidth]="true"
          ></app-button>
        </div>
      </form>
    </div>
    <div *ngIf="validated">
      <app-alert
        type="success"
        imageSmallSrc="check"
        title="Password reset received"
        message="Thank you. If your email address is registered with us,
                        you will receive an email with a link to reset your
                        password shortly. Don't forget to check your junk
                        folder."
      ></app-alert>

      <div class="grid gap-2 py-4 md:py-5">
        <app-button
          text="Return to log in"
          buttonStyle="primary"
          routerLink="/log-in"
          [fullWidth]="true"
        ></app-button>
      </div>
    </div>

    <app-need-help />
    <p class="text-center mt-4 md:mt-5">App version: {{ appConstants.version }}</p>
  </div>
</section>
