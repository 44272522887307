import { Component, Input } from '@angular/core';
import { NotificationItem, Notifications } from '@app/models/member-portal';

type NotificationItemWithReadStatus = NotificationItem & { read: boolean };

interface NotificationsWithReadStatus extends Notifications {
  items: NotificationItemWithReadStatus[];
}

@Component({
  selector: 'app-notification-summary',
  templateUrl: './notification-summary.component.html',
  styleUrls: ['./notification-summary.component.scss'],
})
export class NotificationSummaryComponent {
  readonly url = {
    NOTIFICATION: `/notifications/{0}`,
  };

  @Input() notifications: NotificationsWithReadStatus;

  constructor() {}
}
