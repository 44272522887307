<div>
  <app-content-header
    title="Register to use the app"
    customClassTitle="text-sf-white mb-2"
    customClassContainer=" p-0"
  />

  <app-steps
    [currentStep]="step"
    [enumType]="stepOpts"
    (click)="goOneStepBack()"
    customClass="sf-card--ctf"
  />
</div>

<div class="mb-4">
  <div class="sf-card sf-card--ctf">
    <div
      class="step"
      [ngClass]="{
        'hidden-step': !(step === stepOpts['PLAN']),
      }"
    >
      <app-register-account-step-one
        *ngIf="showRegisterAccountStepOne"
        [showJisaLabelText]="showRegisterAccountStepOneExtended"
        (onValid)="stepOneComplete = $event"
        (onUpdate)="onUpdateStepOne($event)"
      />

      <app-register-account-step-one-extended
        *ngIf="showRegisterAccountStepOneExtended"
        [showSeparator]="showRegisterAccountStepOne"
        (onValid)="stepOneExtendedComplete = $event"
        (onUpdate)="onUpdateStepOneExtended($event)"
      />

      <app-button
        text="Continue"
        [disabled]="!canContinue"
        (buttonClick)="goToStep('YOUR_DETAILS')"
        buttonStyle="primary"
        [isSubmitButton]="false"
      ></app-button>
    </div>

    <div
      class="step"
      [ngClass]="{
        'hidden-step': !(step === stepOpts['YOUR_DETAILS']),
      }"
    >
      <app-register-account-step-two
        (onValid)="stepTwoComplete = $event"
        (onUpdate)="onUpdateStepTwo($event)"
      />
      <app-button
        text="Continue"
        [disabled]="!stepTwoComplete"
        (buttonClick)="goToStep('ACCOUNT')"
        buttonStyle="primary"
        [isSubmitButton]="false"
      ></app-button>
    </div>
    <div
      class="step"
      [ngClass]="{
        'hidden-step': !(step === stepOpts['ACCOUNT']),
      }"
    >
      <app-register-account-step-three
        (onValid)="stepThreeComplete = $event"
        (onUpdate)="onUpdateStepThree($event)"
      />
      <app-button
        text="Continue"
        [disabled]="!stepThreeComplete"
        (buttonClick)="goToStep('CONFIRM')"
        buttonStyle="primary"
        [isSubmitButton]="false"
      ></app-button>
    </div>
    <div
      class="step mb-4"
      [ngClass]="{
        'hidden-step': !(step === stepOpts['CONFIRM']),
      }"
    >
      <app-register-consent
        (onValid)="stepConsentComplete = $event"
        (onUpdateConsent)="onUpdateConsent($event)"
      ></app-register-consent>
    </div>

    <app-button
      *ngIf="step === stepOpts['CONFIRM']"
      [disabled]="!stepConsentComplete"
      text="Register"
      (buttonClick)="register()"
      buttonStyle="primary"
      [noBorder]="true"
      [isSubmitButton]="false"
    ></app-button>
  </div>
</div>
