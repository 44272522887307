<div class="sf-card mt-4 mb-4">
  <p class="font-bold">I declare that:</p>

  <ng-container *ngIf="['ISA', 'SISA'].includes(planTypePrefix)">
    <ul>
      <li class="text-sm pb-4 marker:text-[10px]">
        All subscriptions made, and to be made, belong to me;
      </li>
      <li class="text-sm pb-4 marker:text-[10px]">I am 18 years of age or over;</li>
      <li class="text-sm pb-4 marker:text-[10px]">
        I have not subscribed/made payments, and will not subscribe/make payments, more than the
        overall subscription limit in total to a cash ISA, a stocks and shares ISA, an innovative
        finance ISA, and a Lifetime ISA in the same tax year;
      </li>
      <li class="text-sm pb-4 marker:text-[10px]">
        I am a resident in the United Kingdom for tax purposes or, if not so resident, either
        perform duties which, by vertue of Section 28 of Income Tax (Earnings & Pensions) Act 2003
        (crown employees serving overseas), are treated as being performed in the United Kingdom, or
        I am married to, or in a civil partnership with, a person who performs such duties. I will
        inform Shepherds Friendly Society if I cease to be so resident and ordinarily resident or to
        perform such duties or be married to, or in a civil partnership with, a person who performs
        such duties.
      </li>
      <li class="text-sm pb-4 marker:text-[10px]">
        I declare that to the best of my knowledge and belief, the answer given above are true and
        complete and that no important fact has been omitted or falsely stated.
      </li>
      <li class="text-sm pb-4">I agree to the ISA Terms and Conditions.</li>

      <li class="text-sm pb-4 marker:text-[10px]">
        I authorise The Shepherds Friendly Society Limited:
        <p class="mt-2">
          to hold the my cash subscription, any other proceeds in respect of my ISA investments and
          any other cash
        </p>
        <p class="text-sm">and</p>
        <p class="mb-0">
          to make on the my behalf any claims to relief from tax in respect of ISA investments.
        </p>
      </li>
    </ul>
  </ng-container>

  <ng-container *ngIf="['JISA', 'SJISA'].includes(planTypePrefix)">
    <ul>
      <li class="text-sm pb-4 marker:text-[10px]">
        All subscriptions made, and to be made, belong to the child;
      </li>
      <li class="text-sm pb-4 marker:text-[10px]">I am 16 years of age or over;</li>
      <li class="text-sm pb-4 marker:text-[10px]">
        I am the child/I have parental responsibility for the child;
      </li>
      <li class="text-sm pb-4 marker:text-[10px]">I/the child does not have a Child Trust Fund;</li>
      <li class="text-sm pb-4 marker:text-[10px]">
        I will be the registered contact for the Junior ISA;
      </li>
      <li class="text-sm pb-4 marker:text-[10px]">
        The child is resident in the United Kingdom, or is a UK crown servant, a dependant of a UK
        crown servant, or is married to/in a civil partnership with a UK crown servant;
      </li>
      <li class="text-sm pb-4 marker:text-[10px]">
        I have not subscribed and will not subscribe to another Junior ISA of this type for this
        child;
      </li>
      <li class="text-sm pb-4 marker:text-[10px]">
        I am not aware that this child has another Junior ISA of this type;
      </li>
      <li class="text-sm pb-4 marker:text-[10px]">
        I am not aware of other Junior ISA subscriptions that will result in this child exceeding
        the annual limit;
      </li>
      <li class="text-sm pb-4 marker:text-[10px]">
        I will not knowingly make subscriptions to Junior ISAs for this child that will result in
        the subscription limit being exceeded;
      </li>

      <li class="text-sm pb-4 marker:text-[10px]">
        I authorise The Shepherds Friendly Society Limited:
        <p class="mt-2">
          to hold the child's subscription, Junior ISA investments, interest, dividends and any
          other rights or proceeds in respect of those investments and cash;
        </p>
        <p class="text-sm">and</p>
        <p class="mb-0">
          to make on the child's behalf any claims to relief from tax in respect of Junior ISA
          investments.
        </p>
      </li>
    </ul>
  </ng-container>
  <app-checkbox
    label="I confirm acceptance of the above conditions"
    [(ngModel)]="checked"
    (ngModelChange)="processChange($event)"
    aria-labelledby="labelAcceptConditions"
    id="labelAcceptConditions"
    [additionalClass]="'py-4'"
    hasBorderTop="true"
  ></app-checkbox>
  <ng-content></ng-content>
</div>
