import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationItem, Notifications } from '@app/models/member-portal';
import { NotificationsService } from '@app/services';
import { NotificationUtility } from '@app/shared/utilities';
import { Subscription, take } from 'rxjs';

@Component({
  selector: 'app-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.scss'],
})
export class NotificationDetailComponent implements OnInit {
  readonly url = {
    NOTIFICATIONS: `/notifications`,
  };
  private subscribeUntilDestroyed = new Subscription();
  public notification: NotificationItem;

  constructor(
    private activatedRoute: ActivatedRoute,
    private notificationsService: NotificationsService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.loadNotificationFromRoute();
    this.notificationsService.setNotificationRead(this.notification.id);
  }

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  /**
   * Navigate to notifications page
   */
  public navigateToNotifications(): void {
    this.router.navigate([this.url.NOTIFICATIONS]);
  }

  /**
   * Set current notification as unread then navigate back to notifications
   */
  public setNotificationUnread(): void {
    this.notificationsService.setNotificationUnread(this.notification.id);
    this.navigateToNotifications();
  }

  /**
   * Maps a typeId string back to an icon class
   * @param {string} [typeId]
   */
  public typeIdToIconString(typeId: string): string {
    return NotificationUtility.typeIdToIconString(typeId);
  }

  /**
   * Retrieves notification data from the activated route data
   */
  private loadNotificationFromRoute(): void {
    this.subscribeUntilDestroyed.add(
      this.activatedRoute.data.subscribe(
        (routeData) => (this.notification = routeData['notification'])
      )
    );
  }
}
