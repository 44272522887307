<div
  class="flex gap-2.5 justify-center lg:justify-end"
  [class]="btnWrapperClasses"
  [ngClass]="{ '!justify-end': alignRight, '!justify-start': alignLeft }"
>
  <button
    *ngIf="showButton"
    class="btn text-center rounded-full py-4 px-8 flex-grow font-semibold cursor-pointer text-primary-sf-navy lg:flex-grow-0 hover:brightness-90"
    [ngClass]="[
      noBorder ? 'border-none' : '',
      inline ? 'flex-grow-0' : '',
      buttonStyle ? buttonStyle : '',
      btnSmall ? 'btn-small' : '',
      isButtonShadow ? 'btn-shadow' : '',
      customClass ? customClass : '',
      disabled ? 'disabled' : '',
      fullWidth ? 'w-full' : ''
    ]"
    [disabled]="disabled"
    [type]="isSubmitButton ? 'submit' : 'button'"
    (click)="handleButtonClick()"
  >
    <span *ngIf="icon" class="btn-icon">
      <i
        [class]="icon"
        [ngStyle]="{ 'font-size': iconSize }"
        [ngClass]="[iconColor ? iconColor : '']"
      ></i>
    </span>
    <span>{{ text }}</span>
  </button>

  <button
    *ngIf="showSecondButton"
    class="btn"
    [ngClass]="[
      secondButtonNoBorder ? 'border-none' : '',
      secondButtonInline ? 'flex-grow-0' : '',
      secondButtonStyle ? secondButtonStyle : '',
      btnSmall ? 'btn-small' : ''
    ]"
    [disabled]="secondButtonDisabled"
    [type]="isSecondButtonSubmit ? 'submit' : 'button'"
    (click)="handleSecondButtonClick()"
  >
    <span *ngIf="secondButtonIcon" class="btn-icon"><i [class]="secondButtonIcon"></i></span>
    <span>{{ secondButtonText }}</span>
  </button>
</div>
