import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MemberProfile, StepOptions } from '@app/models/profile';
import { AuthService, ProfileService } from '@app/services';
import { RegexUtility } from '@app/shared/utilities';

type RouteStateData = { memberProfile: MemberProfile };

@Component({
    selector: 'app-profile-change-mobile-number',
    templateUrl: './profile-change-mobile-number.component.html',
    styleUrls: ['./profile-change-mobile-number.component.css'],
    standalone: false
})
export class ProfileChangeMobileNumberComponent implements OnInit {
  public readonly url = {
    HOME: `/home`,
    PROFILE_OPTIONS: `/profile/options`,
  };

  public memberProfile: MemberProfile;
  public changeMobileNumberForm: FormGroup;
  public mobileCtrl: AbstractControl;

  public stepOpts = StepOptions;
  public step = this.stepOpts.UPDATE;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    private profileService: ProfileService
  ) {
    this.loadMemberProfileFromRoute();
  }

  ngOnInit(): void {
    this.buildForm();

    this.mobileCtrl = this.changeMobileNumberForm.controls['mobile'];
  }

  /**
   * Caches a copy of the current change into the profile service and proceeds to the password step
   */
  public goToNextStep(): void {
    if (this.changeMobileNumberForm.valid) {
      this.profileService.updatedDetails = {
        mobileNumber: this.mobileCtrl.value,
      };

      this.step = this.stepOpts.VERIFY;
    }
  }

  /**
   * Prcoeeds to OTP step on successful password entry
   * @param {boolean} [verified]
   */
  public handleVerification(verified: boolean): void {
    if (verified) {
      this.step = this.stepOpts.CONFIRM;
    }
  }

  /**
   * Prcoeeds to complete step on successful OTP code
   * @param {boolean} [verified]
   */
  public handleCode(verified: boolean): void {
    if (verified) {
      this.step = this.stepOpts.COMPLETE;
    }
  }

  /**
   * Builds the form configuration with angulars form builder service
   */
  private buildForm(): void {
    this.changeMobileNumberForm = this.fb.group({
      mobile: ['', [Validators.required, Validators.pattern(RegexUtility.mobileNo)]],
    });
  }

  /**
   * Retrieves member profile data from the route state, if non exists
   * redirect back to the profile options page.
   */
  private loadMemberProfileFromRoute(): void {
    const state = this.router.getCurrentNavigation()?.extras?.state as RouteStateData;

    if (state) {
      this.memberProfile = state.memberProfile;
    } else {
      this.router.navigate([this.url.PROFILE_OPTIONS]);
    }
  }
}
