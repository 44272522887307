import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WP_REST_API_Post_Override } from '../models/wp-types-overrides/WP_REST_API_Post_Override';
import {
  WP_REST_API_Post_OverrideCacheService,
  WP_REST_API_Posts_OverrideCacheService,
} from './cache-concrete.service';
import { HttpService } from './http.service';

@Injectable()
export class WordpressApiService {
  public wpUrl: string = environment.backend.wpURL;
  // Ids from the Wordpress database representing the 'App' and 'App-Only' tags
  public appTagIds: string = environment.appTagIds;

  constructor(
    private httpService: HttpService,
    private wpRestApiPostOverrideCacheService: WP_REST_API_Post_OverrideCacheService,
    private wpRestApiPostsOverrideCacheService: WP_REST_API_Posts_OverrideCacheService
  ) {}

  getPage(id: string): Observable<WP_REST_API_Post_Override> {
    let post$ = this.wpRestApiPostOverrideCacheService.getValue(id);
    if (!post$) {
      post$ = this.httpService
        .get<WP_REST_API_Post_Override>(`${this.wpUrl}` + '/pages/' + id)
        .pipe(
          map((response) => {
            return response;
          }),
          shareReplay(1)
        );
      this.wpRestApiPostOverrideCacheService.setValue(post$, id);
    }
    return post$;
  }

  getPost(id: string): Observable<WP_REST_API_Post_Override> {
    let post$ = this.wpRestApiPostOverrideCacheService.getValue(id);
    if (!post$) {
      post$ = this.httpService
        .get<WP_REST_API_Post_Override>(`${this.wpUrl}` + '/posts/' + id + '?_embed')
        .pipe(
          map((response) => {
            response = this.fillImageProperty(response);
            return response;
          }),
          shareReplay(1)
        );
      this.wpRestApiPostOverrideCacheService.setValue(post$, id);
    }
    return post$;
  }

  getPosts(count: number): Observable<WP_REST_API_Post_Override[]> {
    let posts$ = this.wpRestApiPostsOverrideCacheService.getValue();
    if (!posts$) {
      posts$ = this.httpService
        .get<
          WP_REST_API_Post_Override[]
        >(`${this.wpUrl}` + '/posts?tags=' + `${this.appTagIds}` + '&per_page=' + count + '&_embed')
        .pipe(
          map((response) => {
            for (let r of response) {
              r = this.fillImageProperty(r);
            }
            return response;
          }),
          shareReplay(1)
        );
      this.wpRestApiPostsOverrideCacheService.setValue(posts$);
    }
    return posts$;
  }

  // Not every api call made to wordpress's posts api returns an array of different sized images
  // Where the call has returned a small image, use that one to ensure it downloads faster, otherwise
  // fall back to a larger file
  fillImageProperty(p: WP_REST_API_Post_Override): WP_REST_API_Post_Override {
    if (Object.keys(p._embedded['wp:featuredmedia']['0'].media_details.sizes).length > 0) {
      p.small_image_url =
        p._embedded['wp:featuredmedia']['0'].media_details.sizes.post_header.source_url;
    } else {
      p.small_image_url = p._embedded['wp:featuredmedia']['0'].source_url;
    }
    return p;
  }
}
