<section *ngIf="plan" class="my-4 mx-0">
  <app-card-with-image
    title="Your deposit was successful"
    imageSrc="doing-the-right-thing"
    imageAlt="doing-the-right-thing"
    [customClass]="'mb-4 md:mb-6 xl:mb-8'"
    [titleStyle]="'text-primary-sf-navy dark:text-sf-white'"
    [isButtonOff]="false"
    [button_text]="'Return to home'"
    [routerLink]="url.HOME"
    [button_fullWidth]="false"
    [button_small]="false"
  >
    <p class="md:mb-6 xl:mb-8">
      Your
      {{ payment | currency : 'GBP' }}
      deposit into
      <strong>{{ plan.formattedPlanNumber }}</strong>
      was successful and will show in your account in the next 48 hours
    </p>
  </app-card-with-image>

  <div class="mt-4 md:mt-6 xl:mt-8">
    <app-survey-prompt
      imageSrc="survey-prompt"
      imageAlt="survey-prompt"
      title="Was it easy to top up your account?"
      content="Please take our short survey if you have any feedback for us."
      [button_text]="'Give Feedback'"
      [button_small]="false"
      [button_fullWidth]="true"
      [feedbackType]="topUpFeedbackType"
      [additionalData]="{ plan_type: plan.planTypeName, plan_id: plan.planId }"
    ></app-survey-prompt>
  </div>
</section>
