import { Injectable } from '@angular/core';
import {
  MemberDashboardCacheService,
  TransactionsCacheService,
  ProfileCacheService,
  NotificationsCacheService,
} from './cache-concrete.service';

@Injectable({
  providedIn: 'root',
})
export class CacheManagerService {
  constructor(
    private memberDashboardCacheService: MemberDashboardCacheService,
    private transactionsCacheService: TransactionsCacheService,
    private profileCacheService: ProfileCacheService,
    private notificationsCacheService: NotificationsCacheService
  ) {}

  public clearAllCaches(): void {
    this.memberDashboardCacheService.clearCache();
    this.transactionsCacheService.clearCache();
    this.notificationsCacheService.clearCache();
    this.profileCacheService.clearCache();
  }
}
