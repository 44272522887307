import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { FeatureFlagService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class SSIGuard implements CanActivate {
  constructor(private featureFlagService: FeatureFlagService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      const isEnabled = await this.featureFlagService.isEnabled('appSSIPlans');
      if (!isEnabled) {
        this.router.navigate(['/home']);
        return false;
      }
      return true;
    } catch (error) {
      this.router.navigate(['/home']);
      return false;
    }
  }
}
