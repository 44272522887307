import { Injectable } from '@angular/core';

import { catchError, map, Observable, of, throwError } from 'rxjs';
import { MemberDashboard, MemberRow } from '../models/member-portal';
import { MemberPortalService } from '../services';
import { TypeUtility } from '../shared/utilities';

@Injectable({
  providedIn: 'root',
})
export class DocumentsResolver {
  constructor(private memberPortalService: MemberPortalService) {}

  resolve(): Observable<MemberRow[]> {
    return this.memberPortalService
      .getMemberDashboard({ includeDocs: true })
      .pipe(catchError((error) => throwError(() => `MemberDashboardResolver -> ${error}`)))
      .pipe(
        map((memberDashboard) => this.getDocumentsByMember(memberDashboard)),
        catchError((error) => throwError(() => `DocumentsResolver -> ${error}`))
      );
  }

  /**
   * Finds document member rows and returns them
   * @param {MemberDashboard} [memberDashboard]
   * @return {MemberRow[]}
   */
  private getDocumentsByMember(memberDashboard: MemberDashboard): MemberRow[] {
    const result = memberDashboard.documents.memberRows;

    if (TypeUtility.isNullOrUndefined(result)) {
      throw new Error('No member rows found');
    }

    return result;
  }
}
