import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-boot',
  templateUrl: './boot.component.html',
  styleUrls: ['./boot.component.css'],
})
export class BootComponent implements OnInit {
  public readonly url = {
    LOG_IN: `/log-in`,
  };

  constructor(
    private authService: AuthService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.authService.logout().then(() => this.router.navigate([this.url.LOG_IN]));
  }
}
