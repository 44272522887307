<section *ngIf="plan">
  <app-content-header
    [title]="'Review your new Direct Debit'"
    [isButtonOff]="true"
    [subtitle]="plan.formattedPlanNumber"
  ></app-content-header>

  <app-direct-debit-overview
    [plan]="plan"
    [directDebit]="directDebit"
    [hasAmend]="true"
    (onAmend)="navigateWithState(url.DIRECT_DEBIT)"
  />

  <app-direct-debit-declaration
    [planTypePrefix]="plan.planTypePrefix"
    (hasAcceptedConditions)="handleHasAcceptedConditions($event)"
  >
    <div class="flex items-center justify-between">
      <blockquote class="text-sm">
        The company name showing on your bank statement will be SHEPHERDS FRIENDLY
      </blockquote>
      <app-button
        text="Confirm Direct Debit"
        buttonStyle="secondary"
        [noBorder]="true"
        [disabled]="!hasAcceptedConditions"
        (click)="onSubmit()"
      />
    </div>
  </app-direct-debit-declaration>
</section>
