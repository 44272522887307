import { Injectable } from '@angular/core';
import { catchError, Observable, shareReplay, throwError } from 'rxjs';
import { Transactions, TransactionsSummary, TransactionTypes } from '../models/member-portal';
import { AppConstantsService, HttpService, TransactionsCacheService } from '.';
import { TypeUtility } from '@app/shared/utilities';

export enum TransactionTypeOptions {
  'All' = 'All',
  'Withdrawal' = 'Withdrawal',
  'Top_Up' = 'Top Up',
  'Bonus' = 'Bonus',
}
export type TransactionFilter = {
  page: number;
  type: TransactionTypeOptions;
  start: string;
  end: string;
};

@Injectable()
export class TransactionsService {
  readonly api = {
    TRANSACTIONS: (planId: string, filter: TransactionFilter): string =>
      `${this.configuration.server}/api/Member/Transactions/${planId}/${this.filterTransactions(
        filter
      )}`,
    TRANSACTIONS_SUMMARY: (planId: string): string =>
      `${this.configuration.server}/api/Member/TransactionsSummary/${planId}`,
    TRANSACTION_TYPES: (planId: string): string =>
      `${this.configuration.server}/api/Member/TransactionTypes/${planId}`,
  };
  readonly maxItemsPerPage = 5;

  constructor(
    private http: HttpService,
    private configuration: AppConstantsService,
    private transactionsCacheService: TransactionsCacheService
  ) {}

  /**
   * Checks state management for a recent copy of `Transactions`, if outdated or non existant,
   * a new copy is retrieved and recached.
   * @param {string} [planId]
   * @return Observable that emits the latest Transactions
   */
  public getTransactions(
    planId: string,
    filter: TransactionFilter = {
      page: 0,
      type: TransactionTypeOptions.All,
      start: '',
      end: '',
    }
  ): Observable<Transactions> {
    let transactions$ = this.transactionsCacheService.getValue();
    if (!transactions$ || filter) {
      transactions$ = this.http.get<Transactions>(this.api.TRANSACTIONS(planId, filter)).pipe(
        shareReplay(1),
        catchError((error) => throwError(() => `getTransactions -> ${error}`))
      );
      this.transactionsCacheService.setValue(transactions$);
    }
    return transactions$;
  }

  /**
   * Checks state management for a recent copy of `TransactionsSummary`, if outdated or non existant,
   * a new copy is retrieved and recached.
   * @param {string} [planId]
   * @return Observable that emits the latest TransactionsSummary
   */
  public getTransactionsSummary(
    planId: string,
    filter: TransactionFilter = {
      page: 0,
      type: TransactionTypeOptions.All,
      start: '',
      end: '',
    }
  ): Observable<TransactionsSummary> {
    let transactionsSummary$;
    if (!transactionsSummary$ || filter) {
      transactionsSummary$ = this.http
        .get<TransactionsSummary>(this.api.TRANSACTIONS_SUMMARY(planId))
        .pipe(
          shareReplay(1),
          catchError((error) => throwError(() => `getTransactionsSummary -> ${error}`))
        );
    }
    return transactionsSummary$;
  }

  /**
   * Requests a copy of the transaction types by plan identifier
   * @param {string} [planId]
   * @return Observable that emits TransactionTypes[]
   */
  public getTransactionTypes(planId: string): Observable<TransactionTypes[]> {
    return this.http.get<TransactionTypes[]>(this.api.TRANSACTION_TYPES(planId));
  }

  /**
   * Takes a declaritive filter object and returns as a decorated url
   * @param {TransactionFilter} [filters]
   * @return A string consisting of query parameters
   */
  public filterTransactions(filters: TransactionFilter): string {
    let qs = '?$top=' + this.maxItemsPerPage;
    qs += '&$skip=' + (filters.page !== 0 ? filters.page - 1 : 0) * this.maxItemsPerPage;
    qs += '&$inlinecount=allpages';
    qs += '&$orderby=TransactionDate desc,ID desc';

    const hasType = filters.type !== TransactionTypeOptions.All;
    const hasStart = !TypeUtility.isNullOrEmpty(filters.start);
    const hasEnd = !TypeUtility.isNullOrEmpty(filters.end);

    const filter = (query: string) => `&$filter=${query}`;

    const concatTypeFilter = (fieldName = 'TransactionTypeDescription') =>
      `${fieldName} eq '${filters.type}'`;

    const concatDateFilter = (fieldName = 'TransactionDate') => {
      if (hasEnd && !hasStart) {
        return `${fieldName} le DateTime'${filters.end}T23:59:59.999'`;
      } else if (hasStart && !hasEnd) {
        return `${fieldName} ge DateTime'${filters.start}'`;
      } else {
        return `${fieldName} ge DateTime'${filters.start}' and ${fieldName} le DateTime'${filters.end}T23:59:59.999'`;
      }
    };

    // If has a type set, but no start/end date set, apply type filter
    if (hasType && !hasStart && !hasEnd) {
      qs += filter(`(${concatTypeFilter()})`);
    } else if (hasType && (hasStart || hasEnd)) {
      // If has a type set, and also has got either a start or end date set, apply type and date filters
      qs += filter(`(${concatTypeFilter()}) and (${concatDateFilter()})`);
    } else if (hasStart || hasEnd) {
      // If start or end date set, apply date filter
      qs += filter(`(${concatDateFilter()})`);
    }

    return qs;
  }
}
