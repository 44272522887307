import { Member } from './member.model';
import { IncomeProtectionPlan } from './income-protection-plan.model';
import { Over50sPlan } from './over50s-plan.model';
import { SavingsPlan } from './savings-plan.model';
import { Registrant } from './registrant.model';
import { AuthorisedPlans } from './authorised-plans.model';
import { PlanCapacities } from './plan-capacities.model';
import { ClientConfiguration } from './client-configuration.model';
import { MemberPlans } from './member-plans.model';
import { DocumentListViewModel, DocumentList } from './Pdf/member-document-list.model';

export class MemberDashboard {
  registrant: Registrant;
  member: Member;
  memberPlans: MemberPlans;
  authorisedPlans: AuthorisedPlans;
  clientConfiguration: ClientConfiguration;
  continuationToken: string;
  documents: DocumentList;

  constructor(
    registrant: Registrant,
    member: Member,
    memberPlans: MemberPlans,
    authorisedPlans: AuthorisedPlans,
    clientConfiguration: ClientConfiguration,
    documents: DocumentList,
    continuationToken: string
  ) {
    this.registrant = registrant;
    this.member = member;
    this.memberPlans = memberPlans;
    this.authorisedPlans = authorisedPlans;
    this.clientConfiguration = clientConfiguration;
    this.continuationToken = continuationToken;
    this.documents = documents;
  }

  // Check if the dashboard has a valid registrant
  hasRegistrant(): boolean {
    return this.registrant?.isMapped ?? false;
  }

  // Check if the dashboard has a valid member
  hasMember(): boolean {
    return this.member?.isMapped ?? false;
  }
}
