import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { ObjectUtility } from '@app/shared/utilities';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        // Extract error, ensuring type safety and prevent breaking JSON.stringify with circular references
        const errorDetails = {
          message: errorResponse.message || 'Unknown error',
          status: errorResponse.status || 'No Status',
          url: errorResponse.url || 'Unknown URL',
          error: ObjectUtility.safeStringify(this.extractError(errorResponse)),
        };

        // Log error locally for debugging and Sentry breadcrumbs
        console.error('HTTP Error:', errorDetails);

        // Send error details to Sentry
        Sentry.captureException(new Error('HTTP Error'), {
          extra: errorDetails,
        });

        return throwError(() => errorResponse);
      })
    );
  }

  /**
   * Extracts a meaningful error object from API responses.
   */
  private extractError(errorResponse: HttpErrorResponse): any {
    if (!errorResponse) return 'No error response available';

    if (typeof errorResponse.error === 'string') {
      return errorResponse.error; // A plain string error
    } else if (typeof errorResponse.error === 'object' && errorResponse.error !== null) {
      return errorResponse.error; // A structured error object
    } else {
      return 'Unknown error structure'; // Handle edge cases
    }
  }
}
