export class NotificationUtility {
  /**
   * Maps a typeId string back to an icon class
   * @param {string} [typeId]
   */
  static typeIdToIconString(typeId: string): string {
    switch (typeId) {
      case 'security':
        return 'fa-eye';
      case 'plan':
        return 'fa-coins';
      case 'account':
        return 'fa-user';
      case 'marketing':
        return 'fa-gift-card';
      default:
        return 'fa-money-bill';
    }
  }
}
