import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FeedbackType } from '@app/models/feedback';
import { MemberProfile, StepOptions } from '@app/models/profile';
import { PostcodeService, ProfileService } from '@app/services';

type RouteStateData = { memberProfile: MemberProfile };

@Component({
  selector: 'app-profile-change-address',
  templateUrl: './profile-change-address.component.html',
  styleUrls: ['./profile-change-address.component.css'],
})
export class ProfileChangeAddressComponent implements OnInit {
  public readonly url = {
    HOME: `/home`,
    PROFILE_OPTIONS: `/profile/options`,
  };
  public readonly updateProfileFeedbackType: FeedbackType = FeedbackType.UPDATE_PROFILE;

  public memberProfile: MemberProfile;
  public address: any;
  public enteredManually: boolean = false;

  public stepOpts = StepOptions;
  public step = this.stepOpts.UPDATE;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private profileService: ProfileService
  ) {
    this.loadMemberProfileFromRoute();
  }

  ngOnInit(): void {}

  public onChangedAddress(address: any): void {
    this.address = address;

    if (this.enteredManually) {
      this.goToNextStep();
    }
  }

  /**
   * Format the current member profile address and exclude any empty fields
   */
  public get formattedCurrentAddress(): string {
    return [
      this.memberProfile.address.address1,
      this.memberProfile.address.address2,
      this.memberProfile.address.address3,
      this.memberProfile.address.address4,
      this.memberProfile.address.postCode.toUpperCase(),
    ]
      .filter(Boolean)
      .join('\r\n');
  }

  /**
   * Format the form address and exclude any empty fields
   */
  public get formattedNewAddress(): string {
    return [
      this.address.line1,
      this.address.line2,
      this.address.county,
      this.address.city,
      this.address.postcode.toUpperCase(),
    ]
      .filter(Boolean)
      .join('\r\n');
  }

  /**
   * Caches a copy of the current change into the profile service and proceeds to the password step
   */
  public goToNextStep(): void {
    if (this.address) {
      let address = {
        $id: this.memberProfile.address.$id,
        address1: this.address.line1,
        address2: this.address.line2,
        address3: this.address.county,
        address4: this.address.city,
        postCode: this.address.postcode.toUpperCase(),
      };

      this.profileService.updatedDetails = {
        address,
      };

      this.step = this.stepOpts.VERIFY;
    }
  }

  /**
   * Proceeds to OTP step on successful password entry
   * @param {boolean} [verified]
   */
  public handleVerification(verified: boolean): void {
    if (verified) {
      this.step = this.stepOpts.CONFIRM;
    }
  }

  /**
   * Proceeds to complete step on successful OTP code
   * @param {boolean} [verified]
   */
  public handleCode(verified: boolean): void {
    if (verified) {
      this.step = this.stepOpts.COMPLETE;

      this.profileService.clearCache();
    }
  }

  /**
   * Retrieves member profile data from the route state, if non exists
   * redirect back to the profile options page.
   */
  private loadMemberProfileFromRoute(): void {
    const state = this.router.getCurrentNavigation()?.extras?.state as RouteStateData;

    if (state) {
      this.memberProfile = state.memberProfile;
    } else {
      this.router.navigate([this.url.PROFILE_OPTIONS]);
    }
  }
}
