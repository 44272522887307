import { AuthorisedSavingsPlan } from '../member-portal/authorised-savings-plan.model';
import { AuthorisedIncomeProtectionPlan } from '../member-portal/authorised-income-protection-plan.model';
import { AuthorisedOver50sPlan } from '../member-portal/authorised-over50s-plan.model';

export class AuthorisedPlans {
  public authorisedOver50sPlans: Array<AuthorisedOver50sPlan>;

  public authorisedIncomeProtectionPlans: Array<AuthorisedIncomeProtectionPlan>;

  public authorisedSavingsPlans: Array<AuthorisedSavingsPlan>;
}
