<app-content-header [title]="'My profile'"></app-content-header>

<div class="flex flex-col mb-4 shadow-sm md:mb-6 xl:mb-8 relative overflow-auto">
  <button
    type="button"
    aria-label="name"
    class="flex flex-col justify-center bg-white dark:bg-primary-sf-navy text-primary-sf-navy dark:text-sf-white md:text-base p-4 font-semibold rounded-t-md border-b border-b-primary-sf-navy/10 dark:border-b-sf-white/10"
  >
    Name
    <span class="font-normal text-sf-body-font dark:text-sf-white">
      {{ memberProfile.name }}
    </span>
  </button>
  <button
    type="button"
    aria-label="Change your email"
    class="flex flex-col justify-center bg-white dark:bg-primary-sf-navy text-primary-sf-navy dark:text-sf-white md:text-base p-4 font-semibold border-b border-b-primary-sf-navy/10 dark:border-b-sf-white/10 after:content-[''] after:absolute after:bg-[url('/assets/icons/pen.svg')] dark:after:bg-[url('/assets/icons/pen-dark.svg')] after:w-8 after:h-8 after:self-end"
    (click)="navigateWithState(url.CHANGE_EMAIL)"
  >
    Email address
    <span class="font-normal text-sf-body-font dark:text-sf-white">
      {{ memberProfile.emailAddress }}
    </span>
  </button>
  <button
    type="button"
    aria-label="Change your mobile number"
    class="flex flex-col justify-center bg-white dark:bg-primary-sf-navy text-primary-sf-navy dark:text-sf-white md:text-base p-4 font-semibold border-b border-b-primary-sf-navy/10 dark:border-b-sf-white/10 after:content-[''] after:absolute after:bg-[url('/assets/icons/pen.svg')] dark:after:bg-[url('/assets/icons/pen-dark.svg')] after:w-8 after:h-8 after:self-end"
    (click)="navigateWithState(url.CHANGE_MOBILE_NUMBER)"
  >
    Mobile number
    <span class="font-normal text-sf-body-font dark:text-sf-white">
      {{ memberProfile.mobileNumber }}
    </span>
  </button>
  <button
    type="button"
    aria-label="Change your address"
    class="flex flex-col justify-center bg-white dark:bg-primary-sf-navy text-primary-sf-navy dark:text-sf-white md:text-base p-4 font-semibold border-none rounded-b-md after:content-[''] after:absolute after:bg-[url('/assets/icons/pen.svg')] dark:after:bg-[url('/assets/icons/pen-dark.svg')] after:w-8 after:h-8 after:self-end"
    (click)="navigateWithState(url.CHANGE_ADDRESS)"
  >
    Residential address
    <span class="font-normal whitespace-pre-line text-left text-sf-body-font dark:text-sf-white">
      {{ formattedCurrentAddress }}
    </span>
  </button>
</div>

<app-alert
  *ngIf="isWebAuthnAvailable && !isDeviceRegistered"
  type="info"
  additionalClass="mb-4 md:mb-6 xl:mb-8"
  title="Biometric authentication"
  message="Your device supports biometrics, such as face or fingerprint ID to login."
  imageSrc="mobile-fingerprint"
  imageAlt="mobile-fingerprint"
  additionalImageClass="!w-16 !h-16"
  [isButtonOff]="false"
  [button_text]="'Set up biometric authentication'"
  routerLink="/profile/options/authn"
  [button_fullWidth]="true"
  [button_small]="false"
  button_customClass="mt-4"
></app-alert>

<app-alert
  *ngIf="isWebAuthnAvailable && isDeviceRegistered"
  type="info"
  additionalClass="mb-4"
  title="Biometrics supported"
  message="Your device supports biometrics, such as face or fingerprint ID to login."
  imageSrc="mobile-fingerprint"
  imageAlt="mobile-fingerprint"
  additionalImageClass="!w-16 !h-16"
  [isButtonOff]="false"
  [button_text]="'Manage biometric authentication'"
  routerLink="/profile/options/authn"
  [button_fullWidth]="true"
  [button_small]="false"
  button_customClass="mt-4"
></app-alert>

<app-button
  text="Log out"
  buttonStyle="secondary"
  routerLink="/log-out"
  [noBorder]="true"
  [fullWidth]="true"
></app-button>

<p class="text-center mt-4">
  To view our privacy policy,
  <a
    href="https://www.shepherdsfriendly.co.uk/privacy-policy/"
    class="font-semibold text-primary-sf-navy dark:text-secondary-sf-blue-light"
  >
    click here
  </a>
</p>

<p class="text-center mt-4">App version: {{ appConstants.version }}</p>
