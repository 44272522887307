<div class="sf-card mb-2">
  <div class="m-4 max-xs:m-0">
    <h6>Year investing history</h6>
    <div #chart echarts [options]="options" theme="macarons"></div>
    <div
      class="flex items-center justify-center p-2.5 bg-sf-background-light dark:bg-sf-white-10-opacity shadow-sm rounded-md m-auto w-full"
    >
      <div class="flex items-center my-0 mx-2.5">
        <div class="w-5 h-2.5 rounded-md mr-[5px] bg-sf-mid-grey"></div>
        <span class="max-xs:text-sm">Total Ins</span>
      </div>
      <div class="flex items-center my-0 mx-2.5">
        <div class="w-5 h-2.5 rounded-md mr-[5px] bg-primary-sf-yellow"></div>
        <span class="max-xs:text-sm">Withdrawals</span>
      </div>
    </div>
  </div>
</div>
