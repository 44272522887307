import { PlanCapacities } from './plan-capacities.model';

export class ClientConfiguration {
  public today: string;

  public useLiveWorldpayConfiguration: boolean;

  public useLiveStripeConfiguration: boolean;

  public planCapacities: PlanCapacities;
}
