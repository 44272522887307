<app-content-header
  *ngIf="plan && showParentContent"
  [title]="
    'Make a withdrawal' +
    (step !== stepOpts['AMOUNT']
      ? ': ' + (withdrawalForm.value.amount | currency : 'GBP' : 'symbol' : '1.2-2')
      : '')
  "
  [isButtonOff]="true"
  [subtitle]="plan.planTypeName + ' - ' + plan.formattedPlanNumber"
></app-content-header>

<app-steps
  *ngIf="showParentContent && !hideStepper"
  [currentStep]="step"
  [enumType]="stepOpts"
></app-steps>

<form
  *ngIf="plan && showParentContent"
  [ngClass]="{ hidden: submittingWithdrawal }"
  id="member-withdrawal-form"
  [formGroup]="withdrawalForm"
  (ngSubmit)="onSubmit(withdrawalForm)"
  (keydown.enter)="$event.preventDefault()"
>
  <div [ngClass]="{ 'hidden-step': !(step === stepOpts['AMOUNT']) }">
    <div
      class="bg-primary-sf-navy dark:bg-sf-white/5 rounded-t-lg relative bg-[url('assets/images/sf-faint-logo.svg')] bg-no-repeat bg-right-bottom max-xs:p-2 p-4 md:p-6 lg:p-8"
    >
      <h3 class="text-white text-lg font-bold">Current Balance</h3>
      <p class="text-primary-sf-yellow font-bold text-3xl my-1">£8,603.33</p>
    </div>
    <div class="sf-card mb-4 !rounded-tl-none !rounded-tr-none">
      <p class="border-b border-primary-sf-navy/10 dark:border-sf-white/10 pb-4">
        To keep your plan open, you will need a
        <span class="font-bold">minimum balance of £30.</span>
      </p>

      <div class="mb-2">
        <label for="projectionChoice" class="form-label">
          Temporary projection scenario test helper
        </label>
        <div>
          <select
            id="projectionChoice"
            name="projectionChoice"
            formControlName="projectionChoice"
            class="form-control"
          >
            <option value="A">[A] A relatively small withdrawal</option>
            <option value="B">[B] A relatively small withdrawal with large DD</option>
            <option value="C">[C] A relatively small withdrawal with no DD</option>
            <option value="D">[D] A large withdrawal</option>
            <option value="E">[E] A large withdrawal with large DD</option>
            <option value="F">[F] Leaving only a £30 balance</option>
            <option value="G">[G] Full withdrawal - Showing a different screen</option>
          </select>
        </div>
      </div>

      <label for="amount" class="form-label">Enter withdrawal amount (£)</label>
      <div>
        <input
          id="amount"
          name="amount"
          formControlName="amount"
          type="number"
          min="0"
          step="0.01"
          class="form-control"
          placeholder="£"
          required
          [ngClass]="{
              'is-invalid': amountCtrl.touched && amountCtrl.errors,
            }"
        />
      </div>

      <div
        class="rounded-md max-xs:p-2 p-4 mt-3 md:mt-4 xl:mt-6 md:p-6 dark:border"
        [ngClass]="
          selectedProjection.requestedWithdrawal >= selectedProjection.currentBalance ||
          selectedProjection.balanceAfterWithdrawal <= 30
            ? 'bg-sf-error-tint dark:bg-sf-toast-error-swap dark:border-sf-error-border'
            : 'bg-sf-info-a-tint dark:bg-sf-info-a-tint-dark dark:border-primary-sf-yellow'
        "
      >
        <div class="flex items-center justify-center gap-3">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_488_1814)">
              <path
                d="M21.75 18V21C21.75 21.3978 21.592 21.7794 21.3107 22.0607C21.0294 22.342 20.6478 22.5 20.25 22.5H3C2.40326 22.5 1.83097 22.2629 1.40901 21.841C0.987053 21.419 0.75 20.8467 0.75 20.25V3.75C0.75 3.15326 0.987053 2.58097 1.40901 2.15901C1.83097 1.73705 2.40326 1.5 3 1.5H18.75C19.1478 1.5 19.5294 1.65804 19.8107 1.93934C20.092 2.22064 20.25 2.60218 20.25 3V5.25"
                class="stroke-primary-sf-navy dark:stroke-sf-white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.75 18C22.1478 18 22.5294 17.842 22.8107 17.5607C23.092 17.2794 23.25 16.8978 23.25 16.5V12C23.25 11.6022 23.092 11.2206 22.8107 10.9393C22.5294 10.658 22.1478 10.5 21.75 10.5H18C17.0054 10.5 16.0516 10.8951 15.3483 11.5983C14.6451 12.3016 14.25 13.2554 14.25 14.25C14.25 15.2446 14.6451 16.1984 15.3483 16.9017C16.0516 17.6049 17.0054 18 18 18H21.75Z"
                class="stroke-primary-sf-navy dark:stroke-sf-white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18 13.875C18.0742 13.875 18.1467 13.897 18.2083 13.9382C18.27 13.9794 18.3181 14.038 18.3465 14.1065C18.3748 14.175 18.3823 14.2504 18.3678 14.3232C18.3533 14.3959 18.3176 14.4627 18.2652 14.5152C18.2127 14.5676 18.1459 14.6033 18.0732 14.6178C18.0004 14.6323 17.925 14.6248 17.8565 14.5965C17.788 14.5681 17.7294 14.52 17.6882 14.4583C17.647 14.3967 17.625 14.3242 17.625 14.25C17.625 14.1505 17.6645 14.0552 17.7348 13.9848C17.8052 13.9145 17.9005 13.875 18 13.875Z"
                class="stroke-primary-sf-navy dark:stroke-sf-white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.75 10.5005V6.75047C21.75 6.35264 21.592 5.97111 21.3106 5.68981C21.0293 5.4085 20.6478 5.25047 20.25 5.25047H5.62499C5.2994 5.26578 4.97598 5.19017 4.69093 5.0321C4.40588 4.87403 4.17044 4.63975 4.01099 4.35547"
                class="stroke-primary-sf-navy dark:stroke-sf-white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_488_1814">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <p class="mb-0 font-bold text-primary-sf-navy dark:text-sf-white">
            Balance after withdrawal:
            <span class="font-normal">£{{ selectedProjection.balanceAfterWithdrawal }}</span>
          </p>
        </div>
      </div>

      <div *ngIf="amountCtrl.touched && amountCtrl.errors">
        <app-validation-message
          *ngIf="amountCtrl.errors['rangeValueToBig']"
          message="The deposit amount entered will exceed your remaining allowance in this tax year. The maximum amount you can deposit is
                                £{{ plan.taxAllowanceRemaining }}. Please adjust the deposit amount.
                                "
        ></app-validation-message>

        <app-validation-message
          *ngIf="amountCtrl.errors['rangeValueToSmall']"
          message="The minimum amount you can deposit for an
                                {{ plan.planTypePrefix }} is £{{ plan.topUpMin }}."
        ></app-validation-message>
      </div>

      <app-alert
        *ngIf="selectedProjection.requestedWithdrawal < plan.topUpMin"
        type="warning"
        additionalClass="my-4"
        imageSmallSrc="exclamation-triangle"
        imageSmallAlt="exclamation-triangle icon"
        title="Below £10 minimum"
        message="A withdrawal request on your {{ plan.planTypePrefix }} must be a minimum of £{{
          plan.topUpMin
        }}."
      ></app-alert>

      <app-alert
        *ngIf="selectedProjection.balanceAfterWithdrawal < 30"
        type="warning"
        additionalClass="my-4"
        imageSmallSrc="exclamation-triangle"
        imageSmallAlt="exclamation-triangle icon"
        title="Insufficient funds"
        message="This withdrawal amount will take your balance below the minimum amount of £30. You may wish to reduce the amount you'd like to withdraw, so your plan can stay open."
      ></app-alert>

      <app-button
        text="Proceed with your withdrawal"
        (click)="goToInfoStep()"
        buttonStyle="secondary"
        [noBorder]="true"
        [isSubmitButton]="false"
        [disabled]="!amountCtrl.valid"
        customClass="mt-4"
      ></app-button>
    </div>
  </div>

  <div [ngClass]="{ 'hidden-step': !(step === stepOpts['INFO']) }">
    <div class="sf-card mb-4">
      <ng-container
        *ngIf="
          selectedProjection.currentBalance === selectedProjection.requestedWithdrawal;
          else regularContent
        "
      >
        <h3 class="font-bold mb-4 text-xl">Confirmation needed</h3>
        <p>
          Before you continue, please confirm that you would like to make a full withdrawal of your
          <strong>
            {{ selectedProjection.currentBalance | currency : 'GBP' : 'symbol' : '1.2-2' }}
          </strong>
          balance. If you choose to do this, your plan will be closed and any Direct Debits paying
          into it will be cancelled.
        </p>
        <p class="mb-8">
          Alternatively, you can choose to keep your plan open by ensuring that you have at least
          <strong>£30</strong>
          in your account.
        </p>

        <app-button
          text="Cancel your withdrawal"
          (click)="navigateToPlanOverview()"
          [noBorder]="true"
          buttonStyle="warning"
          [isSubmitButton]="false"
          customClass="mb-4"
        ></app-button>

        <app-button
          text="Maintain £30 balance"
          (click)="onMaintainMinimumBalance()"
          [noBorder]="true"
          buttonStyle="primary"
          [isSubmitButton]="false"
          customClass="mb-4"
        ></app-button>

        <app-button
          text="Continue with your withdrawal"
          (click)="goToDetailsStep()"
          [noBorder]="true"
          buttonStyle="secondary"
          [isSubmitButton]="false"
        ></app-button>
      </ng-container>

      <ng-template #regularContent>
        <h3 class="font-bold mb-4 text-xl">
          How could withdrawing affect my investment over the next
          {{ selectedProjection.investmentPeriod }} years?
        </h3>
        <p>
          Withdrawing
          <strong>
            {{ selectedProjection.requestedWithdrawal | currency : 'GBP' : 'symbol' : '1.2-2' }}
          </strong>
          now could affect the growth potential of your investment in the future. Please see the
          examples below for more information on how your money could grow over the next
          {{ selectedProjection.investmentPeriod }} years, using the
          <strong>current {{ selectedProjection.interestRate }}%</strong>
          rate as an example. Please note this is for illustration purposes only, the rate is not
          guaranteed and is subject to change.
        </p>
        <div>
          <app-withdrawal-projection
            [withdrawalProjection]="selectedProjection"
            [title]="selectedProjection.title"
          ></app-withdrawal-projection>
        </div>

        <section class="growth-warning text-center mb-4">
          <div
            class="p-6 rounded-lg mb-4 bg-sf-error-tint border border-sf-error-border dark:bg-sf-error-tint-dark"
          >
            <h3 class="text-xl font-bold text-primary-sf-navy dark:text-sf-white">
              You could miss out on
              <span class="text-sf-red">
                {{
                  selectedProjection.interestReductionDiff | currency : 'GBP' : 'symbol' : '1.2-2'
                }}
              </span>
              additional growth
            </h3>
            <p>
              on your investment over the next
              {{ selectedProjection.investmentPeriod }} years. Based on an example rate of
              {{ selectedProjection.interestRate }}% AER.
            </p>
          </div>
        </section>

        <p class="mb-8">
          By continuing with your withdrawal you confirm that you have read the above information
          and understand that withdrawing from my Stocks & Shares ISA may negatively impact how much
          your investment could grow in the future.
        </p>

        <div class="md:grid md:gap-6 md:grid-cols-[repeat(2,1fr)]">
          <app-button
            text="Cancel your withdrawal"
            (click)="navigateToPlanOverview()"
            [noBorder]="true"
            buttonStyle="warning"
            [isSubmitButton]="false"
            customClass="max-md:mb-4"
            [fullWidth]="true"
          ></app-button>

          <app-button
            text="Continue with your withdrawal"
            (click)="goToDetailsStep()"
            [noBorder]="true"
            buttonStyle="secondary"
            [isSubmitButton]="false"
            [fullWidth]="true"
          ></app-button>
        </div>
      </ng-template>
    </div>
  </div>

  <div [ngClass]="{ 'hidden-step': !(step === stepOpts['DETAILS']) }">
    <div class="sf-card mb-4">
      <div>
        <app-withdrawal-friction-questions
          *ngIf="!frictionQuestionsCompleted"
          (complete)="onFrictionQuestionsComplete($event)"
          (cancel)="onFrictionQuestionsCancel()"
          (decline)="onFrictionQuestionsDecline()"
        ></app-withdrawal-friction-questions>
      </div>
      <div class="mb-6" *ngIf="frictionQuestionsCompleted && !useDifferentAccount">
        <h3 class="font-bold mb-4 text-xl">Where your money will be sent</h3>
        <p>
          Please review the bank details below. This is the account we have on file for you and we
          will pay your withdrawal amount into this account. If you are happy with this and are
          satisfied the details are correct, please confirm and proceed. Alternatively, you can

          <a class="underline" (click)="toggleAccountDetails()">
            request for the funds to be paid into a different account.
          </a>
        </p>

        <div
          class="flex justify-between bg-sf-white dark:bg-primary-sf-navy py-4 border-y border-y-primary-sf-navy/10 dark:border-y-sf-white/10"
        >
          <span
            class="max-sm:text-sm text-base font-semibold text-primary-sf-navy dark:text-sf-white"
          >
            Account name
          </span>
          <span class="max-sm:text-sm text-base text-sf-body-font dark:text-sf-white">
            {{ accountDetails.controls['accountName'].value }}
          </span>
        </div>

        <div
          class="bg-sf-white dark:bg-primary-sf-navy py-4 flex justify-between border-b border-b-primary-sf-navy/10 dark:border-b-sf-white/10"
        >
          <span
            class="max-sm:text-sm text-base font-semibold text-primary-sf-navy dark:text-sf-white"
          >
            Sort code
          </span>

          <span class="max-sm:text-sm text-base text-sf-body-font dark:text-sf-white">
            <span *ngIf="!hasAlreadyMaskedSortCode; else nonMaskSortcode">
              {{ accountDetails.controls['sortCode'].value | mask : sortCodeMask }}
            </span>
            <ng-template #nonMaskSortcode>
              {{ accountDetails.controls['sortCode'].value }}
            </ng-template>
          </span>
        </div>

        <div
          class="bg-sf-white dark:bg-primary-sf-navy py-4 flex justify-between border-b border-b-primary-sf-navy/10 dark:border-b-sf-white/10"
        >
          <span
            class="max-sm:text-sm text-base font-semibold text-primary-sf-navy dark:text-sf-white"
          >
            Account number
          </span>
          <span class="max-sm:text-sm text-base text-sf-body-font dark:text-sf-white">
            {{ accountDetails.controls['accountNumber'].value }}
          </span>
        </div>
      </div>

      <!-- Show new account fields if toggled -->
      <div *ngIf="frictionQuestionsCompleted && useDifferentAccount" class="mt-4">
        <h3 class="font-bold mb-4 text-xl">Which bank account should we send the money to?</h3>
        <p>
          Please enter the details of your chosen bank account below. We may need to carry out some
          additional checks to confirm this.
        </p>
        <hr class="border-gray-500 border-opacity-25 border-b mb-6" />
        <h3 class="font-bold mb-4 text-xl">Your account details</h3>
        <div formGroupName="accountDetails">
          <div class="mb-4">
            <label for="accountName" class="form-label">Account name</label>
            <div>
              <input
                id="accountName"
                name="accountName"
                type="text"
                formControlName="accountName"
                class="form-control"
                placeholder="Enter account name"
                required
                [ngClass]="{
                  'is-invalid':
                    accountDetails.controls['accountName'].touched &&
                    accountDetails.controls['accountName'].errors
                }"
              />
            </div>
          </div>

          <div class="mb-4">
            <label for="accountNumber" class="form-label">Account number</label>
            <div>
              <input
                id="accountNumber"
                name="accountNumber"
                type="text"
                formControlName="accountNumber"
                class="form-control"
                placeholder="Enter account number"
                required
                [ngClass]="{
                  'is-invalid':
                    accountDetails.controls['accountNumber'].touched &&
                    accountDetails.controls['accountNumber'].errors
                }"
              />
            </div>
          </div>

          <div class="mb-4">
            <label for="sortCode" class="form-label">Sort code</label>
            <div>
              <input
                id="sortCode"
                name="sortCode"
                type="text"
                formControlName="sortCode"
                class="form-control"
                placeholder="Enter sort code"
                [mask]="sortCodeMask"
                required
                [ngClass]="{
                  'is-invalid':
                    accountDetails.controls['sortCode'].touched &&
                    accountDetails.controls['sortCode'].errors
                }"
              />
            </div>
          </div>

          <app-checkbox
            [additionalClass]="'pb-4'"
            label="I confirm these details are correct"
            formControlName="confirmDetails"
          ></app-checkbox>
        </div>
      </div>

      <div *ngIf="frictionQuestionsCompleted">
        <app-button
          text="Proceed with your withdrawal"
          (click)="goToConfirmStep()"
          buttonStyle="secondary"
          customClass="[&>*]:py-4 [&>*]:px-8"
          [noBorder]="true"
          [isSubmitButton]="false"
          [disabled]="useDifferentAccount ? !accountDetails.valid : false"
        ></app-button>
      </div>
    </div>
  </div>

  <div [ngClass]="{ 'hidden-step': !(step === stepOpts['CONFIRM']) }">
    <div class="sf-card mb-4">
      <div>
        <p class="text-lg font-semibold text-primary-sf-navy dark:text-sf-white">Confirmation</p>
        <p>
          Please carefully review the following information regarding your withdrawal request to
          make sure it is correct. You have requested to make a withdrawal from the below Shepherds
          Friendly plan:
        </p>
        <div>
          <div
            class="flex justify-between py-4 border-y border-y-primary-sf-navy/10 dark:border-y-sf-white/10"
          >
            <span
              class="max-sm:text-sm text-base font-semibold text-primary-sf-navy dark:text-sf-white"
            >
              Plan number
            </span>
            <span class="max-sm:text-sm text-base text-sf-body-font dark:text-sf-white">
              {{ plan.formattedPlanNumber }}
            </span>
          </div>

          <div
            class="flex justify-between py-4 border-b border-b-primary-sf-navy/10 dark:border-b-sf-white/10"
          >
            <span
              class="max-sm:text-sm text-base font-semibold text-primary-sf-navy dark:text-sf-white"
            >
              Current balance
            </span>
            <span class="max-sm:text-sm text-base text-sf-body-font dark:text-sf-white">
              {{ plan.currentValue | currency : 'GBP' : 'symbol' : '1.2-2' }}
            </span>
          </div>

          <div
            class="flex justify-between py-4 border-b border-b-primary-sf-navy/10 dark:border-b-sf-white/10"
          >
            <span
              class="max-sm:text-sm text-base font-semibold text-primary-sf-navy dark:text-sf-white"
            >
              Withdrawal amount
            </span>
            <span class="max-sm:text-sm text-base text-sf-body-font dark:text-sf-white">
              {{ withdrawalForm.value.amount | currency : 'GBP' : 'symbol' : '1.2-2' }}
            </span>
          </div>

          <div
            class="flex justify-between py-4 border-b border-b-primary-sf-navy/10 dark:border-b-sf-white/10"
          >
            <span
              class="max-sm:text-sm text-base font-semibold text-primary-sf-navy dark:text-sf-white"
            >
              Balance after withdrawal
            </span>
            <span class="max-sm:text-sm text-base text-sf-body-font dark:text-sf-white">
              [BALANCE AFTER WITHDRAWAL]
            </span>
          </div>

          <p class="pt-4">The funds you have requested will be paid into the bank account below:</p>

          <div
            class="flex justify-between bg-sf-white dark:bg-primary-sf-navy py-4 border-y border-y-primary-sf-navy/10 dark:border-y-sf-white/10"
          >
            <span
              class="max-sm:text-sm text-base font-semibold text-primary-sf-navy dark:text-sf-white"
            >
              Account name
            </span>
            <span class="max-sm:text-sm text-base text-sf-body-font dark:text-sf-white">
              {{ accountDetails.controls['accountName'].value }}
            </span>
          </div>

          <div
            class="bg-sf-white dark:bg-primary-sf-navy py-4 flex justify-between border-b border-b-primary-sf-navy/10 dark:border-b-sf-white/10"
          >
            <span
              class="max-sm:text-sm text-base font-semibold text-primary-sf-navy dark:text-sf-white"
            >
              Sort code
            </span>

            <span class="max-sm:text-sm text-base text-sf-body-font dark:text-sf-white">
              <span *ngIf="!hasAlreadyMaskedSortCode; else nonMaskSortcode">
                {{ accountDetails.controls['sortCode'].value | mask : sortCodeMask }}
              </span>
              <ng-template #nonMaskSortcode>
                {{ accountDetails.controls['sortCode'].value }}
              </ng-template>
            </span>
          </div>

          <div
            class="bg-sf-white dark:bg-primary-sf-navy py-4 flex justify-between border-b border-b-primary-sf-navy/10 dark:border-b-sf-white/10"
          >
            <span
              class="max-sm:text-sm text-base font-semibold text-primary-sf-navy dark:text-sf-white"
            >
              Account number
            </span>
            <span class="max-sm:text-sm text-base text-sf-body-font dark:text-sf-white">
              {{ accountDetails.controls['accountNumber'].value }}
            </span>
          </div>
        </div>
        <app-button
          text="Confirm and complete request"
          buttonStyle="secondary"
          btnWrapperClasses="mt-4"
          [noBorder]="true"
          [isSubmitButton]="true"
          [disabled]="submittingWithdrawal || !withdrawalForm.valid"
        ></app-button>
      </div>
    </div>
  </div>
</form>

<router-outlet />
