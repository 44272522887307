import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, shareReplay } from 'rxjs/operators';
import { AuthorisedPlans } from '../models/member-portal/authorised-plans.model';
import { ClientConfiguration } from '../models/member-portal/client-configuration.model';
import { DirectDebitInformation } from '../models/member-portal/direct-debit-information.model';
import { DirectDebit } from '../models/member-portal/direct-debit.model';
import { IncomeProtectionPlan } from '../models/member-portal/income-protection-plan.model';
import { MemberDashboard } from '../models/member-portal/member-dashboard.model';
import { MemberPlans } from '../models/member-portal/member-plans.model';
import { Member } from '../models/member-portal/member.model';
import { Over50sPlan } from '../models/member-portal/over50s-plan.model';
import { DocumentList } from '../models/member-portal/Pdf/member-document-list.model';
import { PlanCapacities } from '../models/member-portal/plan-capacities.model';
import { Registrant } from '../models/member-portal/registrant.model';
import { SavingsPlan } from '../models/member-portal/savings-plan.model';
import { TrackingInformation } from '../models/member-portal/tracking.model';
import { AppConstantsService, DocumentService, HttpService, MemberDashboardCacheService } from '.';

interface MemberDashboardOptions {
  includeDocs?: boolean;
}

@Injectable({ providedIn: 'root' })
export class MemberPortalService {
  private _hasVerificationRequirement = new BehaviorSubject<boolean>(false);
  public readonly hasVerificationRequirement$ = this._hasVerificationRequirement.asObservable();

  public readonly api = {
    GET_MEMBER_DASHBOARD_NO_DOCUMENTS: `${this.configuration.server}/api/Member/GetMemberDashboard?excludeDocuments=true`,
    GET_MEMBER_DASHBOARD_FULL: `${this.configuration.server}/api/Member/GetMemberDashboard`,
  };

  constructor(
    private http: HttpService,
    private configuration: AppConstantsService,
    private memberDashboardCacheService: MemberDashboardCacheService,
    private documentService: DocumentService
  ) {}

  /**
   * Checks state management for a recent copy of `MemberDashboard`, if outdated or non existant,
   * a new copy is retrieved and recached. An optional MemberDashboardOptions object can be passed to
   * include docs (another http request which merges the returned payload)
   * @param {MemberDashboardOptions} [MemberDashboardOptions]
   * @return Observable that emits the latest MemberDashboard
   */
  public getMemberDashboard(options: MemberDashboardOptions = {}): Observable<MemberDashboard> {
    let memberDashboard$ = this.memberDashboardCacheService.getValue();

    if (!memberDashboard$) {
      memberDashboard$ = this.http
        .get<MemberDashboard>(this.api.GET_MEMBER_DASHBOARD_NO_DOCUMENTS)
        .pipe(
          map(
            (response) =>
              new MemberDashboard(
                this.mapRegistrant(response.registrant),
                this.mapMember(response.member),
                this.mapMemberPlans(response.memberPlans),
                this.mapAuthorisedPlans(response.authorisedPlans),
                this.mapClientConfiguration(response.clientConfiguration),
                this.mapDocuments(response.documents),
                response.continuationToken || ''
              )
          ),
          shareReplay(1),
          catchError((error) => EMPTY)
        );
      this.memberDashboardCacheService.setValue(memberDashboard$);
    }

    // Only check for includeDocs option now.
    if (options.includeDocs) {
      return memberDashboard$.pipe(
        mergeMap((memberDashboard) => {
          if (memberDashboard.continuationToken) {
            return this.documentService.getMemberDocuments(memberDashboard.continuationToken).pipe(
              map((documents) => {
                const updatedDashboard = new MemberDashboard(
                  memberDashboard.registrant,
                  memberDashboard.member,
                  memberDashboard.memberPlans,
                  memberDashboard.authorisedPlans,
                  memberDashboard.clientConfiguration,
                  documents,
                  memberDashboard.continuationToken
                );
                this.memberDashboardCacheService.setValue(
                  of(updatedDashboard).pipe(shareReplay(1))
                );
                return updatedDashboard;
              })
            );
          } else {
            const updatedDashboard = new MemberDashboard(
              memberDashboard.registrant,
              memberDashboard.member,
              memberDashboard.memberPlans,
              memberDashboard.authorisedPlans,
              memberDashboard.clientConfiguration,
              { memberRows: [] }, // Default empty documents
              memberDashboard.continuationToken
            );
            this.memberDashboardCacheService.setValue(of(updatedDashboard).pipe(shareReplay(1)));
            return of(updatedDashboard);
          }
        })
      );
    }

    return memberDashboard$;
  }

  /**
   * Clears the current cache for this service
   */
  public clearCache(): void {
    this.memberDashboardCacheService.clearCache();
  }

  /**
   * Checks `memberDashboard` that `memberPlans` exists
   * @param {MemberDashboard} [memberDashboard]
   * @return true if memberDashboard has `memberPlans`
   */
  private mapMemberDashboard(source: any): MemberDashboard {
    return new MemberDashboard(
      this.mapRegistrant(source.registrant),
      this.mapMember(source.member),
      this.mapMemberPlans(source.memberPlans),
      this.mapAuthorisedPlans(source.authorisedPlans),
      this.mapClientConfiguration(source.clientConfiguration),
      this.mapDocuments(source.documents),
      source.continuationToken
    );
  }

  private mapRegistrant(source: any): Registrant {
    let registrant = new Registrant('', false, '', '', '', false);

    if (source != null) {
      registrant = new Registrant(
        source.registrationId as string,
        source.awaitingAddressVerification as boolean,
        source.emailAddress as string,
        source.firstname as string,
        source.surname as string,
        true
      );
    }

    if (registrant.awaitingAddressVerification) {
      this._hasVerificationRequirement.next(true);
    }

    return registrant;
  }

  private mapMember(source: any): Member {
    let member = new Member(-1, '', '', '', '', '', '', '', '', '', '', '', '', '', false);

    if (source != null) {
      member = new Member(
        source.memberId as number,
        source.title as string,
        source.firstname as string,
        source.surname as string,
        source.email as string,
        source.dob as string,
        source.telephone as string,
        source.mobileTelephone as string,
        source.businessTelephone as string,
        source.address1 as string,
        source.address2 as string,
        source.address3 as string,
        source.townCity as string,
        source.postcode as string,
        true
      );
    }

    return member;
  }

  private mapMemberPlans(source: any): MemberPlans {
    const memberPlans = new MemberPlans();

    if (source != null) {
      if (source.savingsPlans != null) {
        memberPlans.savingsPlans = this.mapSavingsPlans(source.savingsPlans);
      }
      if (source.over50sPlans != null) {
        memberPlans.over50sPlans = this.mapOver50sPlans(source.over50sPlans);
      }
      if (source.incomeProtectionPlans != null) {
        memberPlans.incomeProtectionPlans = this.mapIncomeProtectionPlans(
          source.incomeProtectionPlans
        );
      }
    }

    return memberPlans;
  }

  private mapAuthorisedPlans(source: any): AuthorisedPlans {
    const authPlans = new AuthorisedPlans();

    if (source != null && source.authorisedSavingsPlans != null) {
      authPlans.authorisedSavingsPlans = source.authorisedSavingsPlans.map((authPlan: any) => {
        return {
          member: this.mapMember(authPlan.member),
          plan: this.mapSavingsPlan(authPlan.plan),
        };
      });
    }

    return authPlans;
  }

  private mapSavingsPlans(source: any): SavingsPlan[] {
    return source.map((plan: any) => this.mapSavingsPlan(plan));
  }

  private mapSavingsPlan(source: any): SavingsPlan {
    const directDebitInformation = this.mapDirectDebitInformation(source.directDebitInformation);
    const trackingInformation = this.mapTrackingInfo(source.tracking);

    return new SavingsPlan(
      source.planId,
      source.planTypeId,
      source.planTypeName,
      source.planStatusId,
      source.planStatusName,
      source.planNumber,
      source.formattedPlanNumber,
      source.startDate,
      source.premium,
      source.isNetPremium,
      source.planTypePrefix,
      source.endDate,
      source.premiumFrequency,
      source.currentValue,
      source.annualBonus,
      source.annualBonusYear,
      source.taxYearAllowance,
      source.taxAllowanceRemaining,
      source.taxYearDescription,
      source.totalContributions,
      source.totalWithdrawals,
      source.taxYearContributions,
      source.taxYearWithdrawals,
      source.sumAssured,
      directDebitInformation,
      source.planIdentifier,
      source.capacity,
      source.pendingTransactionAmount,
      source.schemeType,
      source.planGroup,
      source.topUpMin,
      source.topUpMax,
      source.planMinimumPayment,
      trackingInformation
    );
  }

  private mapIncomeProtectionPlans(source: any): Array<IncomeProtectionPlan> {
    const incomeProtectionPlans = new Array<IncomeProtectionPlan>();

    if (source != null) {
      for (const plan of source) {
        const incomeProtectionPlan = new IncomeProtectionPlan(
          plan.planId,
          plan.planTypeId,
          plan.planTypeName,
          plan.planStatusId,
          plan.planStatusName,
          plan.planNumber,
          plan.formattedPlanNumber,
          plan.startDate,
          plan.premium,
          plan.isNetPremium,
          plan.planTypePrefix,
          plan.endDate,
          plan.benefitAmount,
          plan.capacity
        );

        incomeProtectionPlans.push(incomeProtectionPlan);
      }
    }

    return incomeProtectionPlans;
  }

  private mapOver50sPlans(source: any): Array<Over50sPlan> {
    const over50sPlans = new Array<Over50sPlan>();

    if (source != null) {
      for (const plan of source) {
        const over50sPlan = new Over50sPlan(
          plan.planId,
          plan.planTypeId,
          plan.planTypeName,
          plan.planStatusId,
          plan.planStatusName,
          plan.planNumber,
          plan.formattedPlanNumber,
          plan.startDate,
          plan.premium,
          plan.isNetPremium,
          plan.planTypePrefix,
          plan.sumAssured,
          plan.capacity
        );

        over50sPlans.push(over50sPlan);
      }
    }

    return over50sPlans;
  }

  private mapDirectDebitInformation(source: any): DirectDebitInformation {
    const ddi = new DirectDebitInformation();

    if (source != null) {
      ddi.noActiveFound = source.noActiveFound;
      ddi.singleActiveFound = source.singleActiveFound;
      ddi.multipleActiveFound = source.multipleActiveFound;

      const ddiDirectDebits = source.directDebits?.map(
        (directDebit: any) =>
          new DirectDebit(
            directDebit.id,
            directDebit.sortCode,
            directDebit.acNumber,
            directDebit.acName,
            directDebit.amount,
            directDebit.period,
            directDebit.periodDue,
            directDebit.dayDue,
            directDebit.startDate,
            directDebit.isLive,
            directDebit.nextPaymentDate,
            directDebit.changeCount,
            directDebit.followingPaymentAmount,
            directDebit.followingPaymentDate
          )
      );

      ddi.directDebits = ddiDirectDebits || [];
    }

    return ddi;
  }

  private mapTrackingInfo(source: any): TrackingInformation {
    if (!source) return null;

    return new TrackingInformation(
      source.$id,
      source.utmCampaign,
      source.utmMedium,
      source.utmTerm
    );
  }

  private mapClientConfiguration(source: any): ClientConfiguration {
    const clientConfig = new ClientConfiguration();

    if (source != null) {
      clientConfig.today = source.today;
      clientConfig.useLiveWorldpayConfiguration = source.useLiveWorldpayConfiguration;
      clientConfig.useLiveStripeConfiguration = source.useLiveStripeConfiguration;
      clientConfig.planCapacities = this.mapPlanCapacities(source.planCapacities);
    }

    return clientConfig;
  }

  private mapPlanCapacities(source: any): PlanCapacities {
    const planCapacities = new PlanCapacities();

    if (source != null) {
      planCapacities.owner = source.owner;
      planCapacities.authorisedContact = source.authorisedContact;
    }

    return planCapacities;
  }

  private mapDocuments(source: any): DocumentList {
    const documents = new DocumentList();
    documents.memberRows = source?.memberRows || [];

    return documents;
  }
}
