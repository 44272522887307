import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-up-declaration',
  templateUrl: './top-up-declaration.component.html',
  styleUrls: ['./top-up-declaration.component.scss'],
})
export class TopUpDeclarationComponent implements OnInit {
  @Input() planTypePrefix: string = 'ISA';
  @Input() importantInfoGuideLink: string = '';

  public planType: string;

  ngOnInit(): void {
    // Default SJISA/SISA to ISA/JISA for the declaration text
    if (['ISA', 'SISA'].includes(this.planTypePrefix)) {
      this.planType = 'ISA';
    } else if (['JISA', 'SJISA'].includes(this.planTypePrefix)) {
      this.planType = 'JISA';
    }
  }
}
