import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

export interface AccordionItem {
  title: string;
  content: string | string[];
}

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*',
          overflow: 'hidden',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
        })
      ),
      transition(
        'closed => open',
        animate('200ms', keyframes([style({ height: '0' }), style({ height: '*', opacity: 0.3 })]))
      ),
      transition(
        'open => closed',
        animate(
          '200ms',
          keyframes([
            style({ height: '50px', opacity: 0.5 }),
            style({ height: '10px', opacity: 0.2 }),
          ])
        )
      ),
    ]),
  ],
  standalone: false,
})
export class AccordionComponent {
  @Input() accordionHeader: string;
  @Input() accordionContent: string;
  @Input() items: AccordionItem[] = [];
  openItemIndex: number[] = [];

  toggleItem(index: number): void {
    if (this.isOpen(index)) {
      this.closeItem(index);
    } else {
      this.openItem(index);
    }
  }

  isOpen(index: number): boolean {
    return this.openItemIndex.includes(index);
  }

  openItem(index: number): void {
    this.openItemIndex.push(index);
  }

  isItemOpen(index: number): string {
    return this.isOpen(index) ? 'open' : 'closed';
  }

  closeItem(index: number): void {
    this.openItemIndex = this.openItemIndex.filter((i) => i !== index);
  }

  isContentArray(item: AccordionItem): boolean {
    return Array.isArray(item.content);
  }
}
