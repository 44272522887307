import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PostcodeService } from '@app/services';
import { RegexUtility } from '@app/shared/utilities';
import { catchError, throwError } from 'rxjs';

@Component({
    selector: 'app-address-picker',
    templateUrl: './address-picker.component.html',
    styleUrls: ['./address-picker.component.css'],
    standalone: false
})
export class AddressPickerComponent implements OnInit {
  @Output() onSelectedAddress: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEnterManually: EventEmitter<boolean> = new EventEmitter<boolean>();

  public addressForm: FormGroup;

  public addressOptions = [];
  public searchPostCode: string;
  public invalidPostCode: boolean = false;
  public enterManually: boolean = false;

  public lineOneCtrl: AbstractControl;
  public lineTwoCtrl: AbstractControl;
  public countyCtrl: AbstractControl;
  public cityCtrl: AbstractControl;
  public postCodeCtrl: AbstractControl;

  public addressComplete: boolean = false;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    private postCodeService: PostcodeService
  ) {}

  ngOnInit(): void {
    this.buildForm();

    this.lineOneCtrl = this.addressForm.controls['line1'];
    this.lineTwoCtrl = this.addressForm.controls['line2'];
    this.countyCtrl = this.addressForm.controls['county'];
    this.cityCtrl = this.addressForm.controls['city'];
    this.postCodeCtrl = this.addressForm.controls['postcode'];
  }

  /**
   * Format the form address and exclude any empty fields
   */
  public get formattedAddress(): string {
    return [
      this.lineOneCtrl.value,
      this.lineTwoCtrl.value,
      this.countyCtrl.value,
      this.cityCtrl.value,
      this.postCodeCtrl.value.toUpperCase(),
    ]
      .filter(Boolean)
      .join(',\r\n');
  }

  /**
   * Checks postcode validity and uses postcode finder service to return eligble addresses
   */
  public searchAddress(): void {
    const postCode = this.searchPostCode.toUpperCase();

    if (this.checkPostCodeValidity(postCode)) {
      this.postCodeService
        .search(postCode, 'English', 'None')
        .pipe(catchError((error) => throwError(() => `searchPostcode -> ${error}`)))
        .subscribe((res) => {
          if (res.length > 0) {
            this.addressOptions = res;
            this.invalidPostCode = false;
          } else {
            this.invalidPostCode = true;
          }
        });
    } else {
      this.invalidPostCode = true;
    }
  }

  /**
   * Patches the selected address to the address form, or starts manual entry if they select not found
   * @param {any} [event]
   */
  public selectAddress(event: any): void {
    const id = event.target.value;

    if (id === 'notFound') {
      this.enterManually = true;
    } else {
      this.postCodeService.getById(id, 'English').subscribe((res) => {
        Object.keys(this.addressForm.controls).forEach((key) => {
          if (res[0][key]) {
            this.addressForm.controls[key].setValue(res[0][key]);
          } else {
            this.addressForm.controls[key].setValue('');
          }
        });

        this.emitSelectedAddress();
      });
    }
  }

  public emitSelectedAddress(): void {
    this.onSelectedAddress.emit(this.addressForm.getRawValue());
    this.addressComplete = true;
  }

  public toggleEnterManually(): void {
    this.onEnterManually.emit((this.enterManually = !this.enterManually));
  }

  public handleOnClickAmend(): void {
    this.addressComplete = false;
    this.enterManually = false;
    this.addressForm.reset();
  }

  /**
   * Tests provided postal code passes post code regex
   * @param {string} [postCode]
   */
  private checkPostCodeValidity(postCode: string): boolean {
    return new RegExp(RegexUtility.postCode).test(postCode);
  }

  /**
   * Builds the form configuration with angulars form builder service
   */
  private buildForm(): void {
    this.addressForm = this.fb.group({
      line1: ['', Validators.required],
      line2: [''],
      city: ['', Validators.required],
      county: [''],
      postcode: ['', Validators.required],
    });
  }
}
