import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentsHomeComponent } from './components/documents/documents-home/documents-home.component';
import { PageNotFoundComponent } from './components/general/page-not-found/page-not-found.component';
import { StagingComponent } from './components/general/staging/staging.component';
import { HelpHomeComponent } from './components/help/help-home/help-home.component';
import { HelpPageComponent } from './components/help/help-page/help-page.component';
import { HelpPostComponent } from './components/help/help-post/help-post.component';
import { HelpAdultSavingsComponent } from './components/help/help-adult-savings/help-adult-savings.component';
import { HelpChildSavingsComponent } from './components/help/help-child-savings/help-child-savings.component';
import { HomeHomeComponent } from './components/home/home-home/home-home.component';
import { NotificationsHomeComponent } from './components/notifications/notifications-home/notifications-home.component';
import { NotificationDetailComponent } from './components/notifications/notification-detail/notification-detail.component';
import { NotificationsComponent } from './components/profile/notifications/notifications.component';
import { TopUpComponent } from './components/payments/top-up/top-up.component';
import { TopUpThankYouComponent } from './components/payments/top-up-thank-you/top-up-thank-you.component';
import { TopUpErrorComponent } from './components/payments/top-up-error/top-up-error.component';
import { LinkJISAComponent } from './components/plans/plan-linking/link-jisa/link-jisa.component';
import { LinkPlanSubmittedComponent } from './components/plans/plan-linking/link-plan-submitted/link-plan-submitted.component';
import { IsaDetailsComponent } from './components/plans/isa-details/isa-details.component';
import { JisaDetailsComponent } from './components/plans/jisa-details/jisa-details.component';
import { CTFDetailsComponent } from './components/plans/ctf/ctf-details/ctf-details.component';
import { CTFRequestSubmittedComponent } from './components/plans/ctf/ctf-request-submitted/ctf-request-submitted.component';
import { CTFTransactionsSummaryResolver } from './components/plans/ctf/resolvers';
import { PlanChoiceComponent } from './components/plans/plan-choice/plan-choice.component';
import { ProfileHomeComponent } from './components/profile/profile-home/profile-home.component';
import { ProfileChangeMobileNumberComponent } from './components/profile/profile-change-mobile-number/profile-change-mobile-number.component';
import { ProfileChangeEmailComponent } from './components/profile/profile-change-email/profile-change-email.component';
import { ProfileChangeAddressComponent } from './components/profile/profile-change-address/profile-change-address.component';
import { AddressVerification } from './components/security/address-verification/address-verification.component';
import { VerifyEmailComponent } from './components/security/verify-email/verify-email.component';
import { ForgottenPasswordComponent } from './components/security/forgotten-password/forgotten-password.component';
import { ResetPasswordComponent } from './components/security/reset-password/reset-password.component';
import { LogInTwoFactorAuthComponent } from './components/security/log-in.twofactorauth/log-in.twofactorauth.component';
import { LogInUserComponent } from './components/security/log-in.user/log-in.user.component';
import { LogInPasswordComponent } from './components/security/log-in.password/log-in.password.component';
import { LogOutComponent } from './components/security/log-out/log-out.component';
import { BootComponent } from './components/security/boot/boot.component';
import { ManageAuthnComponent } from './components/security/manage-authn/manage-authn.component';
import { TransactionsHomeComponent } from './components/transactions/transactions-home/transactions-home.component';
import { DirectDebitSetupComponent } from './components/direct-debit/direct-debit-setup/direct-debit-setup.component';
import { DirectDebitReviewComponent } from './components/direct-debit/direct-debit-review/direct-debit-review.component';
import { DirectDebitErrorComponent } from './components/direct-debit/direct-debit-error/direct-debit-error.component';
import { DirectDebitThankYouComponent } from './components/direct-debit/direct-debit-thank-you/direct-debit-thank-you.component';
import { DirectDebitVaryComponent } from './components/direct-debit/direct-debit-vary/direct-debit-vary.component';
import { DirectDebitVaryReviewComponent } from './components/direct-debit/direct-debit-vary-review/direct-debit-vary-review.component';
import { DirectDebitViewAllComponent } from './components/direct-debit/direct-debit-view-all/direct-debit-view-all.component';
import { MatureCTFComponent } from './components/plans/ctf/mature-ctf/mature-ctf.component';
import { RegisterCTFComponent } from './components/register/register-ctf/register-ctf.component';
import { RegisterAccountComponent } from './components/register/register-account/register-account.component';
import { RegisterSuccessComponent } from './components/register/register-success/register-success.component';
import { RegisterFailureComponent } from './components/register/register-failure/register-failure.component';
import { RegisterHomeComponent } from './components/register/register-home/register-home.component';
import { AccountProblemComponent } from './components/general/account-problem/account-problem.component';
import { PageErrorComponent } from './components/general/page-error/page-error.component';
import { SSIDetailsComponent } from './components/plans/ssi/ssi-details/ssi-details.component';
import { SSIFundTransferComponent } from './components/plans/ssi/ssi-fund-transfer/ssi-fund-transfer.component';
import { SSIFundTransferDeclarationComponent } from './components/plans/ssi/ssi-fund-transfer-declaration/ssi-fund-transfer-declaration.component';
import { SSIFundTransferReviewComponent } from './components/plans/ssi/ssi-fund-transfer-review/ssi-fund-transfer-review.component';
import { SSIFundTransferThankYouComponent } from './components/plans/ssi/ssi-fund-transfer-thank-you/ssi-fund-transfer-thank-you.component';

import { WithdrawalHomeComponent } from './components/withdrawal/withdrawal-home/withdrawal-home.component';
import { WithdrawalThankYouComponent } from './components/withdrawal/withdrawal-thank-you/withdrawal-thank-you.component';
import { WithdrawalErrorComponent } from './components/withdrawal/withdrawal-error/withdrawal-error.component';

import {
  DocumentsResolver,
  IsaDetailsResolver,
  JisaDetailsResolver,
  MemberDashboardResolver,
  NotificationsResolver,
  NotificationsSummaryResolver,
  ProfileResolver,
  TransactionsResolver,
  TransactionsSummaryResolver,
  CTFDetailsResolver,
  SSIDetailsResolver,
} from './resolvers';

import {
  AnonymousGuard,
  AuthGuard,
  TFAGuard,
  DirectDebitGuard,
  TopUpGuard,
  AccountVerifiedGuard,
  ResetPasswordGuard,
  WithdrawalGuard,
  SSIGuard,
} from './guards';

// Mapped array options for ISA/JISA/CTF/SSI plan routes
const ISA_OPTIONS = ['isa', 'sisa'];
const JISA_OPTIONS = ['jisa', 'sjisa'];
const CTF_OPTIONS = ['ctf'];
const SSI_OPTIONS = ['ssi'];

const routes: Routes = [
  {
    path: '',
    canActivateChild: [AuthGuard], // Auth Guard
    children: [
      {
        path: '',
        canActivateChild: [AccountVerifiedGuard], // Account Verified Guard
        children: [
          { path: '', component: StagingComponent }, // Home or base route
          {
            path: 'home',
            component: HomeHomeComponent,
            resolve: {
              memberDashboard: MemberDashboardResolver,
              notifications: NotificationsResolver,
            },
          },
          {
            path: 'plans',
            resolve: {
              memberDashboard: MemberDashboardResolver,
            },
            children: [
              ...ISA_OPTIONS.map((path) => ({
                path: `${path}/:planNumber`,
                component: IsaDetailsComponent,
                resolve: {
                  memberDashboard: MemberDashboardResolver,
                  isaDetails: IsaDetailsResolver,
                  transactionsSummary: TransactionsSummaryResolver,
                },
                data: { animation: 'isRight' },
              })),
              ...JISA_OPTIONS.map((path) => ({
                path: `${path}/:planNumber`,
                component: JisaDetailsComponent,
                resolve: {
                  memberDashboard: MemberDashboardResolver,
                  jisaDetails: JisaDetailsResolver,
                  transactionsSummary: TransactionsSummaryResolver,
                },
                data: { animation: 'isRight' },
              })),
              ...CTF_OPTIONS.map((path) => ({
                path: `${path}/:planNumber`,
                component: CTFDetailsComponent,
                resolve: {
                  memberDashboard: MemberDashboardResolver,
                  ctfDetails: CTFDetailsResolver,
                  transactionsSummary: CTFTransactionsSummaryResolver,
                },
                data: { animation: 'isRight' },
              })),
              ...SSI_OPTIONS.map((path) => ({
                path: `${path}/:planNumber`,
                component: SSIDetailsComponent,
                canActivate: [SSIGuard],
                resolve: {
                  memberDashboard: MemberDashboardResolver,
                  ssiDetails: SSIDetailsResolver,
                },
                data: { animation: 'isRight' },
              })),
              {
                path: ':intent',
                component: PlanChoiceComponent,
                data: { animation: 'isRight' },
              },
              {
                path: 'withdrawal/:planNumber',
                component: WithdrawalHomeComponent,
                canActivate: [WithdrawalGuard],
                children: [
                  {
                    path: 'thank-you',
                    component: WithdrawalThankYouComponent,
                  },
                  {
                    path: 'error',
                    component: WithdrawalErrorComponent,
                  },
                ],
              },
              {
                path: 'deposit/:planNumber',
                component: TopUpComponent,
                canActivate: [TopUpGuard],
                children: [
                  {
                    path: 'thank-you',
                    component: TopUpThankYouComponent,
                    canDeactivate: [TopUpGuard],
                  },
                  {
                    path: 'error',
                    component: TopUpErrorComponent,
                  },
                ],
              },
            ],
          },
          {
            path: `plans/ssi/:planNumber/fund-transfer`,
            component: SSIFundTransferComponent,
            canActivate: [SSIGuard],
            resolve: {
              memberDashboard: MemberDashboardResolver,
              ssiDetails: SSIDetailsResolver,
            },
          },
          {
            path: `plans/ssi/:planNumber/fund-transfer/review`,
            component: SSIFundTransferReviewComponent,
            canActivate: [SSIGuard],
            resolve: {
              memberDashboard: MemberDashboardResolver,
              ssiDetails: SSIDetailsResolver,
            },
          },
          {
            path: `plans/ssi/:planNumber/fund-transfer/declaration`,
            component: SSIFundTransferDeclarationComponent,
            canActivate: [SSIGuard],
            resolve: {
              memberDashboard: MemberDashboardResolver,
              ssiDetails: SSIDetailsResolver,
            },
          },
          {
            path: `plans/ssi/:planNumber/fund-transfer/thank-you`,
            component: SSIFundTransferThankYouComponent,
            canActivate: [SSIGuard],
            resolve: {
              memberDashboard: MemberDashboardResolver,
              ssiDetails: SSIDetailsResolver,
            },
          },
          {
            path: `plans/ctf/:planNumber/mature-ctf`,
            component: MatureCTFComponent,
            resolve: {
              memberProfile: ProfileResolver,
              memberDashboard: MemberDashboardResolver,
              ctfDetails: CTFDetailsResolver,
            },
          },
          {
            path: `plans/ctf/:planNumber/mature-ctf/submitted`,
            component: CTFRequestSubmittedComponent,
          },
          {
            path: `plans/plan-linking/link-jisa`,
            component: LinkJISAComponent,
          },
          {
            path: `plans/plan-linking/link-jisa/submitted`,
            component: LinkPlanSubmittedComponent,
          },
          {
            path: 'profile',
            resolve: {
              memberProfile: ProfileResolver,
            },
            children: [
              {
                path: 'options',
                component: ProfileHomeComponent,
                data: { animation: 'isRight' },
              },
              {
                path: 'change/mobile-number',
                component: ProfileChangeMobileNumberComponent,
                canDeactivate: [DirectDebitGuard],
                data: { animation: 'isRight' },
              },
              {
                path: 'change/email',
                component: ProfileChangeEmailComponent,
                canDeactivate: [DirectDebitGuard],
                data: { animation: 'isRight' },
              },
              {
                path: 'change/address',
                component: ProfileChangeAddressComponent,
                canDeactivate: [DirectDebitGuard],
                data: { animation: 'isRight' },
              },
              {
                path: 'options/authn',
                component: ManageAuthnComponent,
                data: { animation: 'isRight' },
              },
              {
                path: 'options/notifications',
                component: NotificationsComponent,
                data: { animation: 'isRight' },
              },
              { path: '', component: ProfileHomeComponent, pathMatch: 'full' },
            ],
          },
          {
            path: 'notifications',
            component: NotificationsHomeComponent,
            resolve: {
              notifications: NotificationsResolver,
            },
          },
          {
            path: 'notifications/:notificationId',
            component: NotificationDetailComponent,
            resolve: {
              notification: NotificationsSummaryResolver,
            },
          },
          {
            path: 'documents',
            component: DocumentsHomeComponent,
            resolve: {
              documentsByMember: DocumentsResolver,
            },
          },
          {
            path: 'documents/plan/:planNumber',
            component: DocumentsHomeComponent,
            resolve: {
              documentsByMember: DocumentsResolver,
            },
          },

          {
            path: 'help/page/:id',
            component: HelpPageComponent,
            data: { animation: 'isRight' },
          },
          {
            path: 'help/post/:id',
            component: HelpPostComponent,
            data: { animation: 'isRight' },
          },
          {
            path: 'help/adult-savings',
            component: HelpAdultSavingsComponent,
            data: { animation: 'isRight' },
          },
          {
            path: 'help/child-savings',
            component: HelpChildSavingsComponent,
            data: { animation: 'isRight' },
          },
          {
            path: 'help',
            component: HelpHomeComponent,
          },
          {
            path: 'transactions/:planId/:formattedPlanNumber',
            component: TransactionsHomeComponent,
            resolve: {
              transactions: TransactionsResolver,
            },
          },
          {
            path: 'direct-debit/plan',
            component: DirectDebitSetupComponent,
          },
          {
            path: 'direct-debit/plan/review',
            component: DirectDebitReviewComponent,
          },
          {
            path: 'direct-debit/plan/view-all',
            component: DirectDebitViewAllComponent,
          },
          {
            path: 'direct-debit/plan/vary',
            component: DirectDebitVaryComponent,
          },
          {
            path: 'direct-debit/plan/vary/review',
            component: DirectDebitVaryReviewComponent,
          },
          {
            path: 'direct-debit/thank-you',
            component: DirectDebitThankYouComponent,
            canDeactivate: [DirectDebitGuard],
          },
          {
            path: 'direct-debit/error',
            component: DirectDebitErrorComponent,
          },
          {
            path: 'address-verification',
            component: AddressVerification,
            resolve: {
              memberDashboard: MemberDashboardResolver,
            },
          },
          {
            path: 'account-problem',
            component: AccountProblemComponent,
            resolve: {
              memberDashboard: MemberDashboardResolver,
            },
          },
        ],
      },
    ],
  },
  // TFA
  {
    path: 'log-in-tfa',
    component: LogInTwoFactorAuthComponent,
    canActivate: [TFAGuard],
    data: { animation: 'isRight' },
  },
  // Routes only accessible when not authenticated
  {
    path: 'log-out',
    component: LogOutComponent,
    data: { animation: 'isLeft' },
  },
  {
    path: 'boot',
    component: BootComponent,
  },
  {
    path: 'log-in',
    component: LogInUserComponent,
    canActivate: [AnonymousGuard],
  },
  {
    path: 'log-in-auth',
    component: LogInPasswordComponent,
    canActivate: [AnonymousGuard],
    data: { animation: 'isRight' },
  },
  {
    path: 'register/home',
    component: RegisterHomeComponent,
    data: { showBackBtn: true },
    canActivate: [AnonymousGuard],
  },
  {
    path: 'register/account/:type',
    component: RegisterAccountComponent,
    data: { showBackBtn: true },
    canActivate: [AnonymousGuard],
  },
  {
    path: 'register/ctf',
    component: RegisterCTFComponent,
    data: { showBackBtn: true },
    canActivate: [AnonymousGuard],
  },
  {
    path: 'register/failed',
    component: RegisterFailureComponent,
    canActivate: [AnonymousGuard],
  },
  {
    path: 'register/success/:email/:registrationId',
    component: RegisterSuccessComponent,
    canActivate: [AnonymousGuard],
  },
  // Routes available at all times
  { path: 'verify-email', component: VerifyEmailComponent },
  {
    path: 'forgotten-password',
    component: ForgottenPasswordComponent,
    data: { showBackBtn: true },
  },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [ResetPasswordGuard] },
  { path: 'reset', component: LogOutComponent },
  { path: 'error', component: PageErrorComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      bindToComponentInputs: true,
      // enableTracing: true, // <-- debugging purposes only
      // preloadingStrategy: PreloadAllModules // <-- debugging purposes only
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
