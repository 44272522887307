<app-content-header
  [title]="'My transactions'"
  [subtitle]="formattedPlanNumber"
  [isButtonOff]="transactions == undefined"
  [button_text]="'Date'"
  [button_icon]="'far fa-filter'"
  [button_style]="'white'"
  [button_iconColor]="'text-primary-sf-yellow '"
  [button_customClass]="
    'hover:bg-primary-sf-yellow dark:hover:text-primary-sf-navy focus:bg-primary-sf-yellow dark:focus:text-primary-sf-navy'
  "
  (buttonClick)="toggleDateFilterAndReset()"
  [button_hover]="true"
></app-content-header>

<div *ngIf="transactions == undefined">
  <span>No transaction details are available.</span>
</div>

<div *ngIf="transactions != undefined">
  <div class="sf-card" *ngIf="isDateFilterOpen">
    <p class="mb-3 pb-3 font-bold border-b border-primary-sf-navy/10 dark:border-sf-white/10">
      Filter your transactions by date
    </p>
    <form [formGroup]="dateFilterForm">
      <div class="flex justify-between gap-3 max-xs:gap-2">
        <div class="flex-1">
          <label for="transaction-date-filter-from" class="font-bold text-normal">From</label>
          <input
            type="date"
            class="form-control font-normal flex-row-reverse p-3 min-w-[90%] max-xs:text-sm"
            name="transaction-date-filter-from"
            formControlName="start"
            [max]="endDateCtrl.value || date.today"
            (change)="transactionDateChange()"
          />
        </div>

        <div class="flex-1">
          <label for="transaction-date-filter-to" class="font-bold text-normal">To</label>
          <input
            type="date"
            class="form-control font-normal flex-row-reverse p-3 min-w-[90%] max-xs:text-sm"
            formControlName="end"
            name="transaction-date-filter-to"
            [min]="startDateCtrl.value"
            [max]="date.today"
            (change)="transactionDateChange()"
          />
        </div>
      </div>
    </form>
  </div>
  <div class="transaction__type-filter mb-4 md:mb-6 xl:mb-8">
    <div
      class="flex flex-col gap-0.5 bg-sf-white dark:bg-primary-sf-navy max-xs:p-1 p-2 rounded-md shadow-sm"
      role="toolbar"
    >
      <div
        btnRadioGroup
        ngDefaultControl
        [(ngModel)]="transactionType"
        (ngModelChange)="transactionTypeChange($event)"
        class="transaction-controls-button flex justify-between items-center"
      >
        <label
          [ngClass]="{
            'transaction-controls-button--active': transactionType === transactionTypeOpts.All,
          }"
          class="font-normal text-sm md:text-base text-center w-full flex-1 mb-0 cursor-pointer max-xs:px-2 px-4 py-1"
          btnRadio="All"
          tabindex="0"
          role="button"
        >
          All
        </label>
        <label
          [ngClass]="{
            'transaction-controls-button--active':
              transactionType == transactionTypeOpts.Withdrawal,
          }"
          class="font-normal text-sm md:text-base text-center w-full flex-1 mb-0 cursor-pointer max-xs:px-2 px-4 py-1"
          btnRadio="Withdrawal"
          tabindex="0"
          role="button"
        >
          Withdrawal
        </label>
        <label
          [ngClass]="{
            'transaction-controls-button--active': transactionType == transactionTypeOpts.Top_Up,
          }"
          class="font-normal text-sm md:text-base text-center w-full flex-1 mb-0 cursor-pointer max-xs:px-2 px-4 py-1"
          btnRadio="Top Up"
          tabindex="0"
          role="button"
        >
          Top Up
        </label>
        <label
          [ngClass]="{
            'transaction-controls-button--active': transactionType == transactionTypeOpts.Bonus,
          }"
          class="font-normal text-sm md:text-base text-center w-full flex-1 mb-0 cursor-pointer max-xs:px-2 px-4 py-1"
          btnRadio="Bonus"
          tabindex="0"
          role="button"
        >
          Bonus
        </label>
      </div>
    </div>
  </div>
  <div class="text-center font-medium text-sf-body-font dark:text-sf-white">
    <p class="text-xl" *ngIf="transactions.items.length === 0">You have no transactions yet.</p>
  </div>

  <section
    class="bg-sf-white dark:bg-primary-sf-navy shadow-sm first-of-type:rounded-md last-of-type:rounded-md"
  >
    <div
      *ngFor="let row of transactions.items; let i = index"
      class="text-base [&:not(:last-of-type)]:border-b [&:not(:last-of-type)]:border-b-primary-sf-navy/10 dark:[&:not(:last-of-type)]:border-sf-white/10"
    >
      <div
        class="font-bold text-primary-sf-navy dark:text-sf-white pt-3 pb-0.5 px-3 flex justify-between"
        *ngIf="!isSameDayTransaction(i, row.transactionDate)"
      >
        <div class="md:text-base">
          <span>{{ row.transactionDate | date }}</span>
        </div>
        <div class="text-right md:text-base">
          <span>{{ row.value | currency : 'GBP' }}</span>
        </div>
      </div>
      <div
        class="flex justify-between pb-3 [&:not(:first-of-type)]:pt-0.5 pt-3 px-3 md:font-base dark:text-sf-white"
      >
        <div>
          <span>{{ row.transactionTypeDescription }}</span>
        </div>
        <div class="text-right">
          <span>{{ row.amount | currency : 'GBP' }}</span>
        </div>
      </div>
    </div>
  </section>

  <div *ngIf="transactions.pageMax > 1" class="mt-4 md:mt-6 xl:mt-8 2xl:mt-10">
    <pagination
      class="pagination"
      [maxSize]="paginationSize"
      [customPreviousTemplate]="prevTemplate"
      [customNextTemplate]="nextTemplate"
      [totalItems]="transactions.total"
      [itemsPerPage]="transactions.pageSize"
      (pageChanged)="pageChanged($event)"
    ></pagination>

    <ng-template #nextTemplate>
      <ng-container>
        <i class="fal fa-angle-right fa-lg"></i>
      </ng-container>
    </ng-template>

    <ng-template #prevTemplate>
      <ng-container>
        <i class="fal fa-angle-left fa-lg"></i>
      </ng-container>
    </ng-template>
  </div>
</div>
