import { Component, TemplateRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() btnWrapperClasses: string;

  @Input() showButton: boolean = true;
  @Input() text: string;
  @Input() buttonStyle: string = 'primary';
  @Input() customClass: string;
  @Input() icon: string;
  @Input() iconSize: string;
  @Input() iconColor: string;
  @Input() disabled: boolean;
  @Input() noBorder: boolean;
  @Input() inline: boolean;
  @Input() isSubmitButton: boolean;
  @Input() isButtonShadow: boolean;
  @Input() alignRight: boolean;
  @Input() alignLeft: boolean;
  @Input() btnSmall: boolean;
  @Input() fullWidth: boolean;

  @Input() showSecondButton = false;
  @Input() secondButtonText: string;
  @Input() secondButtonStyle: string = 'primary';
  @Input() secondButtonIcon: string;
  @Input() secondButtonDisabled: boolean;
  @Input() secondButtonNoBorder: boolean;
  @Input() secondButtonInline: boolean;
  @Input() isSecondButtonSubmit: boolean;

  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() secondButtonClick: EventEmitter<void> = new EventEmitter<void>();

  handleButtonClick(): void {
    this.buttonClick.emit();
  }

  handleSecondButtonClick(): void {
    this.secondButtonClick.emit();
  }
}
