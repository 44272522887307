import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, catchError, tap, map } from 'rxjs';
import { HttpOptions } from '../models/general/http-options.model';

@Injectable()
export class HttpService {
  constructor(private http: HttpClient) {}

  get<T>(url: string, options?: HttpOptions) {
    return this.http.get<T>(url, options as unknown).pipe(
      map((response) => {
        return <T>response;
      })
    );
  }

  post<T>(url: string, body: any, options?: HttpOptions) {
    return this.http.post<T>(url, body, options as unknown).pipe(
      map((response) => {
        return <T>response;
      })
    );
  }

  put<T>(url: string, body: any, options?: HttpOptions) {
    return this.http.put<T>(url, body, options as unknown).pipe(
      map((response) => {
        return <T>response;
      })
    );
  }

  delete<T>(url: string, options?: HttpOptions) {
    return this.http.delete<T>(url, options as unknown).pipe(
      map((response) => {
        return <T>response;
      })
    );
  }

  /**
   * Fetches an SVG string from a provided image URL.
   * @param imageUrl - The URL of the SVG image.
   * @returns A promise that resolves with the SVG string fetched from the URL.
   */
  async getSVGStringFromImageUrl(imageUrl: string): Promise<string> {
    try {
      const response = await fetch(imageUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.status} ${response.statusText}`);
      }

      const blob = await response.blob();
      const reader = new FileReader();

      return new Promise<string>((resolve, reject) => {
        reader.onload = (event) => {
          const svgString = event.target?.result as string;
          if (svgString) {
            resolve(svgString);
          } else {
            reject(new Error('Failed to read SVG string from image'));
          }
        };
        reader.readAsText(blob);
      });
    } catch (error) {
      throw new Error(`Error fetching and parsing image`);
    }
  }

  // TODO: Handle some error codes but not all, because sometimes we need to handle API exceptions in components
  // For example, the top up page.
  // private handleError(error: HttpErrorResponse) {
  //     if (error.status === 0) {
  //       // A client-side or network error occurred. Handle it accordingly.
  //       console.error('An error occurred:', error.error);
  //     } else {
  //       // The backend returned an unsuccessful response code.
  //       // The response body may contain clues as to what went wrong.
  //       console.error(
  //         `Backend returned code ${error.status}, body was: `, error.error);
  //     }
  //     // Return an observable with a user-facing error message.
  //     return throwError(() => new Error('Something bad happened; please try again later.'));
  // }
}
