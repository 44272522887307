import { Injectable } from '@angular/core';
import { NavigationEnd, Router, UrlTree } from '@angular/router';

// https://stackoverflow.com/questions/50850125/check-if-a-route-exist-in-angular-2/50850163#50850163

@Injectable()
export class RouterHelperService {
  private validRouteRegices: any;
  private usingLightMode: boolean;
  private initialised: boolean;

  constructor(private router: Router) {
    const validRoutes = [];

    // router.config will not change so let's cache

    // get all routes
    this.router.config.forEach((route) => {
      const routePath: string = route.path;
      validRoutes.push(routePath);

      // and child routes
      const routeChildren = route.children || [];
      routeChildren.forEach((routeChild) => {
        const routeChildPath: string = route.path + '/' + routeChild.path;
        validRoutes.push(routeChildPath);
      });
    });

    // swap routes for regices to support URL params and tidy up a little
    this.validRouteRegices = validRoutes
      .map((route) => (route.startsWith('/') ? route.replace('/', '') : route))
      .map((route) => route.replace(/\/:[a-zA-Z]+/g, '/[a-zA-Z0-9-]+'))
      .filter((route) => route !== '' && route !== '**')
      .map((route) => '^' + route + '$');

    /* this.validRouteRegices.forEach(r => console.debug(r)); */

    // hook navigation to handle "deep link" notification click URLs
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const urlTree = this.router.parseUrl(event.url);
        if (urlTree.queryParamMap.has('deeplink')) {
          // remove all query params from URL
          urlTree.queryParams = {};

          console.warn(`fakehistory: detected deep-link '${urlTree}'`);

          // create a fake history based upon the URL segments
          let fakeHistory = [];
          const primarySegmentGroup = urlTree.root.children['primary'];
          while (primarySegmentGroup.segments.length > 0) {
            // check if route is valid before adding it
            if (this.isRouteValid(urlTree.toString())) {
              fakeHistory.unshift(urlTree.toString());
            }
            primarySegmentGroup.segments.pop();
          }
          fakeHistory.unshift('/');

          // push fake history into browser
          fakeHistory.forEach((url) => {
            console.warn(`fakehistory: pushing '${url}' onto browser history`);
            history.pushState(null, undefined, url);
          });
        }
      }
    });
  }

  /**
   * Call this to check if a route exists or not
   */
  isRouteValid(pathname = location.pathname): boolean {
    let match = false;
    const locationPathname = pathname.startsWith('/') ? pathname.replace('/', '') : pathname;
    this.validRouteRegices.forEach((strValidRouteRegex: string) => {
      const validRouteRegex = new RegExp(strValidRouteRegex);
      if (validRouteRegex.test(locationPathname)) {
        match = true;
      }
    });
    return match;
  }

  /**
   * Redirects to 'route' whilst adding/maintaining 'destination' query param
   */
  redirectWithDestination(route: string, targetUrl: string = '/') {
    // (17/02/2023) destination Urls of anything other than deep links are confusing to the user
    // (it also breaks the browser history) so disable destination Urls for non-deep links for now.

    const urlTree = this.router.parseUrl(targetUrl);
    if (!urlTree.queryParamMap.has('deeplink')) {
      targetUrl = undefined; // disable target Url
    }

    let params = new URLSearchParams(document.location.search);
    let previousDestination = params['destination'] || '/';

    // maintain any existing destination, otherwise add new if specified
    var destinationUrl = undefined;
    if (previousDestination && previousDestination != '/') {
      destinationUrl = previousDestination;
    } else if (targetUrl && targetUrl != '/') {
      destinationUrl = targetUrl;
    }

    if (destinationUrl) {
      // route with params
      this.router.navigate([route], {
        queryParams: { destination: destinationUrl },
      });
    } else {
      // route without params
      this.router.navigate([route]);
    }
  }
}
