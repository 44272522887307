import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registration-home',
  templateUrl: './registration-home.component.html',
  styleUrls: ['./registration-home.component.css'],
})
export class RegistrationHomeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
