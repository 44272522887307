export class DocumentList {
  public memberRows: MemberRow[];
}

export class DocumentListViewModel {
  public memberRows: MemberRow[];
}

export class MemberRow {
  public memberName: string;
  public planRows: PlanRow[];
  public docRows: DocRow[];
}

export class PlanRow {
  public planType: string;
  public planNumber: string;
  public docRows: DocRow[];
  public latestDocs: DocRow[];
}

export class DocRow {
  public documentType: string;
  public planNumber: string;
  public fileName: string;
  public title: string;
  public timeStamp: string;
  public documentStatus: string;
  public reference: string;
}
