import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Register } from '@app/models/registration';
import { LoginHeaderService, RegistrationService } from '@app/services';
import { catchError, EMPTY, filter, Subscription } from 'rxjs';

const stepOpts: Record<string, string> = {
  YOUR_DETAILS: 'Details',
  ACCOUNT: 'Account',
  DECLARATION: 'Declaration',
};

@Component({
    selector: 'app-register-ctf',
    templateUrl: './register-ctf.component.html',
    styleUrls: ['./register-ctf.component.scss'],
    standalone: false
})
export class RegisterCTFComponent {
  private subscribeUntilDestroyed = new Subscription();

  public stepOpts = stepOpts;
  public step = this.stepOpts['YOUR_DETAILS'];

  public registerStepOne: any = {};
  public registerStepTwo = {};
  public registerConsent = {};

  public stepOneComplete = false;
  public stepTwoComplete = false;
  public stepConsentComplete = false;

  public registerForm: FormGroup;

  public readonly url = {
    SUCCESS_PAGE: (email: string, registerId: string) =>
      `/register/success/${btoa(email)}/${registerId}`,
    ERROR_PAGE: `/register/failed`,
  };

  constructor(
    private fb: FormBuilder,
    private registrationService: RegistrationService,
    private router: Router,
    private location: Location,
    private loginHeaderService: LoginHeaderService
  ) {}

  ngOnInit(): void {
    this.loginHeaderService.buttonClick$.subscribe(() => {
      this.goOneStepBack();
    });

    this.registerForm = this.fb.group({
      isOver16: [false, [Validators.requiredTrue]],
      authoriseShepherdsFriendly: [false, [Validators.requiredTrue]],
    });

    this.subscribeUntilDestroyed.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe((event: any) => {
          if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
            if (event.restoredState) {
              this.goOneStepBack();
            }
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  public register(): void {
    const registerPayload = {
      ...this.registerStepOne,
      ...this.registerStepTwo,
      ...this.registerConsent,
    } as Register;

    // ensure only CTF plans can be registered
    registerPayload.allowOnlyCTF = true;

    this.registrationService
      .register(registerPayload)
      .pipe(
        catchError((e) => {
          this.router.navigate([this.url.ERROR_PAGE], {
            state: { ctf: true },
          });
          return EMPTY;
        })
      )
      .subscribe((result) => {
        if (!result.registered) {
          this.router.navigate([this.url.ERROR_PAGE], {
            state: { ctf: true },
          });
        } else {
          this.router.navigate([
            this.url.SUCCESS_PAGE(registerPayload.emailAddress, result.registrationId),
            {
              state: { ctf: true },
            },
          ]);
        }
      });
  }

  public goToStep(step: string): void {
    if (this.stepOpts[step]) {
      this.step = this.stepOpts[step];
      this.updateUrlHash(step);
      this.scrollToTop();
    }
  }

  public goOneStepBack(): void {
    const curStep = window.location.hash.substring(1);
    if (this.step === this.stepOpts[curStep]) {
      this.location.back();
    } else {
      this.step = this.stepOpts[curStep];
    }
    this.scrollToTop();
  }

  public scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  public onUpdateStepOne(value: any): void {
    this.registerStepOne = value;
  }

  public onUpdateStepTwo(value: any): void {
    this.registerStepTwo = value;
  }

  public onUpdateConsent(value: any) {
    this.registerConsent = value;
  }

  private updateUrlHash(step: string) {
    const currentHash = `#${step}`;
    window.location.hash = currentHash;
  }
}
