<app-content-header
  *ngIf="ssiDetails"
  [title]="'Stocks and Shares ISA'"
  [subtitle]="'Fund switch request for ' + ssiDetails.formattedPlanNumber"
/>
<div *ngIf="ssiDetails">
  <div class="relative flex flex-col w-full">
    <app-alert
      type="info"
      additionalClass="my-4"
      imageSmallSrc="icon-question"
      imageSmallAlt="question icon"
      [title]="'Fund switch current unavailable'"
      button_customClass="mt-4 lg:w-[340px]"
    >
      <p>
        Your Stocks and Shares ISA cannot currently switch funds, this could be due to the recency
        of your last switch. If you would like to speak to someone please contact our Member
        Services team on 0800 526 249. Alternatively, you can request a call back via our online
        support form.
      </p>
    </app-alert>
    <div
      class="mb-4 md:mb-6 xl:mb-8 bg-sf-white dark:bg-primary-sf-navy max-xs:p-1 p-2 rounded-md shadow-sm"
      role="toolbar"
    >
      <div
        btnRadioGroup
        ngDefaultControl
        [(ngModel)]="fundType"
        (ngModelChange)="fundTypeChange($event)"
        class="fund-controls-button flex justify-between items-center"
      >
        <label
          [ngClass]="{
            'fund-controls-button--active': fundType === fundTypeOpts.Defensive,
          }"
          class="font-normal text-sm md:text-base text-center w-full flex-1 mb-0 cursor-pointer max-xs:px-2 px-4 py-1"
          btnRadio="Defensive"
          tabindex="0"
          role="button"
        >
          1
        </label>
        <label
          [ngClass]="{
            'fund-controls-button--active': fundType == fundTypeOpts.Balanced
          }"
          class="font-normal text-sm md:text-base text-center w-full flex-1 mb-0 cursor-pointer max-xs:px-2 px-4 py-1"
          btnRadio="Balanced"
          tabindex="0"
          role="button"
        >
          2
        </label>
        <label
          [ngClass]="{
            'fund-controls-button--active': fundType == fundTypeOpts.Growth
          }"
          class="font-normal text-sm md:text-base text-center w-full flex-1 mb-0 cursor-pointer max-xs:px-2 px-4 py-1"
          btnRadio="Growth"
          tabindex="0"
          role="button"
        >
          3
        </label>
        <label
          [ngClass]="{
            'fund-controls-button--active':  fundType == fundTypeOpts.Adventurous,
          }"
          class="font-normal text-sm md:text-base text-center w-full flex-1 mb-0 cursor-pointer max-xs:px-2 px-4 py-1"
          btnRadio="Adventurous"
          tabindex="0"
          role="button"
        >
          4
        </label>
        <label
          [ngClass]="{
            'fund-controls-button--active':  fundType == fundTypeOpts.Dynamic,
          }"
          class="font-normal text-sm md:text-base text-center w-full flex-1 mb-0 cursor-pointer max-xs:px-2 px-4 py-1"
          btnRadio="Dynamic"
          tabindex="0"
          role="button"
        >
          5
        </label>
      </div>
    </div>

    <div
      class="bg-sf-white dark:bg-primary-sf-navy p-4 rounded-md shadow-sm w-full flex flex-col relative"
    >
      <div
        class="fund-controls-arrow absolute -top-[10px] transform w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-b-[10px] border-b-sf-white transition-all duration-300"
        [ngClass]="arrowPosition"
      ></div>

      <div *ngIf="fundType === fundTypeOpts.Defensive">
        <p class="text-base md:text-lg font-semibold mb-1 md:mb-2 text-primary-sf-navy">
          Defensive
        </p>
        <p>
          A lower-risk fund that is focused on cash, gilts, as well as government and corporate
          bonds, with limited exposure to the stock market. Aims for steady growth, with reduced
          risk of loss.
        </p>
      </div>

      <div *ngIf="fundType === fundTypeOpts.Balanced">
        <p class="text-base md:text-lg font-semibold mb-1 md:mb-2 text-primary-sf-navy">Balanced</p>
        <p>
          A balanced fund that’s focused on a mixture of lower-risk assets, and some exposure to the
          stock market. Aims for steady growth, while remaining invested in the stock market.
        </p>
      </div>

      <div *ngIf="fundType === fundTypeOpts.Growth">
        <p class="text-base md:text-lg font-semibold mb-1 md:mb-2 text-primary-sf-navy">Growth</p>
        <p>
          A fund that is invested in a mixture of UK and global equities, and supported with some
          defensive assets such as gilts and bonds. Aims for long-term growth by actively investing
          in stock market opportunities.
        </p>
      </div>

      <div *ngIf="fundType === fundTypeOpts.Adventurous">
        <p class="text-base md:text-lg font-semibold mb-1 md:mb-2 text-primary-sf-navy">
          Adventurous
        </p>
        <p>
          A fund that is invested mostly in a mixture of global and UK stock markets, with a limited
          number of defensive assets such as property and bonds. Aims for higher growth, and likely
          to see some ups and downs.
        </p>
      </div>

      <div *ngIf="fundType === fundTypeOpts.Dynamic">
        <p class="text-base md:text-lg font-semibold mb-1 md:mb-2 text-primary-sf-navy">Dynamic</p>
        <p>
          A higher-risk and reward fund that’s invested in a mixture of global, UK and emerging
          market equities. Aims to outpace the stock market, but very likely to see short-term ups
          and downs.
        </p>
      </div>
    </div>
    <app-ssi-fund-performance-calculator
      class="mt-4 md:mt-6 xl:mt-8"
    ></app-ssi-fund-performance-calculator>
  </div>
</div>
