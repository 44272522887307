<!-- <div class="overlay"></div>
<div id="loading-spinner" class="text-center">
    <div id="logoSolo" style="width: 3rem; height: 3rem;" role="status">
        <img src="/assets/images/logosolo.svg" alt="Shepherds Friendly Shield">
        <span class="sr-only">Loading...</span>
    </div>
</div> -->

<section id="stagingHeroImage" class="mt-8 mb-4 pt-4 pb-4">
  <div class="flex justify-center">
    <svg
      width="122.133"
      height="233.739"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 122.133 233.739"
    >
      <g
        fill="#13243a"
        stroke="#13243a"
        stroke-miterlimit="10"
        stroke-width=".3"
        style="isolation: isolate"
      >
        <path
          d="m57.319 26.585-.043-.422a1.077 1.077 0 0 1 .065-.551.714.714 0 0 1 .441-.337l.267.282a1.161 1.161 0 0 0-.157.595c.008.187.021.366.035.54l.014.152a8.891 8.891 0 0 0-.194 1.472 10.51 10.51 0 0 0-.038 1.5 4.587 4.587 0 0 0 .345 1.214q.3.755.6 1.445a4.065 4.065 0 0 1 .318.916.408.408 0 0 1-.076.326.35.35 0 0 1-.22.112.306.306 0 0 1-.239-.1 1.09 1.09 0 0 1-.217-.315 5.752 5.752 0 0 1-.374-.962c-.056-.19-.1-.358-.147-.506s-.084-.3-.123-.448-.065-.252-.084-.305c-.01.128-.019.454-.03.978a6.782 6.782 0 0 1-.106 1.2c.006.028.012.079.019.15.028.321-.068.491-.293.511a.492.492 0 0 1-.275-.055.244.244 0 0 1-.142-.207 4.967 4.967 0 0 1 .07-1.278c.081-.478.163-.954.242-1.432a4.729 4.729 0 0 0 .08-1.18 11.831 11.831 0 0 0-.5-1.915 7.737 7.737 0 0 0-.628-1.649l-.013-.139a.392.392 0 0 1-.146-.274c-.019-.2.1-.316.358-.339q.268-.024.555.793.257.717.4 1.272c.095.369.152.584.169.641 0-.055.013-.163.027-.324s.027-.28.032-.361.013-.184.017-.316a5.6 5.6 0 0 0-.011-.688"
          data-name="Path 911"
        />
        <path
          d="m60.469 29.486.078 2.763v.388l-.005.362h.171l.375-.014c.356 0 .535.1.535.321a.369.369 0 0 1-.128.283.445.445 0 0 1-.3.119h-.571a6.414 6.414 0 0 1-.756-.022 1.246 1.246 0 0 1-.16.014c-.308 0-.465-.095-.465-.29a.39.39 0 0 1 .076-.229.222.222 0 0 1 .15-.115h.37l.024-.172-.1-2.763.055-2.9c0-.5-.027-.962-.071-1.4H59.5l-.3.014a.519.519 0 0 1-.3-.071.309.309 0 0 1-.109-.275q0-.3.641-.3l1.549-.008c.351 0 .528.089.528.271a.286.286 0 0 1-.112.222.382.382 0 0 1-.261.1h-.666q.055.534.057 1.207Z"
          data-name="Path 912"
        />
        <path
          d="m63.422 29.619-.163 2.76-.031.386-.036.361.172.014.374.017c.354.03.524.152.506.367a.367.367 0 0 1-.154.271.435.435 0 0 1-.31.093l-.569-.048a6.224 6.224 0 0 1-.751-.087 1.334 1.334 0 0 1-.16 0c-.308-.025-.454-.135-.438-.329a.394.394 0 0 1 .093-.222.234.234 0 0 1 .163-.1l.367.03.039-.169.136-2.763.307-2.881q.062-.74.052-1.4l-.247-.021-.3-.013a.53.53 0 0 1-.293-.1.314.314 0 0 1-.086-.285q.026-.3.665-.245l1.546.127c.35.03.517.135.5.316a.3.3 0 0 1-.131.212.385.385 0 0 1-.269.079l-.666-.054c.006.358-.011.76-.047 1.207Z"
          data-name="Path 913"
        />
        <path
          d="m79.118 35.074-1.445 2.358-.21.326-.2.3.143.093.323.191c.3.193.389.38.272.562a.362.362 0 0 1-.263.168.435.435 0 0 1-.316-.065l-.481-.31a7.132 7.132 0 0 1-.622-.43 1.438 1.438 0 0 1-.141-.076c-.26-.168-.335-.332-.231-.5a.39.39 0 0 1 .188-.152.233.233 0 0 1 .19-.013l.31.2.114-.13 1.423-2.372 1.628-2.4c.269-.416.5-.82.706-1.209l-.21-.135-.258-.152a.516.516 0 0 1-.21-.223.308.308 0 0 1 .059-.29q.161-.252.7.1l1.3.839q.441.287.3.516a.3.3 0 0 1-.219.125.384.384 0 0 1-.274-.055l-.56-.361c-.165.316-.369.665-.612 1.041Z"
          data-name="Path 914"
        />
        <path
          d="m89.995 46.553-2.35 1.456-.336.2-.315.177.087.149.2.316q.27.46-.01.623a.363.363 0 0 1-.308.032.427.427 0 0 1-.255-.2l-.29-.492a6.9 6.9 0 0 1-.362-.665 1.38 1.38 0 0 1-.09-.131c-.157-.266-.152-.449.017-.547a.389.389 0 0 1 .234-.051.23.23 0 0 1 .177.074l.185.318.161-.066 2.339-1.476 2.533-1.408q.641-.375 1.173-.763l-.125-.214-.163-.25a.521.521 0 0 1-.089-.3c0-.085.068-.163.183-.233.172-.1.365.033.582.4l.786 1.337q.266.453.031.593a.3.3 0 0 1-.25.013.377.377 0 0 1-.22-.172l-.337-.574q-.434.313-1.016.655Z"
          data-name="Path 915"
        />
        <path
          d="m91.351 49.155-2.467 1.247-.349.166-.329.15.073.155.172.332c.154.323.133.529-.063.622a.366.366 0 0 1-.31 0 .437.437 0 0 1-.237-.223l-.245-.516a6.6 6.6 0 0 1-.3-.693 1.467 1.467 0 0 1-.081-.138c-.131-.28-.111-.46.065-.544a.382.382 0 0 1 .239-.03.226.226 0 0 1 .169.089l.158.332.166-.051 2.457-1.271 2.644-1.184c.448-.214.859-.432 1.234-.658l-.106-.223-.141-.264a.528.528 0 0 1-.062-.3c.013-.085.081-.157.2-.215.18-.085.361.063.546.451l.668 1.4c.151.316.144.516-.021.593a.3.3 0 0 1-.25-.006.383.383 0 0 1-.2-.193l-.288-.6q-.461.275-1.068.565Z"
          data-name="Path 916"
        />
        <path
          d="m94.526 61.508-2.753.261-.386.027-.362.021.013.171.04.373q.04.532-.285.555a.37.37 0 0 1-.291-.109.431.431 0 0 1-.139-.293l-.041-.571a6.4 6.4 0 0 1-.029-.755 1.525 1.525 0 0 1-.024-.158c-.022-.309.063-.47.258-.483a.385.385 0 0 1 .234.059c.074.046.117.093.125.144l.025.367.174.014 2.75-.288 2.894-.138c.5-.035.959-.09 1.391-.163l-.018-.247-.035-.3a.534.534 0 0 1 .051-.3.313.313 0 0 1 .269-.127c.2-.014.313.191.343.619l.112 1.546c.024.351-.054.532-.236.546a.3.3 0 0 1-.23-.1.383.383 0 0 1-.12-.253l-.047-.666q-.529.09-1.2.138Z"
          data-name="Path 917"
        />
        <path
          d="m94.588 64.463-2.764.022-.388-.006-.363-.011v.171l.007.375c0 .356-.116.532-.332.529a.364.364 0 0 1-.28-.133.437.437 0 0 1-.114-.3l.01-.573a6.622 6.622 0 0 1 .037-.755 1.079 1.079 0 0 1-.01-.16c0-.309.1-.462.3-.459a.385.385 0 0 1 .226.079.227.227 0 0 1 .112.153v.369l.171.028 2.766-.047 2.9.114c.5.008.96-.006 1.4-.043v-.247l-.008-.3a.53.53 0 0 1 .076-.3.318.318 0 0 1 .28-.1c.2 0 .294.218.288.647l-.024 1.549c0 .351-.1.525-.282.524a.3.3 0 0 1-.22-.117.389.389 0 0 1-.1-.263l.011-.668q-.534.043-1.209.033Z"
          data-name="Path 918"
        />
        <path
          d="m94.396 67.404-2.756-.218-.384-.04-.359-.043-.019.171-.025.375c-.035.353-.161.519-.375.5a.366.366 0 0 1-.269-.158.438.438 0 0 1-.087-.312l.059-.57a6.686 6.686 0 0 1 .1-.748 1.589 1.589 0 0 1 0-.16c.032-.307.144-.451.339-.432a.384.384 0 0 1 .219.1.227.227 0 0 1 .1.163l-.038.367.167.043 2.758.191 2.877.366c.492.049.957.076 1.4.078l.024-.245.019-.3a.517.517 0 0 1 .1-.29.31.31 0 0 1 .285-.079c.2.021.275.244.232.669l-.158 1.541c-.035.35-.144.516-.324.5a.3.3 0 0 1-.211-.138.379.379 0 0 1-.074-.269l.068-.665q-.536 0-1.206-.071Z"
          data-name="Path 919"
        />
        <path
          d="m88.093 83.751-2.287-1.552-.315-.226-.291-.215-.1.139-.207.313q-.308.434-.573.247a.374.374 0 0 1-.155-.27.428.428 0 0 1 .079-.313l.332-.465a6.648 6.648 0 0 1 .46-.6 1.278 1.278 0 0 1 .082-.138q.268-.377.506-.207a.394.394 0 0 1 .143.195.227.227 0 0 1 0 .19l-.215.3.126.12 2.3 1.532 2.318 1.741c.4.288.8.54 1.175.759l.144-.2.163-.25a.53.53 0 0 1 .233-.2.311.311 0 0 1 .288.073c.163.116.119.348-.13.7l-.9 1.263c-.2.285-.38.375-.528.269a.3.3 0 0 1-.116-.222.385.385 0 0 1 .07-.271l.386-.544q-.463-.268-1.011-.658Z"
          data-name="Path 920"
        />
        <path
          d="M83.939 84.533q.89 1.163 2.65 3.267t2.041 2.451l-.193.22a.3.3 0 0 1-.2.1.255.255 0 0 1-.214-.065 7.1 7.1 0 0 1-1.176-1.448q-.627-.966-.831-1.22t-.707-.769q-.5-.517-.97-1.032c-.315-.343-.622-.7-.926-1.084a4.081 4.081 0 0 1-.636-1.038.66.66 0 0 1 .03-.706l.307-.052a17.859 17.859 0 0 0 2.622 1.907q1.436.859 2.465 1.381t1.321.642a4.383 4.383 0 0 1 .81.407.338.338 0 0 1 .131.247.314.314 0 0 1-.079.223q-.239.273-1.391-.3a22.672 22.672 0 0 1-2.087-1.176c-.617-.4-1.09-.7-1.415-.915s-.65-.43-.973-.649-.518-.35-.584-.391"
          data-name="Path 921"
        />
        <path
          d="M74.091 92.001q.37 1.417 1.18 4.038t.934 3.051l-.264.128a.3.3 0 0 1-.22.016.265.265 0 0 1-.172-.142 7.057 7.057 0 0 1-.524-1.791c-.135-.756-.234-1.237-.3-1.446s-.177-.535-.353-.983-.34-.889-.5-1.328-.3-.889-.432-1.358a4.088 4.088 0 0 1-.185-1.2c.01-.334.111-.548.3-.639l.3.071a17.824 17.824 0 0 0 1.68 2.772q.99 1.348 1.739 2.228t.97 1.1a4.354 4.354 0 0 1 .589.688.339.339 0 0 1 .025.279.326.326 0 0 1-.158.176c-.218.1-.606-.168-1.168-.818a22.062 22.062 0 0 1-1.467-1.891c-.418-.6-.734-1.07-.953-1.391s-.432-.649-.646-.976-.342-.522-.388-.585"
          data-name="Path 922"
        />
        <path
          d="M61.208 95.133q-.242 1.443-.58 4.168t-.4 3.165l-.293.008a.3.3 0 0 1-.209-.076.261.261 0 0 1-.1-.2 7.045 7.045 0 0 1 .258-1.848c.185-.745.294-1.223.323-1.438s.057-.563.082-1.043.053-.949.092-1.413.092-.935.163-1.416a4.057 4.057 0 0 1 .325-1.172c.147-.3.324-.454.536-.46l.247.19a17.826 17.826 0 0 0 .4 3.217c.234 1.09.455 2.006.671 2.745s.359 1.209.432 1.405a4.218 4.218 0 0 1 .253.87.327.327 0 0 1-.09.263.32.32 0 0 1-.216.1c-.24.006-.485-.4-.728-1.225a22.689 22.689 0 0 1-.563-2.326c-.131-.723-.229-1.277-.3-1.66s-.128-.769-.188-1.155-.1-.617-.112-.693"
          data-name="Path 923"
        />
        <path
          d="m57.62 97.786.141-2.761.029-.386.033-.361-.171-.013-.375-.014c-.354-.027-.524-.149-.508-.364a.37.37 0 0 1 .15-.272.444.444 0 0 1 .311-.095l.569.043a6.948 6.948 0 0 1 .753.081 1.323 1.323 0 0 1 .159 0c.308.022.454.131.442.326a.4.4 0 0 1-.094.222.225.225 0 0 1-.16.1l-.369-.027-.038.169-.116 2.763-.285 2.885c-.036.494-.049.96-.04 1.4l.249.019.3.01a.531.531 0 0 1 .293.095.313.313 0 0 1 .087.283c-.014.2-.234.283-.661.25l-1.546-.114c-.351-.027-.52-.13-.506-.312a.293.293 0 0 1 .131-.214.373.373 0 0 1 .266-.081l.666.049c-.008-.358.006-.761.038-1.209Z"
          data-name="Path 924"
        />
        <path
          d="M46.684 91.995q-.88 1.168-2.435 3.43t-1.812 2.625l-.264-.127a.293.293 0 0 1-.149-.164.267.267 0 0 1 0-.223 7.08 7.08 0 0 1 1.081-1.521c.509-.576.824-.953.951-1.13s.308-.472.551-.888.487-.818.732-1.212.513-.788.8-1.182a4.109 4.109 0 0 1 .829-.891.665.665 0 0 1 .688-.161l.133.282a17.8 17.8 0 0 0-1.131 3.038q-.444 1.614-.67 2.747a27.46 27.46 0 0 0-.263 1.445 4.415 4.415 0 0 1-.176.891.334.334 0 0 1-.2.191.328.328 0 0 1-.237-.016q-.326-.157-.082-1.422a22.49 22.49 0 0 1 .571-2.324q.325-1.054.5-1.609t.364-1.112c.124-.37.2-.592.218-.666"
          data-name="Path 925"
        />
        <path
          d="m42.266 92.698 1.394-2.386.2-.331.195-.305-.147-.09-.324-.185q-.456-.28-.285-.555a.361.361 0 0 1 .258-.172.435.435 0 0 1 .319.059l.488.3a6.419 6.419 0 0 1 .63.418 1.162 1.162 0 0 1 .142.073q.394.242.241.492a.391.391 0 0 1-.183.155.225.225 0 0 1-.19.016l-.313-.191-.113.131-1.373 2.4-1.578 2.432q-.389.631-.677 1.223l.211.13.261.146a.522.522 0 0 1 .215.218.308.308 0 0 1-.052.293q-.157.254-.7-.082l-1.322-.812c-.3-.185-.4-.354-.305-.51a.294.294 0 0 1 .213-.13.38.38 0 0 1 .276.051l.568.35q.237-.482.59-1.055Z"
          data-name="Path 926"
        />
        <path
          d="m39.836 91.033 1.594-2.26.231-.312.221-.286-.139-.1-.309-.212c-.285-.212-.366-.405-.236-.579a.365.365 0 0 1 .274-.149.43.43 0 0 1 .312.085l.459.34a6.906 6.906 0 0 1 .594.47 1.437 1.437 0 0 1 .134.084c.25.185.315.354.2.511a.394.394 0 0 1-.2.139.232.232 0 0 1-.191 0l-.3-.218-.122.122-1.573 2.274-1.78 2.288q-.444.6-.782 1.16l.2.149.247.168a.527.527 0 0 1 .2.236.313.313 0 0 1-.079.286q-.176.24-.693-.142l-1.245-.922q-.423-.313-.261-.533a.3.3 0 0 1 .223-.111.376.376 0 0 1 .271.074l.536.4c.185-.307.41-.641.677-1Z"
          data-name="Path 927"
        />
        <path
          d="M34.572 81.653q-1.341.589-3.8 1.8t-2.864 1.4l-.168-.239a.309.309 0 0 1-.05-.217.257.257 0 0 1 .114-.191 7.037 7.037 0 0 1 1.685-.8c.725-.253 1.187-.427 1.381-.521s.5-.259.914-.5.826-.476 1.234-.7.831-.437 1.272-.642a4.07 4.07 0 0 1 1.158-.372.668.668 0 0 1 .681.2l-.024.31a17.99 17.99 0 0 0-2.473 2.1q-1.175 1.191-1.928 2.068t-.937 1.131a4.2 4.2 0 0 1-.587.69.333.333 0 0 1-.271.068.321.321 0 0 1-.2-.128q-.206-.3.625-1.282a22.927 22.927 0 0 1 1.635-1.747q.8-.759 1.226-1.158t.861-.793c.289-.261.462-.419.517-.473"
          data-name="Path 928"
        />
        <path
          d="m30.37 80.1 2.385-1.4.34-.188.319-.171-.084-.149-.193-.323q-.259-.468.025-.623a.369.369 0 0 1 .312-.024.443.443 0 0 1 .249.207l.277.5a6.747 6.747 0 0 1 .345.674 1.259 1.259 0 0 1 .089.133c.149.269.138.453-.031.546a.377.377 0 0 1-.237.044.229.229 0 0 1-.174-.078l-.177-.323-.165.062-2.372 1.418-2.568 1.345c-.432.241-.831.486-1.191.734l.12.217.155.255a.51.51 0 0 1 .082.3.313.313 0 0 1-.19.229q-.261.145-.573-.416l-.752-1.356c-.171-.309-.176-.506-.017-.593a.291.291 0 0 1 .25-.008.376.376 0 0 1 .215.177l.324.584c.3-.2.638-.411 1.03-.63Z"
          data-name="Path 929"
        />
        <path
          d="m29.071 77.455 2.5-1.185.354-.157.334-.142-.07-.157-.165-.337c-.146-.324-.119-.532.079-.619a.362.362 0 0 1 .31 0 .439.439 0 0 1 .231.228l.233.522a6.448 6.448 0 0 1 .285.7c.035.059.061.1.078.139.125.283.1.464-.079.543a.4.4 0 0 1-.241.024.229.229 0 0 1-.166-.093l-.15-.337-.167.047-2.487 1.207-2.674 1.117q-.679.3-1.251.628l.1.226.134.267a.527.527 0 0 1 .054.3.312.312 0 0 1-.207.212c-.182.081-.361-.073-.535-.464l-.631-1.416q-.216-.482.035-.593a.289.289 0 0 1 .248.014.377.377 0 0 1 .2.2l.272.609q.465-.263 1.081-.54Z"
          data-name="Path 930"
        />
        <path
          d="m28.003 74.694 2.59-.964.369-.127.343-.114-.057-.161-.134-.35c-.116-.337-.071-.54.133-.611a.368.368 0 0 1 .309.03.441.441 0 0 1 .21.248l.186.54a6.3 6.3 0 0 1 .223.723 1.348 1.348 0 0 1 .065.146c.1.293.058.47-.124.533a.383.383 0 0 1-.242 0 .227.227 0 0 1-.159-.106l-.12-.348-.171.032-2.584.987-2.759.881q-.7.244-1.3.517l.083.234.109.278a.531.531 0 0 1 .028.305.311.311 0 0 1-.226.193q-.283.1-.491-.508l-.508-1.465q-.174-.5.085-.589a.3.3 0 0 1 .247.036.374.374 0 0 1 .182.212l.218.631q.486-.223 1.124-.443Z"
          data-name="Path 931"
        />
        <path
          d="m26.195 64.624 2.76-.16.388-.014.362-.006-.006-.171-.026-.375c-.012-.354.089-.536.305-.544a.369.369 0 0 1 .286.12.43.43 0 0 1 .128.3l.019.573a6.25 6.25 0 0 1 0 .755 1.5 1.5 0 0 1 .019.16c.011.309-.083.467-.275.473a.389.389 0 0 1-.231-.068.232.232 0 0 1-.12-.149l-.014-.367-.171-.021-2.76.185-2.9.032q-.743.026-1.4.112l.01.247.022.3a.529.529 0 0 1-.061.3.312.312 0 0 1-.272.117c-.2.008-.307-.2-.321-.63l-.054-1.549c-.013-.351.071-.53.255-.538a.3.3 0 0 1 .226.108.379.379 0 0 1 .111.256l.022.668c.354-.047.756-.077 1.205-.095Z"
          data-name="Path 932"
        />
        <path
          d="m23.205 61.655-.426-.014a1.071 1.071 0 0 1-.536-.142.711.711 0 0 1-.274-.483l.316-.226a1.156 1.156 0 0 0 .568.237c.185.019.366.03.538.04l.152.008a9.265 9.265 0 0 0 1.432.394 10.37 10.37 0 0 0 1.486.244 4.531 4.531 0 0 0 1.25-.174q.788-.2 1.514-.4a3.981 3.981 0 0 1 .949-.19.412.412 0 0 1 .313.12.348.348 0 0 1 .081.234.307.307 0 0 1-.131.222 1.119 1.119 0 0 1-.343.171c-.144.047-.3.093-.464.133s-.347.076-.539.1l-.522.076c-.155.022-.308.043-.462.06s-.258.032-.313.043q.19.04.965.165a6.619 6.619 0 0 1 1.176.271 1.013 1.013 0 0 1 .154 0c.321.016.476.134.466.359a.5.5 0 0 1-.093.266.246.246 0 0 1-.225.112 4.983 4.983 0 0 1-1.256-.245l-1.386-.437a4.705 4.705 0 0 0-1.158-.241 11.711 11.711 0 0 0-1.965.231 7.731 7.731 0 0 0-1.72.4l-.139-.006a.39.39 0 0 1-.292.106c-.2-.009-.3-.144-.286-.4.01-.177.3-.324.862-.441q.743-.154 1.313-.218c.38-.043.6-.07.66-.079a8.494 8.494 0 0 1-.318-.073l-.353-.081a19.889 19.889 0 0 0-.31-.06 5.619 5.619 0 0 0-.684-.084"
          data-name="Path 933"
        />
        <path
          d="m27.99 45.347-.375-.2a1.077 1.077 0 0 1-.418-.366.725.725 0 0 1-.028-.554l.384-.062a1.147 1.147 0 0 0 .4.465c.158.1.313.19.465.274l.133.076a9.1 9.1 0 0 0 1.106.989 10.338 10.338 0 0 0 1.222.88 4.589 4.589 0 0 0 1.2.4c.53.115 1.041.223 1.533.318a3.958 3.958 0 0 1 .935.255.4.4 0 0 1 .226.247.329.329 0 0 1-.032.244.3.3 0 0 1-.217.141 1.174 1.174 0 0 1-.381 0 6.375 6.375 0 0 1-.476-.087 5.327 5.327 0 0 1-.53-.147l-.5-.163q-.221-.074-.438-.152a4.987 4.987 0 0 0-.3-.1c.1.081.364.272.793.576a6.79 6.79 0 0 1 .932.766 1.387 1.387 0 0 1 .136.07c.282.157.367.334.258.528a.5.5 0 0 1-.2.2.248.248 0 0 1-.252 0 5.159 5.159 0 0 1-1.016-.78l-1.046-1.006a4.805 4.805 0 0 0-.929-.733 11.884 11.884 0 0 0-1.864-.666 7.787 7.787 0 0 0-1.717-.411l-.122-.068a.388.388 0 0 1-.309-.033q-.266-.15-.076-.487.128-.233.967-.013.736.195 1.274.389t.627.223c-.044-.035-.128-.1-.253-.207s-.217-.18-.278-.229-.147-.114-.253-.191a5.21 5.21 0 0 0-.575-.38"
          data-name="Path 934"
        />
        <path
          d="m39.323 32.985-.247-.347a1.092 1.092 0 0 1-.217-.511.726.726 0 0 1 .219-.511l.372.114a1.153 1.153 0 0 0 .158.593c.1.158.2.309.3.451l.089.125a8.818 8.818 0 0 0 .56 1.375 10.5 10.5 0 0 0 .712 1.328 4.606 4.606 0 0 0 .9.883q.636.506 1.239.959a3.948 3.948 0 0 1 .73.638.408.408 0 0 1 .095.32.333.333 0 0 1-.136.207.3.3 0 0 1-.256.032 1.175 1.175 0 0 1-.345-.168 6.097 6.097 0 0 1-.801-.65l-.377-.369c-.113-.108-.221-.217-.329-.328s-.184-.185-.224-.223c.053.115.207.405.457.866a6.7 6.7 0 0 1 .5 1.1 1.347 1.347 0 0 1 .092.122q.276.4 0 .589a.481.481 0 0 1-.266.089.245.245 0 0 1-.228-.109 5.087 5.087 0 0 1-.571-1.147l-.5-1.362a4.712 4.712 0 0 0-.516-1.065 11.691 11.691 0 0 0-1.382-1.416 7.629 7.629 0 0 0-1.362-1.122l-.081-.116a.386.386 0 0 1-.261-.166c-.117-.166-.069-.324.144-.472.146-.1.438.038.875.415q.577.5.975.908c.266.274.421.432.465.473-.024-.049-.07-.149-.136-.3s-.117-.256-.15-.328-.082-.166-.144-.285a5.271 5.271 0 0 0-.35-.592"
          data-name="Path 935"
        />
        <path
          d="m43.497 33.945 1.435 2.362.193.335.176.318.149-.085.318-.2c.308-.177.517-.172.625.014a.365.365 0 0 1 .029.31.444.444 0 0 1-.2.253l-.5.285a6.465 6.465 0 0 1-.668.356 1.124 1.124 0 0 1-.131.09c-.268.155-.45.147-.548-.022a.4.4 0 0 1-.047-.236.23.23 0 0 1 .076-.176l.318-.184-.063-.161-1.457-2.35-1.386-2.546q-.37-.643-.753-1.179l-.215.123-.252.16a.533.533 0 0 1-.3.087.317.317 0 0 1-.231-.185c-.1-.174.036-.367.406-.581l1.344-.774c.3-.176.5-.184.593-.027a.307.307 0 0 1 .013.25.392.392 0 0 1-.176.218l-.579.334c.208.291.424.631.647 1.019Z"
          data-name="Path 936"
        />
        <path
          d="M116.94 122.791H4.707a4.988 4.988 0 0 1-3.644-1.071c-.919-.923-.916-2.266-.913-3.565V5.196C.15 1.256 3.307.802 5.682.802c.345 0 1.79-.027 4.109-.066C19.016.576 43.519.151 67.165.151c21.022 0 41.782.456 48.605.606 1.255.028 2 .046 2.143.046a3.856 3.856 0 0 1 4.068 3.9v115.4c.002 2.487-2.281 2.688-5.041 2.688ZM67.169 1.131c-23.638 0-48.135.424-57.357.585-2.331.04-3.78.065-4.127.065-3.233 0-4.552.99-4.552 3.415v112.962c0 1.2-.006 2.237.628 2.87a4.144 4.144 0 0 0 2.948.783H116.94c3.5 0 4.065-.5 4.065-1.7V4.711a2.88 2.88 0 0 0-3.088-2.924c-.144 0-.9-.016-2.165-.044-6.822-.155-27.574-.612-48.585-.612Z"
          data-name="Path 937"
        />
        <path
          d="M60.391 109.7a46.006 46.006 0 1 1 46.007-46.005A46.058 46.058 0 0 1 60.391 109.7Zm0-91.031a45.026 45.026 0 1 0 45.027 45.026 45.077 45.077 0 0 0-45.027-45.026Zm0 86.936a41.912 41.912 0 1 1 41.913-41.91 41.959 41.959 0 0 1-41.913 41.91Zm0-82.845a40.933 40.933 0 1 0 40.933 40.934 40.981 40.981 0 0 0-40.933-40.933Zm0 69.833a28.9 28.9 0 1 1 28.9-28.9 28.932 28.932 0 0 1-28.9 28.901Zm0-56.819a27.92 27.92 0 1 0 27.919 27.92 27.953 27.953 0 0 0-27.919-27.916Zm17.32 45.8a.492.492 0 0 1-.346-.142l-5.214-5.211a2.689 2.689 0 0 1-3.417-.315 2.679 2.679 0 0 1-.763-2.228 3.514 3.514 0 0 1-3.5-.873 3.511 3.511 0 0 1-.322-4.586l-.245-.245a5.482 5.482 0 0 1-7.385-.331 5.445 5.445 0 0 1-1.6-3.872V63.7a5.486 5.486 0 0 1 4.987-5.457v-6.32a2.586 2.586 0 0 1-.6-.187 2.684 2.684 0 0 1 .6-5.09v-7.372a.49.49 0 1 1 .979 0v7.372a2.665 2.665 0 0 1 .606.188 2.721 2.721 0 0 1 1.1-.233 2.684 2.684 0 1 1-1.1 5.135 2.577 2.577 0 0 1-.6.187v6.32a5.486 5.486 0 0 1 4.988 5.457v.231a5.466 5.466 0 0 1-1.267 3.35l.243.245a3.586 3.586 0 0 1 4.588.324 3.5 3.5 0 0 1 .876 3.5 2.654 2.654 0 0 1 2.225.764 2.689 2.689 0 0 1 .317 3.416l5.212 5.212a.489.489 0 0 1-.346.835Zm-8.283-6.361a1.7 1.7 0 1 0 0-2.413 1.71 1.71 0 0 0 0 2.408Zm-4.266-3.1a2.531 2.531 0 0 0 3.573 0 2.521 2.521 0 0 0 0-3.57 2.526 2.526 0 1 0-3.573 3.571Zm-9.268-8.355a4.5 4.5 0 1 0 1.317-3.171 4.5 4.5 0 0 0-1.318 3.168Zm6.573-12.777a1.723 1.723 0 0 0 1.82-1.7 1.7 1.7 0 0 0-1.82-1.7 2.679 2.679 0 0 1 0 3.4Zm-3.782-1.7a1.7 1.7 0 1 0 1.706-1.7 1.707 1.707 0 0 0-1.706 1.697Zm-2.191 0a1.706 1.706 0 0 0 1.819 1.7 2.679 2.679 0 0 1 0-3.4 1.7 1.7 0 0 0-1.82 1.697Z"
          data-name="Path 938"
        />
        <path
          d="M108.644 133.934c-3.769 0-6.834-2.19-6.834-4.883a3.841 3.841 0 0 1 .813-2.315v-4.924h12.04v4.924a3.843 3.843 0 0 1 .813 2.315c.001 2.693-3.062 4.883-6.832 4.883Zm-5.041-11.143v4.288l-.109.135a2.928 2.928 0 0 0-.7 1.837c0 2.154 2.628 3.9 5.855 3.9s5.853-1.75 5.853-3.9a2.925 2.925 0 0 0-.7-1.837l-.111-.135v-4.288Zm-90.111 11.143c-3.769 0-6.833-2.19-6.833-4.883a3.84 3.84 0 0 1 .812-2.315v-4.924h12.042v4.924a3.849 3.849 0 0 1 .812 2.315c-.001 2.693-3.064 4.883-6.833 4.883ZM8.45 122.791v4.288l-.109.135a2.924 2.924 0 0 0-.7 1.837c0 2.154 2.627 3.9 5.853 3.9s5.853-1.75 5.853-3.9a2.925 2.925 0 0 0-.7-1.837l-.111-.135v-4.288Z"
          data-name="Path 939"
        />
        <path
          d="M60.511 233.588a15.813 15.813 0 0 1-.37-31.621v-78.758a.49.49 0 1 1 .979 0v78.767a15.812 15.812 0 0 1-.609 31.613Zm0-30.644a14.833 14.833 0 1 0 14.832 14.832 14.85 14.85 0 0 0-14.832-14.832Z"
          data-name="Path 940"
        />
        <path
          d="M48.618 218.268a.49.49 0 0 1-.489-.491 12.4 12.4 0 0 1 12.382-12.383.49.49 0 0 1 0 .979 11.416 11.416 0 0 0-11.4 11.4.491.491 0 0 1-.493.495Z"
          data-name="Path 941"
        />
        <path
          d="M83.107 218.691a.491.491 0 0 1-.063-.978 154.726 154.726 0 0 0 11.847-2.017.491.491 0 1 1 .2.96 155.355 155.355 0 0 1-11.921 2.032.433.433 0 0 1-.063.003Zm-43-.315a.574.574 0 0 1-.071 0 155.432 155.432 0 0 1-35.53-9.578.494.494 0 0 1-.274-.639.5.5 0 0 1 .639-.272 154.371 154.371 0 0 0 35.307 9.519.49.49 0 0 1-.071.975Z"
          data-name="Path 942"
        />
      </g>
    </svg>
  </div>
</section>
