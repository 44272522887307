export class HashUtility {
  /* Generates a hash code for a given string using the DJB2 algorithm. */
  static hashDJB2(str: string) {
    let hash = 5381; // Initial hash value

    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      // Bitwise left shift with overflow and add character code
      hash = (hash << 5) + hash + char;
      // Convert to 32bit integer
      hash = hash & hash;
    }

    // Convert to positive and return as string
    return (hash >>> 0).toString(16);
  }
}
