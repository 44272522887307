<div class="w-full max-w-[500px] my-0 mx-auto lg:max-w-[600px]">
  <h1 class="text-sf-white font-bold text-2xl xl:text-4xl mb-4 mx:mb-6 xl:mb-8">Reset password</h1>
  <app-alert
    *ngIf="!codeCtrl.value || !emailCtrl.value"
    type="warning"
    additionalClass="mb-4 md:mb-6 xl:mb-8 [&_p]:mb-0"
    message="Reset code or email has not been supplied."
  ></app-alert>

  <div *ngIf="codeCtrl.value && emailCtrl.value">
    <form #form="ngForm" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
      <app-alert
        type="info"
        additionalClass="mb-4 md:mb-6 xl:mb-8 [&_p]:mb-0"
        message=" Your password must be at least 8 characters long,
                      contain one lowercase and one uppercase letter, one
                      number and one special character (e.g. # & % ^ ! ?)"
      ></app-alert>

      <input id="email" name="email" formControlName="email" type="hidden" class="form-control" />
      <input id="code" name="code" formControlName="code" type="hidden" class="form-control" />
      <div class="mb-4">
        <div>
          <div class="mb-4">
            <label for="password" class="form-label">New password</label>
            <input
              id="password"
              name="password"
              formControlName="password"
              type="password"
              class="form-control"
              [ngClass]="{
                'is-invalid': passwordCtrl.touched && passwordCtrl.errors,
              }"
            />
          </div>

          <div class="mb-4">
            <label for="confirmPassword" class="form-label">Confirm your new password</label>
            <input
              id="confirmPassword"
              name="confirmPassword"
              formControlName="confirmPassword"
              type="password"
              class="form-control"
              [ngClass]="{
                'is-invalid': passwordConfirmCtrl.touched && passwordConfirmCtrl.errors,
              }"
            />
          </div>

          <div class="mb-4">
            <div *ngIf="passwordCtrl.touched && resetPasswordForm.errors">
              <app-validation-message
                *ngIf="resetPasswordForm.errors['passwordMismatch']"
                message="Your password does not match the
                                      confirmation field"
              ></app-validation-message>

              <app-validation-message
                *ngIf="resetPasswordForm.errors['passwordStrength']"
                message="Your password must be at least 8
                                      characters long, contain one
                                      lowercase and one uppercase letter,
                                      one number and one special character"
              ></app-validation-message>
            </div>
          </div>
        </div>
      </div>
      <div class="py-4 md:py-5">
        <app-button
          text="Update"
          buttonStyle="primary"
          [noBorder]="true"
          [disabled]="!form.valid"
          [isSubmitButton]="true"
        ></app-button>
      </div>
    </form>
  </div>

  <app-need-help />
</div>
