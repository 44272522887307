import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { MemberDashboardCacheService, ScriptLoaderService } from '@app/services';
import { Observable, from, map, catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TopUpGuard {
  constructor(
    private memberDashboardCacheService: MemberDashboardCacheService,
    private scriptLoader: ScriptLoaderService
  ) {}

  /**
   * Ensures that the Stripe.js script is loaded before the route activation.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return from(this.scriptLoader.loadScript('https://js.stripe.com/v3/')).pipe(
      map(() => true),
      catchError((error) => {
        console.error('Error loading script', error);
        return of(false);
      })
    );
  }

  canDeactivate(): boolean {
    this.memberDashboardCacheService.clearCache();
    return true;
  }
}
