import { Injectable } from '@angular/core';
import { AppConstantsService } from './app-constants.service';
import { CookieService } from 'ngx-cookie';
import { StorageKey } from '@app/models/security';

import {
  AuthenticationPublicKeyCredential,
  RegistrationPublicKeyCredential,
  create,
  get,
  parseCreationOptionsFromJSON,
  parseRequestOptionsFromJSON,
} from '@github/webauthn-json/browser-ponyfill';

// extension declarations for running in WebView under iOS/Android
declare function interop_android(): boolean;
declare function interop_ios(): boolean;
declare function invoke_DoCreate(data: string): any;
declare function invoke_DoGet(data: string): any;

@Injectable()
export class FidoService {
  private readonly kConsoleTrace = false;
  private readonly kAlreadyRegistered = 'Already Registered';

  private baseUri: string = 'http://localhost:61490';

  public readonly api = {
    OBTAIN_USER_TOKEN: `/api/Fido/ObtainUserToken`,
    MAKE_CREDENTIAL_OPTIONS: `/api/Fido/MakeCredentialOptions`,
    MAKE_CREDENTIAL: `/api/Fido/MakeCredential`,
    GET_ASSERTION_OPTIONS: `/api/Fido/GetAssertionOptions`,
    MAKE_ASSERTION: `/api/Fido/MakeAssertion`,
  };

  constructor(private _configuration: AppConstantsService, private cookie: CookieService) {
    this.baseUri = this._configuration.server;
  }

  get isWebAuthnAvailable(): boolean {
    return (
      navigator.credentials !== undefined &&
      navigator.credentials.create !== undefined &&
      navigator.credentials.get !== undefined
    );
  }

  checkForBiometricAutomaticDataUpdate() {
    // check for last know user name usage
    const userName = localStorage.getItem(StorageKey.SF_USERNAME);
    if (userName) {
      // auto convert any existing biometric keys to the new [multi user] format
      const legacyFlag = localStorage.getItem(StorageKey.SF_BIOMETRICS);
      const legacyToken = localStorage.getItem(StorageKey.SF_USER_TOKEN);
      if (legacyFlag && legacyToken) {
        // convert 'flag' & 'token'
        this.setRegistered(legacyToken, userName);

        // convert 'prompt'
        const legacyPrompt = localStorage.getItem(StorageKey.SF_BIOMETRICS_PROMPT);
        if (legacyPrompt) {
          localStorage.setItem(StorageKey.SF_BIOMETRICS_PROMPT_MULTI + ':' + userName, 'yes');
        }

        // reset legacy keys
        localStorage.removeItem(StorageKey.SF_BIOMETRICS);
        localStorage.removeItem(StorageKey.SF_USER_TOKEN);
        localStorage.removeItem(StorageKey.SF_BIOMETRICS_PROMPT);
      }
    }
  }

  setRegistered(token: string, userName: string) {
    // enable webauthn for user
    localStorage.setItem(StorageKey.SF_BIOMETRICS_MULTI + ':' + userName, 'yes');
    localStorage.setItem(StorageKey.SF_USER_TOKEN_MULTI + ':' + userName, token);
  }

  isDeviceRegistered(userName: string): boolean {
    // is webauthn enabled for user
    return localStorage.getItem(StorageKey.SF_BIOMETRICS_MULTI + ':' + userName) != null;
  }

  clearRegistered(userName: string): boolean {
    // reset legacy keys
    localStorage.removeItem(StorageKey.SF_BIOMETRICS);
    localStorage.removeItem(StorageKey.SF_USER_TOKEN);
    localStorage.removeItem(StorageKey.SF_BIOMETRICS_PROMPT);

    // disable webauthn for user
    localStorage.removeItem(StorageKey.SF_BIOMETRICS_MULTI + ':' + userName);
    localStorage.removeItem(StorageKey.SF_USER_TOKEN_MULTI + ':' + userName);

    // if the user has unregistered don't pester with uptake UI
    localStorage.setItem(StorageKey.SF_BIOMETRICS_PROMPT_MULTI + ':' + userName, 'yes');

    return false;
  }

  promptUser(userName: string): boolean {
    // show the user uptake UI?
    return localStorage.getItem(StorageKey.SF_BIOMETRICS_PROMPT_MULTI + ':' + userName) == null;
  }

  isAlreadyRegisteredException(e: any) {
    // exception code throw by 'navigator.credentials.create' which indicates (on Win10/Android & iOS tested) that the device
    // has already been registered for webauthn with the given domain
    return e == this.kAlreadyRegistered;
  }

  async obtainUserToken(): Promise<string> {
    let authToken = this.cookie.get(StorageKey.SF_ACCESS_TOKEN);

    // call remote API 'ObtainUserToken'
    let response = await fetch(this.baseUri + this.api.OBTAIN_USER_TOKEN, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });

    let js = await response.json();
    if (this.kConsoleTrace) console.log(js);
    if (response.status == 400) {
      throw js.message;
    }

    return js.Token;
  }

  async register(userName: string, password: string, token: string) {
    if (!this.isWebAuthnAvailable) {
      throw 'WebAuthn is not available on this platform.';
    }
    if (userName == undefined || password == undefined) {
      throw 'Invalid argument.';
    }
    if (!token || token.length == 0) {
      throw 'No user token?';
    }

    // call remote API 'MakeCredentialOptions'
    let makeCredentialOptionsModel = {
      userName: userName,
      password: password,
    };
    let response = await fetch(this.baseUri + this.api.MAKE_CREDENTIAL_OPTIONS, {
      method: 'POST',
      body: JSON.stringify(makeCredentialOptionsModel),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    let js = await response.json();
    if (this.kConsoleTrace) console.log(js);
    if (response.status == 400) {
      throw js.message;
    }

    // call local javascript API 'navigator.credentials.create'
    let credential = undefined;
    const options = parseCreationOptionsFromJSON({ publicKey: js });

    try {
      // check for native support
      if (
        (typeof interop_android === 'function' && interop_android()) ||
        (typeof interop_ios === 'function' && interop_ios())
      ) {
        credential = await this.invoke_native_create(js as PublicKeyCredentialCreationOptions);
      } else {
        credential = await create(options);
      }
      if (this.kConsoleTrace) console.log(JSON.stringify(credential));
    } catch (e) {
      if ((e as DOMException).code == DOMException.INVALID_STATE_ERR) {
        throw 'Already Registered';
      } else {
        throw e;
      }
    }

    // call remote API 'MakeCredential'
    let makeCredentialModel = {
      options: js,
      attestationResponse: credential,
    };
    response = await fetch(this.baseUri + this.api.MAKE_CREDENTIAL, {
      method: 'POST',
      body: JSON.stringify(makeCredentialModel),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    js = await response.json();
    if (this.kConsoleTrace) console.log(js);
    if (response.status == 400) {
      throw js.message;
    }

    // assume that if we get this far registration was successful
    this.setRegistered(token, userName);
  }

  async authorise(userName: string): Promise<string> {
    if (!this.isWebAuthnAvailable) {
      throw 'WebAuthn is not available on this platform.';
    }
    if (userName == undefined) {
      throw 'Invalid argument.';
    }
    let token = localStorage.getItem(StorageKey.SF_USER_TOKEN_MULTI + ':' + userName);
    if (!token || token.length == 0) {
      throw 'No user token?';
    }

    // call remote API 'GetAssertionOptions'
    let getAssertionOptionsModel = { userName: userName, token: token };
    let response = await fetch(this.baseUri + this.api.GET_ASSERTION_OPTIONS, {
      method: 'POST',
      body: JSON.stringify(getAssertionOptionsModel),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    let js = await response.json();
    if (this.kConsoleTrace) console.log(js);
    if (response.status == 400) {
      throw js.message;
    }

    // call local javascript API 'navigator.credentials.get'
    let credential = undefined;
    const options = parseRequestOptionsFromJSON({ publicKey: js });

    try {
      // check for native support
      if (
        (typeof interop_android === 'function' && interop_android()) ||
        (typeof interop_ios === 'function' && interop_ios())
      ) {
        credential = await this.invoke_native_get(js as PublicKeyCredentialRequestOptions);
      } else {
        credential = await get(options);
      }
      if (this.kConsoleTrace) console.log(JSON.stringify(credential));
    } catch (e) {
      throw e;
    }

    // call remote API 'MakeAssertion'
    let makeAssertionModel = { options: js, clientResponse: credential };
    response = await fetch(this.baseUri + this.api.MAKE_ASSERTION, {
      method: 'POST',
      body: JSON.stringify(makeAssertionModel),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    js = await response.json();
    if (this.kConsoleTrace) console.log(js);
    if (response.status == 400) {
      throw js.message;
    }

    // return value is a temporary password
    const password = js;
    return password;
  }

  invoke_native_create(
    options: PublicKeyCredentialCreationOptions
  ): Promise<RegistrationPublicKeyCredential> {
    // resolve promise on native callback
    return new Promise((resolve, reject) => {
      (window as any).DoneCreate = (jsonIn: string) => {
        // JSON -> obj
        if (!jsonIn) {
          reject();
        } else {
          let rv = JSON.parse(jsonIn);
          resolve(rv);
        }
      };
      // obj -> JSON
      let jsonOut = JSON.stringify(options);

      // call native function
      invoke_DoCreate(jsonOut);
    });
  }

  invoke_native_get(
    options: PublicKeyCredentialRequestOptions
  ): Promise<AuthenticationPublicKeyCredential> {
    // resolve promise on native callback
    return new Promise((resolve, reject) => {
      (window as any).DoneGet = (jsonIn: string) => {
        // JSON -> obj
        if (!jsonIn) {
          reject();
        } else {
          let rv = JSON.parse(jsonIn);
          resolve(rv);
        }
      };
      // obj -> JSON
      let jsonOut = JSON.stringify(options);

      // call native function
      invoke_DoGet(jsonOut);
    });
  }
}
