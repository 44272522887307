<app-content-header
  *ngIf="jisaDetails.plan"
  [title]="'My Stocks and Shares Junior ISA'"
  [subtitle]="jisaDetails.plan.formattedPlanNumber"
></app-content-header>

<section *ngIf="jisaDetails.plan">
  <app-plan-overview [plan]="jisaDetails.plan" [member]="jisaDetails.member" [fullView]="true" />
  <div class="mt-4">
    <h2 class="mb-3 text-primary-sf-navy dark:text-sf-white">Quick actions</h2>

    <div class="md:grid md:grid-cols-2 md:auto-rows-fr md:gap-6">
      <app-quick-action-button
        *ngIf="jisaDetails.plan.isIssued"
        (click)="navigateWithState(url.TOP_UP(jisaDetails.plan.formattedPlanNumber))"
        [quickActionBtnClass]="'my-4 md:my-0'"
        [quickActionBtnIcon]="'icon-make-deposit-min'"
        [quickActionBtnAlt]="'Make a deposit icon'"
        [quickActionBtnText]="'Make a deposit'"
      ></app-quick-action-button>

      <app-quick-action-button
        routerLink="{{
          url.TRANSACTIONS({
            planId: jisaDetails.plan.planId.toString(),
            formattedPlanNumber: jisaDetails.plan.formattedPlanNumber,
          })
        }}"
        [quickActionBtnClass]="'my-4 md:my-0'"
        [quickActionBtnIcon]="'icon-view-transactions-min'"
        [quickActionBtnAlt]="'View transactions icon'"
        [quickActionBtnText]="'View transactions'"
      ></app-quick-action-button>

      <app-quick-action-button
        routerLink="{{ url.DOCUMENTS | interpolate : jisaDetails.plan.formattedPlanNumber }}"
        [quickActionBtnClass]="'my-4 md:my-0'"
        [quickActionBtnIcon]="'icon-view-documents-min'"
        [quickActionBtnAlt]="'View documents icon'"
        [quickActionBtnText]="'View documents'"
      ></app-quick-action-button>

      <app-quick-action-button
        *ngIf="hasNoDirectDebits"
        (click)="navigateWithState(url.DIRECT_DEBIT)"
        [quickActionBtnClass]="'my-4 md:my-0'"
        [quickActionBtnIcon]="'icon-setup-dd-min'"
        [quickActionBtnAlt]="'Setup direct debit icon'"
        [quickActionBtnText]="'Set up Direct Debit'"
      ></app-quick-action-button>

      <app-quick-action-button
        *ngIf="hasDirectDebit && !hasMultipleDirectDebits"
        (click)="
            navigateWithState(url.DIRECT_DEBIT_VARY, {
              directDebit: directDebits[0],
            })
          "
        [quickActionBtnClass]="'my-4 md:my-0'"
        [quickActionBtnIcon]="'icon-setup-dd-min'"
        [quickActionBtnAlt]="'Manage direct debit icon'"
        [quickActionBtnText]="'Manage Direct Debit'"
      ></app-quick-action-button>

      <app-quick-action-button
        *ngIf="hasMultipleDirectDebits"
        (click)="navigateWithState(url.DIRECT_DEBIT_VIEW_ALL, { directDebits })"
        [quickActionBtnClass]="'my-4 md:my-0'"
        [quickActionBtnIcon]="'icon-setup-dd-min'"
        [quickActionBtnAlt]="'Manage direct debit icon'"
        [quickActionBtnText]="'View Direct Debits'"
      ></app-quick-action-button>

      <app-quick-action-button
        *appFeatureFlag="'appWithdrawals'"
        (click)="navigateWithState(url.WITHDRAWAL(jisaDetails.plan.formattedPlanNumber))"
        [quickActionBtnClass]="'my-4 md:my-0'"
        [quickActionBtnIcon]="'icon-request-a-withdrawal'"
        [quickActionBtnAlt]="'Request a withdrawal icon'"
        [quickActionBtnText]="'Request a withdrawal'"
      ></app-quick-action-button>
    </div>
  </div>
  <div class="mt-4">
    <h2 class="mb-3 text-primary-sf-navy dark:text-sf-white">Plan Performance</h2>
    <app-plan-performance-graph [transactionsSummary]="transactionsSummary" />
  </div>
</section>
