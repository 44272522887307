<app-content-header
  [title]="'Thank you for requesting to connect your Junior ISA to your online account'"
/>
<div class="sf-card mt-4 mb-4">
  <p>
    You’re one step closer to managing your Junior ISA online. Before we can add your Junior ISA to
    your account, we need to run a few standard security checks. This is for your protection and to
    make sure we’re adding the right plan to your account. Therefore, your Junior ISA should be
    added to your plan within the
    <strong>next 5 working days.</strong>
  </p>
  <p>
    <strong>Please note:</strong>
    Once it has been successfully added to your online account, you may need to log out and log back
    in again before you can see your Junior ISA in your list of investments.
  </p>

  <p>
    In the meantime, if you have any questions or would like to get in touch, our Member Services
    team will be happy to help. Simply fill in our
    <a
      class="text-primary-sf-navy dark:text-secondary-sf-blue-light"
      href="https://www.shepherdsfriendly.co.uk/help-and-support/"
    >
      online contact form
    </a>
    and a member of the team will get back to you as soon as possible.
  </p>

  <div class="mt-4">
    <app-button
      [text]="'Back to your account'"
      [buttonStyle]="'secondary'"
      [noBorder]="true"
      [routerLink]="url.HOME"
    ></app-button>
  </div>
</div>
