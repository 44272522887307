import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

/*
 * Example usage in our component templates:
 *
 * ```html
 * <nav *ngIf="visibilityService.isMdOrAbove">Nav for md or larger screens</nav>
 * <nav *ngIf="visibilityService.isSmOrBelow">Nav for sm or smaller screens</nav>
 * ```
 * */
@Injectable({
  providedIn: 'root',
})
export class VisibilityService {
  /**
   * Standard TAIL WIND breakpoints
   */
  public readonly breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 1024,
    xl: 1240,
    xxl: 1440,
    full: 1920, // Need to add support for
  };

  private resize$: Observable<string>;
  private currentBreakpoint: string;

  constructor() {
    /**
     * Observable that emits the current breakpoint whenever the window is resized.
     * The resize events are debounced to improve performance.
     */
    this.resize$ = fromEvent(window, 'resize').pipe(
      debounceTime(100),
      map(() => this.getBreakpoint()),
      startWith(this.getBreakpoint())
    );

    /**
     * Subscribe to the resize observable to update the current breakpoint.
     */
    this.resize$.subscribe((breakpoint) => (this.currentBreakpoint = breakpoint));
  }

  /**
   * Determines the current Bootstrap breakpoint based on the window width.
   * We return the current breakpoint as a string ('xs', 'sm', 'md', 'lg', 'xl', or 'xxl').
   */
  private getBreakpoint(): string {
    const width = window.innerWidth;
    if (width >= this.breakpoints.xxl) return 'xxl';
    if (width >= this.breakpoints.xl) return 'xl';
    if (width >= this.breakpoints.lg) return 'lg';
    if (width >= this.breakpoints.md) return 'md';
    if (width >= this.breakpoints.sm) return 'sm';
    return 'xs';
  }

  /** Checks if the current breakpoint is 'xs' */
  get isXs(): boolean {
    return this.currentBreakpoint === 'xs';
  }
  /** Checks if the current breakpoint is 'sm' */
  get isSm(): boolean {
    return this.currentBreakpoint === 'sm';
  }
  /** Checks if the current breakpoint is 'md' */
  get isMd(): boolean {
    return this.currentBreakpoint === 'md';
  }
  /** Checks if the current breakpoint is 'lg' */
  get isLg(): boolean {
    return this.currentBreakpoint === 'lg';
  }
  /** Checks if the current breakpoint is 'xl' */
  get isXl(): boolean {
    return this.currentBreakpoint === 'xl';
  }
  /** Checks if the current breakpoint is 'xxl' */
  get isXxl(): boolean {
    return this.currentBreakpoint === 'xxl';
  }

  /** Checks if the current breakpoint is 'sm' or larger */
  get isSmOrAbove(): boolean {
    return this.isSm || this.isMd || this.isLg || this.isXl || this.isXxl;
  }
  /** Checks if the current breakpoint is 'md' or larger */
  get isMdOrAbove(): boolean {
    return this.isMd || this.isLg || this.isXl || this.isXxl;
  }
  /** Checks if the current breakpoint is 'lg' or larger */
  get isLgOrAbove(): boolean {
    return this.isLg || this.isXl || this.isXxl;
  }
  /** Checks if the current breakpoint is 'xl' or larger */
  get isXlOrAbove(): boolean {
    return this.isXl || this.isXxl;
  }

  /** Checks if the current breakpoint is 'sm' or smaller */
  get isSmOrBelow(): boolean {
    return this.isXs || this.isSm;
  }
  /** Checks if the current breakpoint is 'md' or smaller */
  get isMdOrBelow(): boolean {
    return this.isXs || this.isSm || this.isMd;
  }
  /** Checks if the current breakpoint is 'lg' or smaller */
  get isLgOrBelow(): boolean {
    return this.isXs || this.isSm || this.isMd || this.isLg;
  }
  /** Checks if the current breakpoint is 'xl' or smaller */
  get isXlOrBelow(): boolean {
    return this.isXs || this.isSm || this.isMd || this.isLg || this.isXl;
  }
}
