import { BasePlan } from './base-plan.model';
import { DirectDebitInformation } from './direct-debit-information.model';
import { TrackingInformation } from './tracking.model';

export class SavingsPlan extends BasePlan {
  public endDate: Date;
  public premiumFrequency: string;
  public currentValue: number;
  public annualBonus: number;
  public annualBonusYear: number;
  public taxYearAllowance: number;
  public taxAllowanceRemaining: number;
  public taxYearDescription: string;
  public totalContributions: number;
  public totalWithdrawals: number;
  public taxYearContributions: number;
  public taxYearWithdrawals: number;
  public sumAssured: number;
  public directDebitInformation: DirectDebitInformation;
  public override planIdentifier: string;
  public topUpMin: number;
  public topUpMax: number;
  public planMinimumPayment: number;
  public tracking: TrackingInformation;

  constructor(
    planId: number,
    planTypeId: number,
    planTypeName: string,
    planStatusId: number,
    planStatusName: string,
    planNumber: number,
    formattedPlanNumber: string,
    startDate: Date,
    premium: number,
    isNetPremium: boolean,
    planTypePrefix: string,
    endDate: Date,
    premiumFrequency: string,
    currentValue: number,
    annualBonus: number,
    annualBonusYear: number,
    taxYearAllowance: number,
    taxAllowanceRemaining: number,
    taxYearDescription: string,
    totalContributions: number,
    totalWithdrawals: number,
    taxYearContributions: number,
    taxYearWithdrawals: number,
    sumAssured: number,
    directDebitInformation: DirectDebitInformation,
    planIdentifier: string,
    capacity: number,
    pendingTransactionAmount: number,
    schemeType: number,
    planGroup: string,
    topUpMin: number,
    topUpMax: number,
    planMinimumPayment: number,
    tracking: TrackingInformation
  ) {
    super();

    this.planId = planId;
    this.planTypeId = planTypeId;
    this.planTypeName = planTypeName;
    this.planStatusId = planStatusId;
    this.planStatusName = planStatusName;
    this.planNumber = planNumber;
    this.formattedPlanNumber = formattedPlanNumber;
    this.startDate = startDate;
    this.premium = premium;
    this.isNetPremium = isNetPremium;
    this.planTypePrefix = planTypePrefix;
    this.endDate = endDate;
    this.premiumFrequency = premiumFrequency;
    this.currentValue = currentValue;
    this.annualBonus = annualBonus;
    this.annualBonusYear = annualBonusYear;
    this.taxYearAllowance = taxYearAllowance;
    this.taxAllowanceRemaining = taxAllowanceRemaining;
    this.taxYearDescription = taxYearDescription;
    this.totalContributions = totalContributions;
    this.totalWithdrawals = totalWithdrawals;
    this.taxYearContributions = taxYearContributions;
    this.taxYearWithdrawals = taxYearWithdrawals;
    this.sumAssured = sumAssured;
    this.directDebitInformation = directDebitInformation;
    this.planIdentifier = planIdentifier;
    this.capacity = capacity;
    this.pendingTransactionAmount = pendingTransactionAmount;
    this.schemeType = schemeType;
    this.planGroup = planGroup;
    this.topUpMin = topUpMin;
    this.topUpMax = topUpMax;
    this.planMinimumPayment = planMinimumPayment;
    this.tracking = tracking;
  }

  public get isIssued(): boolean {
    return this.planStatusId === 10;
  }

  public get directDebitDivisibleAmount(): number {
    return this.planTypePrefix.toUpperCase() === 'SSI' ? 5 : 10;
  }
}
