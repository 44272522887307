import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, of, tap } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { RouterHelperService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class AnonymousGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
    private routerHelperService: RouterHelperService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.validateToken().pipe(
      take(1),
      map((status) => {
        return this.actionStatus(status);
      })
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.validateToken().pipe(
      take(1),
      map((status) => {
        return this.actionStatus(status);
      })
    );
  }

  private actionStatus(status: number): boolean {
    if (status === 200) {
      // Token fully-validated, navigate to application home (shouldn't proceed as request is authenticated i.e. already logged in)
      this.router.navigate(['/']);
      return false;
    } else {
      // Token not valid, allow navigation
      return true;
    }
  }
}

export function anonymousGuardFactory(
  authService: AuthService,
  router: Router,
  routerHelperService: RouterHelperService
) {
  return new AnonymousGuard(authService, router, routerHelperService);
}
