<div
  class="sf-alert {{ type }} rounded-md text-sf-body-font dark:text-sf-white border-2 p-4 {{
    additionalClass
  }}"
>
  <div [ngClass]="{ 'flex items-start gap-4': imageSrc }">
    <div
      *ngIf="imageSrc"
      class="w-20 h-auto overflow-hidden max-w-full max-h-full {{ additionalImageClass }}"
      [innerHTML]="svg | safe"
    ></div>

    <div>
      <header
        [ngClass]="[
          imageSrc ? '' : 'flex items-center',
          imageSmallSrc ? 'items-center gap-2 mb-1.5 ' : ''
        ]"
      >
        <div
          *ngIf="imageSmallSrc"
          class="w-6 h-auto max-h-full md:w-8"
          [innerHTML]="svg | safe"
        ></div>
        <i class="{{ iconClass }}" [ngClass]="[iconClass ? 'mr-2.5 text-2xl' : '']"></i>
        <h2 *ngIf="title" [ngClass]="{ 'mb-0': imageSrc }">
          {{ title }}
        </h2>
      </header>
      <div class="dark:text-sf-white">
        <p class="leading-5 mb-2">{{ message }}</p>
        <p *ngIf="htmlMessage" [innerHTML]="htmlMessage" class="mb-0"></p>
        <ng-content></ng-content>
      </div>
    </div>
  </div>

  <app-button
    *ngIf="!isButtonOff"
    [text]="button_text"
    [buttonStyle]="button_style"
    [btnSmall]="button_small"
    [alignRight]="button_alignRight"
    [noBorder]="button_noBorder"
    [isButtonShadow]="button_isShadow"
    [customClass]="button_customClass"
    [isSubmitButton]="button_isSubmit"
    [icon]="button_icon"
    [fullWidth]="button_fullWidth"
    (buttonClick)="handleButtonClick()"
    (secondButtonClick)="handleSecondButtonClick()"
  ></app-button>
</div>
