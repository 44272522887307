import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeService } from '@app/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    standalone: false
})
export class AlertComponent {
  @Input() type!: string;
  @Input() iconClass!: string;
  @Input() title: string;
  @Input() message!: string;
  @Input() htmlMessage?: string;
  @Input() additionalClass?: string;
  @Input() additionalImageClass?: string;
  @Input() imageSrc: string;
  @Input() imageSmallSrc: string;
  @Input() imageAlt: string;
  @Input() imageSmallAlt: string;
  @Input() customAlignment?: string;

  @Input() isButtonOff: boolean = true;

  // embedded button properties
  @Input() button_text: string;
  @Input() button_style: string = 'primary';
  @Input() button_small: boolean = true;
  @Input() button_alignRight: boolean = false;
  @Input() button_noBorder: boolean = true;
  @Input() button_isShadow: boolean = true;
  @Input() button_customClass: string;
  @Input() button_isSubmit: boolean = false;
  @Input() button_icon: string;
  @Input() button_fullWidth: boolean;

  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() secondButtonClick: EventEmitter<void> = new EventEmitter<void>();

  handleButtonClick(): void {
    this.buttonClick.emit();
  }

  handleSecondButtonClick(): void {
    this.secondButtonClick.emit();
  }

  public svg: string;

  private subscribeUntilDestroyed = new Subscription();

  constructor(private themeService: ThemeService) {}

  public ngOnInit(): void {
    this.subscribeUntilDestroyed = this.themeService.mode$.subscribe(() => this.processSVG());
  }

  public ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  /*
   * Processes an SVG image using the theme service's colour conversion.
   */
  public processSVG(): void {
    this.themeService
      .processSVGFromImageUrl(`/assets/images/${this.imageSrc}.svg`)
      .then((convertedSVG) => {
        this.svg = convertedSVG;
      })
      .catch((error) => {
        console.error(error);
      });

    this.themeService
      .processSVGFromImageUrl(`/assets/images/${this.imageSmallSrc}.svg`)
      .then((convertedSVG) => {
        this.svg = convertedSVG;
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
