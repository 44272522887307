import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
    standalone: false
})
export class LoadingSpinnerComponent implements OnInit {
  public loading$ = this.loader.loading$;

  constructor(public loader: LoadingService) {}

  ngOnInit(): void {}
}
