import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SavingsPlan } from '@app/models/member-portal';

type RouteStateData = { plan: SavingsPlan; error: string };

@Component({
  selector: 'app-direct-debit-error',
  templateUrl: './direct-debit-error.component.html',
  styleUrls: ['./direct-debit-error.component.css'],
})
export class DirectDebitErrorComponent {
  public readonly url = {
    HOME: `/home`,
    PLAN_CHOICE: `/plans/plan-choice/direct-debit`,
  };

  public plan: SavingsPlan;
  public errors: any;

  constructor(public router: Router) {
    this.loadErrorFromRoute();
  }

  /**
   * The errors object that comes back is a bit tricky to deal with,
   * First we remove the $id property, and obtain the inner array value for each object property
   * @param {any} [error]
   */
  public parseErrors(error: any): void {
    const { $id, ...errors } = error;
    this.errors = Object.values(errors).map((error) => error[0]);
  }

  /**
   * Retrieves error from the route state, if it does not exist
   * redirect back to the plan choice page.
   */
  private loadErrorFromRoute(): void {
    const state = this.router.getCurrentNavigation()?.extras?.state as RouteStateData;

    if (state) {
      this.plan = state.plan;
      this.parseErrors(state.error);
    } else {
      this.router.navigate([this.url.PLAN_CHOICE]);
    }
  }
}
