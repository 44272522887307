import { FeedbackType } from './feedback-type.enum';

const getFeedbackBaseUrl = 'https://www.getfeedback.com/r/';

/**
 * A collection of feedback URLs mapped to defined `FeedbackType` values.
 * Each key corresponds to a specific path appended to the base feedback URL.
 */
export const FeedbackUrls: Record<FeedbackType, () => string> = {
  [FeedbackType.GENERIC]: () => `${getFeedbackBaseUrl}Yard8t9B`,
  [FeedbackType.DIRECT_DEBIT]: () => `${getFeedbackBaseUrl}T7Dz9irl`,
  [FeedbackType.LOGIN]: () => `${getFeedbackBaseUrl}fih0cy0B`,
  [FeedbackType.UPDATE_PROFILE]: () => `${getFeedbackBaseUrl}Wwm5lmfB`,
  [FeedbackType.TOP_UP]: () => `${getFeedbackBaseUrl}77JCLz81`,
};
