import { BasePlan } from './base-plan.model';

export class IncomeProtectionPlan extends BasePlan {
  public endDate: Date;
  public benefitAmount: number;

  constructor(
    planId: number,
    planTypeId: number,
    planTypeName: string,
    planStatusId: number,
    planStatusName: string,
    planNumber: number,
    formattedPlanNumber: string,
    startDate: Date,
    premium: number,
    isNetPremium: boolean,
    planTypePrefix: string,
    endDate: Date,
    benefitAmount: number,
    capacity: number
  ) {
    super();

    this.planId = planId;
    this.planTypeId = planTypeId;
    this.planTypeName = planTypeName;
    this.planStatusId = planStatusId;
    this.planStatusName = planStatusName;
    this.planNumber = planNumber;
    this.formattedPlanNumber = formattedPlanNumber;
    this.startDate = startDate;
    this.premium = premium;
    this.isNetPremium = isNetPremium;
    this.planTypePrefix = planTypePrefix;
    this.endDate = endDate;
    this.benefitAmount = benefitAmount;
    this.capacity = capacity;
  }
}
