<app-content-header [title]="'Connect a Junior ISA plan'" />
<div
  class="step"
  [ngClass]="{
  'hidden-step': !(step === stepOpts['YOUR_DETAILS']),
}"
>
  <div class="sf-card mb-0">
    <p class="mb-0">
      You are about to connect a Junior ISA to your account. As a Registered Contact for a Junior
      ISA you are responsible for managing the plan, which allows you to make changes on behalf of
      the child up to the age of 16 years.
    </p>
  </div>
  <div class="sf-card mt-4 md:mt-6 xl:mt-8 mb-0">
    <form #form="ngForm" id="link-jisa-step-one" class="mb-4" [formGroup]="linkJISAForm">
      <div class="mb-4">
        <label for="childsFirstName" class="form-label">Child's first name</label>
        <div class="input-group mb-4">
          <input
            formControlName="childsFirstName"
            type="text"
            class="form-control"
            [ngClass]="{
            'is-invalid': childsFirstNameCtrl.touched && childsFirstNameCtrl.errors,
          }"
          />
        </div>
      </div>

      <div class="mb-4">
        <label for="childsSurname" class="form-label">Child's surname</label>
        <div class="input-group mb-4">
          <input
            formControlName="childsSurname"
            type="text"
            class="form-control"
            [ngClass]="{
            'is-invalid': childsSurnameCtrl.touched && childsSurnameCtrl.errors,
          }"
          />
        </div>
      </div>

      <div class="mb-4">
        <label for="dob" class="form-label">Child's date of birth</label>
        <div class="input-group">
          <input
            formControlName="childsDob"
            type="date"
            class="form-control"
            [ngClass]="{
            'is-invalid': childsDobCtrl.touched && childsDobCtrl.errors,
          }"
            min="1900-01-01"
            max="2099-12-31"
          />
        </div>
        <div *ngIf="childsDobCtrl.touched && childsDobCtrl.errors">
          <app-validation-message
            *ngIf="childsDobCtrl.touched && childsDobCtrl.errors['over18']"
            [message]="'Child must be under 18'"
          ></app-validation-message>
        </div>
      </div>

      <div class="mb-4">
        <label for="childsPlanNumber" class="form-label">Child's plan number</label>
        <div class="input-group">
          <input
            formControlName="childsPlanNumber"
            type="text"
            class="form-control"
            placeholder="e.g. JISA000123 or SJISA000123"
            [ngClass]="{
            'is-invalid': childsPlanNumberCtrl.touched && childsPlanNumberCtrl.errors,
          }"
          />
        </div>
        <app-validation-message
          *ngIf="childsPlanNumberCtrl.touched && childsPlanNumberCtrl.errors"
          [message]="'Please enter a valid plan number'"
        ></app-validation-message>
      </div>
    </form>
    <app-button
      [disabled]="!linkJISAForm.valid"
      (buttonClick)="goToStep('REVIEW')"
      text="Connect Junior ISA"
      buttonStyle="secondary"
      [noBorder]="true"
      [isSubmitButton]="false"
    ></app-button>
  </div>
</div>
<div
  class="step"
  [ngClass]="{
  'hidden-step': !(step === stepOpts['REVIEW']),
}"
>
  <div class="sf-card mb-0">
    <p class="mb-0">
      Before we connect another plan to your account, please can you read through and check the
      details below. If anything is incorrect and you would like to make changes, please select the
      ‘Back' button.
    </p>
  </div>
  <div class="sf-card !p-0 mt-4 md:mt-6 xl:mt-8 mb-0">
    <ul class="p-0 m-0 list-none">
      <li
        class="flex justify-between py-5 px-8 border-b border-primary-sf-navy/10 dark:border-sf-white/10"
      >
        <span class="text-primary-sf-navy dark:text-sf-white font-bold">Child's first name</span>
        <span class="text-right">{{ childsFirstNameCtrl.value }}</span>
      </li>
      <li
        class="flex justify-between py-5 px-8 border-b border-primary-sf-navy/10 dark:border-sf-white/10"
      >
        <span class="text-primary-sf-navy dark:text-sf-white font-bold">Child’s surname</span>
        <span class="text-right">{{ childsSurnameCtrl.value }}</span>
      </li>
      <li
        class="flex justify-between py-5 px-8 border-b border-primary-sf-navy/10 dark:border-sf-white/10"
      >
        <span class="text-primary-sf-navy dark:text-sf-white font-bold">Child's date of birth</span>
        <span class="text-right">{{ childsDobCtrl.value }}</span>
      </li>
      <li
        class="flex justify-between py-5 px-8 border-b border-primary-sf-navy/10 dark:border-sf-white/10"
      >
        <span class="text-primary-sf-navy dark:text-sf-white font-bold">Child's plan number</span>
        <span class="text-right">{{ childsPlanNumberCtrl.value }}</span>
      </li>
    </ul>
    <div class="lg:flex lg:justify-end lg:gap-6 lg:px-8 lg:pt-8 lg:pb-0 pb-4 px-8 pt-8">
      <app-button
        class="block mb-6"
        (buttonClick)="goOneStepBack()"
        text="Back"
        buttonStyle="secondary"
        [noBorder]="true"
        [isSubmitButton]="false"
        [customClass]="'[&>*]:py-4 [&>*]:px-8'"
      ></app-button>
      <app-button
        class="block mb-6"
        (buttonClick)="link()"
        text="Connect Junior ISA"
        buttonStyle="primary"
        [noBorder]="true"
        [isSubmitButton]="false"
      ></app-button>
    </div>
  </div>
</div>
