import { Component, OnInit, ViewChild } from '@angular/core';
import { Form, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from '@app/global.component';
import {
  AppConstantsService,
  FeatureFlagService,
  FidoService,
  LoadingService,
} from '@app/services';
import { Login } from 'src/app/models/security/log-in.model';
import { AuthService } from 'src/app/services/auth.service';
import {
  MemberDashboardCacheService,
  TransactionsCacheService,
} from 'src/app/services/cache-concrete.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-log-in-user',
  templateUrl: './log-in.user.component.html',
  styleUrls: ['./log-in.user.component.css'],
})
export class LogInUserComponent implements OnInit {
  public loading$ = this.loader.loading$;

  public email: string;
  private returnUrl: string;
  public submitted = false;
  public loginForm = this.fb.group({
    username: ['', Validators.compose([Validators.required, Validators.email])],
  });
  public showInstallPrompt: boolean = false;

  public globalComponent = GlobalComponent;

  public appInAppRegistrationEnabled = this.featureFlagService.isEnabled('appInAppRegistration');

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private memberDashboardCacheService: MemberDashboardCacheService,
    private transactionsCacheService: TransactionsCacheService,
    private fido: FidoService,
    private featureFlagService: FeatureFlagService,
    public appConstants: AppConstantsService,
    public loader: LoadingService
  ) {}

  ngOnInit(): void {
    this.fido.checkForBiometricAutomaticDataUpdate();

    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params['destination'] || '/';
      this.email = params['email'];

      if (this.email) {
        this.loginForm.get('username').setValue(this.email);
      }
    });

    // XXX: we need to ensure any previous session caches are cleared before (re) logging-in
    // (this might not be the best place to do it, but there doesn't seem to be a single point of exit, only entry?)
    this.memberDashboardCacheService.clearCache();
    this.transactionsCacheService.clearCache();

    // automatically restore login user name
    const userName = this.auth.loginVarUserName;
    if (!this.email && userName) {
      this.loginForm.get('username').setValue(userName);
    }
  }

  async onSubmit() {
    this.submitted = true;

    if (this.loginForm.valid) {
      // store login variables
      const formContents = this.loginForm.value as Login;
      this.auth.loginVarUserName = formContents.username;

      // proceed to password/biometric authentication
      this.router.navigate(['/log-in-auth'], {
        queryParams: { destination: this.returnUrl },
      });
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  fillEmailAddress() {
    if (environment.hasOwnProperty('devUsername')) {
      this.loginForm.get('username').setValue((<any>environment).devUsername);
    }
  }
}
