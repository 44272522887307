<section>
  <app-content-header
    [title]="'Direct Debits'"
    [isButtonOff]="true"
    [subtitle]="plan.formattedPlanNumber"
  ></app-content-header>
  <div *ngFor="let dd of directDebits; let i = index">
    <div class="mb-4 md:mb-6 xl:mb-8">
      <app-direct-debit-overview [plan]="plan" [directDebit]="dd" />
    </div>
  </div>

  <app-alert
    type="info"
    additionalClass="mt-4 md:mt-6 xl:mt-8"
    imageSmallSrc="wallet"
    imageSmallAlt="wallet icon"
    title="Manage or set up additional Direct Debits"
  >
    <p>
      To manage or set up additional Direct Debits please call our Member Services team on
      <strong>0800 526 249</strong>
      .
    </p>

    <div class="flex justify-center items-center gap-3 md:max-w-[300px]">
      <a
        href="tel:0800526249"
        class="text-primary-sf-navy dark:text-sf-white bg-sf-white dark:bg-sf-white-10-opacity text-sm font-semibold no-underline px-4 py-2 w-full flex justify-center items-center gap-2 rounded-full"
      >
        Call us
      </a>
      <a
        href="mailto:members@shepherdsfriendly.co.uk"
        class="text-primary-sf-navy dark:text-sf-white bg-sf-white dark:bg-sf-white-10-opacity text-sm font-semibold no-underline px-4 py-2 w-full flex justify-center items-center gap-2 rounded-full"
      >
        Email us
      </a>
    </div>
  </app-alert>
</section>
