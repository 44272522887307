<app-content-header
  [title]="'Helpful resources'"
  [customClassContainer]="' max-md:text-center !grid-cols-none'"
></app-content-header>

<div>
  <div class="md:grid md:gap-6 md:grid-cols-[repeat(2,1fr)]">
    <app-button
      text="Adult savings"
      buttonStyle="primary"
      customClass="mb-4 md:mb-6 xl:mb-8"
      routerLink="/help/adult-savings"
      [noBorder]="true"
      [fullWidth]="true"
    ></app-button>

    <app-button
      text="Child savings"
      buttonStyle="primary"
      customClass="mb-4 md:mb-6 xl:mb-8"
      routerLink="/help/child-savings"
      [noBorder]="true"
      [fullWidth]="true"
    ></app-button>
  </div>

  <div class="sm:grid md:gap-6 md:grid-cols-[repeat(2,1fr)]">
    <div class="sf-card md:p-6 md:mb-0" routerLink="/help/page/68540">
      <img
        class="mb-3 object-cover w-full md:min-h-[17rem] lg:min-h-55 dark:brightness-75 dark:contrast-125"
        src="https://shepherdsfriendly.blob.core.windows.net/wp-media/2022/08/back-to-school-630x340.jpg"
      />
      <div>
        <h2>What is a stocks and shares ISA?</h2>
        <span>
          There is a lot of information to consider when choosing an ISA. Discover all you need to
          know in our “what is a stocks and shares ISA” guide.
        </span>
      </div>
    </div>

    <div class="sf-card md:p-6 md:mb-0" routerLink="/help/page/68603">
      <img
        class="mb-3 object-cover w-full md:min-h-[17rem] lg:min-h-55 dark:brightness-75 dark:contrast-125"
        src="https://shepherdsfriendly.blob.core.windows.net/wp-media/2020/05/micheile-henderson-lZ_4nPFKcV8-unsplash_opt.jpg"
      />
      <div>
        <h2>Cash ISA vs stocks and shares ISA</h2>
        <span>
          What’s the difference between a cash ISA and a stocks and shares ISA – and which should
          you choose? Read our clear and simple guide.
        </span>
      </div>
    </div>

    <div class="sf-card md:p-6 md:mb-0" routerLink="/help/page/69056">
      <img
        class="mb-3 object-cover w-full md:min-h-[17rem] lg:min-h-55 dark:brightness-75 dark:contrast-125"
        src="https://shepherdsfriendly.blob.core.windows.net/wp-media/2022/01/towfiqu-barbhuiya-nApaSgkzaxg-unsplash-445x260.jpg"
      />
      <div>
        <h2>Stocks and shares ISA allowance</h2>
        <span>
          An ISA allowance is the total amount of money you can save in an ISA within one financial
          year. Find out more about 2022/23 ISA allowances.
        </span>
      </div>
    </div>

    <div class="sf-card md:p-6 md:mb-0 object-cover" routerLink="/help/page/69471">
      <img
        class="mb-3 object-cover w-full md:min-h-[17rem] lg:min-h-55 dark:brightness-75 dark:contrast-125"
        src="https://shepherdsfriendly.blob.core.windows.net/wp-media/2019/12/christin-hume-Hcfwew744z4-unsplash_opt.jpg"
      />
      <div>
        <h2>How to open an ISA</h2>
        <span>
          Discover how to open an ISA with our helpful guide. Open today in less than 10 minutes,
          from just £30 a month, or with a lump sum of £100.
        </span>
      </div>
    </div>

    <div class="sf-card md:p-6 md:mb-0" routerLink="/help/page/68826">
      <img
        class="mb-3 object-cover w-full md:min-h-[17rem] lg:min-h-55 dark:brightness-75 dark:contrast-125"
        src="https://shepherdsfriendly.blob.core.windows.net/wp-media/2020/01/alyssa-stevenson-slpibXIhizI-unsplash_opt.jpg"
      />
      <div>
        <h2>What is a Junior ISA?</h2>
        <span>
          There is a lot of information to consider when choosing a Junior ISA. Discover all you
          need to know in our “what is a Junior ISA” guide.
        </span>
      </div>
    </div>
  </div>
</div>
