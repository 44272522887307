<form #form="ngForm" id="register-step-two" [formGroup]="registerForm">
  <div>
    <div class="mb-2">
      <p>
        Thank you for confirming your Child Trust Fund details. Now we just need a few final details
        to set up your online account.
      </p>
    </div>

    <hr />

    <div class="mb-2">
      <label for="email" class="form-label">Email address</label>
      <div class="input-group mb-4">
        <input
          id="email"
          name="email"
          formControlName="emailAddress"
          type="email"
          class="form-control"
          [ngClass]="{
            'is-invalid': emailCtrl.touched && emailCtrl.errors,
          }"
        />
      </div>
    </div>

    <div class="mb-2">
      <label for="emailConfirm" class="form-label">Confirm email address</label>
      <div class="input-group">
        <input
          id="confirmEmail"
          name="confirmEmail"
          formControlName="emailAddressConfirm"
          type="email"
          class="form-control"
          [ngClass]="{
            'is-invalid':
              (emailConfirmCtrl.touched && emailConfirmCtrl.errors) ||
              registerForm.hasError('emailAddressMismatch'),
          }"
        />
      </div>

      <app-validation-message
        *ngIf="registerForm.hasError('emailAddressMismatch')"
        [message]="'Email addresses do not match'"
      ></app-validation-message>
    </div>

    <div class="mb-4">
      <div *ngIf="emailCtrl.touched && emailConfirmCtrl.touched && registerForm.errors">
        <app-alert
          *ngIf="registerForm.errors['emailMismatch']"
          type="warning"
          additionalClass="mt-2 mb-4"
          iconClass="far fa-exclamation-circle"
          title="Error"
          message="Your email address does not match the confirmation field"
        ></app-alert>
      </div>
    </div>

    <div class="mb-2">
      <label for="password" class="form-label">Password</label>
      <div class="input-group mb-4">
        <input
          id="password"
          name="password"
          formControlName="password"
          type="{{ showPassword ? 'text' : 'password' }}"
          class="form-control password-input"
          [ngClass]="{
            'is-invalid': passwordCtrl.touched && passwordCtrl.errors,
          }"
        />
        <span class="password-toggle input-group-text" (click)="togglePasswordVisibility()">
          <i class="far" [class.fa-eye]="showPassword" [class.fa-eye-slash]="!showPassword"></i>
        </span>
      </div>

      <p class="mb-4">
        Your password must be at least 8 characters long, contain one lowercase and one uppercase
        letter, one number and one special character (e.g. # & % ^ ! ?)
      </p>
    </div>

    <div class="mb-2">
      <label for="passwordConfirm" class="form-label">Confirm password</label>
      <div class="input-group">
        <input
          id="passwordConfirm"
          name="passwordConfirm"
          formControlName="passwordConfirm"
          type="{{ showConfirmPassword ? 'text' : 'password' }}"
          class="form-control password-input"
          [ngClass]="{
            'is-invalid':
              (passwordConfirmCtrl.touched && passwordConfirmCtrl.errors) ||
              registerForm.hasError('passwordMismatch'),
          }"
        />
        <span class="password-toggle input-group-text" (click)="toggleConfirmPasswordVisibility()">
          <i
            class="far"
            [class.fa-eye]="showConfirmPassword"
            [class.fa-eye-slash]="!showConfirmPassword"
          ></i>
        </span>
      </div>

      <app-validation-message
        *ngIf="registerForm.hasError('passwordMismatch')"
        [message]="'Passwords do not match'"
      ></app-validation-message>
    </div>

    <div class="mb-2">
      <label for="mobileNumber" class="form-label">Mobile number</label>
      <div class="input-group mb-4">
        <input
          id="mobileNumber"
          name="mobileNumber"
          formControlName="mobileNumber"
          type="tel"
          class="form-control"
          [ngClass]="{
            'is-invalid': mobileNumberCtrl.touched && mobileNumberCtrl.errors,
          }"
        />
      </div>

      <p class="mb-4">
        To keep your account as secure as possible we will send you a PIN code every time you log in
        on your mobile phone. This helps prevent unauthorised users from accessing your account. We
        will only use the mobile number you give us to authenticate you when you log in.
      </p>
    </div>
  </div>
</form>
