import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-need-help',
  templateUrl: './need-help.component.html',
  styleUrls: ['./need-help.component.scss'],
})
export class NeedHelpComponent implements OnInit {
  constructor() {}

  @Input() imageSmallSrc: string;
  @Input() imageSmallAlt: string;

  alertImageSmallSrc: string;

  ngOnInit(): void {
    this.alertImageSmallSrc = this.imageSmallSrc;
  }
}
