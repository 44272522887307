import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((errorRepsonse: HttpErrorResponse) => {
        // XXX: (10/02/2023) I can't see a situation where we need this broad-brush approach any more.  the guards should do
        // the validation for navigation and the refresh-token system should prevent 401s from 'within' a page.
        // (?)
        console.error(errorRepsonse);

        // bypass
        return throwError(() => errorRepsonse);
      })
    );
  }
}
