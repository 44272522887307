import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  DirectDebit,
  Member,
  MemberDashboard,
  SavingsPlan,
  TransactionsSummary,
} from 'src/app/models/member-portal';

@Component({
  selector: 'app-jisa-details',
  templateUrl: './jisa-details.component.html',
  styleUrls: ['./jisa-details.component.css'],
  standalone: false,
})
export class JisaDetailsComponent implements OnDestroy {
  @Input() jisaDetails?: { plan: SavingsPlan; member: Member };
  @Input() memberDashboard?: MemberDashboard;
  @Input() transactionsSummary?: TransactionsSummary;

  readonly url = {
    TOP_UP: (formattedPlanNumber) => `/plans/deposit/${formattedPlanNumber.toLowerCase()}`,
    TRANSACTIONS: ({ planId, formattedPlanNumber }) =>
      `/transactions/${planId}/${formattedPlanNumber.toLowerCase()}`,
    DOCUMENTS: `/documents/plan/{0}`,
    DIRECT_DEBIT: `/direct-debit/plan`,
    DIRECT_DEBIT_VARY: `/direct-debit/plan/vary`,
    DIRECT_DEBIT_VIEW_ALL: `/direct-debit/plan/view-all`,
    WITHDRAWAL: (formattedPlanNumber) => `/plans/withdrawal/${formattedPlanNumber.toLowerCase()}`,
  };

  private subscribeUntilDestroyed = new Subscription();

  constructor(private router: Router) {}

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  /**
   * Returns true if the plan has 'singleActiveFound' set to true
   */
  public get hasDirectDebit(): boolean {
    return this.jisaDetails.plan.directDebitInformation.singleActiveFound;
  }

  /**
   * Returns true if the plan has 'multipleActiveFound' set to true
   */
  public get hasMultipleDirectDebits(): boolean {
    return this.jisaDetails.plan.directDebitInformation.multipleActiveFound;
  }

  /**
   * Returns true if there are no active Direct Debits exist
   */
  public get hasNoDirectDebits(): boolean {
    return !this.hasDirectDebit && !this.hasMultipleDirectDebits;
  }

  /**
   * Returns all direct debits available
   */
  public get directDebits(): DirectDebit[] {
    return this.jisaDetails.plan.directDebitInformation.directDebits;
  }

  /**
   * Navigates to a url and passes a plan and member object in router state
   * @param {string} [url] the url to navigate to
   * @param {object} [additionalState] optional object to pass along with the route state
   */
  public navigateWithState(url: string, additionalState?: object): void {
    this.router.navigate([url], {
      state: {
        plan: this.jisaDetails.plan,
        member: this.memberDashboard.member,
        ...additionalState,
      },
    });
  }
}
