import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeedbackType } from '@app/models/feedback';
import { SavingsPlan } from '@app/models/member-portal';

type RouteStateData = { plan: SavingsPlan; amended?: boolean };

@Component({
    selector: 'app-direct-debit-thank-you',
    templateUrl: './direct-debit-thank-you.component.html',
    styleUrls: ['./direct-debit-thank-you.component.css'],
    standalone: false
})
export class DirectDebitThankYouComponent {
  public readonly url = {
    HOME: `/home`,
    PLAN_CHOICE: `/plans/plan-choice/direct-debit`,
  };
  public readonly directDebitFeedbackType: FeedbackType = FeedbackType.DIRECT_DEBIT;

  public plan: SavingsPlan;
  public amended: boolean;

  constructor(public router: Router) {
    this.loadPlanDetailsFromRoute();
  }

  /**
   * Retrieves plan data from the route state, if non exists
   * redirect back to the plan choice page.
   */
  private loadPlanDetailsFromRoute(): void {
    const state = this.router.getCurrentNavigation()?.extras?.state as RouteStateData;

    if (state) {
      this.plan = state.plan;
      this.amended = state.amended;
    } else {
      this.router.navigate([this.url.PLAN_CHOICE]);
    }
  }
}
