import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-direct-debit-declaration',
  templateUrl: './direct-debit-declaration.component.html',
  styleUrls: ['./direct-debit-declaration.component.scss'],
})
export class DirectDebitDeclarationComponent {
  @Input() planTypePrefix: string = 'ISA';
  @Output() hasAcceptedConditions: EventEmitter<boolean> = new EventEmitter();

  public checked: boolean = false;

  /**
   * Emits result of checkbox state back to parent components
   */
  public processChange(event: boolean) {
    this.hasAcceptedConditions.emit(event);
  }
}
