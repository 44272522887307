import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginHeaderService } from '@app/services';

@Component({
    selector: 'app-login-header',
    templateUrl: './login-header.component.html',
    styleUrls: ['./login-header.component.scss'],
    standalone: false
})
export class LoginHeaderComponent {
  showBackBtn = true;

  constructor(
    public router: Router,
    private loginHeaderService: LoginHeaderService
  ) {}

  ngOnInit() {
    this.loginHeaderService.showBackBtn$.subscribe((show) => {
      this.showBackBtn = show;
    });
  }

  public handleButtonClick(): void {
    this.loginHeaderService.emitButtonClick();
  }

  public navigateToLoginScreen(): void {
    this.router.navigate(['/log-in']);
  }
}
