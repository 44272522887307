import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  NgZone,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegexUtility } from '@app/shared/utilities';
import { CommonValidators } from '@app/shared/validators';

@Component({
  selector: 'app-register-ctf-step-two',
  templateUrl: './register-ctf-step-two.component.html',
  styleUrls: ['./register-ctf-step-two.component.scss'],
})
export class RegisterCTFStepTwoComponent implements OnInit {
  public registerForm: FormGroup;
  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;

  @Output() onUpdate = new EventEmitter<any>();
  @Output() onValid = new EventEmitter<boolean>();

  @ViewChild('password') passwordInput: ElementRef;
  @ViewChild('passwordConfirm') passwordConfirmInput: ElementRef;
  @ViewChild('emailAddress') emailInput: ElementRef;
  @ViewChild('emailAddressConfirm') emailConfirmInput: ElementRef;

  constructor(private ngZone: NgZone) {}

  ngAfterViewInit() {
    this.watchForAutofill(this.passwordInput, this.passwordCtrl);
    this.watchForAutofill(this.passwordConfirmInput, this.emailConfirmCtrl);
    this.watchForAutofill(this.emailInput, this.emailCtrl);
    this.watchForAutofill(this.emailConfirmInput, this.emailConfirmCtrl);
  }

  get emailCtrl() {
    return this.registerForm.get('emailAddress') as FormControl;
  }
  get emailConfirmCtrl() {
    return this.registerForm.get('emailAddressConfirm') as FormControl;
  }
  get passwordCtrl() {
    return this.registerForm.get('password') as FormControl;
  }
  get passwordConfirmCtrl() {
    return this.registerForm.get('passwordConfirm') as FormControl;
  }
  get mobileNumberCtrl() {
    return this.registerForm.get('mobileNumber') as FormControl;
  }

  ngOnInit(): void {
    this.registerForm = new FormGroup(
      {
        emailAddress: new FormControl('', [Validators.required, Validators.email]),
        emailAddressConfirm: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [
          Validators.required,
          Validators.pattern(RegexUtility.strongPassword),
        ]),
        passwordConfirm: new FormControl('', Validators.required),
        mobileNumber: new FormControl('', [
          Validators.required,
          Validators.pattern(RegexUtility.mobileNo),
        ]),
      },
      {
        validators: [
          CommonValidators.confirmationValidator('emailAddress', 'emailAddressConfirm'),
          CommonValidators.confirmationValidator('password', 'passwordConfirm'),
        ],
      }
    );

    this.registerForm.valueChanges.subscribe((value) => {
      this.onUpdate.emit(value); // Emit the form value whether it's valid or not
      this.onValid.emit(this.registerForm.valid); // Emit the validity status
    });
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  public toggleConfirmPasswordVisibility(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  private watchForAutofill(inputElement: ElementRef, control: FormControl) {
    if (inputElement) {
      control.valueChanges.subscribe(() => {
        // Check if the field is autofilled
        if (inputElement.nativeElement.autofocus) {
          // Mark the field as touched
          this.ngZone.run(() => {
            control.markAsTouched();
          });
        }
      });
    }
  }
}
