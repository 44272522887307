<div>
  <h3 class="font-bold mb-1 text-xl">Question {{ step + 1 }} of {{ questions.length }}</h3>
</div>
<hr class="border-gray-500 border-opacity-25 border-b mb-6" />

<form id="withdrawal-friction-questions-form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div *ngIf="questions[step] as currentQuestion">
    <h4 class="font-bold">{{ currentQuestion.title }}</h4>
    <p>{{ currentQuestion.message }}</p>

    <!-- For radio type questions: display Yes/No options -->
    <div *ngIf="currentQuestion.type === 'radio'" class="flex w-full justify-between gap-5 mb-4">
      <!-- Yes Button -->
      <input
        type="radio"
        id="yes"
        class="hidden peer/yes"
        [formControlName]="currentQuestion.formControlName"
        value="yes"
      />
      <label
        for="yes"
        class="cursor-pointer peer-checked/yes:font-semibold border-primary-sf-navy dark:border-sf-white/20 border rounded-md p-3 peer-checked/yes:bg-primary-sf-yellow peer-checked/yes:text-primary-sf-navy peer-checked/yes:border-primary-sf-yellow flex-1 text-center"
      >
        Yes
      </label>

      <!-- No Button -->
      <input
        type="radio"
        id="no"
        class="hidden peer/no"
        [formControlName]="currentQuestion.formControlName"
        value="no"
      />
      <label
        for="no"
        class="cursor-pointer peer-checked/no:font-semibold border-primary-sf-navy dark:border-sf-white/20 border rounded-md p-3 peer-checked/no:bg-primary-sf-yellow peer-checked/no:text-primary-sf-navy peer-checked/no:border-primary-sf-yellow flex-1 text-center"
      >
        No
      </label>
    </div>

    <!-- For checkbox type questions -->
    <div *ngIf="currentQuestion.type === 'checkbox'">
      <app-checkbox
        [additionalClass]="'pb-4'"
        label=" I understand"
        [formControlName]="currentQuestion.formControlName"
      ></app-checkbox>
    </div>

    <!-- CTA Buttons -->
    <div class="lg:flex lg:flex-row-reverse lg:justify-items-end lg:gap-4">
      <app-button
        text="Continue"
        (click)="onContinue()"
        buttonStyle="primary"
        customClass="[&>*]:py-4 [&>*]:px-8"
        [noBorder]="true"
        [isSubmitButton]="false"
        [disabled]="isContinueDisabled(currentQuestion)"
      ></app-button>
      <div class="mt-4 text-center">
        <button type="button" (click)="onCancel()" class="underline">Cancel withdrawal</button>
      </div>
    </div>
  </div>
</form>
