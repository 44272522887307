import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
// import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-bootstrap/carousel';

const MODULES = [
  ButtonsModule.forRoot(),
  // CollapseModule.forRoot(),
  ModalModule.forRoot(),
  PaginationModule.forRoot(),
  BsDropdownModule.forRoot(),
  CarouselModule.forRoot(),
];

@NgModule({
  imports: [CommonModule, BrowserAnimationsModule, MODULES],
  exports: [MODULES],
})
export class NgxBootstrapModule {}
