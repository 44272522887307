import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlanType } from '@app/models/plans';
import { FeatureFlagService } from '@app/services';
import { PlanUtility } from '@app/shared/utilities';
import { Member, SavingsPlan } from 'src/app/models/member-portal';

@Component({
  selector: 'app-plan-overview',
  templateUrl: './plan-overview.component.html',
  styleUrls: ['./plan-overview.component.scss'],
  standalone: false,
})
export class PlanOverviewComponent implements OnInit {
  @Input() plan: SavingsPlan;
  @Input() member?: Member;
  @Input() concise?: boolean = false;
  @Input() hasWithdrawals: boolean = true;
  @Input() fullView?: boolean = false;
  @Input() disabled?: boolean = false;

  public isHomePage: boolean = false;
  public appSSIPlansEnabled$ = this.featureFlagService.isEnabled('appSSIPlans');

  constructor(private router: Router, private featureFlagService: FeatureFlagService) {}

  ngOnInit(): void {
    this.isHomePage = this.router.url === '/home';
  }

  get isPlanTypeSSI(): boolean {
    return PlanUtility.isPlanType(this.plan, PlanType.SSI);
  }
}
