import { Component, OnInit, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstantsService, AuthService } from '@app/services';

@Component({
  selector: 'app-log-in.twofactorauth',
  templateUrl: './log-in.twofactorauth.component.html',
  styleUrls: ['./log-in.twofactorauth.component.css'],
})
export class LogInTwoFactorAuthComponent implements OnInit {
  @ViewChildren('tfacode') tfaInput: any;
  public invalidToken = false;
  public error: string;
  public submitted: boolean = false;
  public resentCode: boolean = false;
  public resentCodeEmail: boolean = false;
  private returnUrl: string;
  public loginTFAForm = this.fb.group({
    tfacode: [
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(6),
        Validators.maxLength(6),
      ]),
    ],
  });

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    public appConstants: AppConstantsService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params['destination'] || '/';
    });
  }

  public onSubmit() {
    this.submitted = true;
    if (this.loginTFAForm.valid) {
      // Should be validated by the form and therefore not null
      let code = this.loginTFAForm.get('tfacode')?.value;
      if (code) {
        this.auth.loginTwoFactorAuth(code).subscribe({
          next: (n) => {
            this.router.navigateByUrl(this.returnUrl);
          },
          error: (e) => {
            this.loginTFAForm.controls['tfacode'].setErrors({
              invalid: true,
            });
          },
        });
      } else {
        // Form error, code somehow not set
        this.loginTFAForm.controls['tfacode'].setErrors({
          required: true,
        });
      }
    }
    return;
  }

  /**
   * Resends the OTP code to the members mobile number
   */
  public resendCode(): void {
    this.resentCode = false;

    this.auth
      .resendTwoFactorAuth(false)
      .subscribe((response) => (this.resentCode = response.success));
  }

  resendCodeEmail(resendViaSecondaryProvider: boolean) {
    this.resentCode = false;

    this.auth.resendTwoFactorAuth(resendViaSecondaryProvider).subscribe((response) => {
      this.resentCode = response.success;
    });
  }

  public get f(): { [key: string]: AbstractControl } {
    return this.loginTFAForm.controls;
  }
}
