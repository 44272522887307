<section id="loginTwoFactorAuthContent">
  <div
    class="flex flex-col justify-center items-center md:py-8 md:px-0 lg:h-[calc(100vh-258px)] lg:flex-row lg:gap-8"
  >
    <div class="w-full max-w-[464px]">
      <h1 class="text-center my-6 mx-0 lg:text-left font-bold text-3xl md:text-4xl 2xl:text-5xl">
        {{ errorTitle }}
      </h1>

      <p class="text-center lg:text-left mb-4" [innerHTML]="errorMessage"></p>
      <div>
        <div *ngIf="errorType === 'GENERIC' || errorType === 'INVALID_LINK'">
          <p class="text-center lg:text-left mb-4">
            If you think there is a technical problem, please contact us at:
            <a href="mailto:info@shepherdsfriendly.co.uk" class="underline text-sf-white">
              info&#64;shepherdsfriendly.co.uk
            </a>
          </p>
        </div>
        <div *ngIf="errorType === 'EXPIRED_LINK'">
          <p class="text-center lg:text-left mb-4">
            If you need help with anything else, please feel free to
            <a
              href="https://www.shepherdsfriendly.co.uk/help-and-support/"
              class="underline text-sf-white"
            >
              contact our Member Services team.
            </a>
          </p>
        </div>
      </div>

      <app-button text="Return to homescreen" [routerLink]="url.HOME" [alignLeft]="true" />
    </div>
  </div>
</section>
