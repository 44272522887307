import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss'],
})
export class ContentHeaderComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() subSubtitle: string;
  @Input() customClassContainer: string;
  @Input() customClassTitle: string;
  @Input() isButtonOff: boolean = true;

  // embedded button properties
  @Input() button_text: string;
  @Input() button_style: string = 'primary';
  @Input() button_small: boolean = true;
  @Input() button_alignRight: boolean = true;
  @Input() button_noBorder: boolean = true;
  @Input() button_isShadow: boolean = true;
  @Input() button_customClass: string;
  @Input() button_isSubmit: boolean = false;
  @Input() button_icon: string;
  @Input() button_iconSize: string;
  @Input() button_iconColor: string;

  // TODO: any more props ...
  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();

  handleButtonClick(): any {
    this.buttonClick.emit();
  }
}
