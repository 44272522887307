<app-content-header [title]="'Notifications'"></app-content-header>

<div class="notifications__card mb-4">
  <div *ngIf="isSupported">
    <app-alert
      *ngIf="isDeviceRegistered"
      additionalClass="white-alert"
      type="warning"
      title="Notifications are enabled"
      message="Notifications are enabled on this
                    device. Please make sure that the app is
                    installed to your device if you are
                    experiencing any problems."
      imageSrc="notification_enable"
      imageAlt="notification_enable"
      [isButtonOff]="false"
      [button_small]="false"
      [button_text]="'Disable notifications'"
      button_style="secondary"
      (click)="unregister()"
      button_customClass="mt-4"
    ></app-alert>

    <app-alert
      *ngIf="!isDeviceRegistered"
      additionalClass="white-alert dark-alert"
      type="warning"
      title="Notifications are disabled"
      message=" If you want to enable notifications, you
                    can install the app to your device and
                    tap 'Enable notifications'."
      imageSrc="notification_disabled"
      imageAlt="notification_disabled"
      [isButtonOff]="false"
      [button_small]="false"
      [button_text]="'Enable notifications'"
      button_style="secondary"
      (click)="register()"
      button_customClass="mt-4"
    ></app-alert>

    <app-validation-message
      *ngIf="registerError"
      message="Enable notifications failed"
    ></app-validation-message>

    <app-validation-message
      *ngIf="unregisterError"
      message="Disable notifications failed"
    ></app-validation-message>
  </div>
  <div>
    <app-alert
      *ngIf="!isSupported"
      type="warning"
      title="Notification support is not available."
      message="Notification support is not available. To
                enable notifications, please make sure you
                install the app to your device."
      iconClass="fas fa-bell"
    ></app-alert>
  </div>
</div>
<app-need-help></app-need-help>
