import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-child-savings',
  templateUrl: './help-child-savings.component.html',
  styleUrls: ['./help-child-savings.component.scss'],
})
export class HelpChildSavingsComponent implements OnInit {
  isShow: { [key: string]: boolean } = {};
  constructor(private el: ElementRef) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    const toggleableDivs = this.el.nativeElement.querySelectorAll('[data-toggle-id]');

    toggleableDivs.forEach((div: HTMLElement) => {
      const toggleId = div.getAttribute('data-toggle-id');

      if (toggleId) {
        this.isShow[toggleId] = false;
      }
    });
  }

  toggle(toggleId: string) {
    this.isShow[toggleId] = !this.isShow[toggleId];
  }

  isToggle(toggleId: string) {
    return this.isShow[toggleId];
  }

  getButtonIcon(toggleId: string) {
    return this.isShow[toggleId] ? 'visible-icon' : '';
  }
}
