<div *ngIf="directDebit" class="shadow-sm">
  <div
    class="bg-sf-white dark:bg-primary-sf-navy p-4 md:px-6 xl:px-8 border-b border-b-primary-sf-navy/10 dark:border-b-sf-white/10 rounded-t-md"
  >
    <h5 class="capitalize text-lg mb-1 font-semibold">
      {{ paymentPeriod }}
      premium:
      <span class="text-secondary-sf-blue-dark dark:text-secondary-sf-blue-light font-semibold">
        {{ directDebit.amount | currency : 'GBP' }}
      </span>
    </h5>
    <p class="text-base mb-0">
      <span class="font-semibold text-sm">Payment on:</span>
      {{ paymentDayMonth }}
    </p>
  </div>

  <div
    class="flex justify-between bg-sf-white dark:bg-primary-sf-navy p-4 md:px-6 xl:px-8 border-b border-b-primary-sf-navy/10 dark:border-b-sf-white/10"
  >
    <span class="font-semibold block">Account name</span>
    <span class="text-capitalize">{{ directDebit.acName }}</span>
  </div>

  <div
    class="bg-sf-white dark:bg-primary-sf-navy p-4 md:px-6 xl:px-8 flex justify-between border-b border-b-primary-sf-navy/10 dark:border-b-sf-white/10"
  >
    <span class="font-semibold block">Sort code</span>
    <span *ngIf="hasAmend && !hasAlreadyMaskedSortCode; else nonMaskSortcode">
      {{ directDebit.sortCode | mask : sortCodeMask }}
    </span>
    <ng-template #nonMaskSortcode>
      {{ directDebit.sortCode }}
    </ng-template>
  </div>

  <div
    class="bg-sf-white dark:bg-primary-sf-navy p-4 md:px-6 xl:px-8 flex justify-between border-b border-b-primary-sf-navy/10 dark:border-b-sf-white/10"
  >
    <span class="font-semibold block">Account number</span>
    {{ directDebit.acNumber }}
  </div>

  <div
    class="bg-sf-white dark:bg-primary-sf-navy p-4 md:px-6 xl:px-8 flex justify-between border-b border-b-primary-sf-navy/10 dark:border-b-sf-white/10"
    *ngIf="!concise || hasAmend"
  >
    Bank account is held in my name
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.75" cy="11.9072" r="11.75" fill="#118C7F" />
      <path
        d="M16.6666 8.15723L10.25 14.5739L7.33331 11.6572"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>

  <div
    class="bg-sf-white dark:bg-primary-sf-navy p-4 md:px-6 xl:px-8 flex justify-between rounded-b-md"
    *ngIf="!concise || hasAmend"
  >
    Direct Debits are allowed from this account
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.75" cy="11.9072" r="11.75" fill="#118C7F" />
      <path
        d="M16.6666 8.15723L10.25 14.5739L7.33331 11.6572"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>

  <div
    class="block bg-sf-white dark:bg-primary-sf-navy p-4 md:px-6 xl:px-8 border-t border-t-primary-sf-navy/10 dark:border-t-sf-white/10 rounded-b-md"
    *ngIf="hasAmend"
  >
    <app-button
      text="Amend details"
      buttonStyle="secondary"
      [noBorder]="true"
      (click)="handleOnClickAmend()"
    ></app-button>
  </div>

  <ng-content></ng-content>
</div>
