// This file can be replaced during build by using the `fileReplacements` array.
// `ng build-uat` replaces `environment.ts` with `environment.development.ts`.
// The list of file replacements can be found in `angular.json`.
import { Environment } from './environment.d';

export const environment: Environment = {
  production: false,
  appName: 'Shepherds Friendly [uat]',
  backend: {
    wpURL: 'https://www.shepherdsfriendly.co.uk/wp-json/wp/v2',
  },
  azure: {
    appLabel: 'uat',
    appConfig:
      'Endpoint=https://sf-uks-sfappconfig.azconfig.io;Id=A+Wt-lo-s0:6T1rnPiSN+bVQOCyNPik;Secret=zgQt3FwmqxQPDKyNTnBAzyVfbHDkkMI6ls5VyfIKveU=',
  },
  apiKeys: {
    stripe: 'pk_test_jnsgXub9rq41256Ahi7mD6w500qMoDwELy',
    sentryDSN: 'https://2fd095f284c148da81af40213138f33b@o19378.ingest.sentry.io/4504258998960128',
    sfApiURL: 'https://api-dev-1.shepherdsfriendly.co.uk',
  },
  appTagIds: '63,64',
  blobStorage: 'https://shepherdsfriendly.blob.core.windows.net',
  tracking: {
    gtmContainerId: 'GTM-N2BDVJG',
  },
  firebase: {
    apiKey: 'AIzaSyDG-a_XWhgvk5oxGPeR2cQ0yHwYgfPCJdM',
    authDomain: 'sf-app-65e57.firebaseapp.com',
    projectId: 'sf-app-65e57',
    storageBucket: 'sf-app-65e57.appspot.com',
    messagingSenderId: '32880384312',
    appId: '1:32880384312:web:a96ea4a69b9f690933c9cd',
    measurementId: 'G-WLGTBX110Q',
    vapidKey:
      'BE1m2EhWzBYvVN464ZBzy-r6w7CrtayIG2Xy_9c8EQ80875NtoaZJoR6JwNWCBWcjNVE3YUmuQyL9QK8M9m-nZo',
  },
  auth: {
    clientId: 'sf-app',
    clientSecret: 'd95b3e44-8f69-4776-84ef-ae64ce63e5a6',
    clientIdAndroid: 'sf-app-android',
    clientSecretAndroid: '4fbd530d-77a6-43d4-bbfd-3416eb173c27',
    clientIdiOS: 'sf-app-ios',
    clientSecretiOS: '0d430b6e-d55c-4f12-9733-1c9a0bca7659',
  },
  ybugId: {
    id: 'd19ps2akwqmp1vyarm68',
  },
  application: {
    autoLogoutGraceSeconds: 300,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
