<div
  *ngIf="plan && (!isPlanTypeSSI || (appSSIPlansEnabled$ | async))"
  class="plan-overview"
  [ngClass]="{ 'plan-overview__full': fullView, disabled: disabled }"
>
  <header>
    <h2 class="text-xl font-medium mb-1">{{ plan.planTypeName }}</h2>
    <ng-container *ngIf="member && !concise">
      for
      {{ [member.title, member.firstname, member.surname] | join : ' ' }}
    </ng-container>
  </header>

  <div *ngIf="concise; else fullOverview" class="plan-card__overview">
    <p class="text-base opacity-80 mb-1">
      {{ plan.formattedPlanNumber }}
      <span>-</span>
      {{ [member.title, member.firstname, member.surname] | join : ' ' }}
    </p>

    <p class="card-full-value">
      {{ plan.currentValue | currency : 'GBP' }}
    </p>
  </div>

  <ng-template #fullOverview>
    <p class="card-full-value">
      {{ plan.currentValue | currency : 'GBP' }}
    </p>

    <ng-content></ng-content>

    <ng-container>
      <div *ngIf="plan.totalContributions" class="flex justify-between">
        <p class="mb-1 opacity-80">Total contributions</p>
        <p class="mb-0">{{ plan.totalContributions | currency : 'GBP' }}</p>
      </div>

      <div *ngIf="hasWithdrawals" class="flex justify-between">
        <p class="mb-1 opacity-80">Total withdrawals</p>
        <p class="mb-0">{{ plan.totalWithdrawals | currency : 'GBP' }}</p>
      </div>

      <div *ngIf="plan.startDate" class="flex justify-between mb-2 pb-2 yellow-divider">
        <p class="mb-1 opacity-80">Plan start date</p>
        <p class="mb-0">{{ plan.startDate | date : 'longDate' }}</p>
      </div>
    </ng-container>
  </ng-template>

  <div
    class="flex"
    [ngClass]="{ 'justify-start gap-0.5': isHomePage, 'justify-between': !isHomePage }"
  >
    <p class="mb-0 max-xs:text-sm">Remaining {{ plan.taxYearDescription }} allowance:</p>
    <p class="mb-0 max-xs:text-sm">{{ plan.taxAllowanceRemaining | currency : 'GBP' }}</p>
  </div>
</div>
