import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MemberDashboard, SavingsPlan } from 'src/app/models/member-portal';

@Component({
  selector: 'app-ssi-fund-transfer-declaration',
  templateUrl: './ssi-fund-transfer-declaration.component.html',
  styleUrls: ['./ssi-fund-transfer-declaration.component.scss'],
  standalone: false,
})
export class SSIFundTransferDeclarationComponent implements OnInit, OnDestroy {
  @Input() ssiDetails?: SavingsPlan;
  @Input() memberDashboard?: MemberDashboard;

  private subscribeUntilDestroyed = new Subscription();

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }
}
