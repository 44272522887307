<app-content-header [title]="'My profile'" [subtitle]="'Change your address'"></app-content-header>

<div class="sf-card mb-4 md:mb-6 xl:mb-8">
  <div [ngSwitch]="step">
    <ng-container *ngSwitchCase="stepOpts.UPDATE">
      <div
        class="flex flex-col border-b border-primary-sf-navy/10 dark:border-sf-white/10 md:flex-row"
      >
        <p class="mb-0">Your address is currently:&nbsp;&nbsp;</p>
        <p class="max-md:whitespace-pre font-semibold mb-3">
          {{ formattedCurrentAddress }}
        </p>
      </div>

      <div class="my-4 md:my-4">
        <app-address-picker
          (onSelectedAddress)="onChangedAddress($event)"
          (onEnterManually)="enteredManually = true"
        ></app-address-picker>
      </div>

      <app-button
        *ngIf="address"
        buttonStyle="primary"
        text="Update"
        [noBorder]="true"
        [disabled]="!address"
        (click)="goToNextStep()"
      ></app-button>
    </ng-container>

    <ng-container *ngSwitchCase="stepOpts.VERIFY">
      <app-verify-identity (complete)="handleVerification($event)"></app-verify-identity>
    </ng-container>

    <ng-container *ngSwitchCase="stepOpts.CONFIRM">
      <app-verify-code
        [emailOrMobile]="memberProfile.mobileNumber"
        (complete)="handleCode($event)"
      ></app-verify-code>
    </ng-container>

    <ng-container *ngSwitchCase="stepOpts.COMPLETE">
      <h2>Address changed</h2>
      <p>Your address has been changed to:</p>
      <p class="max-md:whitespace-pre text-center text-lg">{{ formattedNewAddress }}</p>

      <p>
        If you have any questions regarding this, please call
        <a href="tel:0800526249">0800 526 249</a>
        and speak to our Member Services Team.
      </p>

      <app-button text="Return home" buttonStyle="primary" [routerLink]="url.HOME"></app-button>
    </ng-container>
  </div>
</div>
<div [ngSwitch]="step">
  <app-survey-prompt
    *ngSwitchCase="stepOpts.COMPLETE"
    imageSrc="survey-prompt"
    imageAlt="survey-prompt"
    customClass="mb-4"
    title="Was it easy to update your profile?"
    content="Please take our short survey if you have feedback for us."
    [button_text]="'Give Feedback'"
    [button_small]="false"
    [button_fullWidth]="true"
    [feedbackType]="updateProfileFeedbackType"
  ></app-survey-prompt>
</div>
