import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ThemeService } from '@app/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-quick-action-button',
  templateUrl: './quick-action-button.component.html',
  styleUrls: ['./quick-action-button.component.scss'],
  standalone: false,
})
export class QuickActionButtonComponent {
  @Input() quickActionBtnClass: string;
  @Input() quickActionBtnIcon: string;
  @Input() quickActionBtnAlt: string;
  @Input() quickActionBtnText: string;

  public svg: string;

  private subscribeUntilDestroyed = new Subscription();

  constructor(private themeService: ThemeService) {}

  public ngOnInit(): void {
    this.subscribeUntilDestroyed = this.themeService.mode$.subscribe(() => this.processSVG());
  }

  public ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  /*
   * Processes an SVG image using the theme service's colour conversion.
   */
  public processSVG(): void {
    this.themeService
      .processSVGFromImageUrl(`/assets/icons/${this.quickActionBtnIcon}.svg`)
      .then((convertedSVG) => {
        this.svg = convertedSVG;
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
