<div
  class="p-6 bg-primary-sf-navy xl:p-10 w-60 xl:w-80 h-screen sticky top-0 bottom-0 flex flex-col"
>
  <nav>
    <svg
      class="side-bar-logo"
      width="156"
      height="50"
      viewBox="0 0 156 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.00507983 10.6103L31.7701 23.8851C31.7701 23.8851 33.1069 24.4354 33.1069 26.3699V33.4238L1.41812 20.1964C1.41812 20.1964 2.05544e-09 19.7985 2.05544e-09 17.4661C2.05544e-09 15.1329 0.00507983 10.6103 0.00507983 10.6103Z"
        fill="#F8AB00"
      />
      <path
        d="M32.0012 5.63896C30.9708 4.38683 29.8939 3.97199 29.7898 3.93389C29.783 3.93135 29.7771 3.92881 29.7703 3.92627C19.3355 -0.884149 15.9946 -0.870604 7.9609 1.79451C4.73437 2.86463 2.80742 3.92289 1.65768 4.80505C1.65768 4.80505 0.0219817 6.01231 0.0219817 7.31524C0.0219817 8.61732 -0.121946 9.29207 0.326772 9.48086L7.03468 12.2916L7.85931 12.6396L7.87285 12.2501C7.87285 11.9927 8.00069 11.7938 8.11753 11.6634C8.42486 11.3485 8.91422 11.0936 8.91422 11.0936C18.3839 6.59901 31.0462 13.8028 31.0462 13.8028C32.3779 14.3666 33.1077 14.2989 33.1077 11.666C33.1077 9.03216 33.0849 6.95543 32.0012 5.63896Z"
        fill="#F8AB00"
      />
      <path
        d="M1.10571 38.3992C2.13607 39.6513 3.21384 40.0662 3.31713 40.1043C3.3239 40.1068 3.32983 40.1094 3.3366 40.1119C13.7714 44.9223 17.1122 44.9088 25.146 42.2437C28.3734 41.1735 30.2995 40.1153 31.4492 39.2331C31.4492 39.2331 33.0857 38.0259 33.0857 36.7229C33.0857 35.4208 33.2288 34.7461 32.7801 34.5573L26.0722 31.7457L25.2476 31.3986L25.2349 31.7881C25.2349 32.0454 25.107 32.2444 24.9894 32.3748C24.682 32.6897 24.1927 32.9445 24.1927 32.9445C14.723 37.4392 2.06156 30.2354 2.06156 30.2354C0.729801 29.6715 2.05544e-09 29.7393 2.05544e-09 32.3722C2.05544e-09 35.006 0.0220126 37.0827 1.10571 38.3992Z"
        fill="#F8AB00"
      />
      <path
        d="M46.4874 19.5466C46.225 19.3366 46.12 19.1792 46.3562 18.7592L47.301 17.1321C47.511 16.7646 47.9572 16.7646 48.1934 16.9483C49.2695 17.7619 50.4505 18.5755 52.1565 18.5755C53.5221 18.5755 54.5449 17.6832 54.5449 16.5547C54.5449 15.2162 53.4163 14.2976 51.2117 13.4053C48.7446 12.408 46.2775 10.8333 46.2775 7.73557C46.2775 5.39978 48.0097 2.69656 52.1828 2.69656C54.8607 2.69656 56.9078 4.06129 57.4328 4.45497C57.6952 4.61244 57.7739 5.0586 57.5894 5.32189L56.5929 6.81785C56.3821 7.13194 55.9892 7.34274 55.6743 7.13194C54.5449 6.42417 53.4688 5.68847 52.0253 5.68847C50.5293 5.68847 49.7157 6.52831 49.7157 7.52646C49.7157 8.75912 50.6867 9.5998 52.8127 10.465C55.3593 11.4894 58.2989 13.0116 58.2989 16.3972C58.2989 19.1004 55.963 21.5945 52.2615 21.5945C48.9545 21.5945 47.2486 20.2027 46.4874 19.5466Z"
        fill="white"
      />
      <path
        d="M59.6589 3.50379C59.6589 3.21509 59.9476 2.9264 60.2363 2.9264H62.3622C62.6772 2.9264 62.9396 3.21509 62.9396 3.50379V9.93377C63.5433 9.40887 64.5931 8.91022 66.0366 8.91022C69.8948 8.91022 70.9454 11.5872 70.9454 14.6324V20.7212C70.9454 21.0362 70.6567 21.2986 70.3672 21.2986H68.2413C67.9263 21.2986 67.6639 21.0362 67.6639 20.7212V14.6053C67.6639 12.9257 66.9028 11.903 65.4592 11.903C64.042 11.903 63.2546 12.7945 62.9396 14.028V20.7212C62.9396 21.0624 62.7822 21.2986 62.3097 21.2986H60.2363C59.9476 21.2986 59.6589 21.0362 59.6589 20.7212V3.50379Z"
        fill="white"
      />
      <path
        d="M80.7065 13.7135C80.6541 12.5324 79.7092 11.613 78.3707 11.613C76.9009 11.613 75.9561 12.5062 75.7977 13.7135H80.7065ZM78.4494 8.90981C81.5989 8.90981 84.0144 11.2465 84.0144 14.5271C84.0144 14.7108 83.9881 15.1307 83.9602 15.3144C83.9339 15.6293 83.6461 15.8655 83.3836 15.8655H75.6149C75.6411 17.3615 76.8747 18.6737 78.6331 18.6737C79.8404 18.6737 80.6803 18.2276 81.2315 17.7814C81.5202 17.5452 81.8351 17.519 82.0188 17.7814L83.0424 19.1461C83.2524 19.3823 83.2786 19.671 82.9899 19.9335C81.9401 20.8258 80.4441 21.5869 78.4757 21.5869C74.8537 21.5869 72.3341 18.7 72.3341 15.2357C72.3341 11.8238 74.8537 8.90981 78.4494 8.90981Z"
        fill="white"
      />
      <path
        d="M88.6798 17.9126C88.6798 17.9126 89.6762 18.5949 91.1985 18.5949C93.1932 18.5949 94.2176 16.9678 94.2176 15.2618C94.2176 13.5559 93.2474 11.9025 91.3568 11.9025C89.7812 11.9025 88.9422 12.8998 88.6798 13.5822V17.9126ZM91.593 21.5868C90.3857 21.5868 89.2309 21.2982 88.6798 21.0882V26.9679C88.6798 27.2557 88.391 27.5453 88.1023 27.5453H85.9756C85.6606 27.5453 85.3982 27.2557 85.3982 26.9679V9.75043C85.3982 9.43549 85.6606 9.17304 85.9756 9.17304H86.9475C87.2362 9.17304 87.4191 9.40925 87.5249 9.75043L87.8399 10.7215C87.8399 10.7215 89.2309 8.91059 91.8022 8.91059C95.11 8.91059 97.6042 11.5876 97.6042 15.2618C97.6042 18.8049 95.2141 21.5868 91.593 21.5868Z"
        fill="white"
      />
      <path
        d="M99.173 3.50379C99.173 3.21509 99.4617 2.9264 99.7504 2.9264H101.876C102.192 2.9264 102.454 3.21509 102.454 3.50379V9.93377C103.056 9.40887 104.107 8.91022 105.551 8.91022C109.409 8.91022 110.459 11.5872 110.459 14.6324V20.7212C110.459 21.0362 110.171 21.2986 109.882 21.2986H107.756C107.441 21.2986 107.179 21.0362 107.179 20.7212V14.6053C107.179 12.9257 106.417 11.903 104.973 11.903C103.556 11.903 102.768 12.7945 102.454 14.028V20.7212C102.454 21.0624 102.297 21.2986 101.824 21.2986H99.7504C99.4617 21.2986 99.173 21.0362 99.173 20.7212V3.50379Z"
        fill="white"
      />
      <path
        d="M120.218 13.7135C120.166 12.5324 119.22 11.613 117.882 11.613C116.411 11.613 115.467 12.5062 115.31 13.7135H120.218ZM117.96 8.90981C121.11 8.90981 123.524 11.2465 123.524 14.5271C123.524 14.7108 123.499 15.1307 123.473 15.3144C123.446 15.6293 123.158 15.8655 122.895 15.8655H115.126C115.152 17.3615 116.385 18.6737 118.143 18.6737C119.352 18.6737 120.192 18.2276 120.742 17.7814C121.031 17.5452 121.347 17.519 121.53 17.7814L122.554 19.1461C122.765 19.3823 122.79 19.671 122.501 19.9335C121.452 20.8258 119.956 21.5869 117.986 21.5869C114.365 21.5869 111.845 18.7 111.845 15.2357C111.845 11.8238 114.365 8.90981 117.96 8.90981Z"
        fill="white"
      />
      <path
        d="M124.913 9.7504C124.913 9.40922 125.202 9.17302 125.49 9.17302H126.461C126.749 9.17302 126.881 9.30424 126.986 9.56669L127.433 10.7477C127.852 10.1441 128.981 8.91057 130.844 8.91057C132.287 8.91057 133.468 9.30424 133.128 10.0916L132.34 11.8762C132.209 12.1649 131.921 12.2699 131.632 12.1387C131.343 12.0075 131.054 11.9025 130.503 11.9025C129.192 11.9025 128.404 12.7423 128.194 13.1622V20.7207C128.194 21.1669 127.905 21.2981 127.511 21.2981H125.49C125.202 21.2981 124.913 21.0357 124.913 20.7207V9.7504Z"
        fill="white"
      />
      <path
        d="M141.865 12.5849C141.865 12.5849 140.866 11.9026 139.344 11.9026C137.35 11.9026 136.327 13.5297 136.327 15.2357C136.327 16.9416 137.297 18.595 139.186 18.595C140.762 18.595 141.602 17.5977 141.865 16.9153V12.5849ZM138.951 8.90981C140.159 8.90981 141.313 9.19935 141.865 9.40847V3.50338C141.865 3.21469 142.153 2.926 142.442 2.926H144.568C144.882 2.926 145.145 3.21469 145.145 3.50338V20.7208C145.145 21.0358 144.882 21.2982 144.568 21.2982H143.597C143.308 21.2982 143.124 21.062 143.019 20.7208L142.705 19.776C142.705 19.776 141.313 21.5869 138.741 21.5869C135.434 21.5869 132.94 18.9099 132.94 15.2357C132.94 11.6926 135.329 8.90981 138.951 8.90981Z"
        fill="white"
      />
      <path
        d="M146.857 20.4846C146.698 20.3796 146.568 20.091 146.698 19.8285L147.487 18.2538C147.617 17.9651 147.906 17.8601 148.194 18.0439C148.746 18.385 149.612 18.8312 150.872 18.8312C151.791 18.8312 152.21 18.49 152.21 17.9651C152.21 17.414 151.529 16.9153 149.927 16.2855C147.591 15.3931 146.54 14.2908 146.54 12.4537C146.54 10.6166 147.932 8.91066 150.899 8.91066C152.683 8.91066 153.838 9.38307 154.494 9.80299C154.757 10.0129 154.913 10.3279 154.757 10.6428L154.075 12.0338C153.891 12.3225 153.576 12.375 153.313 12.27C152.736 12.0076 151.896 11.6664 150.899 11.6664C150.085 11.6664 149.742 12.06 149.742 12.4537C149.742 13.0049 150.347 13.2935 151.476 13.766C153.812 14.7108 155.491 15.5506 155.491 17.9651C155.491 19.881 153.864 21.5869 150.819 21.5869C148.798 21.5869 147.538 20.9579 146.857 20.4846Z"
        fill="white"
      />
      <path
        d="M46.4352 25.88C46.4352 25.6175 46.6452 25.3813 46.9339 25.3813H57.6159C57.9046 25.3813 58.1155 25.6175 58.1155 25.88V28.032C58.1155 28.2945 57.9046 28.5307 57.6159 28.5307H49.8472V33.4647H56.3299C56.5924 33.4647 56.8294 33.7009 56.8294 33.9634V36.1163C56.8294 36.3779 56.5924 36.6149 56.3299 36.6149H49.8472V43.2549C49.8472 43.5173 49.611 43.7535 49.3485 43.7535H46.9339C46.6452 43.7535 46.4352 43.5173 46.4352 43.2549V25.88Z"
        fill="white"
      />
      <path
        d="M58.5044 32.2055C58.5044 31.8643 58.7931 31.6281 59.0818 31.6281H60.0529C60.3416 31.6281 60.4728 31.7593 60.5778 32.0218L61.024 33.2028C61.4439 32.5991 62.5725 31.3656 64.4359 31.3656C65.8795 31.3656 67.0605 31.7593 66.7193 32.5467L65.932 34.3313C65.8007 34.62 65.512 34.725 65.2233 34.5938C64.9346 34.4625 64.6459 34.3575 64.0947 34.3575C62.7825 34.3575 61.9951 35.1974 61.7851 35.6173V43.1758C61.7851 43.622 61.4964 43.7532 61.1027 43.7532H59.0818C58.7931 43.7532 58.5044 43.4907 58.5044 43.1758V32.2055Z"
        fill="white"
      />
      <path
        d="M67.846 32.2055C67.846 31.8643 68.1347 31.6281 68.4234 31.6281H70.5493C70.838 31.6281 71.1267 31.8643 71.1267 32.2055V43.1758C71.1267 43.4907 70.838 43.7532 70.5493 43.7532H68.4234C68.1347 43.7532 67.846 43.4907 67.846 43.1758V32.2055ZM67.5776 28.2603C67.5776 27.219 68.3828 26.3901 69.4487 26.3901C70.5138 26.3901 71.3426 27.219 71.3426 28.2603C71.3426 29.3025 70.5138 30.1541 69.4487 30.1541C68.3828 30.1541 67.5776 29.3025 67.5776 28.2603Z"
        fill="white"
      />
      <path
        d="M80.7776 36.1687C80.7251 34.9877 79.7803 34.0683 78.4417 34.0683C76.972 34.0683 76.0271 34.9614 75.8696 36.1687H80.7776ZM78.5205 31.3651C81.67 31.3651 84.0837 33.7017 84.0837 36.9823C84.0837 37.166 84.0575 37.5859 84.0312 37.7696C84.0058 38.0846 83.7171 38.3208 83.4547 38.3208H75.6859C75.7122 39.8167 76.9449 41.129 78.7042 41.129C79.9115 41.129 80.7514 40.6828 81.3025 40.2366C81.5912 40.0004 81.9062 39.9742 82.0899 40.2366L83.1135 41.6014C83.3234 41.8376 83.3497 42.1263 83.061 42.3887C82.0112 43.281 80.5151 44.0421 78.5467 44.0421C74.9248 44.0421 72.4044 41.1552 72.4044 37.6909C72.4044 34.2791 74.9248 31.3651 78.5205 31.3651Z"
        fill="white"
      />
      <path
        d="M85.4717 32.2055C85.4717 31.8643 85.7604 31.6281 86.0491 31.6281H87.0473C87.336 31.6281 87.4664 31.7593 87.5451 31.9955L87.9922 33.124C88.4375 32.6254 89.5661 31.3656 91.9019 31.3656C95.7609 31.3656 96.7582 33.9639 96.7582 36.9033V43.1758C96.7582 43.4907 96.4695 43.7532 96.18 43.7532H94.0549C93.74 43.7532 93.4775 43.4907 93.4775 43.1758V36.9033C93.4775 35.3286 92.8476 34.3575 91.3779 34.3575C89.8023 34.3575 89.042 35.4073 88.7533 36.2472V43.1758C88.7533 43.622 88.5696 43.7532 87.9922 43.7532H86.0491C85.7604 43.7532 85.4717 43.4907 85.4717 43.1758V32.2055Z"
        fill="white"
      />
      <path
        d="M107.093 35.0402C107.093 35.0402 106.095 34.3578 104.573 34.3578C102.578 34.3578 101.555 35.985 101.555 37.6909C101.555 39.3968 102.526 41.0502 104.416 41.0502C105.991 41.0502 106.831 40.0529 107.093 39.3706V35.0402ZM104.18 31.3651C105.387 31.3651 106.542 31.6546 107.093 31.8637V25.9586C107.093 25.6699 107.382 25.3812 107.671 25.3812H109.797C110.111 25.3812 110.374 25.6699 110.374 25.9586V43.1761C110.374 43.491 110.111 43.7535 109.797 43.7535H108.825C108.537 43.7535 108.352 43.5173 108.248 43.1761L107.933 42.2313C107.933 42.2313 106.542 44.0421 103.969 44.0421C100.663 44.0421 98.1688 41.3652 98.1688 37.6909C98.1688 34.1478 100.557 31.3651 104.18 31.3651Z"
        fill="white"
      />
      <path
        d="M112.417 25.9589C112.417 25.6702 112.706 25.3815 112.995 25.3815H115.12C115.408 25.3815 115.698 25.6702 115.698 25.9589V43.1763C115.698 43.4912 115.408 43.7537 115.12 43.7537H112.995C112.706 43.7537 112.417 43.4912 112.417 43.1763V25.9589Z"
        fill="white"
      />
      <path
        d="M117.138 32.4151C116.98 31.9952 117.191 31.6278 117.663 31.6278H120.182C120.444 31.6278 120.629 31.759 120.681 31.969L122.861 39.2125H122.912L125.668 31.969C125.852 31.654 126.063 31.6278 126.429 31.6278H128.661C129.133 31.6278 129.369 32.0215 129.184 32.4151L122.046 49.6326C121.968 49.8163 121.758 50 121.521 50H119.001C118.529 50 118.293 49.6063 118.476 49.1864L120.918 43.0443L117.138 32.4151Z"
        fill="white"
      />
    </svg>

    <ul class="text-white p-0 mt-[34px] list-none mb-auto">
      <li
        routerLink="/home"
        routerLinkActive="active"
        aria-label="Home"
        class="flex w-full bg-none mb-6 xl:mb-8 gap-3 group hover:cursor-pointer text-left text-lg items-center font-medium"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 17 20"
          stroke-linecap="round"
          class="w-6 h-6 stroke-sf-white fill-none stroke-[1.5px] group-hover:stroke-secondary-sf-blue-light"
        >
          <path
            d="m1 7.5 7.5-5.833L16 7.5v9.167a1.667 1.667 0 0 1-1.667 1.666H2.667A1.666 1.666 0 0 1 1 16.667V7.5Z"
          />
          <path d="M6 18.333V10h5v8.333" />
        </svg>
        <span class="group-hover:text-secondary-sf-blue-light">Home</span>
      </li>

      <li
        routerLink="/documents"
        routerLinkActive="active"
        aria-label="Documents"
        class="flex w-full bg-none mb-6 xl:mb-8 gap-3 group hover:cursor-pointer text-left text-lg items-center font-medium"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 15 20"
          stroke-linecap="round"
          class="group-hover:stroke-secondary-sf-blue-light w-6 h-6 stroke-sf-white fill-none stroke-[1.5px]"
        >
          <path
            d="M9.167 1.667H2.5A1.665 1.665 0 0 0 .833 3.333v13.334A1.666 1.666 0 0 0 2.5 18.333h10a1.668 1.668 0 0 0 1.667-1.666v-10l-5-5Z"
          />
          <path d="M9.167 1.667v5h5M10.833 10.833H4.167M10.833 14.167H4.167M5.833 7.5H4.167" />
        </svg>
        <span class="group-hover:text-secondary-sf-blue-light">Documents</span>
      </li>

      <li
        routerLink="/plans/deposit"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }"
        aria-label="Deposits"
        class="flex w-full bg-none mb-6 xl:mb-8 gap-3 group hover:cursor-pointer text-left text-lg items-center font-medium"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 22 22"
          stroke-linecap="round"
          class="group-hover:stroke-secondary-sf-blue-light w-6 h-6 stroke-sf-white fill-none stroke-[1.5px]"
        >
          <path
            d="M12.167 17.917H1.75a.832.832 0 0 1-.833-.834V5.417a.835.835 0 0 1 .833-.834h16.667a.832.832 0 0 1 .833.834V12.5"
          />
          <path
            d="m1.75 4.583 12.446-3.112a.719.719 0 0 1 .815.382.71.71 0 0 1 .072.314v2.416M16.333 20.333a4.5 4.5 0 1 0 0-8.999 4.5 4.5 0 0 0 0 9ZM16.333 14.167V17.5M18 15.833h-3.333"
          />
        </svg>
        <span class="group-hover:text-secondary-sf-blue-light">Deposit</span>
      </li>

      <li
        routerLink="/help"
        routerLinkActive="active"
        aria-label="Help"
        class="flex w-full bg-none mb-6 xl:mb-8 gap-3 group hover:cursor-pointer text-left text-lg items-center font-medium"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          stroke-linecap="round"
          class="group-hover:stroke-secondary-sf-blue-light w-6 h-6 stroke-sf-white fill-none stroke-[1.5px]"
        >
          <path d="M10 18.333a8.333 8.333 0 1 0 0-16.666 8.333 8.333 0 0 0 0 16.666Z" />
          <path d="M7.575 7.5a2.501 2.501 0 0 1 4.858.833c0 1.667-2.5 2.5-2.5 2.5M10 14.167h.008" />
        </svg>
        <span class="group-hover:text-secondary-sf-blue-light">Help</span>
      </li>

      <li
        class="flex w-full bg-none mb-6 xl:mb-8 group gap-3 hover:cursor-pointer text-left text-lg items-center font-medium"
      >
        <a (click)="navigateToFeedbackUrl()" class="contents !text-white no-underline">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke-linecap="round"
            class="group-hover:stroke-secondary-sf-blue-light w-6 h-6 stroke-sf-white fill-none stroke-[1.5px]"
          >
            <path
              d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path d="M16 8H7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M13.6667 11.5H7"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="group-hover:text-secondary-sf-blue-light">Feedback</span>
        </a>
      </li>

      <li
        routerLink="/notifications"
        routerLinkActive="active"
        aria-label="Notifications"
        class="flex w-full bg-none mb-6 xl:mb-8 gap-3 group hover:cursor-pointer text-left text-lg items-center font-medium"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 17 19"
          stroke-linecap="round"
          class="group-hover:stroke-secondary-sf-blue-light w-6 h-6 stroke-sf-white fill-none stroke-[1.5px]"
        >
          <path
            d="M13.25 6.333a4.75 4.75 0 1 0-9.5 0c0 5.542-2.375 7.125-2.375 7.125h14.25s-2.375-1.583-2.375-7.125ZM9.87 16.625a1.583 1.583 0 0 1-2.74 0"
          />
        </svg>
        <span class="group-hover:text-secondary-sf-blue-light">Notifications</span>
      </li>

      <li
        routerLink="/profile"
        routerLinkActive="active"
        aria-label="Profile"
        class="flex w-full bg-none mb-6 xl:mb-8 gap-3 group hover:cursor-pointer text-left text-lg items-center font-medium"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          stroke-linecap="round"
          fill="none"
          class="group-hover:stroke-secondary-sf-blue-light w-6 h-6 stroke-sf-white fill-none stroke-[1.5px]"
        >
          <path
            d="M20 20.1429V18.1429C20 17.082 19.5786 16.0646 18.8284 15.3145C18.0783 14.5643 17.0609 14.1429 16 14.1429H8C6.93913 14.1429 5.92172 14.5643 5.17157 15.3145C4.42143 16.0646 4 17.082 4 18.1429V20.1429"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 10.1429C14.2091 10.1429 16 8.35202 16 6.14288C16 3.93374 14.2091 2.14288 12 2.14288C9.79086 2.14288 8 3.93374 8 6.14288C8 8.35202 9.79086 10.1429 12 10.1429Z"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span class="group-hover:text-secondary-sf-blue-light">Profile</span>
      </li>
    </ul>
  </nav>

  <div class="mt-auto bg-primary-sf-navy-darker flex rounded-full p-1 w-max">
    <div>
      <input
        type="radio"
        id="light-theme"
        [(ngModel)]="selectedTheme"
        value="Mode.LIGHT"
        (change)="switchMode(Mode.LIGHT)"
        name="theme"
        class="absolute opacity-0 peer"
      />
      <label
        for="light-theme"
        class="py-3 px-6 rounded-full dark:text-sf-white/65 text-primary-sf-navy font-bold dark:font-normal bg-primary-sf-yellow dark:bg-primary-sf-navy-darker peer-checked:bg-primary-sf-yellow peer-checked:text-primary-sf-navy peer-checked:font-bold transition duration-[0.25s] peer-checked:transition-colors cursor-pointer peer-checked:duration-[0.5s]"
      >
        Light
      </label>
    </div>

    <div>
      <input
        type="radio"
        id="dark-theme"
        [(ngModel)]="selectedTheme"
        [value]="Mode.DARK"
        name="theme"
        (change)="switchMode(Mode.DARK)"
        class="absolute opacity-0 peer"
      />
      <label
        for="dark-theme"
        class="py-3 px-6 rounded-full text-sf-white/65 dark:bg-primary-sf-yellow peer-checked:bg-primary-sf-yellow peer-checked:text-primary-sf-navy peer-checked:font-bold transition duration-[0.25s] peer-checked:transition-colors cursor-pointer peer-checked:duration-[0.5s]"
      >
        Dark
      </label>
    </div>
  </div>
</div>
