import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TypeUtility } from '@app/shared/utilities';
import { catchError, map, mergeMap, Observable, throwError } from 'rxjs';
import { MemberDashboard, TransactionsSummary } from '../models/member-portal';
import { MemberPortalService, TransactionsService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class TransactionsSummaryResolver {
  public planNumber: string;

  constructor(
    private memberPortalService: MemberPortalService,
    private transactionsService: TransactionsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<TransactionsSummary> {
    this.planNumber = route.params['planNumber'];

    return this.memberPortalService
      .getMemberDashboard()
      .pipe(catchError((error) => throwError(() => `MemberDashboardResolver -> ${error}`)))
      .pipe(
        mergeMap((memberDashboard) => this.getTransactionsSummary(memberDashboard)),
        catchError((error) => throwError(() => `TransactionSummaryResolver -> ${error}`))
      )
      .pipe();
  }

  /**
   * Finds matching ISA/JISA SavingsPlan/AuthorisedSavingsPlan then obtains associated TransactionsSummary (or throws an error)
   * @param {MemberDashboard} [memberDashboard]
   * @return {SavingsPlan}
   */
  private getTransactionsSummary(
    memberDashboard: MemberDashboard
  ): Observable<TransactionsSummary> {
    // for ISA
    const savingsPlan = memberDashboard.memberPlans?.savingsPlans?.find(
      (plan) => plan.formattedPlanNumber.toUpperCase() == this.planNumber.toUpperCase()
    );

    // for JISA
    const authorisedSavingsPlan = memberDashboard.authorisedPlans?.authorisedSavingsPlans?.find(
      (plan) => plan.plan.formattedPlanNumber.toUpperCase() == this.planNumber.toUpperCase()
    );

    if (
      TypeUtility.isNullOrUndefined(savingsPlan) &&
      TypeUtility.isNullOrUndefined(authorisedSavingsPlan)
    ) {
      throw new Error('No ISA/JISA plan data');
    }

    // we have the planId, so we can get the summary of transactions
    const planId = savingsPlan ? `${savingsPlan.planId}` : `${authorisedSavingsPlan.plan.planId}`;
    return this.transactionsService.getTransactionsSummary(planId);
  }
}
