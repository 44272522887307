export class PlanUtility {
  /**
   * Checks `planNumber` corresponds to an authorised plan
   * @param {string} [planNumber] the plan number
   * @return true if `planNumber` includes `JISA`
   */
  static isAuthorisedPlan(planNumber: string): boolean {
    return !!planNumber.toUpperCase().includes('JISA');
  }

  /**
   * Checks `planStatusId` corresponds to an issued status
   * @param {number} [planStatusId] the plan status identifier
   * @return true if `planStatusId` equals `10||11`
   */
  static isPlanIssued(planStatusId: number): boolean {
    return planStatusId === 10 || planStatusId === 11;
  }

  /**
   * Checks `planStatusId` corresponds to a pending status
   * @param {number} [planStatusId] the plan status identifier
   * @return true if `planStatusId` equals `6||7||14||23||24||99`
   */
  static isPlanPending(planStatusId: number): boolean {
    return (
      planStatusId === 6 ||
      planStatusId === 7 ||
      planStatusId === 14 ||
      planStatusId === 23 ||
      planStatusId === 24 ||
      planStatusId === 99
    );
  }
}
