export class Register {
  // Indicates if the user already has an online account
  public hasOnlineAccess: boolean;

  // Indicates which plans the user is registering for
  public plansRegisteringFor: PlansRegisteringFor = 4;
  public title: string;
  public firstName: string;
  public surname: string;
  public postcode: string;
  public noPostcode: boolean;
  public telephoneNumber: string;
  public dob: string;
  public planNumber: string;

  public emailAddress: string;
  public emailAddressConfirm: string;
  public password: string;
  public passwordConfirm: string;
  public mobileNumber: string;

  public consentPost: boolean;
  public consentEmail: boolean;
  public consentTelephone: boolean;
  public consentTextMessage: boolean;
  public consentNotGiven: boolean;
  public consentFinancialUpdates: boolean;
  public consentPromotionsNews: boolean;

  // public hasIndicatedOwnPlan: boolean;
  // public hasOwnPlan: boolean;
  public childsPlanNumber: string;
  public ownPlanNumber: string; // second chance to capture the members own plan number if it wasnt capturued in 'planNumber'
  public childsFirstName: string;
  public childsSurname: string;
  public childsDob: string;

  public allowOnlyCTF: boolean;

  public clientSourceId: string;
}

export enum PlansRegisteringFor {
  None,
  AdultAndChild = 1,
  Adult = 2,
  Child = 4,
}
