<app-login-header *ngIf="!(authenticated$ | async)" />
<app-menu-header *ngIf="authenticated$ | async" />

<div
  [ngClass]="{
    'lg:!justify-center lg:!grid-cols-1': !(authenticated$ | async)
  }"
  class="lg:grid lg:grid-cols-auto-1fr"
>
  <app-side-menu *ngIf="(authenticated$ | async) && visibilityService.isLgOrAbove"></app-side-menu>

  <div
    class="max-xs:px-2 p-4 flex justify-center md:p-6 min-h-[calc(100vh-70px)] md:min-h-[calc(100vh-130px)]"
  >
    <div class="w-full flex flex-col max-w-[936px]">
      <router-outlet class="contents" #outlet="outlet"></router-outlet>
    </div>
  </div>
</div>

<app-install-instructions *ngIf="!(authenticated$ | async)" />
<app-loading-spinner />
<app-menu-footer
  *ngIf="authenticated$ | async"
  class="block sticky bottom-0 z-[120] right-0 left-0"
/>
<app-ybug />
