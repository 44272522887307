import { Injectable } from '@angular/core';
import { AbstractCacheService } from './cache-abstract.service';
import { WP_REST_API_Post_Override } from 'src/app/models/wp-types-overrides/WP_REST_API_Post_Override';
import { MemberDashboard, Notifications, Transactions } from '@app/models/member-portal';
import { MemberProfile } from '@app/models/profile';

@Injectable({
  providedIn: 'root',
})
export class MemberDashboardCacheService extends AbstractCacheService<MemberDashboard> {}

@Injectable({
  providedIn: 'root',
})
export class WP_REST_API_Post_OverrideCacheService extends AbstractCacheService<WP_REST_API_Post_Override> {}

@Injectable({
  providedIn: 'root',
})
export class WP_REST_API_Posts_OverrideCacheService extends AbstractCacheService<
  WP_REST_API_Post_Override[]
> {}

@Injectable({
  providedIn: 'root',
})
export class TransactionsCacheService extends AbstractCacheService<Transactions> {}

@Injectable({
  providedIn: 'root',
})
export class NotificationsCacheService extends AbstractCacheService<Notifications> {}

@Injectable({
  providedIn: 'root',
})
export class ProfileCacheService extends AbstractCacheService<MemberProfile> {}
