<form #form="ngForm" id="register-step-one" [formGroup]="registerForm">
  <div>
    <div class="mb-2">
      <h5 class="text-lg font-bold">Your plan details</h5>
      <label class="my-4">
        {{ formLabel }}
      </label>
      <br />
      <label for="planNumber" class="mb-1 form-label">Your Stocks & Shares ISA plan number</label>
      <div class="input-group">
        <input
          formControlName="planNumber"
          type="text"
          class="form-control"
          placeholder="e.g. ISA000123 or SISA000123"
          [ngClass]="{
            'is-invalid': planNumberCtrl.touched && planNumberCtrl.errors,
          }"
        />
      </div>
      <app-validation-message
        *ngIf="planNumberCtrl.touched && planNumberCtrl.errors"
        [message]="'Please enter a valid plan number'"
      ></app-validation-message>
    </div>

    <app-alert
      additionalClass="my-4"
      iconClass="far fa-question-circle"
      type="info"
      title="Your plan number"
      message="Your plan number is important as it allows us to verify the details you're providing during this registration process. You can find this number on your plan document, or on your latest annual ISA statement. Your ISA plan number is in the following format: ISA000123 or SISA000123. If you have any questions regarding your plan number, please get in touch and we'll be happy to help."
    ></app-alert>
  </div>
</form>
