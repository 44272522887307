<app-content-header
  *ngIf="ssiDetails"
  [title]="'Stocks and Shares ISA'"
  [subtitle]="'Fund switch request for ' + ssiDetails.formattedPlanNumber"
/>

<div class="sf-card mb-4">
  <div>
    <h3 class="text-primary-sf-navy dark:text-sf-white font-semibold mb-2 lg:text-xl">
      You're almost there...
    </h3>

    <p>
      By clicking 'Submit fund switch request' below, you confirm that you agree with the following:
    </p>

    <ul>
      <li>You hereby apply to transfer your investment from [X FUND] to [X FUND].</li>
      <li>
        You confirm that you have made your own decision to transfer your investment from [X FUND]
        to [X FUND]. You have not asked for, nor received any financial advice from Shepherds
        Friendly, which therefore takes no responsibility for the product's suitability to your
        circumstances.
      </li>
      <li>
        You have read the Important Information Guide and [FUND FACTSHEET XYZ], and agree that this
        variation shall form the basis of contract with Shepherds Friendly Society Limited.
      </li>
      <li>
        You have not subscribed/made payments, and will not subscribe/make payments, more than the
        overall subscription limit in total to a cash ISA, a stocks and shares ISA, an innovative
        finance ISA, and a Lifetime ISA in the same tax year.
      </li>
      <li>
        You agree to the ISA terms and conditions and declare that this application form has been
        completed to the best of your knowledge and belief.
      </li>
      <li>All subscriptions made, and to be made, belong to you.</li>
      <li>You are 18 years of age or over.</li>
      <li>
        You declare that to the best of your knowledge and belief, any answers given are true and
        complete and that no important fact has been omitted or falsely stated.
      </li>
      <li>
        You have received the Key Information Document (KID) and agree that this application shall
        form the basis of the contract with The Shepherds Friendly Society Limited, which shall be
        made subject to the plan's terms and conditions.
      </li>
      <li>
        You have read and understood the Key Information Document (KID). (It is important that you
        understand the KID before signing this application; please contact the Society if there are
        any parts you do not understand.)
      </li>
      <li>
        You are resident in the United Kingdom for tax purposes or, if not so resident, either
        perform duties which, by virtue of Section 28 of Income Tax (Earnings &amp; Pensions) Act
        2003 (Crown employees serving overseas), are treated as being performed in the UK, or you
        are married to or in a civil partnership with a person who performs such duties. You will
        inform Shepherds Friendly Society if you cease to be so resident, or to perform such duties,
        or if you are no longer married to/in a civil partnership with a person who performs such
        duties.
      </li>
      <li>
        A copy of the completed application and The Shepherds Friendly Society Limited’s Memorandum
        and Rules are available on request.
      </li>
    </ul>

    <app-button
      text="Confirm Fund Switch"
      buttonStyle="primary"
      [alignRight]="true"
      [noBorder]="true"
    ></app-button>
  </div>
</div>
