import { WithdrawalProjection } from '@app/models/withdrawal';

export class WithdrawalProjectionMock {
  public static withdrawalProjectionA: WithdrawalProjection = {
    investmentPeriod: 10,
    interestRate: 1.0475,
    monthlyDD: 30.0,
    currentBalance: 8603.33,
    requestedWithdrawal: 600.0,
    totalDDContributions: 3600.0,
    totalDDInterest: 996.69,
    balanceAfterWithdrawal: 8003.33,
    interestWithoutWithdrawal: 6077.16,
    interestWithWithdrawal: 5722.85,
    interestReductionDiff: 354.31,
    valueWithoutWithdrawal: 18280.49,
  };

  public static withdrawalProjectionB: WithdrawalProjection = {
    investmentPeriod: 10,
    interestRate: 1.0475,
    monthlyDD: 1000.0,
    currentBalance: 8603.33,
    requestedWithdrawal: 600.0,
    totalDDContributions: 120000.0,
    totalDDInterest: 33222.87,
    balanceAfterWithdrawal: 8003.33,
    interestWithoutWithdrawal: 38303.34,
    interestWithWithdrawal: 37949.03,
    interestReductionDiff: 354.31,
    valueWithoutWithdrawal: 166906.67,
  };

  public static withdrawalProjectionC: WithdrawalProjection = {
    investmentPeriod: 10,
    interestRate: 1.0475,
    monthlyDD: 0.0,
    currentBalance: 8603.33,
    requestedWithdrawal: 600.0,
    totalDDContributions: 0.0,
    totalDDInterest: 0.0,
    balanceAfterWithdrawal: 8003.33,
    interestWithoutWithdrawal: 5080.48,
    interestWithWithdrawal: 4726.16,
    interestReductionDiff: 354.31,
    valueWithoutWithdrawal: 13683.81,
  };

  public static withdrawalProjectionD: WithdrawalProjection = {
    investmentPeriod: 10,
    interestRate: 1.0475,
    monthlyDD: 30.0,
    currentBalance: 8603.33,
    requestedWithdrawal: 2500.0,
    totalDDContributions: 3600.0,
    totalDDInterest: 996.69,
    balanceAfterWithdrawal: 6103.33,
    interestWithoutWithdrawal: 6077.16,
    interestWithWithdrawal: 4600.85,
    interestReductionDiff: 1476.31,
    valueWithoutWithdrawal: 18280.49,
  };

  public static withdrawalProjectionE: WithdrawalProjection = {
    investmentPeriod: 10,
    interestRate: 1.0475,
    monthlyDD: 1200.0,
    currentBalance: 8603.33,
    requestedWithdrawal: 3000.0,
    totalDDContributions: 144000.0,
    totalDDInterest: 39867.44,
    balanceAfterWithdrawal: 5603.33,
    interestWithoutWithdrawal: 44947.92,
    interestWithWithdrawal: 43176.34,
    interestReductionDiff: 1771.57,
    valueWithoutWithdrawal: 197551.25,
  };

  public static withdrawalProjectionF: WithdrawalProjection = {
    investmentPeriod: 10,
    interestRate: 1.0475,
    monthlyDD: 30.0,
    currentBalance: 8603.33,
    requestedWithdrawal: 8573.33,
    totalDDContributions: 3600.0,
    totalDDInterest: 996.69,
    balanceAfterWithdrawal: 30.0,
    interestWithoutWithdrawal: 6077.16,
    interestWithWithdrawal: 1014.4,
    interestReductionDiff: 5062.76,
    valueWithoutWithdrawal: 18280.49,
  };

  public static withdrawalProjectionG: WithdrawalProjection = {
    investmentPeriod: 10,
    interestRate: 1.0475,
    monthlyDD: 0.0,
    currentBalance: 8603.33,
    requestedWithdrawal: 8603.33,
    totalDDContributions: 0.0,
    totalDDInterest: 0.0,
    balanceAfterWithdrawal: 0.0,
    interestWithoutWithdrawal: 5080.48,
    interestWithWithdrawal: 0.0,
    interestReductionDiff: 5080.48,
    valueWithoutWithdrawal: 13683.81,
  };
}
