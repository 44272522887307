<div class="w-full max-w-[500px] my-0 mx-auto lg:max-w-[600px]">
  <header class="mb-6">
    <h1 class="text-3xl md:text-5xl 2xl:text-6xl font-bold">Welcome</h1>
    <p class="mb-2">Enter your log-in details below</p>
  </header>

  <hr class="border-gray-500 border-opacity-25 border-b mb-6" />

  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <label for="username" class="form-label">Email address</label>
    <input
      type="text"
      class="form-control"
      id="username"
      name="username"
      placeholder="Email address"
      required
      formControlName="username"
      [ngClass]="{ 'is-invalid': submitted && f['username'].errors }"
    />

    <div *ngIf="submitted && f['username'].errors">
      <app-validation-message
        *ngIf="f['username'].errors['required']"
        message="Please enter your email address"
      ></app-validation-message>

      <app-validation-message
        *ngIf="f['username'].errors['email']"
        message="Please enter a valid email address"
      ></app-validation-message>
    </div>

    <app-button
      btnWrapperClasses="my-4 md:my-5"
      text="Log in"
      buttonStyle="primary"
      [isSubmitButton]="true"
      [noBorder]="true"
      [fullWidth]="true"
    ></app-button>

    <app-login-info-message
      imageSrc="./assets/images/feedback-survey-white.svg"
      imageAlt="feedback-survey-white"
      additionalClass="grid grid-cols-32 grid-rows-auto mb-4"
      imageWidth="24"
      imageHeight="27"
      title="Register to use the app"
    >
      <p class="col-span-full mb-0">
        To access the app, please
        <a routerLink="/register/home" class="!text-primary-sf-yellow underline">
          tap here and create an account
        </a>
        if you haven't already.
      </p>
    </app-login-info-message>
  </form>
</div>
<div class="text-center mt-4 md:mt-5">
  <a class="btn-link text-secondary-sf-blue-light" routerLink="/forgotten-password">
    Forgotten your password?
  </a>
</div>
<p class="text-center mt-4 md:mt-5">App version: {{ appConstants.version }}</p>
