import { Injectable } from '@angular/core';

import { HttpService } from './http.service';
import { AppConstantsService } from './app-constants.service';
import {
  PlanConnectionRequest,
  PlanConnectionRequestResult,
  Register,
  RegistrationResult,
  ResendRegistrationEmail,
  ResendRegistrationEmailResult,
  VerificationResult,
} from '@app/models/registration';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class RegistrationService {
  public readonly api = {
    REGISTER: `${this.configuration.server}/api/Member/Register`,
    RESEND_REGISTRATION_EMAIL: `${this.configuration.server}/api/Member/ResendRegistrationEmail`,
    PLAN_CONNECTION: `${this.configuration.server}/api/Member/RequestPlanConnection`,
    ADDRESS_VERIFICATION: `${this.configuration.server}/api/Member/VerifyAddressCode`,
  };

  constructor(
    private http: HttpService,
    private configuration: AppConstantsService,
    private auth: AuthService
  ) {}

  /**
   * `POST` register payload and return response
   * @param {Register} [data]
   */
  register(data: Register): Observable<RegistrationResult> {
    // attach unique client identification
    data.clientSourceId = this.auth.clientSecret;
    return this.http.post<RegistrationResult>(this.api.REGISTER, data);
  }

  /**
   * `POST` user email and registration Id to resend email endpoint
   */
  resendRegistrationEmail(
    data: ResendRegistrationEmail
  ): Observable<ResendRegistrationEmailResult> {
    return this.http.post(this.api.RESEND_REGISTRATION_EMAIL, data);
  }

  /**
   * `POST` plan connection request payload and return response
   * @param {Register} [data]
   */
  planConnectionRequest(data: PlanConnectionRequest): Observable<PlanConnectionRequestResult> {
    const planRequest = new PlanConnectionRequest();
    planRequest.requestFirstName = data.requestFirstName;
    planRequest.requestSurname = data.requestSurname;
    planRequest.requestDOB = data.requestDOB;
    planRequest.requestPlanNumber = data.requestPlanNumber;

    return this.http.post<PlanConnectionRequestResult>(
      this.api.PLAN_CONNECTION,
      planRequest.toJSON()
    );
  }

  /**
   * `POST` request to verify the address code and returns the verification result
   * @param {{ id: string, emailAddress: string, code: string }} data - The verification request payload.
   */
  verifyAddressCode(data: {
    id: string;
    emailAddress: string;
    code: string;
  }): Observable<VerificationResult> {
    return this.http.post(this.api.ADDRESS_VERIFICATION, data);
  }
}
