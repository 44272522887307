import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-registration-plan-choice',
    templateUrl: './registration-plan-choice.component.html',
    styleUrls: ['./registration-plan-choice.component.css'],
    standalone: false
})
export class RegistrationPlanChoiceComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
