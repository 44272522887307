import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { FeatureFlagService } from '@app/services';

@Injectable({
  providedIn: 'root',
})
export class WithdrawalGuard implements CanActivate {
  constructor(private featureFlagService: FeatureFlagService, private router: Router) {}

  async canActivate(): Promise<boolean> {
    const isFeatureEnabled = await this.featureFlagService.isEnabled('appWithdrawals');

    if (!isFeatureEnabled) {
      // Redirect to home if the app withdrawals feature is disabled
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }
}
