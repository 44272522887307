import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Router } from '@angular/router';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { httpInterceptorProviders } from './interceptors/http-interceptor-providers';

import * as Sentry from '@sentry/angular';

import {
  AuthService,
  HttpService,
  MemberPortalService,
  LoadingService,
  DocumentService,
  TransactionsService,
  StripeService,
  AppConstantsService,
  WordpressApiService,
  PaymentService,
  FeedbackService,
  FidoService,
  ProfileService,
  PostcodeService,
  GoogleAnalyticsService,
  NotificationsService,
  FeatureFlagService,
  RegistrationService,
  RouterHelperService,
  ScriptLoaderService,
  ThemeService,
  VisibilityService,
  YbugService,
} from './services';

import { CTFOverviewService } from '@app/components/plans/ctf/services';

const APP_SERVICES = [
  AppConstantsService,
  AuthService,
  HttpService,
  MemberPortalService,
  WordpressApiService,
  LoadingService,
  DocumentService,
  TransactionsService,
  StripeService,
  PaymentService,
  FeedbackService,
  FidoService,
  ProfileService,
  PostcodeService,
  GoogleAnalyticsService,
  NotificationsService,
  FeatureFlagService,
  RouterHelperService,
  RegistrationService,
  ScriptLoaderService,
  ThemeService,
  VisibilityService,
  YbugService,
];

const CTF_SERVICES = [CTFOverviewService];

import { StripeElementComponent } from './components/payments/stripe-element/stripe-element.component';
import { LogInComponent } from './components/security/log-in/log-in.component';
import { LogInTwoFactorAuthComponent } from './components/security/log-in.twofactorauth/log-in.twofactorauth.component';
import { LogInUserComponent } from './components/security/log-in.user/log-in.user.component';
import { LogInPasswordComponent } from './components/security/log-in.password/log-in.password.component';
import { LogOutComponent } from './components/security/log-out/log-out.component';
import { VerifyCodeComponent } from './components/profile/verify-code/verify-code.component';
import { VerifyIdentityComponent } from './components/profile/verify-identity/verify-identity.component';
import { ManageAuthnComponent } from './components/security/manage-authn/manage-authn.component';
import { PageNotFoundComponent } from './components/general/page-not-found/page-not-found.component';
import { MenuFooterComponent } from './components/general/menu-footer/menu-footer.component';
import { ProfileHomeComponent } from './components/profile/profile-home/profile-home.component';
import { ProfileChangeMobileNumberComponent } from './components/profile/profile-change-mobile-number/profile-change-mobile-number.component';
import { ProfileChangeEmailComponent } from './components/profile/profile-change-email/profile-change-email.component';
import { ProfileChangeAddressComponent } from './components/profile/profile-change-address/profile-change-address.component';
import { AddressVerification } from './components/security/address-verification/address-verification.component';
import { VerifyEmailComponent } from './components/security/verify-email/verify-email.component';
import { ForgottenPasswordComponent } from './components/security/forgotten-password/forgotten-password.component';
import { ResetPasswordComponent } from './components/security/reset-password/reset-password.component';
import { RegistrationHomeComponent } from './components/registration/registration-home/registration-home.component';
import { NotificationsHomeComponent } from './components/notifications/notifications-home/notifications-home.component';
import { NotificationDetailComponent } from './components/notifications/notification-detail/notification-detail.component';
import { NotificationsComponent } from './components/profile/notifications/notifications.component';
import { DocumentsHomeComponent } from './components/documents/documents-home/documents-home.component';
import { HomeHomeComponent } from './components/home/home-home/home-home.component';
import { HelpHomeComponent } from './components/help/help-home/help-home.component';
import { MenuHeaderComponent } from './components/general/menu-header/menu-header.component';
import { SideMenuComponent } from './components/general/side-menu/side-menu.component';
import { AccountProblemComponent } from './components/general/account-problem/account-problem.component';
import { IsaDetailsComponent } from './components/plans/isa-details/isa-details.component';
import { JisaDetailsComponent } from './components/plans/jisa-details/jisa-details.component';
import { CTFDetailsComponent } from './components/plans/ctf/ctf-details/ctf-details.component';
import { CTFOverviewComponent } from './components/plans/ctf/ctf-overview/ctf-overview.component';
import { CTFMaturityGraphComponent } from './components/plans/ctf/ctf-maturity-graph/ctf-maturity-graph.component';
import { CTFDeclarationComponent } from './components/plans/ctf/ctf-declaration/ctf-declaration.component';
import { CTFRequestSubmittedComponent } from './components/plans/ctf/ctf-request-submitted/ctf-request-submitted.component';
import { PlanChoiceComponent } from './components/plans/plan-choice/plan-choice.component';
import { PlanPerformanceGraphComponent } from './components/plans/plan-performance-graph/plan-performance-graph.component';
import { HelpPostComponent } from './components/help/help-post/help-post.component';
import { HelpPageComponent } from './components/help/help-page/help-page.component';
import { HelpAdultSavingsComponent } from './components/help/help-adult-savings/help-adult-savings.component';
import { HelpChildSavingsComponent } from './components/help/help-child-savings/help-child-savings.component';
import { LoadingSpinnerComponent } from './components/general/loading-spinner/loading-spinner.component';
import { InstallInstructionsComponent } from './components/general/install-instructions/install-instructions.component';
import { StagingComponent } from './components/general/staging/staging.component';
import { CookieModule } from 'ngx-cookie';
import { RegistrationAlreadyRegisteredComponent } from './components/registration/registration-already-registered/registration-already-registered.component';
import { RegistrationPlanChoiceComponent } from './components/registration/registration-plan-choice/registration-plan-choice.component';
import { RegistrationNotAvailableComponent } from './components/registration/registration-not-available/registration-not-available.component';
import { TransactionsHomeComponent } from './components/transactions/transactions-home/transactions-home.component';
import { TopUpComponent } from './components/payments/top-up/top-up.component';
import { TopUpThankYouComponent } from './components/payments/top-up-thank-you/top-up-thank-you.component';
import { TopUpErrorComponent } from './components/payments/top-up-error/top-up-error.component';
import { TopUpDeclarationComponent } from './components/payments/top-up-declaration/top-up-declaration.component';
import { DirectDebitSetupComponent } from './components/direct-debit/direct-debit-setup/direct-debit-setup.component';
import { DirectDebitReviewComponent } from './components/direct-debit/direct-debit-review/direct-debit-review.component';
import { DirectDebitDeclarationComponent } from './components/direct-debit/direct-debit-declaration/direct-debit-declaration.component';
import { DirectDebitThankYouComponent } from './components/direct-debit/direct-debit-thank-you/direct-debit-thank-you.component';
import { DirectDebitErrorComponent } from './components/direct-debit/direct-debit-error/direct-debit-error.component';
import { DirectDebitVaryComponent } from './components/direct-debit/direct-debit-vary/direct-debit-vary.component';
import { DirectDebitVaryReviewComponent } from './components/direct-debit/direct-debit-vary-review/direct-debit-vary-review.component';
import { DirectDebitGuaranteeComponent } from './components/direct-debit/direct-debit-guarantee/direct-debit-guarantee.component';
import { DirectDebitViewAllComponent } from './components/direct-debit/direct-debit-view-all/direct-debit-view-all.component';
import { NotificationSummaryComponent } from './components/notifications/notification-summary/notification-summary.component';
import { NotifcationMetaInfoComponent } from './components/notifications/notifcation-meta-info/notifcation-meta-info.component';
import { LinkJISAComponent } from './components/plans/plan-linking/link-jisa/link-jisa.component';
import { LinkPlanSubmittedComponent } from './components/plans/plan-linking/link-plan-submitted/link-plan-submitted.component';
import { MatureCTFComponent } from './components/plans/ctf/mature-ctf/mature-ctf.component';
import { RegisterCTFComponent } from './components/register/register-ctf/register-ctf.component';
import { RegisterAccountComponent } from './components/register/register-account/register-account.component';
import { RegisterAccountStepOneComponent } from './components/register/register-account/register-account-step-one/register-account-step-one.component';
import { RegisterAccountStepOneExtendedComponent } from './components/register/register-account/register-account-step-one-extended/register-account-step-one-extended.component';
import { RegisterAccountStepTwoComponent } from './components/register/register-account/register-account-step-two/register-account-step-two.component';
import { RegisterAccountStepThreeComponent } from './components/register/register-account/register-account-step-three/register-account-step-three.component';
import { RegisterHomeComponent } from './components/register/register-home/register-home.component';
import { RegisterCTFStepOneComponent } from './components/register/register-ctf/register-ctf-step-one/register-ctf-step-one.component';
import { RegisterCTFStepTwoComponent } from './components/register/register-ctf/register-ctf-step-two/register-ctf-step-two.component';
import { RegisterConsentComponent } from './components/register/register-consent/register-consent.component';
import { RegisterSuccessComponent } from './components/register/register-success/register-success.component';
import { RegisterFailureComponent } from './components/register/register-failure/register-failure.component';

import { SharedModule } from './shared/shared-module';
import { NgxBootstrapModule } from './ngx-bootstrap-module';
import { NgxMaskModule } from 'ngx-mask';
import { NgxEchartsModule } from 'ngx-echarts';
import { ToastrModule } from 'ngx-toastr';
import { initializeApp } from 'firebase/app';

import { YbugComponent } from './ybug/ybug.component';

import { GooglePayButtonModule } from '@google-pay/button-angular';
import { CurrencyPipe } from '@angular/common';
import { PageErrorComponent } from './components/general/page-error/page-error.component';

initializeApp(environment.firebase);

@NgModule({
  declarations: [
    AppComponent,
    LogInComponent,
    LogInTwoFactorAuthComponent,
    LogInUserComponent,
    LogInPasswordComponent,
    LogOutComponent,
    AccountProblemComponent,
    VerifyCodeComponent,
    VerifyIdentityComponent,
    ManageAuthnComponent,
    PageNotFoundComponent,
    MenuFooterComponent,
    StripeElementComponent,
    ProfileHomeComponent,
    ProfileChangeMobileNumberComponent,
    ProfileChangeEmailComponent,
    ProfileChangeAddressComponent,
    AddressVerification,
    VerifyEmailComponent,
    ForgottenPasswordComponent,
    ResetPasswordComponent,
    RegistrationHomeComponent,
    NotificationsHomeComponent,
    NotificationDetailComponent,
    NotificationsComponent,
    DocumentsHomeComponent,
    HomeHomeComponent,
    HelpHomeComponent,
    MenuHeaderComponent,
    IsaDetailsComponent,
    JisaDetailsComponent,
    LinkJISAComponent,
    LinkPlanSubmittedComponent,
    CTFDetailsComponent,
    CTFMaturityGraphComponent,
    CTFOverviewComponent,
    CTFDeclarationComponent,
    CTFRequestSubmittedComponent,
    MatureCTFComponent,
    PlanChoiceComponent,
    PlanPerformanceGraphComponent,
    HelpPostComponent,
    HelpPageComponent,
    HelpAdultSavingsComponent,
    HelpChildSavingsComponent,
    LoadingSpinnerComponent,
    InstallInstructionsComponent,
    StagingComponent,
    RegistrationAlreadyRegisteredComponent,
    RegistrationPlanChoiceComponent,
    RegistrationNotAvailableComponent,
    TransactionsHomeComponent,
    TopUpComponent,
    TopUpThankYouComponent,
    TopUpErrorComponent,
    TopUpDeclarationComponent,
    DirectDebitSetupComponent,
    DirectDebitReviewComponent,
    DirectDebitViewAllComponent,
    DirectDebitDeclarationComponent,
    DirectDebitErrorComponent,
    DirectDebitThankYouComponent,
    DirectDebitVaryComponent,
    DirectDebitVaryReviewComponent,
    DirectDebitGuaranteeComponent,
    NotificationSummaryComponent,
    NotifcationMetaInfoComponent,
    RegisterConsentComponent,
    RegisterCTFStepOneComponent,
    RegisterCTFStepTwoComponent,
    RegisterHomeComponent,
    RegisterCTFComponent,
    RegisterAccountComponent,
    RegisterAccountStepOneComponent,
    RegisterAccountStepOneExtendedComponent,
    RegisterAccountStepTwoComponent,
    RegisterAccountStepThreeComponent,
    RegisterSuccessComponent,
    RegisterFailureComponent,
    YbugComponent,
    SideMenuComponent,
    PageErrorComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CookieModule.withOptions(),
    NgxBootstrapModule,
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('global-sw.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    ToastrModule.forRoot({
      maxOpened: 1,
      progressBar: true,
      progressAnimation: 'decreasing',
      preventDuplicates: true,
      closeButton: true,
      timeOut: 5000,
      positionClass: 'toast-top-full-width',
    }),
    GooglePayButtonModule,
  ],
  providers: [
    CurrencyPipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    ...APP_SERVICES,
    ...CTF_SERVICES,
    httpInterceptorProviders,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
