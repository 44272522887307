import { Component, Input, OnInit } from '@angular/core';
import { SavingsPlan } from 'src/app/models/member-portal';

@Component({
    selector: 'app-ctf-overview',
    templateUrl: './ctf-overview.component.html',
    styleUrls: ['./ctf-overview.component.scss'],
    standalone: false
})
export class CTFOverviewComponent implements OnInit {
  @Input() plan: SavingsPlan;
  @Input() valueIncreasePerc?: string;
  @Input() currentMaturityValue?: string;
  @Input() totalContributions?: string;
  @Input() fullView?: boolean;

  constructor() {}

  ngOnInit(): void {}
}
