import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { filter } from 'rxjs';
import { ScriptLoaderService } from './script-loader.service';

@Injectable()
export class GoogleAnalyticsService {
  private gtmId = environment.tracking.gtmContainerId;

  constructor(
    private router: Router,
    private scriptLoader: ScriptLoaderService
  ) {}

  /**
   * initialise GTM datalayer, and load in the gtm script dynamically
   */
  public initialiseGTM() {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    const scriptUrl = `https://www.googletagmanager.com/gtm.js?id=${this.gtmId}`;
    this.scriptLoader.loadScript(scriptUrl).catch((error) => {
      console.error('Error loading GTM script', error);
    });

    // Listen for page router events
    this.listenForRouteChangeAndTrack();
  }

  /**
   * Listens for `NavigationEnd` events to send `page_path` to Google Analytics.
   */
  public listenForRouteChangeAndTrack() {
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event) => this.pushGTMPageView(event.urlAfterRedirects));
  }

  private pushGTMPageView(url: string) {
    window['dataLayer'].push({
      event: 'page_view',
      page_path: url,
    });
  }
}
