<section id="registerHomeTitle" class="my-4 mx-0">
  <h1>You're already registered</h1>
</section>

<section id="registerContent">
  <p>As you've already registered for your account you don't need to do this again.</p>

  <p>
    If you have a Shepherds Friendly Junior ISA you can connect this to your account using your
    existing log in details, and we are working on providing your account access for our other plans
    too.
  </p>
</section>

<section id="registerHeroImage" class="m-2 p-4">
  <div class="flex justify-content-center">
    <img
      src="/assets/images/flower.svg"
      alt="Shepherds Friendly, Simplicity over complexity"
      style="max-height: 300px"
    />
  </div>
</section>

<nav class="fixed-bottom bg-light -lg pb-4" style="display: block">
  <div class="grid gap-2 p-2">
    <app-button text="Log in" buttonStyle="secondary" routerLink="/log-in"></app-button>
  </div>
</nav>
