export class Registrant {
  registrationId: string;
  awaitingAddressVerification: boolean;
  emailAddress: string;
  firstname: string;
  surname: string;
  isMapped: boolean;

  constructor(
    registrationId: string,
    awaitingAddressVerification: boolean,
    emailAddress: string,
    firstname: string,
    surname: string,
    isMapped: boolean
  ) {
    this.registrationId = registrationId;
    this.awaitingAddressVerification = awaitingAddressVerification;
    this.emailAddress = emailAddress;
    this.firstname = firstname;
    this.surname = surname;
    this.isMapped = isMapped;
  }
}
