export class Authorization {
  public access_token: string;
  public token_type: string;
  public expires_in: string;
  public userName: string;
  public issued: string;
  public expires: string;
  public tfaEnabled: boolean;
  public lastLogin: Date;
  public refresh_token: string;
}
