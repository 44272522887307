import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';

import * as Sentry from '@sentry/angular';

Sentry.init({
  environment: environment.production ? 'production' : environment.azure.appLabel,
  dsn: environment.apiKeys.sentryDSN,
  tracePropagationTargets: ['localhost', environment.apiKeys.sfApiURL],
  integrations: [Sentry.browserTracingIntegration()],
  maxValueLength: 1500,
  tracesSampleRate: 1.0,

  // Ignore specific errors
  ignoreErrors: ['InvalidAccessError: Page already has an active payment session.'],
});

const meta = document.querySelector("meta[name='version']") as any;
Sentry.setTag('app.version', meta.content);

(function () {
  window.ybug_settings = { id: environment?.ybugId?.id };
})();

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
