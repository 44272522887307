import { Component, Input } from '@angular/core';
import { VisibilityService } from '@app/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-quick-action-button-list',
  templateUrl: './quick-action-button-list.component.html',
  styleUrls: ['./quick-action-button-list.component.scss'],
  standalone: false,
})
export class QuickActionButtonListComponent {
  @Input() moreLinks: {
    label: string;
    url?: string;
    icon: string;
    onClick?: () => void;
  }[] = [];

  private subscribeUntilDestroyed = new Subscription();

  constructor() {}

  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }
}
