import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  LOCALE_ID,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { EChartsOption, connect, getInstanceByDom, graphic } from 'echarts';
import * as echarts from 'echarts'; // Import echarts to handle the chart instance
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Component({
    selector: 'app-ctf-maturity-graph',
    templateUrl: './ctf-maturity-graph.component.html',
    styleUrls: ['./ctf-maturity-graph.component.css'],
    standalone: false
})
export class CTFMaturityGraphComponent implements AfterViewInit {
  @Input() transactionsSummary;

  @ViewChild('chart', { static: true }) chartElement: ElementRef<HTMLDivElement>;
  private chartInstance: echarts.ECharts; // Store the ECharts instance

  public options: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      confine: true,
      extraCssText: 'z-index: 1000', // ensure the tooltip does not float over either <app-menu-header/footer>
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params: any) => {
        const [firstValue = 0, secondValue = 0] = params.map((param) => param?.value);

        let percentageDifference = (((firstValue - secondValue) / secondValue) * 100).toFixed(2);

        // Check if percentageDifference is Infinity
        if (!isFinite(parseFloat(percentageDifference))) {
          percentageDifference = 'N/A'; // or any other appropriate value or message
        }

        const numericDifference = parseFloat(percentageDifference);

        const svgIcon =
          numericDifference < 0
            ? `<svg style="margin-bottom: 2px;" class="arrow" width="12" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.32645 7.25977L8.50628 0.982265H0.146622L4.32645 7.25977Z" fill="#DC2626" />
                        </svg>`
            : `<svg style="margin-bottom: 2px;" class="arrow" width="12" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.5 0.186523L12.1292 8.71226H0.870835L6.5 0.186523Z" fill="#20C997" />
                        </svg>`;

        return `<strong>${params[0].name}<br>
                        CTF value: ${this.formatCurrency(firstValue)}<br>
                        Contributions: ${this.formatCurrency(secondValue)}<br>
                        Total value: ${svgIcon} ${percentageDifference}%`;
      },
    },
    grid: {
      left: '0',
      right: '1%',
      bottom: '0',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: ['2019', '2020', '2021', '2022', '2023'] /* demo data */,
        axisTick: {
          alignWithLabel: true,
        },
        axisLine: {
          show: false,
        },
      },
    ],
    yAxis: {
      position: 'right',
      axisLabel: {
        formatter: '£{value}',
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.1)', // Change the y-axis grid line color
        },
      },
    },
    series: [
      {
        name: 'Value',
        type: 'line',
        data: [6000, 7000, 7250, 8250, 8250] /* demo data */,
        color: '#F8AB00',
        smooth: true,
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(248,171,0)',
            },
            {
              offset: 1,
              color: 'transparent',
            },
          ]),
        },
      },
      {
        name: 'Contributions',
        type: 'line',
        step: 'end',
        data: [200, 400, 500, 600, 700] /* demo data */,
        color: '#1199D6',
        smooth: false,
      },
    ],
  };

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.initializeChart();
      this.handleResizeEvent();
    });
  }

  private initializeChart(): void {
    const contributionData = this.transactionsSummary.contributionData;
    const planChangesData = this.transactionsSummary.planChangesData;

    if (!contributionData || !planChangesData) {
      console.warn('ctf-maturity-graph: no data was provided');
      return;
    }

    // Extract all possible years
    const years = this.extractYears(contributionData, planChangesData);

    // Assign to x-axis
    this.options.xAxis[0].data = years;

    // Populate the plan changes data
    this.options.series[0].data = this.extractLastPropertyPerYear(years, planChangesData, 'value');

    // Populate the contribution data
    this.options.series[1].data = this.extractLastPropertyPerYear(
      years,
      contributionData,
      'totalAmount'
    );

    // Initialize the chart and store the instance
    this.chartInstance = echarts.init(this.chartElement.nativeElement);
    this.chartInstance.setOption(this.options);
    connect([this.chartInstance]);
  }

  private handleResizeEvent(): void {
    fromEvent(window, 'resize')
      .pipe(throttleTime(100))
      .subscribe(() => this.onResize());
  }

  private onResize(): void {
    if (this.chartInstance) {
      this.chartInstance.resize();
    }
  }

  private extractYears(contributionData, planChangesData): number[] {
    // Extract years from "when" properties in "contributionData"
    const contributionYears = contributionData.map((item) => new Date(item.when).getFullYear());

    // Extract years from "when" properties in "planChangesData"
    const planChangesYears = planChangesData.map((item) => new Date(item.when).getFullYear());

    // Merge and deduplicate the years
    const allYears = [...new Set([...contributionYears, ...planChangesYears])];

    // Sort the years in ascending order
    return allYears.sort((a, b) => a - b);
  }

  private extractLastPropertyPerYear(years, data, propertyName) {
    const lastValuesPerYear = {};

    // Iterate through the data in reverse order
    for (let i = data.length - 1; i >= 0; i--) {
      const item = data[i];
      const year = new Date(item.when).getFullYear();
      const propertyValue = item[propertyName];

      // Check if the year already exists in the object, and if not, set the value
      if (!lastValuesPerYear[year]) {
        lastValuesPerYear[year] = propertyValue;
      }
    }

    // Pad any missing years
    let previousValue = 0;
    for (let i = 0; i < years.length; ++i) {
      const year = years[i];
      const exists = lastValuesPerYear.hasOwnProperty(year);
      if (!exists) {
        // Pad with previous value
        lastValuesPerYear[year] = previousValue;
      } else {
        // Save previous value
        previousValue = lastValuesPerYear[year];
      }
    }

    // Convert the object values to an array
    return Object.values(lastValuesPerYear);
  }

  public formatCurrency(value: number): string {
    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }
}
