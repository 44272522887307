import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MemberProfile, StepOptions } from '@app/models/profile';
import { StorageKey } from '@app/models/security';
import { AuthService, ProfileService } from '@app/services';

type RouteStateData = { memberProfile: MemberProfile };

@Component({
    selector: 'app-profile-change-email',
    templateUrl: './profile-change-email.component.html',
    styleUrls: ['./profile-change-email.component.css'],
    standalone: false
})
export class ProfileChangeEmailComponent implements OnInit {
  public readonly url = {
    HOME: `/home`,
    PROFILE_OPTIONS: `/profile/options`,
  };

  public memberProfile: MemberProfile;
  public changeEmailForm: FormGroup;

  public emailCtrl: AbstractControl;
  public confirmEmailCtrl: AbstractControl;

  public stepOpts = StepOptions;
  public step = this.stepOpts.UPDATE;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    private profileService: ProfileService
  ) {
    this.loadMemberProfileFromRoute();
  }

  ngOnInit(): void {
    this.buildForm();

    this.emailCtrl = this.changeEmailForm.controls['email'];
    this.confirmEmailCtrl = this.changeEmailForm.controls['confirmEmail'];
  }

  /**
   * Caches a copy of the current change into the profile service and proceeds to the password step
   */
  public goToNextStep(): void {
    if (this.changeEmailForm.valid) {
      this.profileService.updatedDetails = {
        emailAddress: this.emailCtrl.value,
      };

      this.step = this.stepOpts.VERIFY;
    }
  }

  /**
   * Prcoeeds to OTP step on successful password entry
   * @param {boolean} [verified]
   */
  public handleVerification(verified: boolean): void {
    if (verified) {
      this.step = this.stepOpts.CONFIRM;
    }
  }

  /**
   * Prcoeeds to complete step on successful OTP code
   * @param {boolean} [verified]
   */
  public handleCode(verified: boolean): void {
    if (verified) {
      this.step = this.stepOpts.COMPLETE;

      // Set local storage username to the updated email ready for re-login
      localStorage.setItem(StorageKey.SF_USERNAME, this.emailCtrl.value);

      // Clear profile cache as canDeactive won't get called once auth tokens are cleared.
      this.profileService.clearCache();

      this.authService.logout();
    }
  }

  /**
   * Builds the form configuration with angulars form builder service
   */
  private buildForm(): void {
    this.changeEmailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      confirmEmail: ['', [Validators.required, Validators.email]],
    });

    this.changeEmailForm.setValidators([this.validateEmailsMatch]);
  }

  /**
   * Validator to ensure both email fields match
   * @param {AbstractControl} [control]
   */
  private validateEmailsMatch(control: AbstractControl): { emailMismatch: boolean } | null {
    return control.value.email === control.value.confirmEmail ? null : { emailMismatch: true };
  }

  /**
   * Retrieves member profile data from the route state, if non exists
   * redirect back to the profile options page.
   */
  private loadMemberProfileFromRoute(): void {
    const state = this.router.getCurrentNavigation()?.extras?.state as RouteStateData;

    if (state) {
      this.memberProfile = state.memberProfile;
    } else {
      this.router.navigate([this.url.PROFILE_OPTIONS]);
    }
  }
}
