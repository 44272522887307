import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { PlansRegisteringFor, Register } from '@app/models/registration';
import { LoginHeaderService, RegistrationService } from '@app/services';
import { catchError, EMPTY, filter, Subscription } from 'rxjs';

const stepOpts: Record<string, string> = {
  PLAN: 'Plan',
  YOUR_DETAILS: 'Details',
  ACCOUNT: 'Account',
  CONFIRM: 'Confirm',
};

@Component({
    selector: 'app-register-account',
    templateUrl: './register-account.component.html',
    styleUrls: ['./register-account.component.scss'],
    standalone: false
})
export class RegisterAccountComponent {
  private subscribeUntilDestroyed = new Subscription();

  public stepOpts = stepOpts;
  public step = this.stepOpts['PLAN'];

  public registerStepOne: any = {};
  public registerStepOneExtended: any = {};
  public registerStepTwo = {};
  public registerStepThree = {};
  public registerConsent = {};

  public stepOneComplete = false;
  public stepOneExtendedComplete = false;
  public stepTwoComplete = false;
  public stepThreeComplete = false;
  public stepConsentComplete = false;

  public plansRegisteringFor: PlansRegisteringFor;

  public readonly url = {
    SUCCESS_PAGE: (email: string, registerId: string) =>
      `/register/success/${btoa(email)}/${registerId}`,
    ERROR_PAGE: `/register/failed`,
  };

  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private loginHeaderService: LoginHeaderService
  ) {}

  ngOnInit(): void {
    this.loginHeaderService.buttonClick$.subscribe(() => {
      this.goOneStepBack();
    });

    this.route.params.subscribe((params) => {
      const planTypeParam = params['type'];
      this.plansRegisteringFor = this.mapParamToEnum(planTypeParam);
    });

    this.subscribeUntilDestroyed.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe((event: any) => {
          if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
            if (event.restoredState) {
              this.goOneStepBack();
            }
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  public register(): void {
    const registerPayload = {
      plansRegisteringFor: this.plansRegisteringFor,
      ...this.registerStepOne,
      ...this.registerStepOneExtended,
      ...this.registerStepTwo,
      ...this.registerStepThree,
      ...this.registerConsent,
    } as Register;

    this.registrationService
      .register(registerPayload)
      .pipe(
        catchError((e) => {
          this.router.navigate([this.url.ERROR_PAGE]);
          return EMPTY;
        })
      )
      .subscribe((result) => {
        if (!result.registered) {
          this.router.navigate([this.url.ERROR_PAGE]);
        } else {
          this.router.navigate([
            this.url.SUCCESS_PAGE(registerPayload.emailAddress, result.registrationId),
          ]);
        }
      });
  }

  public goToStep(step: string): void {
    if (this.stepOpts[step]) {
      this.step = this.stepOpts[step];
      this.updateUrlHash(step);
      this.scrollToTop();
    }
  }

  public goOneStepBack(): void {
    const curStep = window.location.hash.substring(1);
    if (this.step === this.stepOpts[curStep]) {
      this.location.back();
    } else {
      this.step = this.stepOpts[curStep];
    }
    this.scrollToTop();
  }

  public scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  public onUpdateStepOne(value: any): void {
    this.registerStepOne = value;
  }

  public onUpdateStepOneExtended(value: any): void {
    this.registerStepOneExtended = value;
  }

  public onUpdateStepTwo(value: any): void {
    this.registerStepTwo = value;
  }

  public onUpdateStepThree(value: any): void {
    this.registerStepThree = value;
  }

  public onUpdateConsent(value: any) {
    this.registerConsent = value;
  }

  private mapParamToEnum(param: string): PlansRegisteringFor {
    const planValue = Number(param);
    if (!isNaN(planValue) && planValue in PlansRegisteringFor) {
      return planValue as PlansRegisteringFor;
    }
    return PlansRegisteringFor.None;
  }

  private updateUrlHash(step: string) {
    const currentHash = `#${step}`;
    window.location.hash = currentHash;
  }

  public get canContinue(): boolean {
    const stepOneIsRequired = this.showRegisterAccountStepOne;
    const stepOneExtendedIsRequired = this.showRegisterAccountStepOneExtended;

    const stepOneIsComplete = stepOneIsRequired ? this.stepOneComplete : true;
    const stepOneExtendedIsComplete = stepOneExtendedIsRequired
      ? this.stepOneExtendedComplete
      : true;

    return stepOneIsComplete && stepOneExtendedIsComplete;
  }

  public get showRegisterAccountStepOne(): boolean {
    return (
      this.plansRegisteringFor === PlansRegisteringFor.Adult ||
      this.plansRegisteringFor === PlansRegisteringFor.AdultAndChild
    );
  }

  public get showRegisterAccountStepOneExtended(): boolean {
    return (
      this.plansRegisteringFor === PlansRegisteringFor.Child ||
      this.plansRegisteringFor === PlansRegisteringFor.AdultAndChild
    );
  }
}
