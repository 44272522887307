<section class="my-4 mx-0">
  <h1>{{ post?.['title'].rendered }}</h1>
</section>

<section class="my-4 mx-0">
  <div style="margin-bottom: 150px">
    <div class="p-0">
      <img class="p-0" src="{{ post?.small_image_url }}" />
    </div>
    <div class="pt-4 mb-5">
      <span innerHtml="{{ post?.['content'].rendered }}"></span>
    </div>
  </div>
</section>
