<app-content-header [title]="'My investments'"></app-content-header>

<section>
  <div *ngIf="savingsPlanWithPendingTransaction">
    <app-alert
      type="info"
      additionalClass="my-4 md:mt-6 xl:mt-8"
      imageSmallSrc="wallet"
      imageSmallAlt="wallet icon"
      [title]="'Deposit required for ' + savingsPlanWithPendingTransaction.formattedPlanNumber"
      [isButtonOff]="false"
      [button_text]="'Make a deposit'"
      [button_fullWidth]="true"
      [button_small]="false"
      button_customClass="mt-4 lg:w-[340px]"
      (click)="navigateToInitialDeposit(savingsPlanWithPendingTransaction, memberDashboard.member)"
    >
      <p>
        Please make your initial deposit. If you think you have already done this, the payment may
        have been unsuccessful. It`s easy to make a payment, simply click the 'Make a deposit'
        button to get started.
      </p>
    </app-alert>
  </div>

  <div *ngFor="let plan of memberDashboard?.memberPlans?.savingsPlans">
    <app-plan-overview
      (click)="plan.isIssued && navigateToPlan(plan)"
      [plan]="plan"
      [member]="memberDashboard.member"
      [concise]="true"
      [disabled]="!plan.isIssued"
    ></app-plan-overview>
  </div>

  <h2 class="mb-3 text-primary-sf-navy dark:text-sf-white">Plans you are authorised to view</h2>

  <div *ngIf="authorisedSavingsPlanWithPendingTransaction">
    <app-alert
      type="info"
      additionalClass="my-4 md:mt-6 xl:mt-8"
      imageSmallSrc="wallet"
      imageSmallAlt="wallet icon"
      [title]="
        'Deposit required for ' +
        authorisedSavingsPlanWithPendingTransaction.plan.formattedPlanNumber
      "
      [isButtonOff]="false"
      [button_text]="'Make a deposit'"
      [button_fullWidth]="true"
      [button_small]="false"
      button_customClass="mt-4 lg:w-[340px]"
      (click)="
        navigateToInitialDeposit(
          authorisedSavingsPlanWithPendingTransaction.plan,
          memberDashboard.member
        )
      "
    >
      <p>
        Please make your initial deposit. If you think you have already done this, the payment may
        have been unsuccessful. It`s easy to make a payment, simply click the 'Make a deposit'
        button to get started.
      </p>
    </app-alert>
  </div>

  <div *ngFor="let asp of memberDashboard?.authorisedPlans?.authorisedSavingsPlans">
    <app-plan-overview
      (click)="asp.plan.isIssued && navigateToPlan(asp.plan)"
      [plan]="asp.plan"
      [member]="asp.member"
      [concise]="true"
      [disabled]="!asp.plan.isIssued"
    ></app-plan-overview>
  </div>

  <app-survey-prompt
    imageSrc="survey-prompt"
    imageAlt="survey-prompt"
    customClass="mb-4"
    title="Was the log in process straightforward?"
    content="If you have any feedback for us, please let us know."
    [button_text]="'Give Feedback'"
    [button_small]="false"
    [button_fullWidth]="true"
    [feedbackType]="loginFeedbackType"
    [monthsToShow]="3"
  ></app-survey-prompt>
</section>

<div>
  <h2 class="mb-3 text-primary-sf-navy dark:text-sf-white">Quick actions</h2>
  <div class="md:grid md:grid-cols-2 md:auto-rows-fr md:gap-6 mb-4 md:mb-0">
    <app-quick-action-button
      [routerLink]="url.DEPOSIT"
      [quickActionBtnClass]="'my-4 md:my-0'"
      [quickActionBtnIcon]="'icon-make-deposit-min'"
      [quickActionBtnAlt]="'Make a deposit icon'"
      [quickActionBtnText]="'Make a deposit'"
    ></app-quick-action-button>

    <app-quick-action-button
      [quickActionBtnClass]="'my-4 md:my-0'"
      [quickActionBtnIcon]="'save'"
      [quickActionBtnAlt]="'Open a new Junior ISA'"
      [quickActionBtnText]="'Open a new Junior ISA'"
      (click)="goToLink('https://www.shepherdsfriendly.co.uk/junior-isa')"
    ></app-quick-action-button>

    <app-quick-action-button
      [routerLink]="url.CONNECT_JISA"
      fragment="YOUR_DETAILS"
      [quickActionBtnClass]="'my-4 md:my-0'"
      [quickActionBtnIcon]="'key'"
      [quickActionBtnAlt]="'Connect a Junior ISA'"
      [quickActionBtnText]="'Connect a Junior ISA'"
    ></app-quick-action-button>
  </div>
</div>
