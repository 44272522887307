import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';
import { MemberDashboard } from '../models/member-portal/member-dashboard.model';
import { MemberPortalService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class MemberDashboardResolver {
  constructor(private memberPortalService: MemberPortalService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<MemberDashboard> | Observable<any> {
    return this.memberPortalService.getMemberDashboard().pipe(catchError((error) => EMPTY));
  }
}
