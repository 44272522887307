import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

const isProd = environment.azure.appLabel === 'prod';

@Injectable({
  providedIn: 'root',
})
export class YbugService {
  /* Get yBug env variable or return empty string */
  ybugId(): string {
    return environment?.ybugId?.id || '';
  }

  /* Show Ybug if `isProd` is false, and `id` is truthy */
  showYbug(): boolean {
    const id = this.ybugId().trim();
    return !isProd && !!id;
  }
}
