<section *ngIf="plan">
  <div *ngIf="paymentPeriod">
    <app-content-header
      *ngIf="plan"
      [title]="'Set up a new Direct Debit for your ' + plan.planTypePrefix"
      [isButtonOff]="true"
      [subtitle]="plan.formattedPlanNumber"
    ></app-content-header>

    <app-alert
      type="info"
      title="Please enter the details of the bank account you would like to use to pay your Direct Debit below."
      message="The account must be registered in your name or be a joint account you are authorised to make payments from. We cannot accept business account details. For more information, please see our Direct Debit guarantee below."
    ></app-alert>
  </div>

  <app-alert
    *ngIf="directDebitDetailsAPIFailed"
    type="warning"
    additionalClass="my-4"
    iconClass="far fa-times-circle"
    title="Direct Debit details check"
    message="The bank details provided could not be verified at this time."
  ></app-alert>

  <app-alert
    *ngIf="directDebitDetailsVerificationFailed"
    type="warning"
    additionalClass="my-4"
    iconClass="far fa-times-circle"
    title="Direct Debit details check"
    message="The provided bank account details do not appear to be valid."
  ></app-alert>

  <form
    #form="ngForm"
    id="direct-debit-form"
    [formGroup]="directDebitForm"
    (ngSubmit)="onSubmit(form)"
  >
    <div class="sf-card my-4 md:my-6 xl:my-8">
      <div class="mb-4">
        <label for="acName" class="form-label">Account name</label>
        <div class="input-group mb-4">
          <input
            id="acName"
            name="acName"
            formControlName="acName"
            type="text"
            min="0"
            class="form-control"
            [ngClass]="{
              'is-invalid': accountNameCtrl.touched && accountNameCtrl.errors,
            }"
          />
        </div>
      </div>
      <div>
        <div>
          <div class="mb-4">
            <label for="amount" class="form-label">Sort code</label>
            <input
              id="sortCode"
              name="sortCode"
              formControlName="sortCode"
              [mask]="sortCodeMask"
              type="text"
              class="form-control"
              [ngClass]="{
                'is-invalid': sortCodeCtrl.touched && sortCodeCtrl.errors,
              }"
            />
          </div>
          <div class="mb-4">
            <label for="acNumber" class="form-label">Account number</label>
            <input
              id="acNumber"
              name="acNumber"
              formControlName="acNumber"
              type="text"
              class="form-control"
              min="0"
              maxlength="8"
              [ngClass]="{
                'is-invalid': accountNumberCtrl.touched && accountNumberCtrl.errors,
              }"
            />
          </div>
        </div>
      </div>

      <!-- 30/11/2022 - Request annual option to be removed, so no need to show this element. Set form 'period' field to default as 12-->
      <!-- <div class="mb-2">
                              <label for="period" class="form-label"
                                  >Would you like to pay monthly or
                                  annually?</label
                              >
                              <div
                                  class="btn-group flex"
                                  btnRadioGroup
                                  formControlName="period"
                                  (ngModelChange)="
                                      paymentPeriodChange($event)
                                  "
                              >
                                  <label
                                      class="btn btn-secondary"
                                      btnRadio="12"
                                      tabindex="0"
                                      role="button"
                                      >Monthly</label
                                  >
                                  <label
                                      class="btn btn-secondary"
                                      btnRadio="1"
                                      tabindex="0"
                                      role="button"
                                      >Annual</label
                                  >
                              </div>
                          </div> -->

      <div class="mb-4" *ngIf="isMonthly || isAnnual">
        <label for="dayDue" class="form-label">
          Which day of the month would you like to pay on?
        </label>
        <select id="dayDue" name="dayDue" formControlName="dayDue" class="form-select">
          <option value="" disabled class="dark:text-sf-white">Please select</option>
          <option value="1">1</option>
          <option value="8">8</option>
          <option value="16">16</option>
          <option value="24">24</option>
        </select>
      </div>

      <div class="mb-4" *ngIf="isAnnual">
        <label for="periodDue" class="form-label">
          Which month of the year would you like to pay on?
        </label>
        <select name="periodDue" formControlName="periodDue" class="form-select">
          <option value="" disabled class="dark:text-sf-white">Please select</option>
          <option value="1">January</option>
          <option value="2">February</option>
          <option value="3">March</option>
          <option value="4">April</option>
          <option value="5">May</option>
          <option value="6">June</option>
          <option value="7">July</option>
          <option value="8">August</option>
          <option value="9">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
      </div>
      <fieldset class="mb-4" [disabled]="!hasPaymentPeriod">
        <label for="amount" class="form-label">Amount (£)</label>
        <div class="input-group">
          <input
            id="amount"
            name="amount"
            formControlName="amount"
            type="text"
            min="0"
            [step]="plan.directDebitDivisibleAmount"
            class="form-control"
            placeholder="£"
            [ngClass]="{
              'is-invalid': amountCtrl.touched && amountCtrl.errors,
            }"
          />
        </div>

        <div *ngIf="amountCtrl.touched && amountCtrl.errors">
          <app-validation-message
            *ngIf="
              amountCtrl.errors['rangeValueToBig'] ||
              amountCtrl.errors['rangeValueToSmall'] ||
              amountCtrl.errors['valueNotDivisible']
            "
            message="Please enter the amount you'd like to
                                      pay each month. This must be a minimum
                                      of £{{ minMaxValues.min }}, up to a
                                      maximum of £{{ minMaxValues.max }}, and
                                      a multiple of £{{ plan.directDebitDivisibleAmount }}."
          ></app-validation-message>
        </div>
      </fieldset>

      <app-checkbox
        [additionalClass]="'py-4'"
        formControlName="payersName"
        label="I confirm the bank account is held in my name"
        hasBorderTop="true"
      />

      <app-checkbox
        [additionalClass]="'pt-4'"
        formControlName="allowed"
        label="I confirm Direct Debits are allowed from this account"
        hasBorderTop="true"
      />
    </div>

    <app-direct-debit-guarantee>
      <app-button
        text="Review Direct Debit"
        buttonStyle="secondary"
        [noBorder]="true"
        [disabled]="!form.valid"
        [isSubmitButton]="true"
      ></app-button>
    </app-direct-debit-guarantee>
  </form>
</section>
