import { Directive, Input, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { FeatureFlagService } from '@app/services';

/**
 * Directive that conditionally renders based on a feature flag.
 *
 * This directibe is to be used within components, in order to cut down on code for simple feature flag checks.
 *
 * @example
 * Usage:
 * ```html
 * <div *appFeatureFlag="'appNewFeature'">
 *   This content is only visible if the 'appNewFeature' flag is enabled.
 * </div>
 * ```
 *
 * @example
 * Using If/Else:
 * ```html
 * <div *appFeatureFlag="'appNewFeature'; else elseBlock">
 *   This content is visible if the feature flag is enabled.
 * </div>
 *
 * <ng-template #elseBlock>
 *   <p>This is the fallback content when the feature flag is disabled.</p>
 * </ng-template>
 * ```
 */
@Directive({
  selector: '[appFeatureFlag]',
  standalone: false,
})
export class FeatureFlagDirective implements OnInit {
  @Input('appFeatureFlag') featureName: string;
  @Input('appFeatureFlagElse') elseTemplate?: TemplateRef<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagService: FeatureFlagService
  ) {}

  async ngOnInit() {
    this.viewContainer.clear();

    try {
      const enabled = await this.featureFlagService.isEnabled(this.featureName);
      this.viewContainer.clear();
      if (enabled) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else if (this.elseTemplate) {
        this.viewContainer.createEmbeddedView(this.elseTemplate);
      }
    } catch (error) {
      console.error('Error retrieving feature flag', error);
      this.viewContainer.clear();
    }
  }
}
