import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FeedbackType } from '@app/models/feedback';
import { StorageKey } from '@app/models/security';
import { FeedbackService, ThemeService } from '@app/services';
import { HashUtility } from '@app/shared/utilities/hash.utility';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-survey-prompt',
  templateUrl: './survey-prompt.component.html',
  styleUrls: ['./survey-prompt.component.scss'],
})
export class SurveyPromptComponent {
  @Input() isSurveyVisible: boolean = true;
  @Input() imageSrc: string;
  @Input() imageAlt: string;
  @Input() title: string;
  @Input() content: string;

  @Input() feedbackType: FeedbackType;
  @Input() customClass: string;
  @Input() monthsToShow?: number;
  @Input() additionalData?: any;

  @Input() button_text: string;
  @Input() button_style: string = 'primary';
  @Input() button_small: boolean = true;
  @Input() button_alignRight: boolean = false;
  @Input() button_noBorder: boolean = true;
  @Input() button_isShadow: boolean = true;
  @Input() button_customClass: string;
  @Input() button_isSubmit: boolean = false;
  @Input() button_icon: string;
  @Input() button_fullWidth: boolean;

  public svg: string;

  private id: string;

  private subscribeUntilDestroyed = new Subscription();

  constructor(private themeService: ThemeService, private feedbackService: FeedbackService) {}

  public ngOnInit(): void {
    // Generate a survey ID from hashing the URL
    this.id = HashUtility.hashDJB2(this.feedbackType);

    this.subscribeUntilDestroyed = this.themeService.mode$.subscribe(() => this.processSVG());

    this.isSurveyVisible = this.shouldDisplay();
  }

  public ngOnDestroy(): void {
    this.subscribeUntilDestroyed.unsubscribe();
  }

  public close(): void {
    this.hide();
    this.scheduleNextShow();
  }

  private hide(): void {
    this.isSurveyVisible = false;
  }

  public openSurveyPromptUrl(): void {
    this.close();
    this.feedbackService.navigateToFeedbackUrl(this.feedbackType, this.additionalData);
  }

  private scheduleNextShow(): void {
    if (this.monthsToShow) {
      const currentDate = new Date();
      const nextShowDate = new Date(currentDate);
      nextShowDate.setMonth(currentDate.getMonth() + this.monthsToShow);

      // Correct for the edge case where the month does not have the target day
      if (nextShowDate.getDate() < currentDate.getDate()) {
        nextShowDate.setDate(0); // Move to the last day of the previous month
      }

      localStorage.setItem(`${StorageKey.SF_SURVEY}_${this.id}`, nextShowDate.getTime().toString());
    }
  }

  public shouldDisplay(): boolean {
    if (!this.monthsToShow || this.monthsToShow <= 0) {
      return true;
    }

    const nextShowTimestampStr = localStorage.getItem(`${StorageKey.SF_SURVEY}_${this.id}`);

    if (!nextShowTimestampStr) {
      return true;
    }

    const now = new Date().getTime();
    const nextShowDate = Number(nextShowTimestampStr);

    return now >= nextShowDate;
  }

  /*
   * Processes an SVG image using the theme service's colour conversion.
   */
  public processSVG(): void {
    this.themeService
      .processSVGFromImageUrl(`/assets/images/${this.imageSrc}.svg`)
      .then((convertedSVG) => {
        this.svg = convertedSVG;
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
